import DatePicker from "react-datepicker";
import { Button } from "@mui/material";
import "react-datepicker";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOpeningBalance,
  setOpeningBalance,
  setOpening,
} from "../../../../redux/features/OpeningBalance/openingBalance";
import BalanceTable from "./BalanceTable";
import OpTable from "./OpTable";
import BtnLoader from "../../../AppForm/BtnLoader";

function BalanceSheet() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { data, openBalance, opening } = useSelector(
    (state) => state?.openingBalance
  );
  let ope = "BALANCE";
  const [tData, setBankData] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });
  const [child, setChild] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch(setOpeningBalance("table"));
      dispatch(
        fetchOpeningBalance({
          ope: ope,
          fromDate: formattedFromDate,
          toDate: formattedToDate,
        })
      );
    }, 2000);
  };

  const formattedFromDate = moment(tData.fromDate).format(
    "ddd DD-MMM-YY/HH:mm"
  );

  const formattedToDate = moment(tData.toDate).format("ddd DD-MMM-YY/HH:mm");
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      dispatch(setOpening("opb"));
    } else {
      dispatch(setOpening(""));
    }
  };

  return (
    <div className="Full_height" style={{ minHeight: "100vh" }}>
      {openBalance === "form" && child !== "child" && (
        <div className="manual_invoices  mx-auto">
          <div className="manual_invoices_header">
            <div>Balance Sheet</div>
          </div>
          <div className="row py-5 px-2 px-md-4 align-items-end">
            <form
              className="container justify-content-center align-items-center py-3"
              onSubmit={handleSubmit}
            >
              <div className="d-flex gap-4">
                <div className="mb-4  w-50">
                  {/* <label htmlFor="fromDate" className="form-label">
                From
              </label> */}
                  <DatePicker
                    placeholderText="From"
                    selected={tData?.fromDate}
                    onChange={(date) => {
                      setBankData((prev) => ({
                        ...prev,
                        fromDate: date,
                      }));
                    }}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    className="date_picker"
                  />
                </div>
                <div className="mb-4 w-50">
                  {/* <label htmlFor="toDate" className="form-label">To</label> */}
                  <DatePicker
                    placeholderText="To"
                    selected={tData?.toDate}
                    onChange={(date) => {
                      setBankData((prev) => ({
                        ...prev,
                        toDate: date,
                      }));
                    }}
                    dateFormat="dd-MM-yyyy"
                    minDate={tData?.fromDate}
                    maxDate={new Date()}
                    className="date_picker"
                  />
                </div>
              </div>

              <div className="checkbox-rect d-flex justify-content-center form-check mb-4 mt-5">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="checkbox"
                  onClick={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="checkbox">
                  Opening Balance
                </label>
              </div>
              <div className="d-flex justify-content-center mt-4">
                {!loading ? (
                  <div className="text-center py-4">
                    <button
                      type="submit"
                      className="button_style px-4 "
                      disabled={loading}
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  <div className="text-center py-4">
                    <button className="button_style px-4">
                      <BtnLoader />
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      {openBalance === "table" &&
        child !== "child" &&
        opening !== "opb" &&
        !loading && (
          <BalanceTable
            accountData={data}
            fromDate={tData.fromDate}
            toDate={tData.toDate}
            dropdownValue={ope}
          />
        )}
      {openBalance === "table" &&
        child !== "child" &&
        opening === "opb" &&
        !loading && (
          <OpTable
            accountData={data}
            fromDate={tData.fromDate}
            toDate={tData.toDate}
            dropdownValue={ope}
          />
        )}
      {/* {child==="child" && (
        <BalanceParent accData={accData} accountData={data}/>
      )} */}
    </div>
  );
}

export default BalanceSheet;
