import {
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
// import {
//   asyncGetFlightGroupData,
//   asyncGetOriganListData,
// } from "../../../../../redux/features/promotion/FlightGroupsSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { debounce } from "lodash";
import {
  asyncGetFlightGroupData,
  asyncGetOriganListData,
} from "../../../../../redux/features/promotion/FlightGroupsSlice";
import OriginList from "../Flight/pax/OriginList";
// import OriginList from "./pax/OriginList";

const SegmentCredit = ({ values, setFieldValue, auto_manual }) => {
  const [drp, setdrp] = useState("");
  const dispatch = useDispatch();

  const { origanList } = useSelector((state) => state.flightGroup);

  useEffect(() => {
    dispatch(asyncGetFlightGroupData());
  }, [dispatch]);

  const debouncedHandleChange = debounce((e) => {
    dispatch(asyncGetOriganListData(e.target.value));
  }, 2000);
  return (
    <div className="section_box">
      <div className="h5">Segment</div>
      <FieldArray
        name="segmentValues"
        render={(arrayHelpers) => (
          <div>
            {values?.segmentValues?.map(
              (segment, index) => (
                console.log("segment",segment),
                (
                  <>
                    <div key={index}>
                      <div className="row align-items-end my-4 ">
                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <TextField
                            name={`segmentValues.${index}.t_class`}
                            value={values.segmentValues[index].t_class}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue(
                                `segmentValues.${index}.t_class`,
                                value.toUpperCase()
                              );
                            }}
                            autoComplete="off"
                            id={`segmentValues.${index}.t_class`}
                            label="Ticket Class"
                            variant="standard"
                            className="w-100"
                            required
                            disabled={true}
                          />
                        </div>
                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <TextField
                            name={`segmentValues.${index}.air_code`}
                            value={values.segmentValues[index].air_code}
                            onChange={(e) => {
                              const value = e.target.value.toUpperCase();
                              if (/^[A-Z0-9]{0,2}$/.test(value))
                                setFieldValue(
                                  `segmentValues.${index}.air_code`,
                                  value
                                );
                            }}
                            autoComplete="off"
                            id={`segmentValues.${index}.air_code`}
                            label="Airline Code"
                            variant="standard"
                            className="w-100"
                            required
                            disabled={true}
                          />
                        </div>
                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <TextField
                            name={`segmentValues.${index}.air_no`}
                            value={values.segmentValues[index].air_no}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue(
                                `segmentValues.${index}.air_no`,
                                value.toUpperCase()
                              );
                            }}
                            autoComplete="off"
                            id={`segmentValues.${index}.air_no`}
                            label="Flight Number"
                            variant="standard"
                            className="w-100"
                            required
                            disabled={true}
                          />
                        </div>
                        <div
                          className="position-relative col-4  mt-3 mt-md-0"
                          style={{ width: "10%" }}
                        >
                          <FormControl
                            variant="standard"
                            className="w-100"
                            // onClick={() => {
                            //   setdrp(`origin${index}`);
                            // }}
                            required
                          >
                            <InputLabel
                              htmlFor={`segmentValues.${index}?.origin`}
                            >
                              Origin
                            </InputLabel>
                            <Input
                              autoComplete="off"
                              id={`segmentValues.${index}?.origin`}
                              value={values?.segmentValues[
                                index
                              ]?.origin?.toUpperCase()}
                              name={`segmentValues.${index}.origin`}
                              onFocus={(event) => {
                                event.target.select();
                              }}
                              onChange={(e) => {
                                setdrp(`origin${index}`);
                                setFieldValue(
                                  `segmentValues.${index}.origin`,
                                  e.target.value
                                );
                                debouncedHandleChange(e);
                              }}
                              disabled={true}
                              endAdornment={
                                <InputAdornment position="end">
                                  {values.segmentValues[index]?.origin !==
                                    "" && (
                                    <IconButton
                                      onClick={() => {
                                        setFieldValue(
                                          `segmentValues.${index}.origin`,
                                          ""
                                        );
                                      }}
                                    >
                                      <MdClose />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {drp === `origin${index}` ? (
                            <ClickAwayListener onClickAway={() => setdrp("")}>
                              <div>
                                <OriginList
                                  origanList={origanList}
                                  name={`segmentValues.${index}.origin`}
                                  setFieldValue={setFieldValue}
                                  index={index}
                                  setdrp={setdrp}
                                />
                              </div>
                            </ClickAwayListener>
                          ) : null}
                        </div>

                        <div
                          className=" position-relative col-4  mt-3 mt-md-0"
                          style={{ width: "10%" }}
                        >
                          <FormControl
                            variant="standard"
                            className="w-100"
                            // onClick={() => {
                            //   setdrp(`destination${index}`);
                            // }}
                            required
                          >
                            <InputLabel
                              htmlFor={`segmentValues.${index}?.destination`}
                            >
                              Destination
                            </InputLabel>
                            <Input
                              autoComplete="off"
                              id={`segmentValues.${index}?.destination`}
                              value={values?.segmentValues[
                                index
                              ]?.destination?.toUpperCase()}
                              name={`segmentValues.${index}.destination`}
                              onFocus={(event) => {
                                event.target.select();
                              }}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue(
                                  `segmentValues.${index}.destination`,
                                  value?.toUpperCase()
                                );
                                setdrp(`destination${index}`);

                                debouncedHandleChange(e);
                              }}
                              disabled={true}
                              endAdornment={
                                <InputAdornment position="end">
                                  {values.segmentValues[index]?.destination !==
                                    "" && (
                                    <IconButton
                                      onClick={() => {
                                        setFieldValue(
                                          `segmentValues.${index}.destination`,
                                          ""
                                        );
                                      }}
                                    >
                                      <MdClose />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {drp === `destination${index}` ? (
                            <ClickAwayListener onClickAway={() => setdrp("")}>
                              <div>
                                <OriginList
                                  origanList={origanList}
                                  name={`segmentValues.${index}.destination`}
                                  setFieldValue={setFieldValue}
                                  index={index}
                                  setdrp={setdrp}
                                />
                              </div>
                            </ClickAwayListener>
                          ) : null}
                        </div>

                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <ReactDatePicker
                            placeholderText="Dep Date"
                            autoComplete="off"
                            className="date_picker"
                            id={`segmentValues.${index}.flt_date`}
                            minDate={new Date()}
                            disabled={true}
                            dateFormat="dd-MM-yyyy"
                            selected={values.segmentValues[index].flt_date}
                            onChange={(date) => {
                              setFieldValue(
                                `segmentValues.${index}.flt_date`,
                                date
                              );
                              setFieldValue(
                                `segmentValues.${index}.arr_date`,
                                date
                              );
                            }}
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </div>

                        <div className="col-sm-1 datepickerstyle custom-datepicker-container">
                          <DatePicker
                            selected={
                              values.segmentValues[index]?.dep_time
                                ? new Date(
                                    `1970-01-01T${values.segmentValues[index]?.dep_time}`
                                  )
                                : null
                            }
                            // disabled={singleFlightData?.flt_group_id}
                            autoComplete="off"
                            name={`segmentValues.${index}.dep_time`}
                            value={
                              values?.segmentValues[index]?.dep_time
                                ? new Date(
                                    `1970-01-01T${values.segmentValues[index]?.dep_time}`
                                  )
                                : null
                            }
                            className="date_picker"
                            calendarClassName="custom-datepicker"
                            onChange={(e) => {
                              const dateString = e;
                              const dateObject = new Date(dateString);

                              const hours = dateObject.getHours();
                              const minutes = dateObject.getMinutes();

                              const formattedHours = hours
                                .toString()
                                .padStart(2, "0");
                              const formattedMinutes = minutes
                                .toString()
                                .padStart(2, "0");

                              const timeString = `${formattedHours}:${formattedMinutes}`;

                              setFieldValue(
                                `segmentValues.${index}.dep_time`,
                                timeString
                              );
                              setFieldValue(
                                `segmentValues.${index}.arr_time`,
                                timeString
                              );
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={5}
                            placeholderText="Dep Time"
                            timeCaption="Time"
                            dateFormat="HH:mm" // Use "HH:mm" for 24-hour format
                            timeFormat="HH:mm" // Use "HH:mm" for 24-hour format
                          />
                        </div>

                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <ReactDatePicker
                            placeholderText="Arr Date"
                            autoComplete="off"
                            className="date_picker"
                            id={`segmentValues.${index}.arr_date`}
                            minDate={
                              values.segmentValues[index].flt_date || new Date()
                            }
                            dateFormat="dd-MM-yyyy"
                            disabled={true}
                            selected={values.segmentValues[index].arr_date}
                            onChange={(date) => {
                              setFieldValue(
                                `segmentValues.${index}.arr_date`,
                                date
                              );
                            }}
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </div>
                        <div className="col-sm-1 datepickerstyle custom-datepicker-container">
                          <DatePicker
                            selected={
                              values.segmentValues[index]?.arr_time
                                ? new Date(
                                    `1970-01-01T${values.segmentValues[index]?.arr_time}`
                                  )
                                : null
                            }
                            // disabled={singleFlightData?.flt_group_id}
                            autoComplete="off"
                            name={`segmentValues.${index}.arr_time`}
                            value={
                              values?.segmentValues[index]?.arr_time
                                ? new Date(
                                    `1970-01-01T${values.segmentValues[index]?.arr_time}`
                                  )
                                : null
                            }
                            className="date_picker"
                            calendarClassName="custom-datepicker"
                            onChange={(e) => {
                              const dateString = e;
                              const dateObject = new Date(dateString);

                              const hours = dateObject.getHours();
                              const minutes = dateObject.getMinutes();

                              const formattedHours = hours
                                .toString()
                                .padStart(2, "0");
                              const formattedMinutes = minutes
                                .toString()
                                .padStart(2, "0");

                              const timeString = `${formattedHours}:${formattedMinutes}`;

                              setFieldValue(
                                `segmentValues.${index}.arr_time`,
                                timeString
                              );
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={5}
                            placeholderText="Arr Time"
                            timeCaption="Time"
                            dateFormat="HH:mm" // Use "HH:mm" for 24-hour format
                            timeFormat="HH:mm" // Use "HH:mm" for 24-hour format
                          />
                        </div>
                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <TextField
                            id={`segmentValues.${index}.seat_b`}
                            value={values.segmentValues[index].seat_b}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue(
                                `segmentValues.${index}.seat_b`,
                                value?.toUpperCase()
                              );
                            }}
                            autoComplete="off"
                            label="Seat/Bagage"
                            variant="standard"
                            className="w-100"
                            disabled={true}
                          />
                        </div>

                        <div className="col-1 mb-4">
                          <div className="checkbox-rect col-6 col-md-3">
                            <input
                              type="checkbox"
                              id={`checkbox-rect-${index}`}
                              name={`segmentValues.${index}.seg_status`}
                              checked={values.segmentValues[index].seg_status}
                              onChange={(e) =>
                                setFieldValue(
                                  `segmentValues.${index}.seg_status`,
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              htmlFor={`checkbox-rect-${index}`}
                              className="h6"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              )
            )}
          </div>
        )}
      />
    </div>
  );
};
export default SegmentCredit;
