import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";
import { setBookButtonDisable } from "../home/FlightSearchSlice";

const initialState = {
  bookingData: [],
  bookingLoading: false,
  bookingError: null,
  error: null
};

// getting filter airport
export const asyncPostFligthBooking = createAsyncThunk(
  "asyncPostFligthBooking/post",
  async ({booking, navigate}, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/flight-booking`,
        booking
      );
      navigate(`/booking-details/${response.data.data?.book_id}`, {
        state: {
          type: "flight",
        },
      });
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);
export const asyncupdateFlightGroupBookeRequestFlag = createAsyncThunk(
  "asyncupdateFlightGroupBookeRequestFlag/patch",
  async ({ id ,body}, { rejectWithValue }) => {
    try {
      const response = await NODEURL.patch(`/api/v1/b2b/flight-booking-requestflag/${id}`,body)
      return response?.data
    } catch (error) {
      rejectWithValue(error)
    }
  }
)

export const fligthBookingSlice = createSlice({
  name: "fligthBookingSlice",
  initialState,

  reducers: {
    setFligthBookingEmpty(state, payload) {
      state.bookingData = [];
      state.bookingLoading = false;
      state.bookingError = null;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncPostFligthBooking.pending, (state, { payload }) => {
      state.bookingLoading = true;
      state.error = null;
    });
    builder.addCase(asyncPostFligthBooking.fulfilled, (state, { payload }) => {
      state.bookingLoading = false;
      state.error = null;
      state.bookingData = payload;
    });
    builder.addCase(asyncPostFligthBooking.rejected, (state, { payload }) => {
      state.bookingLoading = false;
      state.error = payload;
    });
  },
});

export const {setFligthBookingEmpty} = fligthBookingSlice.actions;

export default fligthBookingSlice.reducer;
