import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../../CustomDrp";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetAllBankandCashData,
  asyncGetBankAndCashStament,
  setChangeBankandCash,
} from "../../../../redux/features/Finance/Account/ChartOfAccountSlice";
import { dropMenuProp } from "../../Vouchers/VoucherEntry/helper";
import BankAndCashStatements from "./BankAndCashStatements";
import { asyncGetAdmin, asyncGetNewAgencyReg } from "../../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import BtnLoader from "../../../AppForm/BtnLoader";

function BankCash() {
  const dispatch = useDispatch();
  const { allBankAndCash, changestatement, getBankandCashtStamentData } =
    useSelector((state) => state.chartAccount);
  const { searchAgency, getAdminData } = useSelector(
    (state) => state.newAgency
  );
  const [bankData, setBankData] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    voucherTypeId: [1, 2, 3],
    acc_id: [],
  });
  useEffect(() => {
    dispatch(asyncGetAllBankandCashData());
    dispatch(asyncGetNewAgencyReg());
    dispatch(asyncGetAdmin({ id: 1 }));
  }, []);
  const [message, setMessage] = useState(false);
  const handleGenerateReport = async () => {
    let data = {
      ...bankData,
    };
    const resultAction = await dispatch(asyncGetBankAndCashStament(data));

    // Check if the action fulfilled or rejected
    if (asyncGetBankAndCashStament.fulfilled.match(resultAction)) {
      setMessage(true);
      setTimeout(() => {
        dispatch(setChangeBankandCash("BandAndCash"));
        setMessage(false);
      }, 3000);

      // You can handle the success case here
    } else if (asyncGetBankAndCashStament.rejected.match(resultAction)) {
      setMessage(true);
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    }
  };
  return (
    <div className="Full_height">
      {changestatement === "form" && (
        <div className="manual_invoices  mx-auto">
          <div className="manual_invoices_header">
            <div>Bank / Cash Transactions</div>
          </div>
          <div className="row py-3 pb-5 px-2 px-md-4 align-items-end">
            <div className="col-6 col-md-4 mt-4 mt-md-0">
              <ReactDatePicker
                placeholderText="From"
                selected={bankData?.fromDate}
                onChange={(date) => {
                  setBankData((prev) => ({
                    ...prev,
                    fromDate: date,
                  }));
                }}
                maxDate={new Date()}
                className="date_picker"
              />
            </div>
            <div className="col-6 col-md-4 mt-5 mt-md-0 ">
              <ReactDatePicker
                placeholderText="To"
                selected={bankData?.toDate}
                onChange={(date) => {
                  setBankData((prev) => ({
                    ...prev,
                    toDate: date,
                  }));
                }}
                minDate={bankData?.fromDate}
                maxDate={new Date()}
                className="date_picker"
              />
            </div>
            <div className="col-6 col-md-4 mt-5 mt-md-0 ">
              <FormControl variant="standard" className="w-100" required>
                <InputLabel id="Select_Creditor_Account">
                  Cash and Bank
                </InputLabel>
                <Select
                  labelId="Select_Creditor_Account"
                  id="Select_Creditor_Account"
                  name="creditor_account"
                  // value={values?.creditor_account}
                  onChange={(e) => {
                    let value = e.target.value;
                    const accId = allBankAndCash
                      ?.filter((flt) => {
                        const splitValue = value?.split("-");

                        if (splitValue?.at(1) === "SELECT ALL") {
                          return flt;
                        } else {
                          return (
                            Number(flt?.acc_id) === Number(splitValue?.at(0))
                          );
                        }
                      })
                      .map((item) => item?.acc_id);
                    setBankData((prev) => ({
                      ...prev,
                      acc_id: accId,
                    }));
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        minWidth: "15%", // Setting min-width for the dropdown itself
                        maxWidth: "auto", // Allowing max-width to be auto
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  {[
                    { acc_id: "0", acc_title: "SELECT ALL" },
                    ...allBankAndCash,
                  ]?.map((item, index) => (
                    <MenuItem
                      value={`${item.acc_id}-${item.acc_title}`}
                      sx={{ bgcolor: "white", m: 1 }}
                      key={index}
                    >
                      <span
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        {item.acc_title}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* button */}
            <div className="container py-4">
              {!message ? (
                <div className="text-center py-4">
                  <button
                    className="button_style px-4"
                    onClick={handleGenerateReport}
                    disabled={!bankData?.acc_id?.length}
                  >
                    Generate Report
                  </button>
                </div>
              ) : (
                <div className="text-center py-4">
                  <button className="button_style px-4">
                    <BtnLoader />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {changestatement === "BandAndCash" && (
        <BankAndCashStatements
          setBankData={setBankData}
          bankData={bankData}
          data={getBankandCashtStamentData}
          report="form"
          text="Bank and Cash"
          getAdminData={getAdminData?.logo}
        />
      )}
    </div>
  );
}

export default BankCash;
