import { green } from "@mui/material/colors";
import React, { useState } from "react";
import { GrPrint } from "react-icons/gr";
import { apiUrl } from "../../Utils/configUrl";
import { CurrencyCode, CurrencyExchange } from "../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import { Col } from "react-bootstrap";
import PaxRecord from "./PaxRecord";

const Invoice = ({
  booking,
  agencyData,
  user,
  bankDetails,
  newAgencyRegData,
  agentGroup,
  supplierRulesData,
}) => {
  const [invoiceNumber, setinvoiceNumber] = useState(
    booking?.booking?.FlightBookingModels &&
      booking?.booking?.FlightBookingModels[0]?.pax_tables?.at(0)?.inv_number
  );
  const agent_grp = agentGroup?.find(
    (item) => item.agent_grp_id === agencyData?.agent_grp_id
  );
  const totalPrice =
    booking?.booking?.FlightBookingModels &&
    booking?.booking?.FlightBookingModels[0]?.pax_tables?.reduce(
      (acc, curr) => acc + curr?.ttl_agt_pay_d_cur,
      0
    );

  const [total, setTotal] = useState({
    baseDfltC:
      booking?.booking?.FlightBookingModels &&
      booking?.booking?.FlightBookingModels[0]?.pax_tables?.at(0)
        ?.ttl_agt_pay_d_cur,
    baseSlctC:
      booking?.booking?.FlightBookingModels &&
      booking?.booking?.FlightBookingModels[0]?.pax_tables?.at(0)
        ?.ttl_agt_pay_s_cur,
  });
  const mainAgency = newAgencyRegData?.filter((item) => item?.agent_id === 1);

  return (
    <div className="justify-content-center mt-4 px-5">
      <div className="my-5 px-2">
        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
          {mainAgency?.map((agency, index) => (
            <>
              <div>
                <img
                  src={`${apiUrl}/${agency?.logo}`}
                  alt="travel-agency"
                  style={{ width: "300px", height: "100px" }}
                />
              </div>

              <div className="d-flex">
                <div className="d-flex flex-column " style={{ width: 300 }}>
                  <h4 className="theme-text-color" style={{ fontSize: "20px" }}>
                    Address
                  </h4>
                  <span
                    className="booktablebody"
                    style={{ fontSize: "20px", color: "#000000" }}
                  >
                    {agency?.address?.split("]")[0]}
                  </span>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{
                    width: 300,
                    borderLeft: "2px solid black",
                    paddingLeft: "20px",
                  }}
                >
                  <h4 className="theme-text-color" style={{ fontSize: "20px" }}>
                    Phone
                  </h4>

                  <div className="d-flex flex-column">
                    <span
                      className="booktablebody"
                      style={{ fontSize: "20px", color: "#000000" }}
                    >
                      {" "}
                      {agency?.mobile_no}
                    </span>
                    <span
                      className="my-2 booktablebody"
                      style={{ fontSize: "20px", color: "#000000" }}
                    >
                      {agency?.email}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>

        <div
          className="d-flex justify-content-between px-4 py-5  border-bottom"
          style={{ color: "#000000" }}
        >
          <div className="d-flex flex-column ">
            <div className="d-flex bg_primary align-items-center  text-white p-2 rounded mb-2">
              {" "}
              <h4 className="">Invoice To:</h4>
            </div>

            <span
              className="booktablebody"
              style={{ fontSize: "20px", color: "#000000" }}
            >
              {agencyData?.agent_name}
            </span>
            <span
              className="my-2 booktablebody"
              style={{ fontSize: "20px", color: "#000000" }}
            >
              {agencyData?.address?.split("]")[0]}
            </span>
            <span
              className="booktablebody"
              style={{ fontSize: "20px", color: "#000000" }}
            >
              {agencyData?.mobile_no}
            </span>
          </div>

          <div className="" style={{ width: 400 }}>
            <div className="d-flex bg_primary align-items-center  text-white p-2 rounded mb-2">
              {" "}
              <h4 className="">Service Invoice:</h4>
            </div>

            <table className="table table-bordered border-dark align-items-center fixed">
              <thead
                className="bg_primary text-white "
                style={{ fontSize: "20px" }}
              >
                <tr>
                  <th scope="col">Invoice Number</th>
                  <th scope="col">Invoice Date</th>
                </tr>
              </thead>
              <tbody
                className="text-dark "
                style={{ fontSize: "20px", color: "#000000" }}
              >
                <tr>
                  <td style={{ width: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      {booking?.booking?.FlightBookingModels &&
                        booking?.booking?.FlightBookingModels[0]?.pax_tables?.map(
                          (invNumber) => (
                            <span
                              style={{
                                cursor: "pointer",
                                textDecoration: `${
                                  invNumber?.inv_number === invoiceNumber
                                    ? "underline"
                                    : "none"
                                }`,
                              }}
                              onClick={(e) => {
                                setinvoiceNumber(invNumber?.inv_number);
                                setTotal({
                                  baseDfltC: invNumber?.ttl_agt_pay_d_cur,
                                  baseSlctC: invNumber?.ttl_agt_pay_s_cur,
                                });
                              }}
                            >
                              {invNumber?.inv_number}
                            </span>
                          )
                        )}
                    </div>
                  </td>
                  <td tyle={{ width: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      {booking?.booking &&
                        booking?.booking?.voucherData?.map((invDate) => (
                          <span>
                            {invDate?.approved_date
                              ? invDate?.approved_date
                              : "Not approved"}
                          </span>
                        ))}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* passenger Data */}
        <div className="p-4">
          <PaxRecord
            booking={booking}
            user={user}
            invoiceNumber={invoiceNumber}
            total={total}
          />
        </div>

        <div className="p-4" style={{ width: 800 }}>
          <div className="d-flex bg_primary align-items-center  text-white p-2 rounded mb-2">
            <h4 className="">Payment Method:</h4>
          </div>

          <table class="table table-bordered border-dark align-items-center ">
            <thead
              className="bg_primary text-white "
              style={{ fontSize: "20px" }}
            >
              <tr>
                <th scope="col">IBFT / By Chq</th>
                <th scope="col">Cash Pay in Head Office</th>
              </tr>
            </thead>
            <tbody
              className="text-dark"
              style={{ fontSize: "20px", color: "#000000" }}
            >
              <tr>
                <td className="d-flex flex-column gap-2 ">
                  {bankDetails
                    ?.filter(
                      (item) => item?.bank_status === 1 && item?.collection
                    )
                    ?.map((bank, index) => (
                      <span key={index}>
                        {bank?.name}
                        {"-"}
                        {bank?.account_iban_no}
                      </span>
                    ))}
                </td>
                <td>
                  {mainAgency?.map((item, index) => (
                    <span key={index}>{item?.address?.split("]")[0]}</span>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-4" style={{ width: 500 }}>
          <div className="d-flex bg_primary align-items-center  text-white p-2 rounded mb-2">
            {" "}
            <h4 className="">Term and Condition:</h4>
          </div>

          {supplierRulesData?.map((supp, index) => (
            <div className="d-flex gap-2   ">
              <img
                style={{ width: 50, height: 50 }}
                src={`${apiUrl}/${supp?.image}`}
              />
              <div className="d-flex flex-column ">
                <h6>{supp?.heading}</h6>
                <p>{supp?.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center my-3">
          <span
            className="booktablebody"
            style={{ fontSize: "20px", color: "#000000" }}
          >
            This is a computer generated document & does not need a signature
            and Stamp
          </span>
          <button
            className="bg_primary  mt-3 "
            style={{ fontSize: "20px", color: "white", padding: 8 }}
          >
            Print invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
