import React from 'react'
import BtnLoader from '../AppForm/BtnLoader';
import { useSelector } from 'react-redux';

const VoidTicket = ({ booking,user,active_tab}) => {
  const { loading, success, error, data } = useSelector((state) => state.voidTicket);
  return (
    <div className='d-flex h-100 align-items-center justify-content-center'>
    <div>
      <h1>
      {loading ? (<BtnLoader  />)
               : (data?.message )}
      </h1>
    </div>
  </div>
  )
}

export default VoidTicket