import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { optionArraySubsidaryInv } from "../../../../../Setup/Pricing/helper";
import { menuProps } from "../../helper";

function Subsidary({ setFieldValue, values, friend, index, auto_manual }) {
  console.log("friend",friend)
  return (
    <div className="px-2 pe-1 tax_pf">
      <div className="h5">Subsidary Amount</div>
      <div className="row">
      <div className="col-6">
        <FormControl variant="standard" className="w-100">
          <InputLabel id="demo-simple-select-standard-label">Option</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            name="other_income_type"
            value={friend.other_income_type || "NET"}
            // defaultValue={friend.other_income_type}
            onChange={(e) => {
              let value = e.target.value;
              setFieldValue(`fareValues.${index}.other_income_type`, value);
              if (value === "NET") {
                setFieldValue(`fareValues.${index}.other_income`, 0);
              } else {
                setFieldValue(`fareValues.${index}.other_income`, 1);
              }
            }}
            MenuProps={menuProps}
          >
            {optionArraySubsidaryInv?.map((op, ind) => (
              <MenuItem value={op} key={ind}>
                {op}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-6">
        <TextField
          variant="standard"
          label={`Fixed Amount in PKR`}
          className="w-100"
          autoComplete="off"
          type="number"
          name="other_income"
          value={friend?.other_income}
          onChange={(e) => {
            setFieldValue(`fareValues.${index}.other_income`, Number(e.target?.value));
          }}
          onWheel={(e) => e.target.blur()}
          disabled={values?.subsidary_option === "Net Net" ? true : false}
        />
      </div>
      </div>
    </div>
  );
}

export default Subsidary;
