import moment from 'moment';
import React from 'react'
import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from 'react-icons/md';

const AirblueSegments = ({ flightSegment, segment, timeDurations, details }) => {
    const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
    const locDeparture = searchValues?.locationDep;
    const locationArrival = searchValues?.locationArrival;

    return (
        <div className={`d-flex align-items-center py-4 ${details ? "" : "border-bottom"} `}>
            <div className="col-2">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <img
                        src={"/NEW_LOGO/PA.png"}
                        alt="airblue"
                        width={90}
                        height={90}
                    />
                    <div className="mt-2">PA {flightSegment?.$?.FlightNumber}</div>
                </div>
            </div>

            <div className="col-10">
                <div className="d-flex justify-content-evenly align-items-center">
                    <div className="d-flex align-items-center">
                        <div className="me-3">
                            <MdOutlineFlightTakeoff
                                size={30}
                                className="color_primary"
                            />
                        </div>
                        <div>
                            <h6>
                                {moment(
                                    new Date(
                                        flightSegment?.$?.DepartureDateTime?.split("T")[0]
                                    )
                                ).format("ddd DD-MMM-YY")}
                            </h6>
                            <h6 className="my-3">
                                {flightSegment?.$?.DepartureDateTime?.split(
                                    "T"
                                )[1]?.slice(0, 5)}
                            </h6>
                            <h6>
                                {segment === "outbound"
                                    ? `${locDeparture?.split("-")[0]} - ${locDeparture?.split("-")[2].toUpperCase()}`
                                    : `${locationArrival?.split("-")[0]} - ${locationArrival?.split("-")[2].toUpperCase()}`}
                            </h6>

                        </div>
                    </div>
                    <div>
                        <h6 className="text-center">
                            {timeDurations(flightSegment?.$?.DepartureDateTime, flightSegment?.$?.ArrivalDateTime)}
                        </h6>

                        <div className="border_rounded mb-2" />
                        <h6 className="mx-3">Non-Stop</h6>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="me-3">
                            <MdOutlineFlightLand size={30} className="color_primary" />
                        </div>
                        <div>
                            <h6>
                                {moment(
                                    new Date(
                                        flightSegment?.$?.ArrivalDateTime?.split("T")[0]
                                    )
                                ).format("ddd DD-MMM-YY")}
                            </h6>
                            <h6 className="my-3">
                                {flightSegment?.$?.ArrivalDateTime?.split("T")[1]?.slice(
                                    0,
                                    5
                                )}
                            </h6>
                            <h6>
                                {segment === "outbound"
                                    ? `${locationArrival?.split("-")[0]} - ${locationArrival?.split("-")[2].toUpperCase()}`
                                    : `${locDeparture?.split("-")[0]} - ${locDeparture?.split("-")[2].toUpperCase()}`}
                            </h6>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AirblueSegments
