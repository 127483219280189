import React from 'react'
import { FaChevronCircleLeft, FaChevronCircleRight, FaSuitcase, FaSuitcaseRolling } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RefundExchange } from '../../../Common/RefundExchange';
import { MdOutlineAirlineSeatReclineNormal, MdOutlineRestaurant, MdSwapHoriz } from 'react-icons/md';
import { CgMenuGridR } from 'react-icons/cg';
import { LightTooltip } from '../OneWaySearchResult/LightTooltip';
import Slider from 'react-slick/lib/slider';
import { Box } from '@mui/material';
import { CurrencyCode, CurrencyExchange, CurrencyExchangeToPKR } from '../../../../Utils/currencyExchange';
import { setExpandFlightDetails, setselectedFlight } from '../../../../redux/features/home/FlightSearchSlice';
import { TwoDecimalPoint } from '../../../../Utils/FixedTwoDecimal';
import moment from 'moment';
import { getSectorsName } from '../../../../Utils/FlightSector';

const AirBlueFares = (
    {
        data,
        fares,
        lowestTotalFare,
        selectedFare,
        setSelectedFare,
        singlePaxFare,
        returnFlightArray,
        combineBaseFare,
        boundToggle,
        setBoundToggle,
        getTaxes,
        outboundflightSegment,
        timeDurations,
        selectedFlight,
        discountTotalFare
    }
) => {
    // console.log("selectedFare", selectedFare)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //local storage data
    const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
    const locDeparture = searchValues?.locationDep;
    const locArrival = searchValues?.locationArrival;

    const CustomPrevArrow = (props) => {
        if (fares?.length < 4) return null;
        return (
            <div
                onClick={props.onClick}
                className="position-absolute top-50 translate-middle-y cursor-pointer custom-left-position custom-text-color"
            >
                <FaChevronCircleLeft size={30} />
            </div>
        )
    }
    const CustomNextArrow = (props) => {
        if (fares?.length < 4) return null;
        return (
            <div
                onClick={props.onClick}
                className="position-absolute top-50 translate-middle-y cursor-pointer custom-right-position custom-text-color custom-z-index"
            >
                <FaChevronCircleRight size={30} />
            </div>
        )
    }
    var settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToShow: fares?.length > 4 ? 4 : fares?.length,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const fareNameArray = boundToggle === "outbound" ? selectedFlight?.outbound?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown : selectedFlight?.fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
    const brandedName = Array.isArray(fareNameArray) ? fareNameArray : [fareNameArray];

    let outBoundPrice = selectedFlight?.outbound?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
    outBoundPrice = Array.isArray(outBoundPrice) ? outBoundPrice : [outBoundPrice];
    let inBoundPrice = selectedFlight?.fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
    inBoundPrice = Array.isArray(inBoundPrice) ? inBoundPrice : [inBoundPrice];


    return (
        <>
            {selectedFlight ?
                <>
                    <div className="fs-5 pb-4 color_primary fw-bold">
                        {!brandedName[0]?.FareInfo[1]?.PassengerFare
                            ?.FareBaggageAllowance
                            ? "Value"
                            : brandedName[0]?.FareInfo[1]?.PassengerFare
                                ?.FareBaggageAllowance?.$
                                ?.UnitOfMeasureQuantity === "20"
                                ? "Flexi"
                                : "Extra"}
                    </div>

                    <Box display={"flex"} alignItems={"center"}>
                        <Box>
                            <FaSuitcaseRolling size={25} className='color_primary' />
                        </Box>
                        <h6 className='ps-2 pt-2'>Hand Carry: 7 kg</h6>
                    </Box>

                    <Box display={"flex"} alignItems={"center"} pt={2}>
                        <Box>
                            <FaSuitcase size={22} className='color_primary' />
                        </Box>
                        <h6 className='ps-2 pt-2'>Check Bag :
                            {!brandedName[0]?.FareInfo[1]?.PassengerFare
                                ?.FareBaggageAllowance ? (
                                <>
                                    <span>0 Kg</span>
                                </>
                            ) : brandedName[0]?.FareInfo[1]?.PassengerFare
                                ?.FareBaggageAllowance?.$
                                ?.UnitOfMeasureQuantity === "20" ? (
                                <>
                                    <span>20 kg (1 Pcs)</span>
                                </>
                            ) : brandedName[0]?.FareInfo[1]?.PassengerFare
                                ?.FareBaggageAllowance?.$
                                ?.UnitOfMeasureQuantity === "30" ? (
                                <>
                                    <span>30 kg (1 Pcs)</span>
                                </>
                            ) : null}
                        </h6>
                    </Box>
                    <Box display={"flex"}
                        alignItems={"center"}
                        pt={2}
                    >
                        <MdOutlineRestaurant
                            size={25}
                            className="color_primary"
                        />
                        <h6 className="ps-2 pt-2">Meal: Allowed</h6>
                    </Box>

                    <Box display={"flex"}
                        alignItems={"center"}
                        pt={2}>
                        <Box>
                            <MdOutlineAirlineSeatReclineNormal size={25} className="color_primary" />
                        </Box>
                        <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                    </Box>

                    <Box
                        pt={2}
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <MdSwapHoriz
                            size={30}
                            className="color_primary"
                        />
                        <h6 className="pt-1 ps-2 text-success">
                            Refundable
                        </h6>
                    </Box>
                    <div className="flight-detail-headings">
                        <RefundExchange />
                    </div>
                    <LightTooltip
                        title={
                            <div className="p-3">
                                <div className="d-flex justify-content-between align-items-end">
                                    <div className="d-flex me-4">
                                        <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                        <h5>* {searchValues?.adultsCount}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                        {CurrencyCode()}{" "}
                                        {singlePaxFare(boundToggle === "outbound" ?
                                            outBoundPrice[0] :
                                            inBoundPrice[0], 0,
                                            1
                                        )}
                                    </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end my-2">
                                    <div className="d-flex">
                                        <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                        <h5>* {searchValues?.childCount}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                        {CurrencyCode()}{" "}
                                        {singlePaxFare(boundToggle === "outbound" ?
                                            outBoundPrice[1] :
                                            inBoundPrice[1], 0,
                                            1
                                        )}
                                    </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end">
                                    <div className="d-flex">
                                        <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                        <h5>* {searchValues?.infantNo}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                        {CurrencyCode()}{" "}
                                        {singlePaxFare(boundToggle === "outbound" ?
                                            outBoundPrice[2] :
                                            inBoundPrice[2], 0,
                                            1
                                        )}
                                    </h6>
                                </div>
                            </div>
                        }
                        placement="top"
                    >
                        <button
                            type="button"
                            // className={`${selectedFare === fare ? "button_acitve" : "button_style"} px-4`}
                            className={`button_style px-4`}
                        >
                            {CurrencyCode()}{" "}
                            {lowestTotalFare(boundToggle === "outbound" ? selectedFlight?.outbound : selectedFlight?.fare)}
                        </button>
                    </LightTooltip>
                </>
                :
                <Slider {...settings}>
                    {fares?.sort(
                        (a, b) =>
                            a?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$
                                ?.Amount -
                            b?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$
                                ?.Amount
                    )?.map((fare, index) => {

                        const inboundsegments = fare?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption?.FlightSegment

                        let paxFare = Array.isArray(
                            fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                                ?.PTC_FareBreakdown
                        )
                            ? fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                                ?.PTC_FareBreakdown
                            : [
                                fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                                    ?.PTC_FareBreakdown,
                            ];

                        let returnFARE = boundToggle === "outbound" ? Array.isArray(
                            returnFlightArray?.AirItineraryPricingInfo
                                ?.PTC_FareBreakdowns?.PTC_FareBreakdown
                        )
                            ? returnFlightArray?.AirItineraryPricingInfo
                                ?.PTC_FareBreakdowns?.PTC_FareBreakdown
                            : [
                                returnFlightArray?.AirItineraryPricingInfo
                                    ?.PTC_FareBreakdowns?.PTC_FareBreakdown,
                            ]
                            :
                            Array.isArray(
                                selectedFare?.AirItineraryPricingInfo
                                    ?.PTC_FareBreakdowns?.PTC_FareBreakdown
                            )
                                ? selectedFare?.AirItineraryPricingInfo
                                    ?.PTC_FareBreakdowns?.PTC_FareBreakdown
                                : [
                                    selectedFare?.AirItineraryPricingInfo
                                        ?.PTC_FareBreakdowns?.PTC_FareBreakdown,
                                ];


                        const outBoundCabineClass = Array.isArray(
                            selectedFare?.AirItineraryPricingInfo
                                ?.PTC_FareBreakdowns?.PTC_FareBreakdown
                        )
                            ? selectedFare?.AirItineraryPricingInfo
                                ?.PTC_FareBreakdowns?.PTC_FareBreakdown
                            : [
                                selectedFare?.AirItineraryPricingInfo
                                    ?.PTC_FareBreakdowns?.PTC_FareBreakdown,
                            ];

                        return (
                            <div key={index}>
                                <div>
                                    <div className="fs-5 pb-4 color_primary fw-bold">
                                        {!paxFare[0]?.FareInfo[1]?.PassengerFare
                                            ?.FareBaggageAllowance
                                            ? "Value"
                                            : paxFare[0]?.FareInfo[1]?.PassengerFare
                                                ?.FareBaggageAllowance?.$
                                                ?.UnitOfMeasureQuantity === "20"
                                                ? "Flexi"
                                                : "Extra"}
                                    </div>

                                    <Box display={"flex"} alignItems={"center"}>
                                        <Box>
                                            <FaSuitcaseRolling size={25} className='color_primary' />
                                        </Box>
                                        <h6 className='ps-2 pt-2'>Hand Carry: 7 kg</h6>
                                    </Box>

                                    <Box display={"flex"} alignItems={"center"} pt={2}>
                                        <Box>
                                            <FaSuitcase size={22} className='color_primary' />
                                        </Box>
                                        <h6 className='ps-2 pt-2'>Check Bag :
                                            {!paxFare[0]?.FareInfo[1]?.PassengerFare
                                                ?.FareBaggageAllowance ? (
                                                <>
                                                    <span>0 Kg</span>
                                                </>
                                            ) : paxFare[0]?.FareInfo[1]?.PassengerFare
                                                ?.FareBaggageAllowance?.$
                                                ?.UnitOfMeasureQuantity === "20" ? (
                                                <>
                                                    <span>20 kg (1 Pcs)</span>
                                                </>
                                            ) : paxFare[0]?.FareInfo[1]?.PassengerFare
                                                ?.FareBaggageAllowance?.$
                                                ?.UnitOfMeasureQuantity === "30" ? (
                                                <>
                                                    <span>30 kg (1 Pcs)</span>
                                                </>
                                            ) : null}
                                        </h6>
                                    </Box>
                                    <Box display={"flex"}
                                        alignItems={"center"}
                                        pt={2}
                                    >
                                        <MdOutlineRestaurant
                                            size={25}
                                            className="color_primary"
                                        />
                                        <h6 className="ps-2 pt-2">Meal: Allowed</h6>
                                    </Box>

                                    <Box display={"flex"}
                                        alignItems={"center"}
                                        pt={2}>
                                        <Box>
                                            <MdOutlineAirlineSeatReclineNormal size={25} className="color_primary" />
                                        </Box>
                                        <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                                    </Box>

                                    <Box
                                        pt={2}
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        <MdSwapHoriz
                                            size={30}
                                            className="color_primary"
                                        />
                                        <h6 className="pt-1 ps-2 text-success">
                                            Refundable
                                        </h6>
                                    </Box>
                                    <div className="flight-detail-headings">
                                        <RefundExchange />
                                    </div>

                                    <LightTooltip
                                        title={
                                            <div className="p-3">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <div className="d-flex me-4">
                                                        <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                                        <h5>* {searchValues?.adultsCount}</h5>
                                                    </div>
                                                    <h6 style={{ minWidth: "7rem" }}>
                                                        {CurrencyCode()}{" "}
                                                        {singlePaxFare(
                                                            paxFare[0],
                                                            returnFARE[0],
                                                            1
                                                        )}
                                                    </h6>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-end my-2">
                                                    <div className="d-flex">
                                                        <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                                        <h5>* {searchValues?.childCount}</h5>
                                                    </div>
                                                    <h6 style={{ minWidth: "7rem" }}>
                                                        {CurrencyCode()}{" "}
                                                        {singlePaxFare(
                                                            paxFare[1],
                                                            returnFARE[1],
                                                            1
                                                        )}
                                                    </h6>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <div className="d-flex">
                                                        <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                                        <h5>* {searchValues?.infantNo}</h5>
                                                    </div>
                                                    <h6 style={{ minWidth: "7rem" }}>
                                                        {CurrencyCode()}{" "}
                                                        {singlePaxFare(
                                                            paxFare[2],
                                                            returnFARE[2],
                                                            1
                                                        )}
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        placement="top"
                                    >
                                        <h6 className=" text-danger">
                                            {discountTotalFare(fare)}
                                        </h6>
                                        <button
                                            type="button"
                                            // className={`${selectedFare === fare ? "button_acitve" : "button_style"} px-4`}
                                            className={`button_style px-4`}
                                            onClick={() => {
                                                if (boundToggle === "outbound") {
                                                    setSelectedFare(fare);
                                                    setBoundToggle("inbound")
                                                }

                                                if (boundToggle === "inbound") {
                                                    const baggage = {
                                                        20: `20 KG (1 Pcs)`,
                                                        30: `30 KG (1 Pcs)`,
                                                    };

                                                    let bookingBody = {
                                                        flight: data,
                                                        fare: fare,
                                                        outbound: selectedFare,
                                                        price: lowestTotalFare(fare),
                                                        ttl_agent_fare: TwoDecimalPoint(CurrencyExchangeToPKR(lowestTotalFare(fare))),
                                                        ttlagent_curr_fare: lowestTotalFare(fare),

                                                        ttl_supplier_fare: TwoDecimalPoint(Number(fare?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$?.Amount) + Number(selectedFare?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$?.Amount)),
                                                        ttlsup_curr_fare: TwoDecimalPoint(CurrencyExchange(Number(fare?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$?.Amount) + Number(selectedFare?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$?.Amount))),

                                                        adultBaseFare: combineBaseFare(paxFare[0], returnFARE[0], 1).basic,
                                                        adultTaxes: combineBaseFare(paxFare[0], returnFARE[0], 1).taxes,
                                                        childBaseFare: searchValues?.childCount === 0 ? 0 : ~~combineBaseFare(paxFare[1], returnFARE[1], 1).basic,
                                                        childTaxes: searchValues?.childCount === 0 ? 0 : ~~combineBaseFare(paxFare[1], returnFARE[1], 1).taxes,
                                                        infantBaseFare: searchValues?.infantNo === 0 ? 0 : ~~combineBaseFare(paxFare[paxFare?.length - 1], returnFARE[returnFARE?.length - 1], 1).basic,
                                                        infantTaxes: searchValues?.infantNo === 0 ? 0 : ~~combineBaseFare(paxFare[paxFare?.length - 1], returnFARE[returnFARE?.length - 1], 1).taxes,
                                                        adlt_ttl_d_curr: CurrencyExchangeToPKR(singlePaxFare(paxFare?.at(0), returnFARE?.at(0), 1)),
                                                        chld_ttl_d_curr: searchValues?.childCount === 0 ? 0 : CurrencyExchangeToPKR(~~singlePaxFare(paxFare[1], returnFARE[1], 1)),
                                                        inft_ttl_d_curr: searchValues?.infantNo === 0 ? 0 : CurrencyExchangeToPKR(~~singlePaxFare(paxFare[paxFare?.length - 1], returnFARE[returnFARE?.length - 1], 1)),
                                                        ADLT_TAXES: getTaxes(paxFare[0], returnFARE[0]),
                                                        CHLD_TAXES: searchValues?.childCount === 0 ? [] : getTaxes(paxFare[1], returnFARE[1]),
                                                        INFT_TAXES: searchValues?.infantNo === 0 ? [] : getTaxes(paxFare[paxFare?.length - 1], returnFARE[returnFARE?.length - 1]),

                                                        ADLT_FARE: paxFare?.at(0),
                                                        CHLD_FARE: searchValues?.childCount === 0 ? {} : paxFare?.at(1),
                                                        INFT_FARE: searchValues?.infantNo === 0 ? {} : paxFare?.at(paxFare?.length - 1),

                                                        baggageName: "",
                                                        flightNumber: `PA ${outboundflightSegment?.$?.FlightNumber}`,
                                                        depDate: moment(
                                                            new Date(
                                                                outboundflightSegment?.$?.DepartureDateTime?.split(
                                                                    "T"
                                                                )[0]
                                                            )
                                                        ).format("DD-MMM-YY"),
                                                        depTime:
                                                            outboundflightSegment?.$?.DepartureDateTime?.split(
                                                                "T"
                                                            )[1]?.slice(0, 5),
                                                        arrDate: moment(
                                                            new Date(
                                                                outboundflightSegment?.$?.ArrivalDateTime?.split(
                                                                    "T"
                                                                )[0]
                                                            )
                                                        ).format("DD-MMM-YY"),
                                                        arrTime:
                                                            outboundflightSegment?.$?.ArrivalDateTime?.split(
                                                                "T"
                                                            )[1]?.slice(0, 5),
                                                        flightDuration: timeDurations(outboundflightSegment?.$?.DepartureDateTime, outboundflightSegment?.$?.ArrivalDateTime),
                                                        logo: "AirBlueReturn",
                                                        code: "PA",
                                                        booking: {
                                                            gds: "AIRBLUE",
                                                            pnr: "",
                                                            agent_name: fare?.agents_name,
                                                            segments: [
                                                                {
                                                                    paxes: outBoundCabineClass,
                                                                    departure_date:
                                                                        outboundflightSegment?.$?.DepartureDateTime?.split(
                                                                            "T"
                                                                        )?.at(0),
                                                                    baggage:
                                                                        baggage[
                                                                        outBoundCabineClass[0]?.FareInfo[1]
                                                                            ?.PassengerFare
                                                                            ?.FareBaggageAllowance?.$
                                                                            ?.UnitOfMeasureQuantity
                                                                        ] || "0 Kg (0 Pcs)",
                                                                    departure_time:
                                                                        outboundflightSegment?.$?.DepartureDateTime?.split(
                                                                            "T"
                                                                        )
                                                                            ?.at(1)
                                                                            ?.slice(0, 5),
                                                                    arrival_date:
                                                                        outboundflightSegment?.$?.ArrivalDateTime?.split(
                                                                            "T"
                                                                        )?.at(0),
                                                                    arrival_time:
                                                                        outboundflightSegment?.$?.ArrivalDateTime?.split(
                                                                            "T"
                                                                        )
                                                                            ?.at(1)
                                                                            ?.slice(0, 5),
                                                                    origin_terminal: "",
                                                                    desti_terminal: "",
                                                                    flight_number:
                                                                        outboundflightSegment?.$?.FlightNumber,
                                                                    status: outboundflightSegment?.$?.Status,
                                                                    res_book_desig_code:
                                                                        outboundflightSegment?.$
                                                                            ?.ResBookDesigCode,
                                                                    rph: outboundflightSegment?.$?.RPH,
                                                                    des_location:
                                                                        searchValues?.locationArrival,
                                                                    dest_code: locArrival
                                                                        ?.split("-")
                                                                        ?.at(0),
                                                                    marketing_airline:
                                                                        outboundflightSegment?.MarketingAirline
                                                                            ?.$?.Code,
                                                                    equipment_code:
                                                                        outboundflightSegment?.Equipment?.$
                                                                            ?.AirEquipType,
                                                                    operatingairline_code:
                                                                        outboundflightSegment?.OperatingAirline
                                                                            ?.$?.Code,
                                                                    orig_location:
                                                                        searchValues?.locationDep,
                                                                    orig_code: locDeparture
                                                                        ?.split("-")
                                                                        ?.at(0),
                                                                    marriage_grp: "",
                                                                    cabin_class:
                                                                        !outBoundCabineClass[0]?.FareInfo[1]?.PassengerFare
                                                                            ?.FareBaggageAllowance
                                                                            ? "Value"
                                                                            : outBoundCabineClass[0]?.FareInfo[1]?.PassengerFare
                                                                                ?.FareBaggageAllowance?.$
                                                                                ?.UnitOfMeasureQuantity === "20"
                                                                                ? "Flexi"
                                                                                : "Extra"
                                                                    ,
                                                                    segment_type: getSectorsName(
                                                                        locDeparture,
                                                                        locArrival
                                                                    ),
                                                                },
                                                                {

                                                                    paxes: paxFare,
                                                                    departure_date:
                                                                        inboundsegments?.$?.DepartureDateTime?.split(
                                                                            "T"
                                                                        )?.at(0),
                                                                    baggage:
                                                                        baggage[
                                                                        paxFare[0]?.FareInfo[1]
                                                                            ?.PassengerFare
                                                                            ?.FareBaggageAllowance?.$
                                                                            ?.UnitOfMeasureQuantity
                                                                        ] || "0 Kg (0 Pcs)",
                                                                    departure_time:
                                                                        inboundsegments?.$?.DepartureDateTime?.split(
                                                                            "T"
                                                                        )
                                                                            ?.at(1)
                                                                            ?.slice(0, 5),
                                                                    arrival_date:
                                                                        inboundsegments?.$?.ArrivalDateTime?.split(
                                                                            "T"
                                                                        )?.at(0),
                                                                    arrival_time:
                                                                        inboundsegments?.$?.ArrivalDateTime?.split(
                                                                            "T"
                                                                        )
                                                                            ?.at(1)
                                                                            ?.slice(0, 5),
                                                                    origin_terminal: "",
                                                                    desti_terminal: "",
                                                                    flight_number:
                                                                        inboundsegments?.$?.FlightNumber,
                                                                    status: inboundsegments?.$?.Status,
                                                                    res_book_desig_code:
                                                                        inboundsegments?.$
                                                                            ?.ResBookDesigCode,
                                                                    rph: inboundsegments?.$?.RPH,
                                                                    des_location:
                                                                        searchValues?.locationDep,
                                                                    dest_code: locDeparture
                                                                        ?.split("-")
                                                                        ?.at(0),
                                                                    marketing_airline:
                                                                        inboundsegments?.MarketingAirline
                                                                            ?.$?.Code,
                                                                    equipment_code:
                                                                        inboundsegments?.Equipment?.$
                                                                            ?.AirEquipType,
                                                                    operatingairline_code:
                                                                        inboundsegments?.OperatingAirline
                                                                            ?.$?.Code,
                                                                    orig_location:
                                                                        searchValues?.locationArrival,
                                                                    orig_code: locArrival
                                                                        ?.split("-")
                                                                        ?.at(0),
                                                                    marriage_grp: "",
                                                                    cabin_class:
                                                                        !paxFare[0]?.FareInfo[1]?.PassengerFare
                                                                            ?.FareBaggageAllowance
                                                                            ? "Value"
                                                                            : paxFare[0]?.FareInfo[1]?.PassengerFare
                                                                                ?.FareBaggageAllowance?.$
                                                                                ?.UnitOfMeasureQuantity === "20"
                                                                                ? "Flexi"
                                                                                : "Extra"
                                                                    ,
                                                                    segment_type: getSectorsName(
                                                                        locArrival,
                                                                        locDeparture
                                                                    ),
                                                                },
                                                            ],
                                                            tp_ur_code: "",
                                                            platting_carrier: "PA",
                                                            tp_ssr_verssion: 0,
                                                            tp_trace_id: "",
                                                        },
                                                    }

                                                    dispatch(
                                                        setselectedFlight({ ...bookingBody })
                                                    );
                                                    navigate("/check-out");
                                                    dispatch(setExpandFlightDetails(null));
                                                }
                                            }}
                                        >
                                            {CurrencyCode()}{" "}
                                            {lowestTotalFare(fare)}
                                        </button>
                                    </LightTooltip>
                                </div>
                            </div>
                        );
                    })}
                </Slider >}
        </>
    )
}

export default AirBlueFares
