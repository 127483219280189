import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FareRulesAccordion = ({ pricingData }) => {
    const [expanded, setExpanded] = useState(false);
    const [fareTab, setFareTab] = useState(
        pricingData?.included?.['detailed-fare-rules']?.[1]?.fareNotes?.descriptions?.at(0)
    );

    const handleChange = (panel, tab) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setFareTab(tab);
    };

    const formatText = (text) => {
        return text
            .replace(/-+/g, '') // Remove hyphens
            .replace(/\n/g, '<br /> <br />'); // Replace newline characters with <br />
    };

    return (
        <div>
            {pricingData?.included?.['detailed-fare-rules']?.[1]?.fareNotes?.descriptions?.map((tab, index) => (
                <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`, tab)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0, fontSize: "18px", fontWeight: "bold", padding: "4px" }}>
                            {tab?.descriptionType?.charAt(0).toUpperCase() + tab?.descriptionType?.slice(1).toLowerCase()}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography
                            sx={{
                                maxHeight: '450px',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 10,
                                WebkitBoxOrient: 'vertical',
                            }}
                            dangerouslySetInnerHTML={{ __html: formatText(tab?.text) }}
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default FareRulesAccordion;
