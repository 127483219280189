import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";

import {
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import {
  asyncGetFlightGroupData,
  asyncGetOriganListData,
  asyncPostFlightGroupData,
  asyncGetFlightGrpType,
  asyncUpdateFlightGroup,
  resetValue,
} from "../../../redux/features/promotion/FlightGroupsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import FightGroupType from "./FightGroupType";
import { asyncGetNewSupplier } from "../../../redux/features/supplier/AddNewSupplier";
import { MdClose } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { asyncGetCurrencyData } from "../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { dropMenuProp } from "../../../Utils/MenuProps";
import BtnLoader from "../../AppForm/BtnLoader";
import { getFlightType, getSectorsName } from "../../../Utils/FlightSector";

const FlightsGroup = () => {
  const [drp, setdrp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //useSelector
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const { origanList, pnrError, singleFlightData, FlightGrpType } = useSelector(
    (state) => state.flightGroup
  );

  const { newSupplier } = useSelector((state) => state.supplier);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const supplierList = newSupplier?.filter((it) => it.cat_id === 2);
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  // Check if the user has add permission to flight group
  const addPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/flight-groups-post"
      )
    : true;

  // Check if the user has edit permission to flight group
  const editPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/flight-groups-edit"
      )
    : true;

  useEffect(() => {
    dispatch(asyncGetFlightGroupData());
    dispatch(asyncGetFlightGrpType());
    dispatch(asyncGetNewSupplier());
    dispatch(asyncGetCurrencyData());
  }, [dispatch, singleFlightData?.flt_group_id]);

  //Yup validation
  const validationSchema = yup.object().shape({
    flt_gtype_id: yup.string().required("Group type Required"),
    ttl_seat: yup
      .number()
      .required("Required")
      .min(1, "Cannot start from 0 or be a negative number"),
    supplier_id: yup.string().required("Select Supplier Required"),
    pnr: yup.string().required("PNR Required"),
    /////////////////////////////////////
    flight_group_pax_fare: yup.array().of(
      yup.object().shape({
        pax_type: yup.string().required("Passanger type Required"), ///currency
        default_currency_pay: yup.number().required("Required"),
        default_currency_received: yup.number().required("Required"),

        selected_currency_pay: yup.number().required("Required"),
        selected_currency_received: yup.number().required("Required"),
      })
    ),
    /////////////////////////////////////
    flight_group_flight: yup.array().of(
      yup.object().shape({
        flight: yup.string().required("Requird"),
        flt_no: yup.string().required("Requird"),
        origin: yup.string().required("Origin Requird"),
        destination: yup.string().required("Destination Requird"),
        flt_dep_date: yup.string().required("Requird"),
        dep_time: yup.string().required("Requird"),
        flt_arr_date: yup.string().required("Requird"),
        arr_time: yup.string().required("Requird"),
        baggage: yup.string().required("Requird"),
      })
    ),
  });
  console.log("singleFlightData", singleFlightData);

 console.log("location", location);

 
  const initialValues = {
    flt_gtype_id: singleFlightData?.flt_gtype_id || "",
    ttl_seat: singleFlightData?.ttl_seat || "",
    supplier_id: singleFlightData?.supplier_id || "",
    pnr: singleFlightData?.pnr || "",
    // flight_type: "DOM",
    cabin_class: singleFlightData?.cabin_class || "ECONOMY (Y)",

    //addpassanger states
    flight_group_pax_fare: singleFlightData?.flight_group_pax_fares?.length
      ? singleFlightData?.flight_group_pax_fares?.map((value) => {
          return {
            group_pax_fare_id: value?.group_pax_fare_id,
            pax_type: value?.pax_type,
            default_currency_pay: value?.default_currency_pay,
            default_currency_received: value?.default_currency_received,
            currency: value?.currency,
            selected_currency_roe: value?.selected_currency_roe,
            selected_currency_pay: value?.selected_currency_pay,
            selected_currency_received: value?.selected_currency_received,
          };
        })
      : [
          {
            pax_type: "ADULT",
            default_currency_pay: "",
            default_currency_received: "",
            currency: "PKR",
            selected_currency_roe: currencyData?.find(
              (item) => item?.curr_code === "PKR"
            )?.roe,
            selected_currency_pay:
              currencyData?.find((item) => item?.curr_code === "PKR") && 0,
            selected_currency_received:
              currencyData?.find((item) => item?.curr_code === "PKR") && 0,
          },
        ],

    flight_group_flight: singleFlightData?.flight_group_flights?.length
      ? singleFlightData?.flight_group_flights?.map((value) => {
          return {
            group_flight_id: value?.group_flight_id,
            flight: value.flight,
            flt_no: value.flt_no,
            origin: value.origin,
            destination: value.destination,
            flt_dep_date: value.flt_dep_date,
            dep_time: value.dep_time,
            flt_arr_date: value.flt_arr_date,
            arr_time: value.arr_time,
            baggage: value.baggage,
          };
        })
      : [
          {
            flight: "",
            flt_no: "",
            origin: "",
            destination: "",
            flt_dep_date: new Date()
              ?.toISOString()
              ?.split("T")[0]
              ?.split("-")
              .reverse()
              .join("-"),
            dep_time: "",
            flt_arr_date: new Date()
              .toISOString()
              ?.split("T")[0]
              ?.split("-")
              ?.reverse()
              ?.join("-"),
            arr_time: "",
            baggage: "",
          },
        ],
  };

  const selectPassangerTypeLists = [
    { value: "ADULT", key: "Adult" },
    { value: "CHILD", key: "Child" },
    { value: "INFANT", key: "Infant" },
  ];
  const formikRef = useRef();

  useEffect(() => {
    if (!pnrError) {
      if (formikRef.current) {
        formikRef.current.resetForm();
      }
    }
  }, [toggle]);

  return (
    <div className="pb-5">
      {!singleFlightData?.flt_group_id && (
        <div className="col-11 mx-auto rounded-2 shadow-sm mt-5 bg-white mb-3">
          <FightGroupType addPermission={addPermission} />
        </div>
      )}
      <div className="col-11 mx-auto rounded-2 shadow-sm mt-5 pb-3 bg-white mb-4">
        <p className="h4 otawix-heading box_title">Add/Edit Flight Group</p>
        <Formik
          innerRef={formikRef}
          validateOnChange={true}
          validateOnBlur={false}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            // getSectorsName;
            // getFlightType;
            let body = {
              ...values,
              ttl_seat: Number(values?.ttl_seat),
              // flight_group_flight: values?.flight_group_flight?.map(
              //   (flt, index) => {
              //     return {
              //       ...flt,
              //       cabin_class:flt?.cabin_class,
              //       // segment_type: getSectorsName(flt?.origin, flt?.destination),
              //     };
              //   }
              // ),
              // flight_type: getFlightType(
              //   values?.flight_group_flight?.at(0)?.origin,
              //   values?.flight_group_flight
              // ),
              flight_group_pax_fare: values?.flight_group_pax_fare.map(
                (item, index) => {
                  return {
                    ...item,
                    selected_currency_roe: Number(item?.selected_currency_roe),
                    default_currency_pay: Number(item?.default_currency_pay),
                    default_currency_received: Number(
                      item?.default_currency_received
                    ),
                    selected_currency_pay: Number(item?.selected_currency_pay),
                    selected_currency_received: Number(
                      item?.selected_currency_received
                    ),
                  };
                }
              ),
            };
            if (singleFlightData?.flt_group_id) {
              let id = singleFlightData?.flt_group_id;
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
                dispatch(asyncUpdateFlightGroup({ id, body }));
                dispatch(resetValue());
                navigate("/flightgroupdetails", {
                  state: {
                    fltPromotion: location.state?.fltPromotion,
                  },
                });
              }, 2000);
            } else {
              setLoading(true);
              dispatch(asyncPostFlightGroupData(body));
              setTimeout(() => {
                setLoading(false);
                setToggle(!toggle);
              }, 2000);
            }
          }}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <div className="row p-4 gap-2">
                  <div className="col-sm-2">
                    <TextField
                      id="standard-basic"
                      label="Total Seats"
                      variant="standard"
                      initialValues
                      autoComplete="off"
                      name="ttl_seat"
                      type="number"
                      className="w-100"
                      disabled={singleFlightData?.flt_group_id}
                      value={values.ttl_seat}
                      onChange={(e) =>
                        setFieldValue("ttl_seat", e.target.value)
                      }
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="ttl_seat"
                    />
                  </div>

                  <div className="col-sm-2">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="demo-simple-select-standard-label">
                        Select Supplier
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        disabled={singleFlightData?.flt_group_id}
                        onChange={(e) => {
                          setFieldValue("supplier_id", e.target.value);
                        }}
                        value={values?.supplier_id}
                        MenuProps={dropMenuProp}
                      >
                        {supplierList?.map((option) => (
                          <MenuItem
                            value={option?.acc_id}
                            sx={{ m: 1, bgcolor: "#fff" }}
                            key={option?.acc_id}
                          >
                            {option?.supp_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="supplier_id"
                    />
                  </div>
                  <div className="col-sm-2 px-3">
                    <FormControl variant="standard" className="w-100 " sx={{}}>
                      <InputLabel id="demo-simple-select-standard-label">
                        Select Group Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        disabled={singleFlightData?.flt_group_id}
                        onChange={(e) =>
                          setFieldValue("flt_gtype_id", e.target.value)
                        }
                        name="flt_gtype_id"
                        value={values?.flt_gtype_id}
                        MenuProps={dropMenuProp}
                      >
                        {FlightGrpType?.map((it) => (
                          <MenuItem
                            value={it?.id}
                            sx={{ m: 1, bgcolor: "#fff" }}
                            key={it?.id}
                          >
                            {it?.type_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="flt_gtype_id"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      id="standard-basic"
                      label="PNR"
                      variant="standard"
                      autoComplete="off"
                      value={values.pnr}
                      disabled={singleFlightData?.flt_group_id}
                      className="w-100"
                      onChange={(e) =>
                        setFieldValue("pnr", e.target.value.toUpperCase())
                      }
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="pnr"
                    />
                    <p className="text-danger mt-1">
                      {pnrError?.response?.data?.message}
                    </p>
                  </div>
                  {/* <div className="col-sm-1">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="FLIGHT_TYPE">FLIGHT TYPE</InputLabel>
                      <Select
                        labelId="FLIGHT_TYPE"
                        id="FLIGHT_TYPE"
                        disabled={singleFlightData?.flt_group_id}
                        onChange={(e) =>
                          setFieldValue("flight_type", e.target.value)
                        }
                        name="flight_type"
                        value={values?.flight_type}
                        MenuProps={dropMenuProp}
                      >
                        <MenuItem value={"DOM"} sx={{ m: 1, bgcolor: "#fff" }}>
                          DOM
                        </MenuItem>
                        <MenuItem value={"SOTO"} sx={{ m: 1, bgcolor: "#fff" }}>
                          SOTO
                        </MenuItem>
                        <MenuItem value={"SITI"} sx={{ m: 1, bgcolor: "#fff" }}>
                          SITI
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="flight_type"
                    />
                  </div> */}

                  <div className="col-sm-2">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="cabin_class">CABIN CLASS</InputLabel>
                      <Select
                        labelId="cabin_class"
                        id="cabin_class"
                        disabled={singleFlightData?.flt_group_id}
                        onChange={(e) =>
                          setFieldValue("cabin_class", e.target.value)
                        }
                        name="cabin_class"
                        value={values?.cabin_class}
                        MenuProps={dropMenuProp}
                      >
                        <MenuItem
                          value={"ECONOMY (Y)"}
                          sx={{ m: 1, bgcolor: "#fff" }}
                        >
                          ECONOMY (Y)
                        </MenuItem>
                        <MenuItem
                          value={"PREMIUM (P)"}
                          sx={{ m: 1, bgcolor: "#fff" }}
                        >
                          PREMIUM (P)
                        </MenuItem>
                        <MenuItem
                          value={"BUSSINESS (C)"}
                          sx={{ m: 1, bgcolor: "#fff" }}
                        >
                          BUSINESS (C)
                        </MenuItem>
                        <MenuItem
                          value={"FIRST (F)"}
                          sx={{ m: 1, bgcolor: "#fff" }}
                        >
                          FIRST (F)
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="cabin_class"
                    />
                  </div>
                </div>

                <FieldArray
                  name="flight_group_pax_fare"
                  render={(arrayHelpers) => (
                    <div>
                      {values.flight_group_pax_fare?.map(
                        (flight_group_pax_fare, index) => (
                          <div key={index} className="row gap-2 px-4 pt-3">
                            <div className="col-sm-2">
                              <FormControl variant="standard" className="w-100">
                                <InputLabel id="demo-simple-select-standard-label">
                                  Passanger Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  name={`flight_group_pax_fare.${index}.pax_type`}
                                  disabled={singleFlightData?.flt_group_id}
                                  value={
                                    values?.flight_group_pax_fare[index]
                                      ?.pax_type
                                  }
                                  onChange={(e) =>
                                    setFieldValue(
                                      `flight_group_pax_fare.${index}.pax_type`,
                                      e.target.value
                                    )
                                  }
                                  MenuProps={dropMenuProp}
                                >
                                  {selectPassangerTypeLists.map(
                                    (selectPassangerTypeList) => (
                                      <MenuItem
                                        value={selectPassangerTypeList?.value}
                                        sx={{ m: 1, bgcolor: "#fff" }}
                                        key={selectPassangerTypeList?.key}
                                      >
                                        {selectPassangerTypeList?.value}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              <p className="text-danger mt-2">
                                {errors?.flight_group_pax_fare &&
                                  errors?.flight_group_pax_fare[index]
                                    ?.pax_type}
                              </p>
                            </div>

                            <div className="col-sm-1">
                              <FormControl variant="standard" className="w-100">
                                <InputLabel id="demo-simple-select-standard-label">
                                  currency
                                </InputLabel>
                                {/* currencyData */}
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  disabled={singleFlightData?.flt_group_id}
                                  name={`flight_group_pax_fare.${index}.currency`}
                                  value={
                                    values?.flight_group_pax_fare[index]
                                      ?.currency
                                  }
                                  onChange={(e) => {
                                    const selectedCurrency = e.target.value;

                                    // Reset relevant fields when selecting PKR
                                    if (selectedCurrency === "PKR") {
                                      setFieldValue(
                                        `flight_group_pax_fare.${index}.selected_currency_roe`,
                                        1
                                      );
                                      setFieldValue(
                                        `flight_group_pax_fare.${index}.selected_currency_pay`,
                                        0
                                      );
                                      setFieldValue(
                                        `flight_group_pax_fare.${index}.selected_currency_received`,
                                        0
                                      );
                                    } else {
                                      // Reset other fields when not selecting PKR
                                      setFieldValue(
                                        `flight_group_pax_fare.${index}.selected_currency_pay`,
                                        0
                                      );
                                      setFieldValue(
                                        `flight_group_pax_fare.${index}.selected_currency_received`,
                                        0
                                      );
                                    }

                                    setFieldValue(
                                      `flight_group_pax_fare.${index}.currency`,
                                      selectedCurrency
                                    );
                                  }}
                                  MenuProps={dropMenuProp}
                                >
                                  {currencyData.map((curr) => (
                                    <MenuItem
                                      value={curr?.curr_code}
                                      sx={{ m: 1, bgcolor: "#fff" }}
                                      // key={selectPassangerTypeList?.key}
                                      onClick={(e) => {
                                        setFieldValue(
                                          `flight_group_pax_fare.${index}.selected_currency_roe`,
                                          Number(curr?.roe)
                                        );
                                      }}
                                    >
                                      {curr?.curr_code}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-sm-1">
                              {" "}
                              <TextField
                                id="standard-basic"
                                label="roe"
                                variant="standard"
                                name={`flight_group_pax_fare.${index}.default_currency_received`}
                                type="number"
                                className="w-100"
                                value={
                                  values?.flight_group_pax_fare[index]
                                    ?.selected_currency_roe || ""
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    `flight_group_pax_fare.${index}.selected_currency_roe`,
                                    e.target.value
                                  );
                                  setFieldValue(
                                    `flight_group_pax_fare.${index}.default_currency_pay`,
                                    e.target.value *
                                      values?.flight_group_pax_fare[index]
                                        ?.selected_currency_pay
                                  );

                                  setFieldValue(
                                    `flight_group_pax_fare.${index}.default_currency_received`,
                                    e.target.value *
                                      values?.flight_group_pax_fare[index]
                                        ?.selected_currency_received
                                  );
                                }}
                                disabled={
                                  values?.flight_group_pax_fare[index]
                                    ?.currency === "PKR"
                                }
                              />
                              {
                                <p className="text-danger">
                                  {errors?.flight_group_pax_fare &&
                                    errors?.flight_group_pax_fare[index]
                                      ?.default_currency_roe}
                                </p>
                              }
                            </div>

                            {/* other currency */}
                            <div className="col-sm-1">
                              <TextField
                                id="standard-basic"
                                label="Cur Pay "
                                variant="standard"
                                type={"number"}
                                className="w-100"
                                value={
                                  values?.flight_group_pax_fare[index]
                                    ?.selected_currency_pay || ""
                                }
                                name={`flight_group_pax_fare.${index}.selected_currency_pay`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `flight_group_pax_fare.${index}.default_currency_pay`,
                                    e.target.value *
                                      values?.flight_group_pax_fare[index]
                                        ?.selected_currency_roe
                                  );

                                  setFieldValue(
                                    `flight_group_pax_fare.${index}.selected_currency_pay`,
                                    e.target.value
                                  );
                                }}
                                disabled={
                                  values?.flight_group_pax_fare[index]
                                    ?.currency === "PKR"
                                }
                              />
                              <p className="text-danger  mt-2">
                                {errors?.flight_group_pax_fare &&
                                  errors?.flight_group_pax_fare[index]
                                    ?.selected_currency_pay}
                              </p>
                            </div>
                            <div className="col-sm-1">
                              <TextField
                                id="standard-basic"
                                label="Cur Rec"
                                variant="standard"
                                type="number"
                                value={
                                  values?.flight_group_pax_fare[index]
                                    ?.selected_currency_received || ""
                                }
                                className="w-100"
                                name={`flight_group_pax_fare.${index}.selected_currency_received`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `flight_group_pax_fare.${index}.default_currency_received`,
                                    e.target.value *
                                      values?.flight_group_pax_fare[index]
                                        ?.selected_currency_roe
                                  );
                                  setFieldValue(
                                    `flight_group_pax_fare.${index}.selected_currency_received`,
                                    e.target.value
                                  );
                                }}
                                disabled={
                                  values?.flight_group_pax_fare[index]
                                    ?.currency === "PKR"
                                }
                              />
                              {
                                <p className="text-danger mt-2">
                                  {errors?.flight_group_pax_fare &&
                                    errors?.flight_group_pax_fare[index]
                                      ?.selected_currency_recived}
                                </p>
                              }
                            </div>

                            {/* PKR Only */}
                            <div className="col-sm-1">
                              <TextField
                                id="standard-basic"
                                label="PKR Pay"
                                variant="standard"
                                type={"number"}
                                value={
                                  values?.flight_group_pax_fare[index]
                                    ?.default_currency_pay
                                }
                                className="w-100"
                                name={`flight_group_pax_fare.${index}.default_currency_pay`}
                                onChange={(e) =>
                                  setFieldValue(
                                    `flight_group_pax_fare.${index}.default_currency_pay`,
                                    e.target.value
                                  )
                                }
                                InputProps={{
                                  readOnly:
                                    values?.flight_group_pax_fare[index]
                                      ?.currency !== "PKR",
                                }}
                              />
                              {
                                <p className="text-danger mt-2">
                                  {errors?.flight_group_pax_fare &&
                                    errors?.flight_group_pax_fare[index]
                                      ?.default_currency_pay}
                                </p>
                              }
                            </div>
                            <div className="col-sm-1">
                              <TextField
                                id="standard-basic"
                                label="PKR Rec "
                                variant="standard"
                                type={"number"}
                                className="w-100"
                                value={
                                  values?.flight_group_pax_fare[index]
                                    ?.default_currency_received
                                }
                                name={`flight_group_pax_fare.${index}.default_currency_received`}
                                onChange={(e) =>
                                  setFieldValue(
                                    `flight_group_pax_fare.${index}.default_currency_received`,
                                    e.target.value
                                  )
                                }
                                InputProps={{
                                  readOnly:
                                    values?.flight_group_pax_fare[index]
                                      ?.currency !== "PKR",
                                }}
                              />
                              <p className="text-danger mt-2">
                                {errors?.flight_group_pax_fare &&
                                  errors?.flight_group_pax_fare[index]
                                    ?.default_currency_received}
                              </p>
                            </div>

                            <div className="col-sm-2">
                              {index === 0 && (
                                <button
                                  type="button"
                                  className="button_style"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      pax_type: "ADULT",
                                      default_currency_pay: "",
                                      selected_currency_received: "",
                                      currency: "PKR",
                                      selected_currency_roe: currencyData?.find(
                                        (item) => item?.curr_code === "PKR"
                                      )?.roe,
                                      selected_currency_pay:
                                        currencyData?.find(
                                          (item) => item?.curr_code === "PKR"
                                        ) && 0,
                                      selected_currency_received:
                                        currencyData?.find(
                                          (item) => item?.curr_code === "PKR"
                                        ) && 0,
                                    })
                                  }
                                  disabled={
                                    values.flight_group_pax_fare.length === 3 ||
                                    singleFlightData?.flt_group_id
                                  }
                                >
                                  + PAX
                                </button>
                              )}
                              {index > 0 && (
                                <button
                                  type="button"
                                  disabled={singleFlightData?.flt_group_id}
                                  className="button_style bg-danger"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  - PAX
                                </button>
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                />

                {/* flight /////////////////////////////////////*/}

                <FieldArray
                  name="flight_group_flight"
                  render={(arrayHelpers) => (
                    <div>
                      {values?.flight_group_flight?.map((addSegment, index) => (
                        <div key={index} className="row  p-4">
                          <div className="col-1 h-5rem">
                            <TextField
                              id="standard-basic"
                              label="Flight"
                              variant="standard"
                              autoComplete="off"
                              name={`flight_group_flight.${index}.flight`}
                              disabled={singleFlightData?.flt_group_id}
                              value={values.flight_group_flight[index]?.flight}
                              className="w-100"
                              inputProps={{ maxLength: 2 }}
                              onChange={(e) =>
                                setFieldValue(
                                  `flight_group_flight.${index}.flight`,
                                  e.target.value
                                    .toUpperCase()
                                    .replace(/[^a-z]/gi, "")
                                )
                              }
                            />
                            <p className="text-danger mt-2">
                              {errors?.flight_group_flight &&
                                errors?.flight_group_flight[index]?.flight}
                            </p>
                          </div>
                          <div className="col-1">
                            <TextField
                              id="standard-basic"
                              label="Flight Number"
                              variant="standard"
                              type={"number"}
                              disabled={singleFlightData?.flt_group_id}
                              name={`flight_group_flight.${index}?.flt_no`}
                              value={values.flight_group_flight[index]?.flt_no}
                              className="w-100"
                              onChange={(e) =>
                                setFieldValue(
                                  `flight_group_flight.${index}.flt_no`,
                                  e.target.value
                                )
                              }
                            />
                            <p className="text-danger mt-2">
                              {errors?.flight_group_flight &&
                                errors?.flight_group_flight[index]?.flt_no}
                            </p>
                          </div>

                          <div className="col-sm-2 relative">
                            <FormControl
                              variant="standard"
                              className="w-100"
                              onClick={() => {
                                setdrp(`origin_${index}`);
                              }}
                            >
                              <InputLabel
                                htmlFor={`flight_group_flight.${index}?.origin`}
                              >
                                Origin
                              </InputLabel>
                              <Input
                                autoComplete="off"
                                id={`flight_group_flight.${index}?.origin`}
                                value={
                                  values?.flight_group_flight[index]?.origin
                                }
                                name={`flight_group_flight.${index}.origin`}
                                disabled={singleFlightData?.flt_group_id}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setFieldValue(
                                    `flight_group_flight.${index}.origin`,
                                    value
                                  );
                                  dispatch(asyncGetOriganListData(value));
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {values.flight_group_flight[index]
                                      ?.origin !== "" && (
                                      <IconButton
                                        onClick={() => {
                                          setFieldValue(
                                            `flight_group_flight.${index}.origin`,
                                            ""
                                          );
                                        }}
                                        disabled={
                                          singleFlightData?.flt_group_id
                                        }
                                      >
                                        <MdClose />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                            {drp === `origin_${index}` ? (
                              <ClickAwayListener onClickAway={() => setdrp("")}>
                                <div className="airport_search_drp">
                                  {origanList?.length > 0 ? (
                                    origanList?.map((item) => {
                                      return (
                                        <div
                                          key={item.id}
                                          className="airport_search_drp_item"
                                          onClick={() => {
                                            let value = `${item.code}-${item.airport_name}-${item?.city}`;
                                            setFieldValue(
                                              `flight_group_flight.${index}.origin`,
                                              value
                                            );
                                            setdrp("");
                                          }}
                                        >
                                          <div className="fs-5">
                                            {item.code} - {item.airport_name}
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="airport_search_drp_item">
                                      No Record Found.
                                    </div>
                                  )}
                                </div>
                              </ClickAwayListener>
                            ) : null}

                            <p className="text-danger  mt-2">
                              {errors?.flight_group_flight &&
                                errors?.flight_group_flight[index]?.origin}
                            </p>
                          </div>

                          <div className="col-2">
                            <FormControl
                              variant="standard"
                              className="w-100"
                              onClick={() => {
                                setdrp(`destination_${index}`);
                              }}
                            >
                              <InputLabel
                                htmlFor={`flight_group_flight.${index}?.destination`}
                              >
                                Destination
                              </InputLabel>
                              <Input
                                autoComplete="off"
                                id={`flight_group_flight.${index}?.destination`}
                                value={
                                  values?.flight_group_flight[index]
                                    ?.destination
                                }
                                disabled={singleFlightData?.flt_group_id}
                                name={`flight_group_flight.${index}.destination`}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setFieldValue(
                                    `flight_group_flight.${index}.destination`,
                                    value
                                  );
                                  dispatch(asyncGetOriganListData(value));
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {values.flight_group_flight[index]
                                      ?.destination !== "" && (
                                      <IconButton
                                        onClick={() => {
                                          setFieldValue(
                                            `flight_group_flight.${index}.destination`,
                                            ""
                                          );
                                        }}
                                        disabled={
                                          singleFlightData?.flt_group_id
                                        }
                                      >
                                        <MdClose />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                            {drp === `destination_${index}` ? (
                              <ClickAwayListener onClickAway={() => setdrp("")}>
                                <div className="airport_search_drp">
                                  {origanList?.length > 0 ? (
                                    origanList?.map((item) => {
                                      return (
                                        <div
                                          key={item.id}
                                          className="airport_search_drp_item"
                                          onClick={() => {
                                            let value = `${item.code}-${item.airport_name}-${item?.city}`;
                                            setFieldValue(
                                              `flight_group_flight.${index}.destination`,
                                              value
                                            );
                                            setdrp("");
                                          }}
                                        >
                                          <div className="fs-5">
                                            {item.code} - {item.airport_name}
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="airport_search_drp_item">
                                      No Record Found.
                                    </div>
                                  )}
                                </div>
                              </ClickAwayListener>
                            ) : null}

                            <p className="text-danger mt-2">
                              {errors?.flight_group_flight &&
                                errors?.flight_group_flight[index]?.origin}
                            </p>
                          </div>
                          <div className="col-1 datepickerstyle custom-datepicker-container">
                            <DatePicker
                              dateFormat="dd-MM-YYYY"
                              name={`flight_group_flight.${index}.flt_dep_date`}
                              value={
                                values?.flight_group_flight[index]?.flt_dep_date
                              }
                              disabled={singleFlightData?.flt_group_id}
                              selected={
                                new Date(
                                  values.flight_group_flight[
                                    index
                                  ]?.flt_dep_date
                                    ?.split("-")
                                    ?.reverse()
                                    ?.join("-")
                                )
                              }
                              className="date_picker "
                              calendarClassName=" custom-datepicker"
                              minDate={new Date()}
                              placeholderText="Dep Date"
                              showYearDropdown
                              scrollableYearDropdown
                              onChange={(e) => {
                                const date = `${e.getDate()}-${
                                  e.getMonth() + 1
                                }-${e.getFullYear()}`;
                                setFieldValue(
                                  `flight_group_flight.${index}.flt_dep_date`,
                                  date
                                );
                                setFieldValue(
                                  `flight_group_flight.${index}.flt_arr_date`,
                                  date
                                );
                              }}
                            />
                            <p
                              className="text-danger"
                              style={{ marginTop: -40 }}
                            >
                              {errors?.flight_group_flight &&
                                errors?.flight_group_flight[index]
                                  ?.flt_dep_date}
                            </p>
                          </div>
                          <div className="col-sm-1 datepickerstyle custom-datepicker-container">
                            <DatePicker
                              selected={
                                values.flight_group_flight[index]?.dep_time
                                  ? new Date(
                                      `1970-01-01T${values.flight_group_flight[index]?.dep_time}`
                                    )
                                  : null
                              }
                              disabled={singleFlightData?.flt_group_id}
                              autoComplete="off"
                              name={`flight_group_flight.${index}.dep_time`}
                              value={
                                values?.flight_group_flight[index]?.dep_time
                                  ? new Date(
                                      `1970-01-01T${values.flight_group_flight[index]?.dep_time}`
                                    )
                                  : null
                              }
                              className="date_picker"
                              calendarClassName="custom-datepicker"
                              onChange={(e) => {
                                const dateString = e;
                                const dateObject = new Date(dateString);

                                const hours = dateObject.getHours();
                                const minutes = dateObject.getMinutes();

                                const formattedHours = hours
                                  .toString()
                                  .padStart(2, "0");
                                const formattedMinutes = minutes
                                  .toString()
                                  .padStart(2, "0");

                                const timeString = `${formattedHours}:${formattedMinutes}`;

                                setFieldValue(
                                  `flight_group_flight.${index}.dep_time`,
                                  timeString
                                );
                                setFieldValue(
                                  `flight_group_flight.${index}.arr_time`,
                                  timeString
                                );
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={5}
                              placeholderText="Dep Time"
                              timeCaption="Time"
                              dateFormat="HH:mm" // Use "HH:mm" for 24-hour format
                              timeFormat="HH:mm" // Use "HH:mm" for 24-hour format
                            />

                            <p
                              className="text-danger "
                              style={{ marginTop: -40 }}
                            >
                              {errors?.flight_group_flight &&
                                errors?.flight_group_flight[index]?.dep_time}
                            </p>
                          </div>
                          <div className="col-1 datepickerstyle custom-datepicker-container">
                            <DatePicker
                              dateFormat={"dd-MM-yyyy"}
                              name={`flight_group_flight.${index}.flt_arr_date`}
                              value={
                                values?.flight_group_flight[index]?.flt_arr_date
                              }
                              disabled={singleFlightData?.flt_group_id}
                              selected={
                                new Date(
                                  values.flight_group_flight[
                                    index
                                  ]?.flt_dep_date
                                    ?.split("-")
                                    ?.reverse()
                                    ?.join("-")
                                )
                              }
                              className="date_picker"
                              calendarClassName=" custom-datepicker"
                              showYearDropdown
                              scrollableYearDropdown
                              placeholderText="Arrival Date"
                              minDate={
                                new Date(
                                  values.flight_group_flight[
                                    index
                                  ]?.flt_dep_date
                                    ?.split("-")
                                    ?.reverse()
                                    ?.join("-")
                                )
                              }
                              onChange={(e) => {
                                const date = `${e.getDate()}-${
                                  e.getMonth() + 1
                                }-${e.getFullYear()}`;
                                setFieldValue(
                                  `flight_group_flight.${index}.flt_arr_date`,
                                  date
                                );
                              }}
                            />
                            <p
                              className="text-danger"
                              style={{ marginTop: -40 }}
                            >
                              {errors?.flight_group_flight &&
                                errors?.flight_group_flight[index]
                                  ?.flt_arr_date}
                            </p>
                          </div>
                          <div className="col-1 datepickerstyle custom-datepicker-container">
                            <DatePicker
                              selected={
                                values.flight_group_flight[index]?.arr_time
                                  ? new Date(
                                      `1970-01-01T${values.flight_group_flight[index]?.arr_time}`
                                    )
                                  : null
                              }
                              disabled={singleFlightData?.flt_group_id}
                              name={`flight_group_flight.${index}.arr_time`}
                              value={
                                values?.flight_group_flight[index].arr_time
                                  ? new Date(
                                      `1970-01-01T${values.flight_group_flight[index]?.arr_time}`
                                    )
                                  : null
                              }
                              autoComplete="off"
                              className="date_picker"
                              calendarClassName=" custom-datepicker"
                              onChange={(e) => {
                                const dateString = e;
                                const dateObject = new Date(dateString);

                                const hours = dateObject.getHours();
                                const minutes = dateObject.getMinutes();

                                const formattedHours = hours
                                  .toString()
                                  .padStart(2, "0");
                                const formattedMinutes = minutes
                                  .toString()
                                  .padStart(2, "0");

                                const timeString = `${formattedHours}:${formattedMinutes}`;
                                setFieldValue(
                                  `flight_group_flight.${index}.arr_time`,
                                  timeString
                                );
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={5}
                              placeholderText="Arrival Time"
                              timeCaption="Time"
                              dateFormat="HH:mm" // Use "HH:mm" for 24-hour format
                              timeFormat="HH:mm" // Use "HH:mm" for 24-hour format
                            />
                            <p
                              className="text-danger "
                              style={{ marginTop: -40 }}
                            >
                              {errors?.flight_group_flight &&
                                errors?.flight_group_flight[index]?.arr_time}
                            </p>
                          </div>
                          <div className="col-1">
                            <TextField
                              id="standard-basic"
                              label="Baggage Info"
                              autoComplete="off"
                              variant="standard"
                              className="w-100"
                              type={"text"}
                              value={values.flight_group_flight[index]?.baggage}
                              name={`flight_group_flight.${index}.baggage`}
                              disabled={singleFlightData?.flt_group_id}
                              onChange={(e) =>
                                setFieldValue(
                                  `flight_group_flight.${index}.baggage`,
                                  e.target.value
                                    .replace(/[^a-z,^0-9 ]/gi, "")
                                    .toUpperCase()
                                )
                              }
                            />
                            <p className="text-danger mt-2">
                              {errors?.flight_group_flight &&
                                errors?.flight_group_flight[index]?.baggage}
                            </p>
                          </div>
                          <div className="col-1">
                            {index === 0 && (
                              <button
                                type="button"
                                className="button_style px-2"
                                onClick={() =>
                                  arrayHelpers.push({
                                    flight: "",
                                    flt_no: "",
                                    origin: "",
                                    destination: "",
                                    flt_dep_date: new Date()
                                      ?.toISOString()
                                      ?.split("T")[0]
                                      ?.split("-")
                                      .reverse()
                                      .join("-"),
                                    dep_time: "",
                                    flt_arr_date: new Date()
                                      .toISOString()
                                      ?.split("T")[0]
                                      ?.split("-")
                                      ?.reverse()
                                      ?.join("-"),
                                    arr_time: "",
                                    baggage: "",
                                  })
                                }
                                disabled={
                                  values.flight_group_flight.length === 4 ||
                                  singleFlightData?.flt_group_id
                                }
                              >
                                + FLIGHT
                              </button>
                            )}
                            {index > 0 && (
                              <button
                                type="button"
                                className="button_style bg-danger px-2"
                                onClick={() => arrayHelpers.remove(index)}
                                disabled={singleFlightData?.flt_group_id}
                              >
                                - FLIGHT
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
                {singleFlightData?.flt_group_id ? (
                  <div className="text-center py-4">
                    <button
                      className="button_style px-5 py-3"
                      type="submit"
                      disabled={!editPermission}
                      // disabled={isLoading ? true : false}
                    >
                      {loading ? <BtnLoader /> : "Update"}
                    </button>
                  </div>
                ) : (
                  <div className="text-center py-4">
                    <button
                      className="button_style px-5 py-3"
                      type="submit"
                      disabled={!addPermission}
                      // disabled={isLoading ? true : false}
                    >
                      {loading ? <BtnLoader /> : "Save"}
                    </button>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>

      {!singleFlightData?.flt_group_id && (
        <div className="col-11 mx-auto rounded-2 shadow-sm bg-white">
          <p className="h4 otawix-heading box_title">Groups</p>
          <div className="d-flex mb-5 p-4">
            {FlightGrpType?.map((it) => {
              return (
                <div>
                  <li
                    className="list-unstyled cursorpointer"
                    onClick={() => {
                      let data = it?.type_name;
                      localStorage.setItem("flight", JSON.stringify(data));
                      window.open("/flightgroupdetails", "_blank");
                    }}
                  >
                    <li className="nav-link">
                      <p className="color_primary border py-4 px-5 shadow-sm rounded fs-6 fw-bold">
                        {it.type_name}
                      </p>
                    </li>
                  </li>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default FlightsGroup;
