import { useLocation, useNavigate, useParams } from "react-router-dom";
import iata from "../../../../assets/images/iata.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import moment from "moment";
import { apiUrl } from "../../../../Utils/configUrl";
import ReactToPrint from "react-to-print";
import { AsyncCreateAgentStatment } from "../../../../redux/features/Statement/StatementSlice";
const JournalLedgerTable = () => {
  const dispatch = useDispatch();
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);
  let cumulativeBalance = 0;
  const calculateCumulativeBalance = (transaction) => {
    const debit = transaction?.acc_voucher_trans?.at(0)?.d_cur_debit;
    const credit = transaction?.acc_voucher_trans?.at(0)?.d_cur_credit;
    cumulativeBalance += debit - credit;
    return cumulativeBalance;
  };
  // const transformSign = (balance) => {
  //   return balance < 0 ? Math.abs(balance) : -Math.abs(balance);
  // };

  const componentRef = useRef();
  const firstColor = adminAgencyData?.first_color;
  const { id } = useParams();
  const location = useLocation();
  const agentData = useSelector((state) => state.Statement);
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const queryParams = new URLSearchParams(location.search);
  const fromDate = queryParams.get("fromDate");
  const op = queryParams.get("op");
  const getAdminData = queryParams.get("getAdminData");
  const toDate = queryParams.get("toDate");
  const acc_title = queryParams.get("acc_title");
  const agentOrSupllier = queryParams.get("agentOrSupllier");
  useEffect(() => {
    const body = {
      fromDate: moment(fromDate).format("YYYY-MM-DD"),
      toDate: moment(toDate).format("YYYY-MM-DD"),
      accID: Number(id),
      curr: "PKR",
      op: op,
      salin: 4,
      vch: [1, 2, 3],
      cn: 5,
    };
    dispatch(AsyncCreateAgentStatment({ body }));
  }, [fromDate]);
  return (
    <div className="d-flex  flex-column gap-4 bg-white">
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      {/* bottom setion */}
      <div className="d-flex flex-column gap-3  mt-4" ref={componentRef}>
        {/* logo */}
        <div className="d-flex justify-content-around align-items-center">
          <div style={{ width: "220px" }}>
            <img
              src={`${apiUrl}/${getAdminData}`}
              alt="logo"
              style={{ width: "80%", height: "auto" }}
            />
          </div>
          <div
            className="d-flex fw-bold fs-6  flex-column gap-2 align-items-center"
            style={{ width: "1200px" }}
          >
            <span className=" text-black">
              {" "}
              {userData?.agents_comp?.agent_name}
            </span>
            <span className="text-black">
              {" "}
              {userData?.agents_comp?.address
                ?.split("]")
                ?.slice(0, 3)
                ?.join(" ")}{" "}
            </span>
            <span className=" text-black"> Email: {userData?.email}</span>
            <span className=" text-black"> Contact:{userData?.mobile_no}</span>
            <span className=" text-black">
              {" "}
              From: {moment(fromDate)?.format("DD-MMM-YY").toUpperCase()}
            </span>
            <span className=" text-black">
              {" "}
              To: {moment(toDate)?.format("DD-MMM-YY").toUpperCase()}
            </span>
          </div>
          <div style={{ width: "200px" }}>
            <img
              src={iata}
              alt="iata"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white fw-bold my-2"> {acc_title}</span>
        </div>

        <div
          className="d-flex justify-content-center  align-items-center mx-1  "
          style={{
            marginBottom: "80px",
          }}
        >
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>VN NO</th>
                <th>DATE</th>
                <th>DESCRIPTION</th>
                <th>DEBIT</th>
                <th>CREDIT</th>
                <th>BALANCE</th>
              </tr>
            </thead>
            <tbody>
              {[
                {
                  acc_voucher_trans: [
                    {
                      d_cur_credit: 0,
                      d_cur_debit: agentData?.agentStatement?.total || 0,
                      trans_description: "Opening balance",
                    },
                  ],
                },
                ...(Array.isArray(agentData?.agentStatement?.filterData)
                  ? agentData?.agentStatement?.filterData
                  : []),
              ]?.map((transaction, index) => (
                <tr
                  key={index}
                  className={transaction?.approved_date ? "text-primary" : ""}
                >
                  {index === 0 ? (
                    <>
                      <td colSpan="5" className=" text-center">
                        {transaction?.acc_voucher_trans
                          ?.at(0)
                          ?.trans_description?.toUpperCase()}
                      </td>
                      <td colSpan="1">
                        {agentOrSupllier === "supplier"
                          ? Math.abs(
                              calculateCumulativeBalance(transaction)
                            )?.toFixed(2)
                          : calculateCumulativeBalance(transaction)?.toFixed(2)}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="" style={{ cursor: "pointer" }}>
                        {transaction?.voucher_no}
                      </td>
                      <td>{transaction?.saved_date?.toUpperCase()}</td>
                      <td>
                        {
                          transaction?.acc_voucher_trans?.at(0)
                            ?.trans_description
                        }
                      </td>
                      <td>
                        {transaction?.acc_voucher_trans?.at(0)?.d_cur_debit}
                      </td>
                      <td>
                        {transaction?.acc_voucher_trans?.at(0)?.d_cur_credit}
                      </td>
                      <td>
                        {agentOrSupllier === "supplier"
                          ? Math.abs(
                              calculateCumulativeBalance(transaction)
                            )?.toFixed(2)
                          : calculateCumulativeBalance(transaction)?.toFixed(2)}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* {!agentData?.agentStatement?.filterData?.length ? (
            <div className="d-flex justify-content-center align-item-center py-0">
              <h1>No Data</h1>
            </div>
          ):""} */}
      </div>
    </div>
  );
};

export default JournalLedgerTable;
