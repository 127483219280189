
import React from "react";

const VisaContact =({agencyData,user})=>{
return (
  <div className="">
    <div className="mt-5 booked_items">
      <div className="d-flex bg_primary w-100 align-items-center  text-white p-2 rounded">
        <p
          className="h5"
          style={{ lineHeight: 1, marginLeft: 6, paddingTop: 5 }}
        >
          Agency Data
        </p>
      </div>
      <div className="col-sm-12 accordian-custom-body px-3 mt-2 booktablebody d-flex gap-5">
        <div className="">
          <p>Name:</p>
          <p>Email:</p>
          <p>Phone:</p>
          <p>Address:</p>
        </div>
        <div className="">
          <p>{agencyData?.agent_name}</p>
          <p>{agencyData?.email}</p>
          <p>{agencyData?.mobile_no}</p>
          <p>{agencyData?.address?.split("]")?.at(0)}</p>
        </div>
      </div>
    </div>
    <div className="booked_items">
      <div className="d-flex bg_primary w-100 align-items-center  text-white p-2 rounded">
        <p
          className="h5"
          style={{ lineHeight: 1, marginLeft: 6, paddingTop: 5 }}
        >
          User Data(Book/request by)
        </p>
      </div>
      <div className="col-sm-12 accordian-custom-body px-3 mt-2 booktablebody d-flex gap-5">
        <div className="">
          <p>Name:</p>
          <p>Email:</p>
        </div>
        <div className="">
          <p>{user?.f_name + " " + user?.l_name}</p>
          <p>{user?.email}</p>
        </div>
      </div>
    </div>
  </div>
);
}
export default VisaContact