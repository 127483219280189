import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "../Styles/manualInvoices.css";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Reports from "../components/Reports/Reports";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetSaleReportData,
  setChangeDailyInvoice,
  setChangeInvoice,
} from "../redux/features/Finance/Sale/SaleReportSlice";
import BtnLoader from "../components/AppForm/BtnLoader";
import { asyncGetAdmin } from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";

function DailySaleReport() {
  const [startDate, setStartDate] = React.useState("");
  const [service, setService] = useState("");
  const { changeInvoice, saleReportData } = useSelector(
    (state) => state.SaleReport
  );
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const { searchAgency, getAdminData } = useSelector(
    (state) => state.newAgency
  );
  const [saleReport, setSaleReport] = useState({
    fromDate: new Date(),
    voucher_type_id: [4],
    report: "DailySaleReport",
    service_type: "Select All",
    check: "Default",
  });

  const isButtonDisabled = () => {
    return saleReport?.service_type === "";
  };
  const dispatch = useDispatch();
  const arry = [
    { id: 0, type: "Select All" },
    { id: 1, type: "Flight" },
    { id: 2, type: "Visa" },
    { id: 3, type: "Hotel" },
    { id: 4, type: "Insurance" },
    { id: 5, type: "Transfer" },
    { id: 6, type: "Activity" },
    // { id: 7, type: "Umrah" },
  ];
  const [message, setMessage] = useState(true);
  useEffect(() => {
    dispatch(asyncGetAdmin({ id: 1 }));
  }, {});
  const handleGenerateReport = () => {
    const queryParams = {
      ...saleReport,
      service_type:
        saleReport?.check === "Default" &&
        saleReport?.service_type === "Select All"
          ? ""
          : saleReport?.check === "Cn"
          ? "Flight"
          : saleReport?.service_type,
      voucher_type_id: saleReport?.check === "Cn" ? [4, 5] : [4],
    };
    setMessage(false);
    setTimeout(() => {
      setMessage(true);
      dispatch(asyncGetSaleReportData(queryParams));
      dispatch(setChangeInvoice("dailyInvoiceReport"));
    }, 3000);
  };
  const editVoucherPermission = ![1, 6, 7].includes(userData.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === userData?.user_id &&
          it?.page_level?.permission_url === "/voucher-edit"
      )
    : true;
  return (
    <div>
      {changeInvoice === "dailyInvoice" && (
        <div className="finance-container Full_height">
          <div className="manual_invoices mx-auto  mt-5">
            <div className="manual_invoices_header ">
              <div className="w-100 text-center">Daily Sale Report</div>
            </div>

            <div className="row align-items-end py-4 mx-5">
              <div className="col-12 col-md-6 pe-4">
                <FormControl variant="standard" className="w-100" sx={{}}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Services
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name={service}
                    value={saleReport?.service_type}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSaleReport((prev) => ({
                        ...prev,
                        service_type: value,
                        check:  value !== "Flight" && "Default",
                      }));
                    }}
                    label="Services"
                  >
                    {arry.map((list) => (
                      <MenuItem
                        value={list?.type}
                        sx={{ bgcolor: "#fff" }}
                        key={list?.id}
                      >
                        {list?.type?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-12 col-md-6">
                <ReactDatePicker
                  placeholderText="Date"
                  selected={saleReport?.fromDate}
                  onChange={(date) => {
                    setSaleReport((prev) => ({
                      ...prev,
                      fromDate: date,
                    }));
                  }}
                  maxDate={new Date()}
                  className="date_picker "
                />
              </div>
              <div className="d-flex">
                <span
                  className="form-control me-5"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    paddingTop: "40px",
                    paddingLeft: "10px",
                  }}
                >
                  <input
                    type="radio"
                    id="without_statement"
                    value="cheque"
                    name="currency"
                    checked={saleReport?.check === "Default"}
                    onChange={() =>
                      setSaleReport({
                        ...saleReport,
                        check: "Default",
                      })
                    }
                  />
                  <label for="without_statement" className="h6 mt-1">
                    Default
                  </label>
                </span>

                {
                saleReport?.service_type === "Flight" ? (
                  <>
                    {/* <span
                      className="form-control me-5"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 8,
                        paddingTop: "40px",
                        paddingLeft: "10px",
                      }}
                    >
                      <input
                        type="radio"
                        id="with_sar"
                        value="cheque"
                        name="currency"
                        checked={saleReport?.check === "All"}
                        onChange={() =>
                          setSaleReport({
                            ...saleReport,
                            check: "All",
                          })
                        }
                      />
                      <label for="with_sar" className="h6 mt-1">
                        All Data
                      </label>
                    </span> */}

                    <span
                      className="form-control me-5"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 8,
                        paddingTop: "40px",
                        paddingLeft: "10px",
                      }}
                    >
                      <input
                        type="radio"
                        id="with_sar"
                        value="cheque"
                        name="currency"
                        checked={saleReport?.check === "Cn"}
                        onChange={() =>
                          setSaleReport({
                            ...saleReport,
                            check: "Cn",
                          })
                        }
                      />
                      <label for="with_sar" className="h6 mt-1">
                        WITH CN
                      </label>
                    </span>
                  </>
                ) : null}
              </div>
            </div>
            <div className="container py-4">
              {message ? (
                <div className="text-center py-4">
                  <button
                    className="button_style px-4"
                    onClick={handleGenerateReport}
                    disabled={isButtonDisabled()}
                  >
                    Generate Report
                  </button>
                </div>
              ) : (
                <div className="text-center py-4">
                  <button className="button_style px-4">
                    <BtnLoader />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {changeInvoice === "dailyInvoiceReport" && (
        <div>
          <Reports
            data={saleReportData}
            report="dailyInvoice"
            text="Daily Sale Report"
            summary={saleReport}
            getAdminData={getAdminData?.logo}
            editVoucherPermission={editVoucherPermission}
            userData={userData}
          />
        </div>
      )}
    </div>
  );
}

export default DailySaleReport;
