import React, { useEffect, useRef, useState } from "react";
import { asyncGetAdmin } from "../../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  resetOpening,
  setOpeningBalance,
} from "../../../../redux/features/OpeningBalance/openingBalance";
import moment from "moment";
import { apiUrl } from "../../../../Utils/configUrl";
import iata from "../../../../assets/images/iata.png";
import ReactToPrint from "react-to-print";
import { Link, useNavigate } from "react-router-dom";
import BtnLoader from "../../../AppForm/BtnLoader";
const OpTable = ({
  accountData,
  fromDate,
  toDate,
  dropdownValue,
  //   handleTitleClick,
}) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { data, opening } = useSelector((state) => state?.openingBalance);
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);
  const { getAdminData } = useSelector((state) => state.newAgency);
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const firstColor = adminAgencyData?.first_color;

  useEffect(() => {
    dispatch(asyncGetAdmin({ id: 1 }));
  }, [dispatch]);

  function groupedAccounts(accounts) {
    const grouped = [];
    const totals = {
      assets: 0,
      liabilities: 0,
      capital: 0,
    };

    // Helper function to find and add child accounts to their parent
    function addChildren(parent, allAccounts) {
      const children = allAccounts.filter(
        (account) => account.acc_parent === parent.acc_id
      );
      children.forEach((child) => {
        grouped.push(child);
        addChildren(child, allAccounts); // Recursively add children's children
        // Update total for the parent category
        if (parent.acc_title === "ASSETS") {
          totals.assets += child.closingDebitAmount + child.closingCreditAmount;
        } else if (parent.acc_title === "LIABILITIES") {
          totals.liabilities +=
            child.closingDebitAmount + child.closingCreditAmount;
        } else if (parent.acc_title === "CAPITAL") {
          totals.capital +=
            child.closingDebitAmount + child.closingCreditAmount;
        }
      });
    }

    // Filter accounts to exclude 'EXPENSES' and 'INCOME'
    const filteredAccounts = accounts?.filter((account) => {
      return (
        !account.acc_title.includes("EXPENSES") &&
        !account.acc_title.includes("INCOME")
      );
    });

    // Start by adding root-level accounts (those with no acc_parent)
    filteredAccounts
      ?.filter((account) => account.acc_parent === 0)
      ?.forEach((account) => {
        grouped.push(account);
        addChildren(account, accounts);
      });

    // Add total rows for each parent category
    if (totals.assets >= 0 || totals.assets === "0") {
      grouped.push({
        acc_title: "ASSETS",
        totalAmount: totals.assets,
        isTotal: true,
      });
    }
    if (totals.liabilities >= 0) {
      grouped.push({
        acc_title: "LIABILITIES",
        totalAmount: totals.liabilities,
        isTotal: true,
      });
    }
    if (totals.capital >= 0) {
      grouped.push({
        acc_title: "CAPITAL",
        totalAmount: totals.capital,
        isTotal: true,
      });
    }
     return grouped;
  }
  const grouped = groupedAccounts(accountData?.accountTotals);
  const assets =grouped?.filter((acc) =>acc.acc_title === "ASSETS" && acc.isTotal)?.at(0)?.totalAmount
  const liabilities =grouped?.filter((acc) =>acc.acc_title === "LIABILITIES" && acc.isTotal)?.at(0)?.totalAmount
  const capital =grouped?.filter((acc) =>acc.acc_title === "CAPITAL" && acc.isTotal)?.at(0)?.totalAmount
  
  function getIndentationLevel(account) {
    const level = account.acc_level_code.split("-").length;
    return level * 10; // Adjust the multiplier for desired indentation width
  }

  const handleTitleClick = (account) => {
    const queryParams = new URLSearchParams({
      acc_title: account.acc_title,
      fromDate: fromDate?.toISOString(),
      toDate: toDate?.toISOString(),
      op: opening,
      getAdminData: getAdminData.logo,
    }).toString();

    const url = `/accounts/${account.acc_id}?${queryParams}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // const findLastChild = (accountId) => {
  //   // Find children of the given account
  //   const children = groupedAccounts(accountData?.accountTotals)
  //     .filter((account) => account.acc_nature !== "SUBSIDIARY")
  //     .filter((account) => account.acc_parent === accountId);

  //   // If there are no children, return null
  //   if (!children || children.length === 0) {
  //     return null;
  //   }

  //   // Recursively find the last child
  //   return (
  //     findLastChild(children[children.length - 1].acc_id) ||
  //     children[children.length - 1]
  //   );
  // };
  function cachedLastChild(accountId) {
    
    const children = grouped.filter(
      (account) => account.acc_nature !== "SUBSIDIARY" && account.acc_parent === accountId
    );
  
    let lastChild = null;
    for (let child of children) {
      const grandChild = cachedLastChild(child.acc_id);
      lastChild = grandChild || child;
    }
    return lastChild;
  }


  const handleBack = (event) => {
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch(resetOpening());
      dispatch(setOpeningBalance("form"));
    }, 1000);
  };

  return (
    <div
      //  className="w-100"
      className="d-flex  flex-column gap-4 bg-white "
      style={{ width: "100%" }}
    >
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />

          {!loading ? (
            <div>
              <button
                onClick={handleBack}
                className="bg_primary px-4 py-1 rounded text-white"
              >
                Back
              </button>
            </div>
          ) : (
            <div className="text-center py-4">
              <button className="button_style px-4">
                <BtnLoader />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-column gap-3  mt-4" ref={componentRef}>
        <div className="d-flex justify-content-around align-items-center">
          <div style={{ width: "220px" }}>
            <img
              src={`${apiUrl}/${getAdminData?.logo}`}
              alt="logo"
              style={{ width: "80%", height: "auto" }}
            />
          </div>
          <div
            className="d-flex fw-bold fs-6  flex-column gap-2 align-items-center"
            style={{ width: "1200px" }}
          >
            <span className=" text-black">
              {" "}
              {/* {userData?.agents_comp?.agent_name} */}
            </span>
            <span className=" text-black">
              {" "}
              {userData?.agents_comp?.agent_name}
            </span>
            <span className="text-black">
              {" "}
              {userData?.agents_comp?.address
                ?.split("]")
                ?.slice(0, 3)
                ?.join(" ")}{" "}
            </span>
            <span className=" text-black"> Email: {userData?.email}</span>
            <span className=" text-black"> Contact:{userData?.mobile_no}</span>
            <span className=" text-black">
              {" "}
              From: {moment(fromDate)?.format("DD-MMM-YY").toUpperCase()}
            </span>
            <span className=" text-black">
              {" "}
              To: {moment(toDate)?.format("DD-MMM-YY").toUpperCase()}
            </span>
          </div>
          <div style={{ width: "200px" }}>
            <img
              src={iata}
              alt="iata"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white fw-bold my-2"> Balance Sheet</span>
        </div>
        {/* {isSubmitted && ( */}
        <div className="d-flex justify-content-center align-items-center">
          <div
            //   className="container d-flex justify-content-end mt-1"
            className="d-flex flex-column gap-3  mt-4 align-items-center"
            style={{ width: "97%", height: "20%" }}
          >
            <table
              className="table table-bordered  table-sm table-striped"
              style={{ width: "100%", height: "20%", fontSize: "0.8rem" }}
            >
              {/* <thead>
                
              </thead> */}
              <tbody>
  {groupedAccounts(accountData?.accountTotals)
    .filter((account) => account.acc_nature !== "SUBSIDIARY")
    .map((account, index, accountsArray) => {
      const subs = account.acc_nature === "SUBSIDIARY";
      const hasSubsidiaryChild = accountData?.accountTotals?.some(
        (childAccount) =>
          childAccount.acc_parent === account.acc_id &&
          childAccount.acc_nature === "SUBSIDIARY"
      );

      // Precompute important IDs for efficiency
      {/* const assetsId = accountData?.accountTotals?.find((account) => account.acc_title === "ASSETS")?.acc_id;
      const liabilitiesId = accountData?.accountTotals?.find((account) => account.acc_title === "LIABILITIES")?.acc_id;
      const capitalId = accountData?.accountTotals?.find((account) => account.acc_title === "CAPITAL")?.acc_id;

      // Cache last child calculations to avoid repeated function calls
      const lastChildOfAssets = cachedLastChild(assetsId);
      const lastChildOfLiabilities = cachedLastChild(liabilitiesId);
      const lastChildOfCapital = cachedLastChild(capitalId); */}

      const accountRow = (
        <tr key={index}>
          <td
            className="hover"
            style={{
              paddingLeft: account.isTotal ? "0px" : `${getIndentationLevel(account)}px`,
              fontWeight: account.acc_level === 1 ? "bold" : "normal",
              cursor: hasSubsidiaryChild ? "pointer" : "default",
              transition: "font-size 0.3s ease",
              color: hasSubsidiaryChild ? "blue" : "black",
            }}
            onClick={hasSubsidiaryChild ? () => handleTitleClick(account) : null}
          >
            {!account.isTotal ? account.acc_title : ""}
          </td>

          {/* Closing Debit and Credit */}
          {(dropdownValue === "BALANCE" || dropdownValue === "All Type") && (
            <td
              style={{
                padding: "2px",
                textAlign: "right",
                width: "100px",
                fontWeight: account.isTotal ? "bold" : subs ? "normal" : "bold",
              }}
            >
              {!account.isTotal
                ? account.closingDebitAmount + account.closingCreditAmount
                : ""}
            </td>
          )}
        </tr>
      );

      // Conditional total row rendering based on last child check
      if (account.acc_id === data.lastChildOfAssets?.acc_id) {
        return renderTotalRow(index, accountRow, "ASSETS", assets);
      }
      if (account.acc_id === data.lastChildOfLiabilities?.acc_id) {
        return renderTotalRow(index, accountRow, "LIABILITIES", liabilities);
      }
      if (account.acc_id === data.lastChildOfCapital?.acc_id) {
        return renderTotalRow(index, accountRow, "CAPITAL", capital);
      }

      return accountRow;
    })}
</tbody>
              
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

function renderTotalRow(index, accountRow, title, totalAmount) {
  return (
    <React.Fragment key={index}>
      {accountRow}
      <tr key={`${title.toLowerCase()}-total`}>
        <td
          className="hover"
          style={{
            paddingLeft: "0px",
            fontWeight: "bold",
            borderTop: "2px solid black",
            borderBottom: "2px solid black",
          }}
        >
          {title} TOTAL
        </td>
        <td
          style={{
            padding: "5px",
            textAlign: "right",
            width: "100px",
            fontWeight: "bold",
            borderTop: "2px solid black",
            borderBottom: "2px solid black",
          }}
        >
          {totalAmount.toFixed(2)}
        </td>
      </tr>
      <tr>
        <td style={{ height: "30px" }} colSpan="2"></td>
      </tr>
    </React.Fragment>
  );
}

export default OpTable;




























// <tbody>
//                 {groupedAccounts(accountData?.accountTotals)
//                   .filter((account) => account.acc_nature !== "SUBSIDIARY")
//                   .map((account, index, accountsArray) => {
//                     const subs = account.acc_nature === "SUBSIDIARY";
//                     const hasSubsidiaryChild = accountData?.accountTotals?.some(
//                       (childAccount) =>
//                         childAccount.acc_parent === account.acc_id &&
//                         childAccount.acc_nature === "SUBSIDIARY"
//                     );

                    
//                     const assetsId = accountData?.accountTotals?.find(
//                       (account) => account.acc_title === "ASSETS"
//                     ).acc_id;
//                     const liabilitiesId = accountData?.accountTotals?.find(
//                       (account) => account.acc_title === "LIABILITIES"
//                     ).acc_id;
//                     const capitalId = accountData?.accountTotals?.find(
//                       (account) => account.acc_title === "CAPITAL"
//                     ).acc_id;

                   
//                     const lastChildOfAssets = findLastChild(assetsId);
//                     const lastChildOfLiabilities = findLastChild(liabilitiesId);
//                     const lastChildOfCapital = findLastChild(capitalId);

                   
//                     const accountRow = (
//                       <tr key={index}>
//                         <td
//                           className="hover"
//                           style={{
//                             paddingLeft: account.isTotal
//                               ? "0px"
//                               : `${getIndentationLevel(account)}px`,
//                             fontWeight:
//                               account.acc_level === 1
//                                 ? "bold"
//                                 : "normal"
//                                 ? "normal"
//                                 : "bold",
//                             cursor: hasSubsidiaryChild ? "pointer" : "default",
//                             transition: "font-size 0.3s ease",
//                             color: hasSubsidiaryChild ? "blue" : "black",
//                           }}
//                           onClick={
//                             hasSubsidiaryChild
//                               ? () => handleTitleClick(account)
//                               : null
//                           }
//                         >
//                           {!account.isTotal ? account.acc_title : ""}
//                         </td>

                        
//                         {(dropdownValue === "BALANCE" ||
//                           dropdownValue === "All Type") && (
//                           <td
//                             style={{
//                               padding: "2px",
//                               textAlign: "right",
//                               width: "100px",
//                               fontWeight: account.isTotal
//                                 ? "bold"
//                                 : subs
//                                 ? "normal"
//                                 : "bold",
//                             }}
//                           >
//                             {!account.isTotal
//                               ? account.closingDebitAmount +
//                                 account.closingCreditAmount
//                               : ""}
//                           </td>
//                         )}
//                       </tr>
//                     );

                   
//                     if (account.acc_id === lastChildOfAssets.acc_id) {
                      
//                       return (
//                         <React.Fragment key={index}>
//                           {accountRow}
                         
//                           <tr key="assets-total">
//                             <td
//                               className="hover"
//                               style={{
//                                 paddingLeft: "0px",
//                                 fontWeight: "bold",
                               
//                                 borderTop: "2px solid black",
//                                 borderBottom: "2px solid black",
//                               }}
//                             >
//                               ASSETS TOTAL
//                             </td>
//                             <td
//                               style={{
//                                 padding: "5px",
//                                 textAlign: "right",
//                                 width: "100px",
//                                 fontWeight: "bold",
                                
//                                 borderTop: "2px solid black",
//                                 borderBottom: "2px solid black",
//                               }}
//                             >
//                               {
//                                 groupedAccounts(accountData?.accountTotals)
//                                   .filter(
//                                     (acc) =>
//                                       acc.acc_title === "ASSETS" && acc.isTotal
//                                   )
//                                   .at(0).totalAmount
//                               }
//                             </td>
//                           </tr>
//                           <tr>
//                             <td style={{ height: "30px" }} colSpan="2"></td>
//                           </tr>
//                         </React.Fragment>
//                       );
//                     }

//                     // Check if the current account is the last child of LIABILITIES
//                     if (account.acc_id === lastChildOfLiabilities?.acc_id) {
//                       // Render the total row right after the last child of LIABILITIES
//                       return (
//                         <React.Fragment key={index}>
//                           {accountRow}
//                           <tr key="liabilities-total">
//                             <td
//                               style={{
//                                 paddingLeft: "0px",
//                                 fontWeight: "bold",
//                                 // fontSize: "1.2rem",
//                                 borderTop: "2px solid black",
//                                 borderBottom: "2px solid black",
//                               }}
//                             >
//                               LIABILITIES TOTAL
//                             </td>
//                             <td
//                               style={{
//                                 padding: "5px",
//                                 textAlign: "right",
//                                 width: "100px",
//                                 fontWeight: "bold",
//                                 // fontSize: "1.2rem",
//                                 borderTop: "2px solid black",
//                                 borderBottom: "2px solid black",
//                               }}
//                             >
//                               {
//                                 groupedAccounts(accountData?.accountTotals)
//                                   .filter(
//                                     (acc) =>
//                                       acc.acc_title === "LIABILITIES" &&
//                                       acc.isTotal
//                                   )
//                                   .at(0).totalAmount
//                               }
//                             </td>
//                           </tr>
//                           <tr>
//                             <td style={{ height: "30px" }} colSpan={2}></td>
//                           </tr>
//                         </React.Fragment>
//                       );
//                     }

//                     // Check if the current account is the last child of CAPITAL
//                     if (account.acc_id === lastChildOfCapital?.acc_id) {
//                       // Render the total row right after the last child of CAPITAL
//                       return (
//                         <React.Fragment key={index}>
//                           {accountRow}
//                           <tr key="capital-total">
//                             <td
//                               style={{
//                                 paddingLeft: "0px",
//                                 fontWeight: "bold",
//                                 // fontSize: "1.2rem",
//                                 borderTop: "2px solid black",
//                                 borderBottom: "2px solid black",
//                               }}
//                             >
//                               CAPITAL TOTAL
//                             </td>
//                             <td
//                               style={{
//                                 padding: "5px",
//                                 textAlign: "right",
//                                 width: "100px",
//                                 fontWeight: "bold",
//                                 // fontSize: "1.2rem",
//                                 borderTop: "2px solid black",
//                                 borderBottom: "2px solid black",
//                               }}
//                             >
//                               {groupedAccounts(accountData?.accountTotals)
//                                     .filter(
//                                       (acc) =>
//                                         acc.acc_title === "CAPITAL" &&
//                                         acc.isTotal === true
//                                     )
//                                     ?.at(0).totalAmount
//                                }
//                             </td>
//                           </tr>
                         
//                         </React.Fragment>
//                       );
//                     }
//                     return accountRow;
//                   })}
//               </tbody>
