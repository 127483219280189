import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CurrencyCode,
  CurrencyExchange,
} from "../../../Utils/currencyExchange";
import { pricingairline } from "../../../Utils/airlinePricing";
import { getSectorsName } from "../../../Utils/FlightSector";
import { TwoDecimalPoint } from "../../../Utils/FixedTwoDecimal";

const SideBar = ({
  agent,
  selectedAncillaries,
  values,
  isExclusiveSector,
  isCodeShare,
  isEconomy,
  segmentLength,
}) => {
  const [totalFlightFare, setTotalFlightFare] = useState();
  let { selectedFlight, returnFlight } = useSelector(
    (state) => state.FlightSearch
  );

  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  /// search values from local storage
  const localsearchValues = JSON.parse(
    localStorage.getItem("flightSearchData")
  ); // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === selectedFlight?.code
  );

  //airline
  const airline = addAirLinesData?.find(
    (it) => it.code_alpha === selectedFlight?.code
  );

  // flight sector
  const sector = getSectorsName(
    localsearchValues?.locationDep,
    localsearchValues?.locationArrival
  );

  /////////////////// calculate pax taxes

  const paxTaxes = (obTax, inTax, totalpax) => {
    if (returnFlight) {
      return (
        pricingairline(airline, pricing, CurrencyExchange(inTax), 0, sector)
          ?.total_fare * totalpax
      );
    }
    // else if (returnFlight && returnFlight?.code !== "PK") {
    //   return (
    //     pricingairline(airline, pricing, CurrencyExchange(obTax), 0, sector)
    //       ?.total_fare +
    //     pricingairline(airline, pricing, CurrencyExchange(inTax), 0, sector)
    //       ?.total_fare *
    //       totalpax
    //   );
    // }
    else {
      return (
        pricingairline(airline, pricing, CurrencyExchange(obTax), 0, sector)
          ?.total_fare * totalpax
      );
    }
  };
  /// calcute total fare according to aitline pricing
  useEffect(() => {
    if (returnFlight) {
      let adult =
        pricingairline(
          airline,
          pricing,
          CurrencyExchange(Math.round(returnFlight?.adultBaseFare)),
          CurrencyExchange(Math.round(returnFlight?.adultTaxes)),
          sector,
          isExclusiveSector,
          isCodeShare,
          isEconomy,
          segmentLength
        )?.total_fare * localsearchValues?.adultsCount || 0;
      let child =
        pricingairline(
          airline,
          pricing,
          CurrencyExchange(returnFlight?.childBaseFare),
          CurrencyExchange(returnFlight?.childTaxes),
          sector,
          isExclusiveSector,
          isCodeShare,
          isEconomy,
          segmentLength
        )?.total_fare * localsearchValues?.childCount || 0;
      let infant =
        pricingairline(
          airline,
          pricing,
          CurrencyExchange(returnFlight?.infantBaseFare),
          CurrencyExchange(returnFlight?.infantTaxes),
          sector,
          isExclusiveSector,
          isCodeShare,
          isEconomy,
          segmentLength,
          false
        )?.total_fare * localsearchValues?.infantNo || 0;
      setTotalFlightFare(Number(adult) + Number(child) + Number(infant));
    }
    // else if (returnFlight && returnFlight?.code !== "PK") {
    //   let adult =
    //     (pricingairline(
    //       airline,
    //       pricing,
    //       CurrencyExchange(returnFlight?.adultBaseFare),
    //       CurrencyExchange(returnFlight?.adultTaxes),
    //       sector
    //     )?.total_fare +
    //       pricingairline(
    //         airline,
    //         pricing,
    //         CurrencyExchange(selectedFlight?.adultBaseFare),
    //         CurrencyExchange(selectedFlight?.adultTaxes),
    //         sector
    //       )?.total_fare) *
    //       localsearchValues?.adultsCount || 0;
    //   let child =
    //     (pricingairline(
    //       airline,
    //       pricing,
    //       CurrencyExchange(selectedFlight?.childBaseFare),
    //       CurrencyExchange(selectedFlight?.childTaxes),
    //       sector
    //     )?.total_fare +
    //       pricingairline(
    //         airline,
    //         pricing,
    //         CurrencyExchange(returnFlight?.childBaseFare),
    //         CurrencyExchange(returnFlight?.childTaxes),
    //         sector
    //       )?.total_fare) *
    //       localsearchValues?.childCount || 0;
    //   let infant =
    //     (pricingairline(
    //       airline,
    //       pricing,
    //       CurrencyExchange(selectedFlight?.infantBaseFare),
    //       CurrencyExchange(selectedFlight?.infantTaxes),
    //       sector
    //     )?.total_fare +
    //       pricingairline(
    //         airline,
    //         pricing,
    //         CurrencyExchange(returnFlight?.infantBaseFare),
    //         CurrencyExchange(returnFlight?.infantTaxes),
    //         sector
    //       )?.total_fare) *
    //       localsearchValues?.infantNo || 0;
    //   setTotalFlightFare(Number(adult) + Number(child) + Number(infant));
    // }
    else {
      let adult =
        pricingairline(
          airline,
          pricing,
          CurrencyExchange(Math.round(selectedFlight?.adultBaseFare)),
          CurrencyExchange(Math.round(selectedFlight?.adultTaxes)),
          sector,
          isExclusiveSector,
          isCodeShare,
          isEconomy,
          segmentLength
        )?.total_fare * localsearchValues?.adultsCount || 0;
      let child =
        pricingairline(
          airline,
          pricing,
          CurrencyExchange(Math.round(selectedFlight?.childBaseFare)),
          CurrencyExchange(Math.round(selectedFlight?.childTaxes)),
          sector,
          isExclusiveSector,
          isCodeShare,
          isEconomy,
          segmentLength
        )?.total_fare * localsearchValues?.childCount || 0;
      let infant =
        pricingairline(
          airline,
          pricing,
          CurrencyExchange(Math.round(selectedFlight?.infantBaseFare)),
          CurrencyExchange(Math.round(selectedFlight?.infantTaxes)),
          sector,
          isExclusiveSector,
          isCodeShare,
          isEconomy,
          segmentLength,
          false
        )?.total_fare * localsearchValues?.infantNo || 0;
      setTotalFlightFare(Number(adult) + Number(child) + Number(infant));
    }

    // Now you can use totalFlightFare for further processing if needed.
  }, [agent]);

  let ancillaryCharges = 0;
  values.pax.forEach((passenger) => {
    // Calculate inbound charges
    if (passenger?.inbound?.baggage) {
      const inboundBaggageCharge = parseFloat(
        passenger?.inbound?.baggage?.split(",")[1]
      );
      ancillaryCharges += inboundBaggageCharge;
    }
    if (passenger?.inbound?.meal) {
      const inboundMealCharge = parseFloat(passenger?.inbound?.meal?.price);
      ancillaryCharges += inboundMealCharge;
    }
    if (passenger?.inbound?.seat) {
      const inboundSeatCharge = parseFloat(
        passenger?.inbound?.seat?.split(",")[1]
      );
      ancillaryCharges += inboundSeatCharge;
    }

    // Calculate outbound charges
    if (passenger?.outbound?.baggage) {
      const outboundBaggageCharge = parseFloat(
        passenger?.outbound?.baggage?.split(",")[1]
      );
      ancillaryCharges += outboundBaggageCharge;
    }
    if (passenger?.outbound?.meal) {
      const outboundMealCharge = parseFloat(passenger?.outbound?.meal?.price);
      ancillaryCharges += outboundMealCharge;
    }
    if (passenger?.outbound?.seat) {
      const outboundSeatCharge = parseFloat(
        passenger?.outbound?.seat?.split(",")[1]
      );
      ancillaryCharges += outboundSeatCharge;
    }
  });

  let totalFare = totalFlightFare + ancillaryCharges;

  // console.log("totalFlightFare",Number(selectedFlight?.adultBaseFare)) *
  // localsearchValues?.adultsCount);

  return (
    <div className="shadow-sm borderradius p-4 py-2 bg-white">
      <div className="py-3 border-bottom">
        <h4>Fare</h4>
        {localsearchValues?.adultsCount > 0 ? (
          <div className="d-flex justify-content-between pt-2">
            <div>{localsearchValues?.adultsCount} * Adult</div>
            <div>{`${CurrencyCode()} ${TwoDecimalPoint(
              CurrencyExchange(
                (returnFlight?.code
                  ? Number(~~returnFlight?.adultBaseFare)
                  : Number(selectedFlight?.adultBaseFare)) *
                  localsearchValues?.adultsCount
              )
            )}`}</div>
          </div>
        ) : null}
        {localsearchValues?.childCount > 0 ? (
          <div className="d-flex justify-content-between my-3">
            <div>{localsearchValues?.childCount} * Child</div>
            <div>{`${CurrencyCode()} ${TwoDecimalPoint(
              CurrencyExchange(
                (returnFlight?.code
                  ? Number(returnFlight?.childBaseFare)
                  : Number(selectedFlight?.childBaseFare)) *
                  localsearchValues?.childCount
              )
            )}`}</div>
          </div>
        ) : null}
        {localsearchValues?.infantNo > 0 ? (
          <div className="d-flex justify-content-between">
            <div>{localsearchValues?.infantNo} * Infant</div>
            <div>{`${CurrencyCode()} ${TwoDecimalPoint(
              CurrencyExchange(
                (returnFlight?.code
                  ? Number(returnFlight?.infantBaseFare)
                  : Number(selectedFlight?.infantBaseFare)) *
                  localsearchValues?.infantNo
              )
            )}`}</div>
          </div>
        ) : null}
      </div>

      {/* /////////////////////////   Taxes   ///////////////////// */}

      <div className="py-3 border-bottom mb-3">
        <h4>Taxes</h4>
        {localsearchValues?.adultsCount > 0 ? (
          <div className="d-flex justify-content-between pt-2">
            <div>{localsearchValues?.adultsCount} * Adult</div>
            <div>{`${CurrencyCode()} ${TwoDecimalPoint(
              paxTaxes(
                selectedFlight?.adultTaxes,
                ~~returnFlight?.adultTaxes,
                localsearchValues?.adultsCount
              )
            )}`}</div>
          </div>
        ) : null}
        {localsearchValues?.childCount > 0 ? (
          <div className="d-flex justify-content-between my-3">
            <div>{localsearchValues?.childCount} * Child</div>
            <div>{`${CurrencyCode()} ${TwoDecimalPoint(
              paxTaxes(
                selectedFlight?.childTaxes,
                ~~returnFlight?.childTaxes,
                localsearchValues?.childCount
              )
            )}`}</div>
          </div>
        ) : null}
        {localsearchValues?.infantNo > 0 ? (
          <div className="d-flex justify-content-between">
            <div>{localsearchValues?.infantNo} * Infant</div>
            <div>{`${CurrencyCode()} ${TwoDecimalPoint(
              paxTaxes(
                selectedFlight?.infantTaxes,
                ~~returnFlight?.infantTaxes,
                localsearchValues?.infantNo
              )
            )}`}</div>
          </div>
        ) : null}
      </div>

      {values.pax.some(
        (passenger) =>
          passenger.inbound.baggage ||
          passenger.inbound.meal ||
          passenger.inbound.seat ||
          passenger.outbound.baggage ||
          passenger.outbound.meal ||
          passenger.outbound.seat ||
          passenger.inbound.service ||
          passenger.outbound.service
      ) && (
        <div>
          {/* Inbound Ancillaries */}
          {values.pax.some(
            (passenger) =>
              passenger.inbound.baggage ||
              passenger.inbound.meal ||
              passenger.inbound.seat ||
              passenger.inbound.service
          ) && (
            <div>
              <div
                className="mb-3 p-1 font-medium dark:text-neutral-300  fs-3 text-white"
                style={{ backgroundColor: "#00324E" }}
              >
                {localsearchValues.locationArrival?.split("-")[0]}-
                {localsearchValues.locationDep?.split("-")[0]}
              </div>

              {/* Selected Baggage */}
              {values.pax.some((passenger) => passenger.inbound.baggage) && (
                <div>
                  <div className="h1 fs-5 mb-1">Baggage:</div>
                  {values.pax.map(
                    (passenger, index) =>
                      passenger.inbound.baggage && (
                        <div key={index}>
                          <h6>{`${passenger.title} ${passenger.f_name}`}</h6>
                          <div className="d-flex justify-content-between">
                            <p className="w-56">
                              {passenger.inbound.baggage.split(",")[0]}
                            </p>
                            <p>
                              PKR{" "}
                              {TwoDecimalPoint(
                                passenger.inbound.baggage.split(",")[1]
                              )}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}

              {/* Selected Meal */}
              {values.pax.some((passenger) => passenger.inbound.meal) && (
                <div>
                  <div className="h1 fs-5 mb-1 mt-3 mb-3">Meal:</div>
                  {values.pax.map(
                    (passenger, index) =>
                      passenger.inbound.meal && (
                        <div key={index}>
                          <h6>{`${passenger.title} ${passenger.f_name}`}</h6>
                          <div className="d-flex justify-content-between">
                            <p className="w-56">
                              {passenger.inbound.meal
                                ? passenger.inbound.meal.mealCode
                                : "No meal selected"}
                            </p>
                            <p>
                              PKR{" "}
                              {TwoDecimalPoint(passenger.inbound.meal.price)}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}

              {/* Selected Seat */}
              {values.pax.some((passenger) => passenger.inbound.seat) && (
                <div>
                  <div className="h1 fs-5 mb-1 mt-3">Seat:</div>
                  {values.pax.map(
                    (passenger, index) =>
                      passenger.inbound.seat && (
                        <div key={index}>
                          <h6>{`${passenger.title} ${passenger.f_name}`}</h6>
                          <div className="d-flex justify-content-between">
                            <p>{passenger.inbound.seat.split(",")[0]}</p>
                            <p>
                              PKR{" "}
                              {TwoDecimalPoint(
                                passenger.inbound.seat.split(",")[1]
                              )}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}

              {/* Selected service */}
              {values.pax.some((passenger) => passenger.inbound.service) && (
                <div>
                  <div className="h1 fs-5 mb-1 mt-3 mb-3">Service:</div>
                  {values.pax.map(
                    (passenger, index) =>
                      passenger.inbound.service && (
                        <div key={index}>
                          <h6>{`${passenger.title} ${passenger.f_name}`}</h6>
                          <div className="d-flex justify-content-between">
                            <p className="w-56">
                              {passenger.inbound.service
                                ? passenger.inbound.service.split(",")[0]
                                : "No service selected"}
                            </p>
                            <p>
                              PKR{" "}
                              {TwoDecimalPoint(
                                passenger.inbound.service.split(",")?.[1]
                              )}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          )}

          {/* Outbound Ancillaries */}
          {values.pax.some(
            (passenger) =>
              passenger.outbound.baggage ||
              passenger.outbound.meal ||
              passenger.outbound.seat ||
              passenger.outbound.service
          ) && (
            <div>
              <div
                className="mb-3 p-1 font-medium dark:text-neutral-300  fs-3 text-white"
                style={{ backgroundColor: "#00324E" }}
              >
                {localsearchValues?.locationDep?.split("-")[0]}-
                {localsearchValues?.locationArrival?.split("-")[0]}
              </div>

              {/* Selected Baggage */}
              {values.pax.some((passenger) => passenger.outbound.baggage) && (
                <div>
                  <div className="h1 fs-5 mb-1">Baggage:</div>
                  {values.pax.map(
                    (passenger, index) =>
                      passenger.outbound.baggage && (
                        <div key={index}>
                          <h6>{`${passenger.title} ${passenger.f_name}`}</h6>
                          <div className="d-flex justify-content-between">
                            <p className="w-56">
                              {passenger.outbound.baggage.split(",")[0]}
                            </p>
                            <p>
                              PKR{" "}
                              {TwoDecimalPoint(
                                passenger.outbound.baggage.split(",")[1]
                              )}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}

              {/* Selected Meal */}
              {values.pax.some((passenger) => passenger.outbound.meal) && (
                <div>
                  <div className="h1 fs-5 mb-1 mt-3 mb-3">Meal:</div>
                  {values.pax.map(
                    (passenger, index) =>
                      passenger.outbound.meal && (
                        <div key={index}>
                          <h6>{`${passenger.title} ${passenger.f_name}`}</h6>
                          <div className="d-flex justify-content-between">
                            <p className="w-56">
                              {passenger.outbound.meal
                                ? passenger.outbound.meal.mealCode
                                : "No meal selected"}
                            </p>
                            <p>
                              PKR{" "}
                              {TwoDecimalPoint(passenger.outbound.meal.price)}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}

              {/* Selected Seat */}
              {values.pax.some((passenger) => passenger.outbound.seat) && (
                <div>
                  <div className="h1 fs-5 mb-1 mt-3">Seat:</div>
                  {values.pax.map(
                    (passenger, index) =>
                      passenger.outbound.seat && (
                        <div key={index}>
                          <h6>{`${passenger.title} ${passenger.f_name}`}</h6>
                          <div className="d-flex justify-content-between">
                            <p>{passenger.outbound.seat.split(",")[0]}</p>
                            <p>
                              PKR{" "}
                              {TwoDecimalPoint(
                                passenger.outbound.seat.split(",")[1]
                              ).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}

              {values.pax.some((passenger) => passenger.outbound.service) && (
                <div>
                  <div className="h1 fs-5 mb-1 mt-3 mb-3">Service:</div>
                  {values.pax.map(
                    (passenger, index) =>
                      passenger.outbound.service && (
                        <div key={index}>
                          <h6>{`${passenger.title} ${passenger.f_name}`}</h6>
                          <div className="d-flex justify-content-between">
                            <p className="w-56">
                              {passenger.outbound.service
                                ? passenger.outbound.service.split(",")[0]
                                : "No service selected"}
                            </p>
                            <p>
                              PKR{" "}
                              {TwoDecimalPoint(
                                passenger.outbound.service.split(",")?.[1]
                              )}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <hr />
      <div className="d-flex fs-4 justify-content-between align-items-center pt-3">
        <h4>Total Fare</h4>
        <h4>{`${CurrencyCode()} ${TwoDecimalPoint(totalFare)}`}</h4>
      </div>
    </div>
  );
};

export default SideBar;
