import { useNavigate, useParams } from "react-router-dom";
import iata from "../../assets/images/iata.png";
// import logo from "../../../../assets/images/zairaa.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
// import { AsyncGetAllVoucher } from "../../../../redux/features/Voucher/VoucherSlice";
// import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { setChangeInvoice } from "../../redux/features/Finance/Sale/SaleReportSlice";
import { apiUrl } from "../../Utils/configUrl";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Reports = ({
  data,
  agentData,
  voucher,
  setChangeComponet,
  fromDate,
  toDate,
  statement,
  userData,
  allwaysCredit,
  report,
  text,
  summary,
  getAdminData,
  editVoucherPermission,
}) => {
  const dispatch = useDispatch();
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);
  const componentRef = useRef();
  const firstColor = adminAgencyData?.first_color;
  const obj = {
    PV: "Payment Voucher",
    RV: "Reciept Voucher",
    JV: "Jounral Voucher",
  };
  const objLink = {
    INV: "manual-invoice",
    RV: "vouchers-entry",
  };

  return (
    <div className="d-flex  flex-column gap-4 bg-white">
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />

          <button
            onClick={(e) => dispatch(setChangeInvoice(report))}
            className="bg_primary px-4 py-1 rounded text-white"
          >
            Back
          </button>
        </div>
      </div>
      {/* bottom setion */}
      <div className="d-flex flex-column gap-3  mt-4" ref={componentRef}>
        {/* logo */}
        <div className="d-flex justify-content-around align-items-center">
          <div style={{ width: "300px" }}>
            <img
              src={`${apiUrl}/${getAdminData}`}
              alt="logo"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
          <div
            className="d-flex fw-bold fs-6  flex-column gap-2 align-items-center"
            style={{ width: "1200px" }}
          >
            <span className=" text-black">
              {"FROM :" +
                " " +
                moment(summary?.fromDate)
                  .format("ddd DD-MMM-YY")
                  .toUpperCase()}{" "}
              {text !== "Daily Sale Report" &&
                "TO :" +
                  moment(summary?.toDate).format("ddd DD-MMM-YY").toUpperCase()}
            </span>
            <span className="text-black">
              {summary?.managerName &&
                "ACOUNT MANAGER  :" + " " + summary?.managerName?.toUpperCase()}
            </span>
            <span className="text-black">
              {summary?.agentName &&
                "AGENT  :" + " " + summary?.agentName?.toUpperCase()}
            </span>
            <span className="text-black">
              {summary?.supplierName &&
                "SUPPLIER  :" + " " + summary?.supplierName?.toUpperCase()}
            </span>
            <span className="text-black">
              {summary?.service_type &&
                "SERVICE TYPE  :" + " " + summary?.service_type?.toUpperCase()}
            </span>
            <span className="text-black">
              {summary?.VoucherName &&
                "VOUCHER TYPE  :" + " " + summary?.VoucherName?.toUpperCase()}
            </span>
          </div>
          <div style={{ width: "200px" }}>
            <img
              src={iata}
              alt="iata"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white fw-bold my-2">
            {" "}
            {text?.toUpperCase()}
          </span>
          {/* {agentData?.credit_limit ? (
            statement === "agent" ? (
              <div className="text-white d-flex flex-column gap-1 align-items-center">
                <span className=" text-black">
                  ({agentData?.agent_name}
                  {" - "}
                  {voucher?.curr === "PKR"
                    ? "PKR"
                    : agentData?.agent_group?.currency?.curr_code}
                  )
                </span>
                <span className="mb-2">
                  ( Date: {moment(fromDate)?.format("DD-MMM-YY")}
                  {" - "} {moment(toDate)?.format("DD-MMM-YY")})
                </span>
              </div>
            ) : null
          ) : (
            <span className=" text-white d-flex flex-column gap-1 align-items-center pb-2">
              ({agentData})
            </span>
          )} */}
        </div>
        <div className="text-center">
          {summary?.check !== "Default" ? (
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button btn btn-success mb-3 "
              table="table-to-xls"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Export Data to Excel Sheet"
            />
          ) : null}
        </div>
        <div
          className="d-flex justify-content-center  align-items-center mx-1  "
          style={{
            marginBottom: "80px",
          }}
        >
          {text !== "Transactional Report" ? (
            summary?.check === "Default" ? (
              <div className="row mt-4 col-12 mx-auto rounded-2 shadow-sm bg-white">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>DATE</th>
                      <th>TYPE</th>
                      <th>{text === "Refund Report" ? "CN NO" : "INV NO"}</th>
                      <th>BOOKING ID</th>
                      <th> PAX NAME</th>
                      {/* {summary?.check !== "Default" ? (
                      <>
                        <th> PNR</th>
                        <th> TICKET NO</th>
                        <th> AIRLINE CODE</th>
                      </>
                    ) : null} */}
                      <th>AGENT</th>
                      <th>SUPPLIER</th>
                      <th> DEBIT</th>
                      <th> CREDIT</th>
                      <th>PROFIT/LOSS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((report, index) => (
                      <tr
                        key={index}
                        className={report?.approved_date ? "text-primary" : ""}
                      >
                        <>
                          <td>
                            {report?.saved_date
                              ?.split("/")
                              ?.at(0)
                              ?.toUpperCase()}
                          </td>
                          <td>{report?.supplier_type?.toUpperCase()}</td>
                          <td
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              let check =
                                userData?.agent_id === 1
                                  ? editVoucherPermission
                                    ? true
                                    : false
                                  : false;
                              if (check) {
                                if (report?.voucher_type_id === 4) {
                                  const url = `/${"manual-invoice"}/${`${~~report?.acc_voucher_trans
                                    ?.at(0)
                                    ?.trans_description?.split("/")
                                    ?.at(0)}/${
                                    report?.acc_voucher_trans?.at(0)
                                      ?.tkt_voucher_no
                                  }/${report?.voucher_id}/${report?.voucher_no
                                    ?.split("-")
                                    ?.at(1)}`}`;

                                  window.open(
                                    url,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                } else {
                                  if (report?.voucher_type_id === 5) {
                                    const url = `/${"manual-credit-new"}/${`${~~report?.acc_voucher_trans
                                      ?.at(0)
                                      ?.trans_description?.split("/")
                                      ?.at(0)}/${
                                      report?.acc_voucher_trans?.at(0)
                                        ?.tkt_voucher_no
                                    }/${report?.voucher_id}/${report?.voucher_no
                                      ?.split("-")
                                      ?.at(1)}`}`;
                                    window.open(
                                      url,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }
                                }
                              }
                            }}
                          >
                            {report?.voucher_no}
                          </td>
                          <td>
                            {report?.voucher_description?.split("/")?.at(0)}
                          </td>
                          <td>
                            {report.acc_voucher_trans
                              ?.at(0)
                              .trans_description?.split("/")
                              ?.at(2) +
                              " " +
                              report.acc_voucher_trans
                                ?.at(0)
                                .trans_description?.split("/")
                                ?.at(3)}
                          </td>
                          {/* {summary?.check !== "Default" ? (
                          <>
                            <td>
                              {report.acc_voucher_trans
                                ?.at(0)
                                .trans_description?.split("/")
                                ?.at(1)}
                            </td>
                            <td>
                              {report.acc_voucher_trans
                                ?.at(0)
                                .trans_description?.split("/")
                                ?.at(4)}
                            </td>
                            <td>
                              {report.acc_voucher_trans
                                ?.at(0)
                                .trans_description?.split("/")
                                ?.at(5)}
                            </td>
                          </>
                        ) : null} */}
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              const queryParams = new URLSearchParams({
                                acc_title: report?.voucher_description
                                  ?.split("/")
                                  ?.at(1),
                                fromDate: summary?.fromDate
                                  ?.toISOString()
                                  ?.split("T")
                                  ?.at(0),
                                toDate:
                                  text === "Daily Sale Report"
                                    ? summary?.fromDate
                                    : summary?.toDate
                                        ?.toISOString()
                                        ?.split("T")
                                        ?.at(0),
                                op: "obp",
                                getAdminData: getAdminData,
                              }).toString();

                              const url = `/journal-Ledger/${report?.debit_acc_id}?${queryParams}`;
                              window.open(url, "_blank", "noopener,noreferrer");
                            }}
                          >
                            {report?.voucher_description?.split("/")?.at(1)}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              const queryParams = new URLSearchParams({
                                acc_title: report?.voucher_description
                                  ?.split("/")
                                  ?.at(2),
                                fromDate: summary?.fromDate
                                  ?.toISOString()
                                  ?.split("T")
                                  ?.at(0),
                                toDate:
                                  text === "Daily Sale Report"
                                    ? summary?.fromDate
                                    : summary?.toDate
                                        ?.toISOString()
                                        ?.split("T")
                                        ?.at(0),
                                op: "obp",
                                getAdminData: getAdminData,
                                agentOrSupllier: "supplier",
                              }).toString();
                              const url = `/journal-Ledger/${report?.credit_acc_id}?${queryParams}`;
                              window.open(url, "_blank", "noopener,noreferrer");
                            }}
                          >
                            {report?.voucher_description?.split("/")?.at(2)}
                          </td>
                          <td>
                            {text === "Refund Report"
                              ? report?.acc_voucher_trans?.at(0).d_cur_debit
                              : report?.acc_voucher_trans?.at(1).d_cur_debit}
                          </td>{" "}
                          <td>
                            {text === "Refund Report"
                              ? report?.acc_voucher_trans?.at(1).d_cur_credit
                              : report?.acc_voucher_trans?.at(0).d_cur_credit}
                          </td>
                          <td>
                            {report?.acc_voucher_trans.length > 2
                              ? report?.acc_voucher_trans?.at(2).d_cur_debit > 0
                                ? report?.acc_voucher_trans?.at(2).d_cur_debit
                                : report?.acc_voucher_trans?.at(2).d_cur_credit
                              : 0}
                          </td>
                        </>
                      </tr>
                    ))}
                    {/* <tr className="fs-6 fw-bold">
                  <td colSpan={7} className="text-end">
                    Total
                  </td>
                 
                  <td>234234</td>
                  <td>32432</td>
                  <td>32432</td>
                </tr> */}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="row mt-4 col-12 mx-auto rounded-2 shadow-sm bg-white">
                <table className="table table-bordered" id="table-to-xls">
                  <thead>
                    <tr>
                      <th>DATE</th>
                      <th> PAX NAME</th>
                      <th> AIRLINE CODE</th>
                      <th> PNR</th>
                      <th> TICKET NO</th>
                      <th>SECTOR</th>
                      <th>TRAVEL DATE</th>
                      <th>SUPPLIER</th>
                      <th>AGENT</th>
                      <th>BASIC</th>
                      {/* ///passenger table base fare */}
                      <th>CREDIT</th>
                      {/* //cost mean credit by manan saib */}
                      <th>DEBIT</th>
                      {/* //sale mean DEBIT by manan saib */}
                      {/* <th>TYPE</th>
                      <th>{text === "Refund Report" ? "CN NO" : "INV NO"}</th>
                      <th>BOOKING ID</th>

                      <th> DEBIT</th>
                      <th> CREDIT</th>
                      <th>PROFIT/LOSS</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((report, index) => (
                      <tr
                        key={index}
                        className={report?.approved_date ? "text-primary" : ""}
                      >
                        <>
                          <td>
                            {moment(
                              report?.saved_date?.split("/")?.at(0)
                            ).format("MM/DD/YYYY")}
                          </td>
                          <td>
                            {report.acc_voucher_trans
                              ?.at(0)
                              .trans_description?.split("/")
                              ?.at(2) +
                              " " +
                              report.acc_voucher_trans
                                ?.at(0)
                                .trans_description?.split("/")
                                ?.at(3)}
                          </td>
                          <td>
                            {report.acc_voucher_trans
                              ?.at(0)
                              .trans_description?.split("/")
                              ?.at(5)}
                          </td>
                          <td>
                            {report.acc_voucher_trans
                              ?.at(0)
                              .trans_description?.split("/")
                              ?.at(1)}
                          </td>
                          <td>
                            {report.acc_voucher_trans
                              ?.at(0)
                              .trans_description?.split("/")
                              ?.at(4)}
                          </td>
                          <td>
                            {report?.acc_voucher_trans
                              ?.at(0)
                              .trans_description?.split("/")
                              ?.at(6)}
                          </td>
                          <td>
                            {report?.acc_voucher_trans
                              ?.at(0)
                              .trans_description?.split("/")
                              ?.at(7)
                              ? moment(
                                  report?.acc_voucher_trans
                                    ?.at(0)
                                    .trans_description?.split("/")
                                    ?.at(7)
                                ).format("MM/DD/YYYY")
                              : null}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              const queryParams = new URLSearchParams({
                                acc_title: report?.voucher_description
                                  ?.split("/")
                                  ?.at(2),
                                fromDate: summary?.fromDate
                                  ?.toISOString()
                                  ?.split("T")
                                  ?.at(0),
                                toDate:
                                  text === "Daily Sale Report"
                                    ? summary?.fromDate
                                    : summary?.toDate
                                        ?.toISOString()
                                        ?.split("T")
                                        ?.at(0),
                                op: "obp",
                                getAdminData: getAdminData,
                              agentOrSupllier : "supllier"
                              }).toString();
                              const url = `/journal-Ledger/${report?.credit_acc_id}?${queryParams}`;
                              window.open(url, "_blank", "noopener,noreferrer");
                            }}
                          >
                            {report?.voucher_description?.split("/")?.at(2)}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            // with excel cn
                            onClick={(e) => {
                              const queryParams = new URLSearchParams({
                                acc_title: report?.voucher_description
                                  ?.split("/")
                                  ?.at(1),
                                fromDate: summary?.fromDate
                                  ?.toISOString()
                                  ?.split("T")
                                  ?.at(0),
                                toDate: summary?.fromDate
                                  ?.toISOString()
                                  ?.split("T")
                                  ?.at(0),
                                op: "obp",
                                getAdminData: getAdminData,
                              }).toString();

                              const url = `/journal-Ledger/${report?.debit_acc_id}?${queryParams}`;
                              window.open(url, "_blank", "noopener,noreferrer");
                            }}
                          >
                            {report?.voucher_description?.split("/")?.at(1)}
                          </td>
                          <td>
                            {report?.acc_voucher_trans
                              ?.at(0)
                              .trans_description?.split("/")
                              ?.at(8)}
                          </td>

                          <td>
                            {report?.voucher_type_id === 5
                              ? report?.acc_voucher_trans?.at(1).d_cur_credit
                              : report?.acc_voucher_trans?.at(0).d_cur_credit}
                          </td>
                          <td>
                            {report?.voucher_type_id === 5
                              ? report?.acc_voucher_trans?.at(0).d_cur_debit
                              : report?.acc_voucher_trans?.at(1).d_cur_debit}
                          </td>
                        </>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          ) : (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>TYPE</th>
                  <th>VN NO</th>
                  <th>DEBITOR</th>
                  <th>CREDITOR</th>
                  <th>DEBIT</th>
                  <th>CREDIT</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((report, index) => (
                  <>
                    {report?.voucher_type_id !== 1 ? (
                      <tr
                        key={index}
                        className={report?.approved_date ? "text-primary" : ""}
                      >
                        <>
                          <td>
                            {report?.saved_date
                              ?.split("/")
                              ?.at(0)
                              ?.toUpperCase()}
                          </td>
                          <td>
                            {obj[
                              report?.voucher_no?.split("-")?.at(0)
                            ]?.toUpperCase()}
                          </td>
                          <td
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              let check =
                                userData?.agent_id === 1
                                  ? editVoucherPermission
                                    ? true
                                    : false
                                  : false;

                              if (check) {
                                const url = `/${"vouchers-entry"}/${
                                  report?.voucher_id
                                }`;
                                window.open(
                                  url,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }
                            }}
                          >
                            {report?.voucher_no}
                          </td>
                          <td>
                            {
                              report?.acc_voucher_trans?.at(1)?.acc_account
                                ?.acc_title
                            }
                          </td>
                          <td>
                            {
                              report?.acc_voucher_trans?.at(0)?.acc_account
                                ?.acc_title
                            }
                          </td>
                          <td>
                            {report?.acc_voucher_trans?.at(1)?.d_cur_debit}
                          </td>
                          <td>
                            {report?.acc_voucher_trans?.at(0)?.d_cur_credit}
                          </td>
                        </>
                      </tr>
                    ) : (
                      report?.acc_voucher_trans?.map((jv, ind) => (
                        <tr
                          key={index}
                          className={
                            report?.approved_date ? "text-primary" : ""
                          }
                        >
                          <>
                            <td>
                              {report?.saved_date
                                ?.split("/")
                                ?.at(0)
                                ?.toUpperCase()}
                            </td>
                            <td>
                              {obj[
                                report?.voucher_no?.split("-")?.at(0)
                              ]?.toUpperCase()}
                            </td>
                            <td
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                let check =
                                  userData?.agent_id === 1
                                    ? editVoucherPermission
                                      ? true
                                      : false
                                    : false;

                                if (check) {
                                  const url = `/${"vouchers-entry"}/${
                                    report?.voucher_id
                                  }`;
                                  window.open(
                                    url,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }
                              }}
                            >
                              {report?.voucher_no}
                            </td>
                            <td>
                              {Number(jv?.d_cur_debit) === 0
                                ? ""
                                : jv?.acc_account?.acc_title}
                            </td>
                            <td>
                              {Number(jv?.d_cur_credit) === 0
                                ? ""
                                : jv?.acc_account?.acc_title}
                            </td>
                            <td>
                              {Number(jv?.d_cur_debit) === 0
                                ? ""
                                : jv?.d_cur_debit}
                            </td>
                            <td>
                              {Number(jv?.d_cur_credit) === 0
                                ? ""
                                : jv?.d_cur_credit}
                            </td>
                          </>
                        </tr>
                      ))
                    )}
                  </>
                ))}
                {/* <tr className="fs-6 fw-bold">
                  <td colSpan={6} className="text-end">
                    Total
                  </td>
                  <td>23432423</td>
                  <td>234234</td>
                  <td>32432</td>
                  <td>32432</td>
                </tr> */}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
