import moment from "moment";

const VisaBookedItem = ({ bookingData }) => {
  return (
    <div className="booked_items">
      <div className="d-flex justify-content-between align-items-center ">
        <div className="  w-100 ">
          {/* <ImBook /> Booked items */}
          <div className="d-flex align-items-center">
            <p className="h5" style={{ lineHeight: 1 }}>
              Date Time:
            </p>
            <p className="ms-2 h6" style={{ lineHeight: 1 }}>
              {moment(bookingData?.bookdatetime?.split("T")[0]).format(
                "ddd DD-MMM-YY"
              ) +
                " " +
                bookingData?.bookdatetime?.split("T")[1]?.slice(0, 5)}
            </p>
          </div>
          <div className="d-flex bg_primary w-100 align-items-center  text-white p-2 rounded">
            <p className="h5" style={{ lineHeight: 1 }}>
              Booking Type: Visa
            </p>
          </div>
        </div>
      </div>
      <div className="booked_items_table">
        <div className="d-flex  justify-content-between align-items-center border-bottom">
          <div className="booked_tabel_items h6">Booking ID</div>
          <div className="booked_tabel_items h6">Country / Visa type</div>
          <div className="booked_tabel_items h6">Status</div>
          <div className="booked_tabel_items h6">Document</div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <div className="booked_tabel_items booktablebody">
            {bookingData?.id}
          </div>
          <div className="booked_tabel_items booktablebody">
            {bookingData?.VisaBookingModels?.at(0)?.VisaCountryModel?.name +
              " / " +
              bookingData?.VisaBookingModels?.at(0)?.VisaTypesModel?.visa_type}
          </div>

          <div className="booked_tabel_items booktablebody">
            {bookingData?.book_status}
          </div>

          <div className="booked_tabel_items booktablebody">
            {bookingData?.doc_status}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisaBookedItem