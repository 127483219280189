import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import {
  CurrencyCode,
  CurrencyExchange,
} from "../../../../../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../../../../../Utils/FixedTwoDecimal";

function PerPaxTotal({ friend, values, index, auto_manual, setFieldValue }) {
  // calculate total supplier amount per pax
  const totalSuppAmountPax = (friend) => {
    const taxes_cur = friend?.text_cur?.reduce(
      (sum, item) => Number(sum) + Number(item.tax_amount),
      0
    );
    const withbfar = Number(friend.b_fare) + Number(taxes_cur);
    const spf_with_taxes =
      withbfar +
      Number(friend?.tax_cur?.s_psf) +
      Number(friend?.tax_cur?.s_gst);
    const ssp_with_taxes =
      withbfar - Number(friend?.tax_cur?.s_sp) + Number(friend?.tax_cur?.s_wht);

    return friend?.tax_cur?.s_psf
      ? TwoDecimalPoint(spf_with_taxes)
      : TwoDecimalPoint(ssp_with_taxes);
  };
  // total supplier to pkr
  const totalSupplierAmountToPkr = (friend) => {
    return TwoDecimalPoint(friend?.roe * totalSuppAmountPax(friend));
  };

  // calculate total Agent amount per pax
  const totalAgentAmountPax = (friend) => {
    // return friend?.eq_b_fare;
    const taxes_cur = friend?.text_cur?.reduce(
      (sum, item) => Number(sum) + Number(item.tax_amount),
      0
    );
    const withbfar = Number(friend.b_fare) + Number(taxes_cur);
    const cpsf_with_taxes =
      withbfar +
      Number(friend?.tax_cur?.c_psf) +
      Number(friend?.tax_cur?.c_gst);
    const csp_with_taxes =
      withbfar - Number(friend?.tax_cur?.c_sp) + Number(friend?.tax_cur?.c_wht);
    
    let total_fare = friend?.tax_cur?.c_psf
    ? TwoDecimalPoint(cpsf_with_taxes)
    : TwoDecimalPoint(csp_with_taxes);

    return total_fare;
  };
  // total supplier to pkr
  const totalAgentToPkr = (friend) => {
    return TwoDecimalPoint(friend?.roe * totalAgentAmountPax(friend));
  };

  // total Suppier taxes
  const totalSupp = (friend) => {
    const taxes_pkr = friend?.text_pkr?.reduce(
      (sum, item) => Number(sum) + Number(item.tax_pkr_amount),
      0
    );
    const withbfar = Number(friend.b_fare) + Number(taxes_pkr);
    const supptaxepsf =
      withbfar +
      Number(friend?.tax_pkr?.s_psf) +
      Number(friend?.tax_pkr?.s_gst);
    const suptaxesssp =
      withbfar - Number(friend?.tax_pkr?.s_sp) + Number(friend?.tax_pkr?.s_wht);
   
    return friend?.tax_pkr?.s_psf > 0
      ? TwoDecimalPoint(supptaxepsf)
      : TwoDecimalPoint(suptaxesssp);
  };
  // total agent taxes
  const tatalAgent = (friend) => {
    const taxes_pkr = friend?.text_pkr?.reduce(
      (sum, item) => Number(sum) + Number(item.tax_pkr_amount),
      0
    );
    const withbfar = Number(friend.b_fare) + Number(taxes_pkr);
    const agenttaxepsf =
      withbfar +
      Number(friend?.tax_pkr?.c_psf) +
      Number(friend?.tax_pkr?.c_gst);

    const agenttaxesssp =
      withbfar - Number(friend?.tax_pkr?.c_sp) + Number(friend?.tax_pkr?.c_wht);

      let total_fare = friend?.tax_pkr?.c_psf >0
      ? TwoDecimalPoint(agenttaxepsf)
      : TwoDecimalPoint(agenttaxesssp);

    total_fare += friend?.other_income_type === "PSF" ? (friend?.other_income) : (-friend?.other_income)
    return total_fare;
  };
  useEffect(() => {
    setFieldValue(
      `fareValues.[${index}].ttl_agt_pay_d_cur`,
      friend?.currency?.curr_code === "PKR"
        ? tatalAgent(friend)
        : totalAgentToPkr(friend)
    );

    setFieldValue(
      `fareValues.[${index}].ttl_sup_pay_d_cur`,
      friend?.currency?.curr_code === "PKR"
        ? totalSupp(friend)
        : totalSupplierAmountToPkr(friend)
    );

    setFieldValue(
      `fareValues.[${index}].ttl_agg_per_pass_assg_curr`,
      TwoDecimalPoint(
        CurrencyExchange(
          friend?.currency?.curr_code === "PKR"
            ? tatalAgent(friend)
            : totalAgentToPkr(friend)
        )
      )
    );

    // values.fareValues[index].ttl_agent_per_pass;

    setFieldValue(
      "ttl_agent_fare",
      friend?.currency?.curr_code === "PKR"
        ? tatalAgent(friend)
        : totalAgentToPkr(friend)
    );
    setFieldValue(
      "ttl_suppl_fare",
      friend?.currency?.curr_code === "PKR"
        ? totalSupp(friend)
        : totalSupplierAmountToPkr(friend)
    );
    setFieldValue(
      "s_cur_debit",
      TwoDecimalPoint(
        CurrencyExchange(
          friend?.currency?.curr_code === "PKR"
            ? tatalAgent(friend)
            : totalAgentToPkr(friend)
        )
      )
    );
  }, [
    friend.tax_pkr.c_psf,
    friend?.tax_pkr?.c_gst,
    friend?.tax_pkr?.c_wht,
    friend?.tax_pkr?.c_sp,
    index,
    friend,
  ]);

  return (
    <div>
      {" "}
      <div className="col-12 mt-4 d-flex">
        <div className="col-4 h6">Supplier:</div>
        <div className="col-8">
          <Table bordered hover>
            <thead>
              <tr>
                {friend?.currency?.curr_code !== "PKR" && (
                  <th className="text-center">{friend?.currency?.curr_code}</th>
                )}
                <th className="text-center">PKR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {friend?.currency?.curr_code !== "PKR" && (
                  <td className="text-center">{totalSuppAmountPax(friend)}</td>
                )}
                <td className="text-center">
                  {friend?.currency?.curr_code === "PKR"
                    ? totalSupp(friend)
                    : totalSupplierAmountToPkr(friend)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="col-12 mt-3 d-flex">
        <div className="col-4 h6">Agent:</div>
        <div className="col-8">
          <Table bordered hover>
            <thead>
              <tr>
                {friend?.currency?.curr_code !== "PKR" && (
                  <th className="text-center">{friend?.currency?.curr_code}</th>
                )}
                <th className="text-center">PKR</th>
                {values.agent && values.agent_name !== "" && (
                  <th className="text-center text-primary">{CurrencyCode()}</th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {friend?.currency?.curr_code !== "PKR" && (
                  <td className="text-center">{totalAgentAmountPax(friend)}</td>
                )}
                <td className="text-center">
                  {friend?.currency?.curr_code === "PKR"
                    ? tatalAgent(friend)
                    : totalAgentToPkr(friend)}
                </td>
                {values.agent && values.agent_name !== "" && (
                  <td className="text-center text-primary">
                    {TwoDecimalPoint(
                      CurrencyExchange(
                        friend?.currency?.curr_code === "PKR"
                          ? tatalAgent(friend)
                          : totalAgentToPkr(friend)
                      )
                    )}
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default PerPaxTotal;
