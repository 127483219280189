import React, { useEffect, useState } from "react";
import AmadeusReturn from "./AmadeusReturn";
import { set_Airline_filter_drp } from "../../../../../redux/features/home/FlightSearchSlice";
import { useDispatch } from "react-redux";

const AmadeusSegmentCard = ({
  amadeusItem,
  index,
  toggleDetail,
  expandFlightDetail,
  selectedFlight
}) => {
  const [showOtherOption, setShowOtherOption] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set_Airline_filter_drp(amadeusItem?.validatingAirlineCodes?.at(0)));
  }, [])
  return (
    <div
      className="search_engin_result_box flight_card_shadow bg-white"
      id={`amadeus${index}`}
    >
      <AmadeusReturn
        amadeusItem={amadeusItem}
        selectedFlight={selectedFlight}
        key={index}
        index={"amadeus" + index}
        isExpanded={expandFlightDetail === "amadeus" + index}
        toggleDetail={toggleDetail}
        showOtherOption={showOtherOption}
        setShowOtherOption={setShowOtherOption}
      />

      {showOtherOption ? (
        <div>
          {amadeusItem?.otherOptions?.map((otherOption, index) => {
            return (
              <AmadeusReturn
                amadeusItem={otherOption}
                key={index}
                index={"amadeus" + index}
                isExpanded={expandFlightDetail === "amadeus" + index}
                toggleDetail={toggleDetail}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default AmadeusSegmentCard;
