import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FieldArray } from "formik";
import React, { useRef, useState } from "react";
import { menuProps, pax_title, pax_type } from "../helper";
import TaxPkr from "./pax/TaxPkr";
import TaxCurr from "./pax/TaxCurr";
import SupplierCurr from "./pax/SupplierCurr";
import SupplierPkr from "./pax/SupplierPkr";
import AgentPkr from "./pax/AgentPkr";
import AgentCurr from "./pax/AgentCurr";
import PerPaxTotal from "./pax/PerPaxTotal";
import Currency from "./pax/Currency";
import { debounce } from "lodash";
import { getAsyncSearchPNR } from "../../../../../redux/features/booking/bookingDetailsSlice";
import { useDispatch } from "react-redux";
import Subsidary from "./pax/Subsiday";

const PaxFare = ({ values, setFieldValue, auto_manual }) => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const [pnrError, setPnrError] = useState({});
  ////////////////// scroll to the new added pax
  const scrollToElement = () => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end", // Align the bottom of the element with the bottom of the scroll container
        inline: "nearest", // Scroll as little as possible to make the element visible
      });
    }
  };
  const debouncedHandleChange = debounce((e, index) => {
    const param = e.target.value;
    dispatch(getAsyncSearchPNR(param))
      .then((response) => {
        setPnrError({
          ...pnrError,
          ["open_" + index]: response?.payload ? true : false,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, 3000);

  return (
    <FieldArray
      name="fareValues"
      render={(arrayHelpers) => (
        <div>
          {values?.fareValues?.map((friend, index) => (
            <>
              <div key={index}>
                <div className="section_box">
                  {/* pax section */}
                  <div className="pax mt-2 mt-md-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="h5 ">PAX {index + 1}:</div>
                      <div>
                        {index >= 1 && (
                          <button
                            onClick={() => arrayHelpers.remove(index)}
                            className="button_style bg-danger"
                            type="button"
                            disabled={auto_manual}
                          >
                            - PAX
                          </button>
                        )}
                        {values?.fareValues?.length <= 4 && index === 0 && (
                          <button
                            className="button_style"
                            type="button"
                            disabled={auto_manual}
                            onClick={() => {
                              arrayHelpers.push({
                                pax_type: "",
                                title: "",
                                first_name: "",
                                last_name: "",
                                ticket_no: "",
                                currency: friend.currency,
                                curr_label: friend?.curr_label,
                                other_income: 0,
                                other_income_type: "NET",
                                roe: friend?.roe,
                                b_fare: "",
                                eq_b_fare: "",
                                text_cur: [
                                  {
                                    tax_yq: "",
                                    tax_amount: "",
                                  },
                                ],
                                text_pkr: [
                                  {
                                    tax_pkr_yq: "",
                                    tax_pkr_amount: "",
                                  },
                                ],

                                tax_pkr: {
                                  s_psf: "",
                                  s_psf_per: "",
                                  s_gst: "",
                                  s_gst_per: "",
                                  s_sp: "",
                                  s_sp_per: "",
                                  s_wht: "",
                                  s_wht_per: "",
                                  c_psf: "",
                                  c_psf_per: "",
                                  c_gst: "",
                                  c_gst_per: "",
                                  c_sp: "",
                                  c_sp_per: "",
                                  c_wht: "",
                                  c_wht_per: "",
                                },

                                tax_cur: {
                                  s_psf: "",
                                  s_psf_per: "",
                                  s_gst: "",
                                  s_gst_per: "",
                                  s_sp: "",
                                  s_sp_per: "",
                                  s_wht: "",
                                  s_wht_per: "",
                                  c_psf: "",
                                  c_psf_per: "",
                                  c_gst: "",
                                  c_gst_per: "",
                                  c_sp: "",
                                  c_sp_per: "",
                                  c_wht: "",
                                  c_wht_per: "",
                                },
                              });

                              setTimeout(() => {
                                scrollToElement();
                              }, 200);
                            }}
                          >
                            + PAX
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-12">
                        <div className="row align-items-end">
                          <div className="col-4 col-md-1">
                            <FormControl
                              variant="standard"
                              className="w-100"
                              required
                            >
                              <InputLabel id={`type-label-${index}`}>
                                Type
                              </InputLabel>
                              <Select
                                id={`fareValues[${index}].pax_type`}
                                value={friend.pax_type}
                                onChange={(e) => {
                                  setFieldValue(
                                    `fareValues[${index}].pax_type`,
                                    e.target.value
                                  );
                                }}
                                label="Type"
                                MenuProps={menuProps}
                                required
                                disabled={auto_manual === 0}
                              >
                                {pax_type?.map((it) => (
                                  <MenuItem value={it} key={it}>
                                    {it}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-4 col-md-1">
                            <FormControl
                              variant="standard"
                              className="w-100"
                              required
                            >
                              <InputLabel id={`title-${index}`}>
                                Title
                              </InputLabel>
                              <Select
                                id={`fareValues[${index}].title`}
                                value={friend.title}
                                onChange={(e) => {
                                  setFieldValue(
                                    `fareValues[${index}].title`,
                                    e.target.value
                                  );
                                }}
                                label="Title"
                                MenuProps={menuProps}
                                required
                                disabled={auto_manual === 0}
                              >
                                {pax_title?.map((it) => (
                                  <MenuItem value={it} key={it}>
                                    {it}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-4 col-md-1">
                            <TextField
                              value={friend.first_name}
                              id={`fareValues.[${index}].first_name`}
                              onChange={(e) => {
                                setFieldValue(
                                  `fareValues.[${index}].first_name`,
                                  e.target.value.toUpperCase()
                                );
                              }}
                              label="F-Name"
                              variant="standard"
                              autoComplete="off"
                              required
                              disabled={auto_manual === 0}
                            />
                          </div>
                          <div className="col-4 col-md-1">
                            <TextField
                              value={friend.last_name}
                              id={`fareValues.[${index}].last_name`}
                              onChange={(e) => {
                                setFieldValue(
                                  `fareValues.[${index}].last_name`,
                                  e.target.value.toUpperCase()
                                );
                              }}
                              label="L-Name"
                              variant="standard"
                              autoComplete="off"
                              required
                              disabled={auto_manual === 0}
                            />
                          </div>
                          <div className="col-4 col-md-1">
                            <TextField
                              value={friend.ticket_no}
                              id={`fareValues.[${index}].ticket_no`}
                              onChange={(e) => {
                                setFieldValue(
                                  `fareValues.[${index}].ticket_no`,
                                  e.target.value
                                );

                                debouncedHandleChange(e, index);
                              }}
                              label="Ticket No"
                              variant="standard"
                              autoComplete="off"
                              required
                              disabled={auto_manual === 0}
                            />

                            {pnrError["open_" + index] ? (
                              <p className="position-absolute text-danger">
                                Ticket number already exist.
                              </p>
                            ) : null}
                          </div>
                          <div className="col-4 ">
                            {/* <button
                              className="me-2 button_style fs-6"
                              disabled={auto_manual === 0}
                            >
                              CHECK TICKET NO
                            </button> */}
                            <button
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  pax_type: friend?.pax_type,
                                  title: "",
                                  first_name: "",
                                  last_name: "",
                                  ticket_no: "",
                                  currency: friend.currency,
                                  curr_label: friend?.curr_label,
                                  roe: friend?.roe,
                                  b_fare: friend?.b_fare,
                                  eq_b_fare: friend?.eq_b_fare,

                                  text_cur: friend?.text_cur.map((item) => ({
                                    ...item,
                                  })),
                                  text_pkr: friend?.text_pkr.map((item) => ({
                                    ...item,
                                  })),

                                  tax_pkr: { ...friend?.tax_pkr },
                                  tax_cur: { ...friend?.tax_cur },
                                })
                              }
                              className="me-2 button_style fs-6"
                              disabled={auto_manual}
                            >
                              + PAX WITH FARE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* fare section */}
                  <div className="black_border rounded p-2 pe-4 py-5 my-4 mt-5">
                    <div className="row">
                      <div className="col-3">
                        <div className="row align-items-start">
                          <div
                            className="h5 col-2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Fare:
                          </div>
                          <Currency
                            setFieldValue={setFieldValue}
                            values={values}
                            friend={friend}
                            index={index}
                            auto_manual={auto_manual}
                          />
                        </div>
                        {/* per passenger total */}
                        <PerPaxTotal
                          friend={friend}
                          values={values}
                          index={index}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      {/* taxes */}
                      <div className="col-3">
                        {/* TAX IN CURRENCY (ANY SELECTED) */}
                        {friend?.currency?.curr_code !== "PKR" && (
                          <TaxCurr
                            setFieldValue={setFieldValue}
                            values={values}
                            friend={friend}
                            index={index}
                          />
                        )}

                        {/* TAX IN PKR */}
                        <TaxPkr
                          setFieldValue={setFieldValue}
                          values={values}
                          friend={friend}
                          index={index}
                          auto_manual={auto_manual}
                        />
                        <div className="mt-5">
                          {/* TAX IN PKR */}
                          <Subsidary
                            setFieldValue={setFieldValue}
                            values={values}
                            friend={friend}
                            index={index}
                            auto_manual={auto_manual}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        {/* supplier in current(any curr selected) */}
                        {friend?.currency?.curr_code !== "PKR" && (
                          <div
                            className="row align-items-center"
                            style={{ marginBottom: 14 }}
                          >
                            {/* SUPPLIER IN CURRENCY (ANY SELECTED) */}
                            <SupplierCurr
                              setFieldValue={setFieldValue}
                              values={values}
                              friend={friend}
                              index={index}
                              auto_manual={auto_manual}
                            />
                            {/* AGENT IN CURRENCY (ANY SELECTED) */}
                            <AgentCurr
                              setFieldValue={setFieldValue}
                              values={values}
                              friend={friend}
                              index={index}
                            />
                          </div>
                        )}
                        <div className={`row align-items-center`}>
                          {/* SUPPLIER IN PKR */}
                          <SupplierPkr
                            setFieldValue={setFieldValue}
                            values={values}
                            friend={friend}
                            index={index}
                            auto_manual={auto_manual}
                          />
                          {/* AGENT IN PKR */}
                          <AgentPkr
                            setFieldValue={setFieldValue}
                            values={values}
                            friend={friend}
                            index={index}
                          />
                        </div>
                      </div>
                    </div>
                    <div ref={elementRef} />
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      )}
    />
  );
};

export default PaxFare;
