import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Airblue from "../../assets/images/airlineLogo/PA.png";
import AirSial from "../../assets/images/airlineLogo/PF.png";
import Flydubai from "../../assets/images/airlineLogo/FZ.png";
import Travelport from "../../assets/images/airlineLogo/TR.png";
import PIA from "../../assets/images/airlineLogo/PK.png";
import Serene from "../../assets/images/airlineLogo/ER.png";
import Sabre from "../../assets/images/airlineLogo/SB.png";
import Paypro from "../../assets/images/airlineLogo/PP.png";
import Flyjinnah from "../../assets/images/airlineLogo/9P.png";
import AirArabia from "../../assets/images/airlineLogo/G9.png";
import EmiratesNDC from "../../assets/images/airlineLogo/EK.png";
import amadeusRounded from "../../assets/images/airlineLogo/amadeusRounded.png";
import OmanNDC from "../../assets/images/airlineLogo/WY.png";
import { useNavigate } from "react-router-dom";
import {
  asyncConfirmOTP,
  asyncGenerateOTP,
  clearOTP,
  otpCodeReduce,
} from "../../redux/features/setupFeature/ApiCredential/ApiCredentials";
import { useDispatch, useSelector } from "react-redux";

import GDSOrder from "./ApiPassword/GDSOrder";
import { asyncGetNewSupplier } from "../../redux/features/supplier/AddNewSupplier";

/// static array for gds
const images = {
  "Travel Port": Travelport,
  Sabre: Sabre,
  "Oman Ndc": OmanNDC,
  "Emirates Ndc": EmiratesNDC,
  "Fly Jinnah": Flyjinnah,
  "Fly Dubai": Flydubai,
  "Air Blue": Airblue,
  HITIT: PIA,
  "Air sial": AirSial,
  Serene: Serene,
  PayPro: Paypro,
  "Air Arabia": AirArabia,
  "Amadeus": amadeusRounded,
};

const Password = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //// use state
  const [authUser, setAuthUser] = useState(false);
  const [code, setCode] = useState("");
  const [timer, setTimer] = useState(60);
  const [ShowCredError, setShowCredError] = useState(false);



  /// use selector redux
  const { GDSData, otpCode, otpMSG } = useSelector(
    (state) => state?.apiCredentials
  );

  /// otp generation handler
  const generateOtp = () => {
    dispatch(asyncGenerateOTP());
  };

  /// otp verification handler
  const confirmOtpCode = () => {
    dispatch(asyncConfirmOTP(code))
    dispatch(otpCodeReduce());
    dispatch(clearOTP);
    setCode("")
    setAuthUser(false);
  };

  useEffect(() => {
    // const auth = JSON.parse(localStorage.getItem("apiCredentials"));
    const auth = otpMSG?.status === "success" && !otpMSG?.loading;
    setAuthUser(auth);
    if (otpCode?.status === "success" && timer >= 1 && otpMSG === null) {
      setTimeout(() => {
        setTimer((prv) => {
          return prv - 1;
        });
      }, 1000);
    } else if (otpMSG) {
      setTimer(60);
    } else {
      dispatch(otpCodeReduce());
      setAuthUser(false);
      setCode("");
    }
  }, [dispatch, timer, otpCode, otpMSG]);

  useEffect(() => {
    setTimeout(() => {
      setShowCredError(false);
    }, 5000);
  }, [ShowCredError]);

  useEffect(() => {
    dispatch(asyncGetNewSupplier());
  }, [])

  return (
    <div className="pb-3">
      {!authUser ? (
        <div className="col-12 col-sm-6 mx-auto rounded-2 shadow-sm my-5 pb-5 bg-white">
          <p className="otawix-heading box_title">API Credentials</p>
          {otpCode === null || otpCode?.status === "fail" ? (
            <div className="text-center pt-4">
              <span className="text-danger">{otpCode?.message}</span>
              <button className="setup-btn" onClick={generateOtp}>
                Generate OTP
              </button>
            </div>
          ) : (
            <div className="col-12 px-4">
              <div className="col-12 mt-4 py-3">
                <TextField
                  label="Enter OTP"
                  variant="standard"
                  type="number"
                  className="col-4"
                  required
                  name="otp"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              {otpMSG ? (
                <p className="text-danger">{otpMSG?.message}</p>
              ) : (
                <span>OTP will expire in {timer} Sec</span>
              )}
              <div className="text-center pt-2">
                <button className="setup-btn" onClick={(e) => confirmOtpCode()}>
                  Confirm
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="pb-5">
          <div className="mx-2 mx-md-5 rounded-2 shadow-sm mt-5 pb-2 bg-white">
            <p className="otawix-heading box_title">API Credentials</p>
            <div className="row">
              <div className="col-3 col-sm-2 p-sm-4">
                <GDSOrder />
              </div>
              <div className="col-9 col-sm-10">
                <div className="row pt-2 pe-4">
                  {GDSData?.map((item) => (
                    <div
                      key={item.name}
                      className="col-6 col-md-4 col-lg-3 col-xl-2 my-3"
                      onClick={(e) => {
                        let path = item?.gds_name
                          ?.replace(" ", "")
                          .toLowerCase();
                        if (item?.show_credentials !== 0) {
                          navigate(`/${path}-credentials`);
                        } else {
                          setShowCredError(true);
                        }
                      }}
                    >
                      <div className="border rounded-2 shadow-sm p-3 py-2 d-flex flex-column align-items-center api_credensial_card">
                        <div className="">
                          <img
                            src={images[item.gds_name]}
                            alt={item.gds_name}
                            width={90}
                            height={90}
                          />
                        </div>
                        <div className="mt-auto">
                          <h5>{item.gds_name}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
                  {ShowCredError && (
                    <p className="fs-5 text-danger">
                      You are not authorized, to open this page.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Password;
