import React, { useEffect } from "react";
import AirBlueLogo from "../../../../assets/images/airlineLogo/PA.png";
import moment from "moment";
//import icons
import {
  MdOutlineRestaurant,
  MdSwapHoriz,
  MdCheck,
  MdRemoveRedEye,
  MdClose,
  MdOutlineFlightTakeoff,
  MdOutlineFlightLand,
} from "react-icons/md";
import { BsBagCheck, BsPerson, BsHandbag } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { getSectorsName } from "../../../../Utils/FlightSector";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { LightTooltip } from "./LightTooltip";
import {
  setExpandFlightDetails,
  set_Airline_filter_drp,
  set_All_Prices_Array,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { ExclusiveSetors } from "../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";

function Airblue({ data, index, isExpanded, toggleDetail, selectedFare }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { selectedFlight } = useSelector((state) => state.FlightSearch);

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  // get values from local storage
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "PA"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "PA");

  const locDeparture = searchValues?.locationDep;
  const locationArrival = searchValues?.locationArrival;

  //flight segment
  const flightSegment =
    data[0]?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption
      ?.FlightSegment;

  // sort by lowest price
  const newFareArray = selectedFare
    ? data
    : data?.sort(
      (a, b) =>
        a?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$?.Amount -
        b?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$?.Amount
    );

  //calculate the lowest total fare
  const lowestTotalFare = (fare) => {
    let paxFare = fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
    paxFare = Array.isArray(paxFare) ? paxFare : [paxFare]

    const getBaseFareAndTaxes = (fare) => ({ baseFare: Number(fare?.PassengerFare?.BaseFare?.$?.Amount), taxes: Number(fare?.PassengerFare?.TotalFare?.$?.Amount) - Number(fare?.PassengerFare?.BaseFare?.$?.Amount) })
    const getNoFare = () => ({ baseFare: 0, taxes: 0 })


    let adultFare = getBaseFareAndTaxes(paxFare?.at(0));
    let childFare = searchValues?.childCount > 0 ? getBaseFareAndTaxes(paxFare?.at(1)) : getNoFare();
    let infantFare = searchValues?.infantNo > 0 ? getBaseFareAndTaxes(paxFare?.at(paxFare?.length - 1)) : getNoFare();

    let newFare = (pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(adultFare?.baseFare)),
      Number(CurrencyExchange(adultFare?.taxes)),
      getSectorsName(locDeparture, locationArrival),
      ExclusiveSetors(searchValues, pricing)
    )?.total_fare * searchValues?.adultsCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(childFare?.baseFare)),
        Number(CurrencyExchange(childFare?.taxes)),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.childCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(infantFare?.baseFare)),
        Number(CurrencyExchange(infantFare?.taxes)),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.infantNo)
      ;

    return `${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
  };

  //DiscountPrice
  const DiscountPrice = (fare, pax) => {
    let paxFare = fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
    paxFare = Array.isArray(paxFare) ? paxFare : [paxFare]

    const getBaseFareAndTaxes = (fare) => ({ baseFare: Number(fare?.PassengerFare?.BaseFare?.$?.Amount), taxes: Number(fare?.PassengerFare?.TotalFare?.$?.Amount) - Number(fare?.PassengerFare?.BaseFare?.$?.Amount) })
    const getNoFare = () => ({ baseFare: 0, taxes: 0 })


    let adultFare = getBaseFareAndTaxes(paxFare?.at(0));
    let childFare = searchValues?.childCount > 0 ? getBaseFareAndTaxes(paxFare?.at(1)) : getNoFare();
    let infantFare = searchValues?.infantNo > 0 ? getBaseFareAndTaxes(paxFare?.at(paxFare?.length - 1)) : getNoFare();

    let newFare = (pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(adultFare?.baseFare)),
      Number(CurrencyExchange(adultFare?.taxes)),
      getSectorsName(locDeparture, locationArrival),
      ExclusiveSetors(searchValues, pricing)
    )?.sp * searchValues?.adultsCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(childFare?.baseFare)),
        Number(CurrencyExchange(childFare?.taxes)),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.childCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(infantFare?.baseFare)),
        Number(CurrencyExchange(infantFare?.taxes)),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.infantNo)
    if (newFare > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
    } return "";

  };

  /// calculate flight duration
  const arrivalDateTime = new Date(flightSegment?.$?.ArrivalDateTime);
  const departureDateTime = new Date(flightSegment?.$?.DepartureDateTime);
  const durationInMilliseconds = arrivalDateTime - departureDateTime;
  // Convert the duration to hours and minutes
  const hours = Math.floor(durationInMilliseconds / 3600000); // 1 hour = 3600000 milliseconds
  const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds
  // Format hours and minutes with leading zeros
  const flightDuration = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;

  //////// single pax fare
  const singlePaxFare = (fare, pax) => {
    let newFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.PassengerFare?.BaseFare?.$?.Amount)),
        Number(CurrencyExchange(fare?.PassengerFare?.TotalFare?.$?.Amount)) -
        Number(CurrencyExchange(fare?.PassengerFare?.BaseFare?.$?.Amount)),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      ).total_fare * pax || 0;

    return `${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
  };
  const getPaxTaxes = (singlePax) => {
    let taxes = singlePax?.PassengerFare?.Taxes?.Tax;
    let fees = singlePax?.PassengerFare?.Fees?.Fee;
    taxes = Array.isArray(taxes) ? taxes : [taxes];
    fees = singlePax?.PassengerFare?.Fees?.Fee
      ? Array.isArray(fees)
        ? fees
        : [fees]
      : [];

    const getAllTaxes = taxes.map((tax) => ({
      code: tax?.$?.TaxCode,
      amount: ~~tax?.$?.Amount,
    }));
    const getAllFees = fees.map((fee) => ({
      code: fee?.$?.FeeCode,
      amount: ~~fee?.$?.Amount,
    }));

    return [...getAllTaxes, ...getAllFees];
  };

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }
  /// USE EFFECT
  useEffect(() => {
    dispatch(set_Airline_filter_drp("PA"));
    dispatch(
      set_All_Prices_Array(
        Number(lowestTotalFare(newFareArray[0])?.split(" ")[1])
      )
    );
  }, []);

  return (
    <>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`airBlue${index}`}
      >
        <div
          className="shadow result_box_body cursorpointer"
          onClick={() => {
            toggleDetail(index);
            setTimeout(() => {
              !isExpanded && scrollToElement(`airBlue${index}`);
            }, 200);
          }}
        >
          <div className="row align-items-center justify-content-center result_box_body">
            <div className="col-2">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={"/NEW_LOGO/PA.png"} alt="AirBlueLogo" width={70} />
                <div className="mt-2">PA {flightSegment?.$?.FlightNumber}</div>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightTakeoff
                      size={30}
                      className="color_primary"
                    />
                  </div>
                  <div>
                    <h6>
                      {moment(
                        new Date(
                          flightSegment?.$?.DepartureDateTime?.split("T")[0]
                        )
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {flightSegment?.$?.DepartureDateTime?.split(
                        "T"
                      )[1]?.slice(0, 5)}
                    </h6>
                    <h6>
                      {locDeparture?.split("-")[0]} -{" "}
                      {locDeparture?.split("-")[2].toUpperCase()}
                    </h6>
                  </div>
                </div>
                <div>
                  <h6 className="text-center">{flightDuration}</h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">Non-Stop</h6>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightLand size={30} className="color_primary" />
                  </div>

                  <div>
                    <h6>
                      {moment(
                        new Date(
                          flightSegment?.$?.ArrivalDateTime?.split("T")[0]
                        )
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {flightSegment?.$?.ArrivalDateTime?.split("T")[1]?.slice(
                        0,
                        5
                      )}
                    </h6>
                    <h6>
                      {locationArrival?.split("-")[0]} -{" "}
                      {locationArrival?.split("-")[2].toUpperCase()}
                    </h6>
                  </div>
                </div>
              </div>{" "}
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body">

                {selectedFare ? (
                  <HiEye className="color_primary" size={40} />
                ) : (
                  <>
                    <h6 className="text-danger">
                      {DiscountPrice(selectedFare ? selectedFlight?.fare : newFareArray[0])}
                    </h6>
                    <div className="d-flex align-items-end gap-3">

                      <h4>
                        {lowestTotalFare(
                          selectedFare ? selectedFlight?.fare : newFareArray[0]
                        )}
                      </h4>
                      <img src={AirBlueLogo} alt="" width={40} height={40} />
                    </div>
                    {user?.agents_comp?.agent_grp_show ? (
                      <div className="fw-bold fs-5">{data[0]?.agents_name}</div>
                    ) : null}
                    <button
                      onClick={() => {
                        toggleDetail(index);
                      }}
                      className="button_style w-75 my-3"
                      type="button"
                    >
                      <div>
                        <MenuOpenIcon className="me-1" />
                        Flight Detail
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {isExpanded ? (
          <div className="p-3">
            <div className="search_engin_result_box py-3 bg-white h-auto">
              <div
                className={`d-flex ${selectedFare
                  ? "justify-content-start"
                  : "justify-content-between"
                  } align-items-start px-5`}
              >
                {/* headings */}
                <div className={`${selectedFare ? "me-5 pe-5" : ""}`}>
                  <div className="mb-4 pb-3"></div>
                  <div className="flight-detail-headings">
                    <BsHandbag size={25} className="color_primary" />
                    <span>Hand Carry Bags</span>
                  </div>
                  <div className="flight-detail-headings">
                    <BsBagCheck size={25} className="color_primary" />
                    <span>Checked Bags</span>
                  </div>
                  <div className="flight-detail-headings">
                    <MdOutlineRestaurant size={25} className="color_primary" />
                    <span>Meals</span>
                  </div>
                  <div className="flight-detail-headings">
                    <BsPerson size={25} className="color_primary" />
                    <span>Seat Selection</span>
                  </div>
                  <div className="flight-detail-headings">
                    <MdSwapHoriz size={25} className="color_primary" />
                    <span>Refund & Exchanges</span>
                  </div>
                </div>

                {data
                  ?.filter((item) => {
                    const paxFare = Array.isArray(
                      item?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                        ?.PTC_FareBreakdown
                    )
                      ? item?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                        ?.PTC_FareBreakdown
                      : [
                        item?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                          ?.PTC_FareBreakdown,
                      ];

                    if (selectedFare === "Value") {
                      return !paxFare[0]?.FareInfo[1]?.PassengerFare
                        ?.FareBaggageAllowance;
                    } else if (selectedFare === "Flexi") {
                      const baggageQuantity =
                        paxFare[0]?.FareInfo[1]?.PassengerFare
                          ?.FareBaggageAllowance?.$?.UnitOfMeasureQuantity;
                      return baggageQuantity === "20";
                    } else if (selectedFare === "Extra") {
                      const baggageQuantity =
                        paxFare[0]?.FareInfo[1]?.PassengerFare
                          ?.FareBaggageAllowance?.$?.UnitOfMeasureQuantity;
                      return baggageQuantity === "30";
                    } else {
                      return true;
                    }
                  })
                  ?.map((fare, index) => {
                    let paxFare = Array.isArray(
                      fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                        ?.PTC_FareBreakdown
                    )
                      ? fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                        ?.PTC_FareBreakdown
                      : [
                        fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                          ?.PTC_FareBreakdown,
                      ];

                    return (
                      <div key={index}>

                        <div className="fs-4 theme-text-color mb-3 fw-bold">
                          {!paxFare[0]?.FareInfo[1]?.PassengerFare
                            ?.FareBaggageAllowance
                            ? "Value"
                            : paxFare[0]?.FareInfo[1]?.PassengerFare
                              ?.FareBaggageAllowance?.$
                              ?.UnitOfMeasureQuantity === "20"
                              ? "Flexi"
                              : "Extra"}
                        </div>
                        <div className="flight-detail-headings">
                          <MdCheck size={25} color="green" />
                          <span>Upto 7 kg</span>
                        </div>
                        <div className="flight-detail-headings">
                          {!paxFare[0]?.FareInfo[1]?.PassengerFare
                            ?.FareBaggageAllowance ? (
                            <>
                              <MdClose size={25} color="red" />
                              <span>0 Kg</span>
                            </>
                          ) : paxFare[0]?.FareInfo[1]?.PassengerFare
                            ?.FareBaggageAllowance?.$
                            ?.UnitOfMeasureQuantity === "20" ? (
                            <>
                              <MdCheck size={25} color="green" />
                              <span>20 kg (1 Pcs)</span>
                            </>
                          ) : paxFare[0]?.FareInfo[1]?.PassengerFare
                            ?.FareBaggageAllowance?.$
                            ?.UnitOfMeasureQuantity === "30" ? (
                            <>
                              <MdCheck size={25} color="green" />
                              <span>30 kg (1 Pcs)</span>
                            </>
                          ) : null}
                        </div>
                        <div className="flight-detail-headings">
                          <MdCheck size={25} color="green" />
                          <span>Allowed</span>
                        </div>
                        <div className="flight-detail-headings">
                          <CgMenuGridR size={25} color="#38025c" />
                          <span>System Allocated</span>
                        </div>
                        {/* refund and exchange dialog  */}
                        <div className="flight-detail-headings">
                          <LightTooltip
                            title={
                              <div className="p-3">
                                <h4>Refund & Exchange</h4>
                                <h6>
                                  Penalty will be charged per passenger segment.
                                </h6>
                                <div className="text-danger font-italic">
                                  for further clarification contact with Airline
                                  Or Call our support team.
                                </div>
                                <div className="text-danger font-italic mt-2">
                                  After 25 days of departure ticket is
                                  Non-Refundable.
                                </div>
                                <AirblueRE pax={paxFare[0]} />
                              </div>
                            }
                            placement="top-start"
                          >
                            <div style={{ cursor: "pointer" }}>
                              <MdRemoveRedEye size={25} color="#262829" />
                              <span>View</span>
                            </div>
                          </LightTooltip>
                        </div>

                        {!selectedFare ? (
                          <div className="pt-3 px-2">
                            <LightTooltip
                              title={
                                <div className="p-3">
                                  <div className="d-flex justify-content-between align-items-end">
                                    <div className="d-flex me-4">
                                      <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                      <h5>* {searchValues?.adultsCount}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                      {singlePaxFare(paxFare[0],
                                        1
                                      )}
                                    </h6>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-end my-2">
                                    <div className="d-flex">
                                      <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                      <h5>* {searchValues?.childCount}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                      {singlePaxFare(paxFare[1],
                                        searchValues?.childCount > 0 && 1
                                      )}
                                    </h6>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-end">
                                    <div className="d-flex">
                                      <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                      <h5>* {searchValues?.infantNo}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                      {singlePaxFare(paxFare[2],
                                        searchValues?.infantNo > 0 && 1
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              }
                              placement="top"
                            >
                              <h6 className="text-danger ms-2">{DiscountPrice(fare)}</h6>
                              <button
                                type="button"
                                className="button_style px-4"
                                onClick={() => {
                                  if (!selectedFare) {
                                    const baggage = {
                                      20: `20 KG (1 Pcs)`,
                                      30: `30 KG (1 Pcs)`,
                                    };

                                    const bookingBody = {
                                      flight: data,
                                      fare: fare,
                                      price: lowestTotalFare(fare),
                                      ttl_agent_fare: TwoDecimalPoint(
                                        CurrencyExchangeToPKR(
                                          Number(
                                            lowestTotalFare(fare)
                                              ?.split(" ")
                                              ?.at(1) || 0
                                          )
                                        )
                                      ),
                                      ttlagent_curr_fare: Number(
                                        lowestTotalFare(fare)
                                          ?.split(" ")
                                          ?.at(1) || 0
                                      ),
                                      ttl_supplier_fare: 0,
                                      ttlsup_curr_fare: TwoDecimalPoint(
                                        CurrencyExchange(
                                          fare?.AirItineraryPricingInfo
                                            ?.ItinTotalFare?.TotalFare?.$
                                            ?.Amount
                                        )
                                      ),

                                      adultBaseFare:
                                        ~~paxFare?.at(0)?.PassengerFare
                                          ?.BaseFare?.$?.Amount,
                                      adlt_ttl_d_curr: CurrencyExchangeToPKR(
                                        Number(
                                          singlePaxFare(paxFare[0], 1)
                                            ?.split(" ")
                                            ?.at(1)
                                        )
                                      ),
                                      chld_ttl_d_curr: CurrencyExchangeToPKR(
                                        Number(
                                          singlePaxFare(paxFare[1], 1)
                                            ?.split(" ")
                                            ?.at(1)
                                        )
                                      ),
                                      inft_ttl_d_curr: CurrencyExchangeToPKR(
                                        Number(
                                          singlePaxFare(paxFare[2], 1)
                                            ?.split(" ")
                                            ?.at(1)
                                        )
                                      ),
                                      adultTaxes:
                                        ~~paxFare?.at(0)?.PassengerFare
                                          ?.TotalFare?.$?.Amount -
                                        ~~paxFare?.at(0)?.PassengerFare
                                          ?.BaseFare?.$?.Amount,
                                      ADLT_TAXES: getPaxTaxes(paxFare[0]),
                                      CHLD_TAXES: getPaxTaxes(paxFare[1]),
                                      INFT_TAXES: getPaxTaxes(paxFare[2]),
                                      childBaseFare:
                                        ~~paxFare?.at(1)?.PassengerFare
                                          ?.BaseFare?.$?.Amount,
                                      childTaxes:
                                        ~~paxFare?.at(1)?.PassengerFare
                                          ?.TotalFare?.$?.Amount -
                                        ~~paxFare?.at(1)?.PassengerFare
                                          ?.BaseFare?.$?.Amount,
                                      infantBaseFare:
                                        ~~paxFare?.at(2)?.PassengerFare
                                          ?.BaseFare?.$?.Amount,
                                      infantTaxes:
                                        ~~paxFare?.at(2)?.PassengerFare
                                          ?.TotalFare?.$?.Amount -
                                        ~~paxFare?.at(2)?.PassengerFare
                                          ?.BaseFare?.$?.Amount,

                                      ADLT_FARE: paxFare?.at(0),
                                      CHLD_FARE: paxFare?.at(1),
                                      INFT_FARE: paxFare?.at(2),

                                      baggageName: !paxFare[0]?.FareInfo[1]
                                        ?.PassengerFare?.FareBaggageAllowance
                                        ? "Value"
                                        : paxFare[0]?.FareInfo[1]
                                          ?.PassengerFare
                                          ?.FareBaggageAllowance?.$
                                          ?.UnitOfMeasureQuantity === "20"
                                          ? "Flexi"
                                          : "Extra",
                                      flightNumber: flightSegment?.$?.FlightNumber,
                                      depDate: moment(
                                        flightSegment?.$?.DepartureDateTime
                                      ).format("DD-MMM-YY"),
                                      depTime:
                                        flightSegment?.$?.DepartureDateTime?.split(
                                          "T"
                                        )
                                          ?.at(1)
                                          ?.slice(0, 5),
                                      arrDate: moment(
                                        flightSegment?.$?.ArrivalDateTime
                                      ).format("DD-MMM-YY"),
                                      arrTime:
                                        flightSegment?.$?.ArrivalDateTime?.split(
                                          "T"
                                        )
                                          ?.at(1)
                                          ?.slice(0, 5),
                                      flightDuration: flightDuration,
                                      logo: "AirBlue",
                                      code: "PA",
                                      booking: {
                                        gds: "AIRBLUE", // should be gds code
                                        pnr: "", // returned in pricing response
                                        agent_name: data[0]?.agents_name,

                                        segments: [
                                          {
                                            departure_date:
                                              flightSegment?.$?.DepartureDateTime?.split(
                                                "T"
                                              )?.at(0),
                                            baggage:
                                              baggage[
                                              paxFare[0]?.FareInfo[1]
                                                ?.PassengerFare
                                                ?.FareBaggageAllowance?.$
                                                ?.UnitOfMeasureQuantity
                                              ] || "0 Kg (0 Pcs)",
                                            departure_time:
                                              flightSegment?.$?.DepartureDateTime?.split(
                                                "T"
                                              )
                                                ?.at(1)
                                                ?.slice(0, 5),
                                            arrival_date:
                                              flightSegment?.$?.ArrivalDateTime?.split(
                                                "T"
                                              )?.at(0),
                                            arrival_time:
                                              flightSegment?.$?.ArrivalDateTime?.split(
                                                "T"
                                              )
                                                ?.at(1)
                                                ?.slice(0, 5),
                                            origin_terminal: "",
                                            desti_terminal: "",
                                            flight_number:
                                              flightSegment?.$?.FlightNumber,
                                            status: flightSegment?.$?.Status,
                                            res_book_desig_code:
                                              flightSegment?.$
                                                ?.ResBookDesigCode,
                                            rph: flightSegment?.$?.RPH,
                                            des_location:
                                              searchValues?.locationArrival,
                                            dest_code: locationArrival
                                              ?.split("-")
                                              ?.at(0),
                                            marketing_airline:
                                              flightSegment?.MarketingAirline
                                                ?.$?.Code,
                                            equipment_code:
                                              flightSegment?.Equipment?.$
                                                ?.AirEquipType, // only mandatory for sabre
                                            operatingairline_code:
                                              flightSegment?.OperatingAirline
                                                ?.$?.Code,
                                            orig_location:
                                              searchValues?.locationDep,
                                            orig_code: locDeparture
                                              ?.split("-")
                                              ?.at(0),
                                            marriage_grp: "",
                                            paxes: paxFare,
                                            cabin_class:
                                              !paxFare[0]?.FareInfo[1]
                                                ?.PassengerFare?.FareBaggageAllowance
                                                ? "Value"
                                                : paxFare[0]?.FareInfo[1]
                                                  ?.PassengerFare
                                                  ?.FareBaggageAllowance?.$
                                                  ?.UnitOfMeasureQuantity === "20"
                                                  ? "Flexi"
                                                  : "Extra",
                                            segment_type: getSectorsName(
                                              locDeparture,
                                              locationArrival
                                            ), // dom | siti | soto | group
                                          },
                                        ],
                                        tp_ur_code: "",
                                        platting_carrier: "PA",
                                        tp_ssr_verssion: 0,
                                        tp_trace_id: "",
                                      },
                                    }
                                    dispatch(
                                      setselectedFlight({ ...bookingBody })
                                    );
                                    dispatch(setExpandFlightDetails(null));
                                    navigate("/check-out");
                                  }
                                }}
                              >
                                {lowestTotalFare(fare)}
                              </button>
                            </LightTooltip>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : null}
      </div >
    </>
  );
}

export const AirblueRE = ({ pax }) => {
  pax = pax?.FareInfo ? pax : pax?.at(0);
  return (
    <div>
      <h6 className="mt-3">contact with airline or call our support team</h6>
      {/* <div className="d-flex align-item-start w-fit mt-4">
        <div className="me-5">
          <h4>Exchange</h4>
          <h6>
            Before 48 Hours: {CurrencyCode()}{" "}
            {CurrencyExchange(
              pax?.FareInfo?.at(
                1
              )?.RuleInfo?.ChargesRules?.VoluntaryChanges?.Penalty?.at(2)?.$
                ?.Amount
            )}
          </h6>
          <h6>
            Within 48 Hours: {CurrencyCode()}{" "}
            {CurrencyExchange(
              pax?.FareInfo?.at(
                1
              )?.RuleInfo?.ChargesRules?.VoluntaryChanges?.Penalty?.at(2)?.$
                ?.Amount
            )}
          </h6>
          <h6>
            No Show: {CurrencyCode()}{" "}
            {CurrencyExchange(
              pax?.FareInfo?.at(
                1
              )?.RuleInfo?.ChargesRules?.VoluntaryChanges?.Penalty?.at(0)?.$
                ?.Amount
            )}
          </h6>
        </div>
        <div>
          <h4>Refund</h4>
          <h6>
            Before 48 Hours: {CurrencyCode()}{" "}
            {CurrencyExchange(
              pax?.FareInfo?.at(
                1
              )?.RuleInfo?.ChargesRules?.VoluntaryRefunds?.Penalty?.at(2)?.$
                ?.Amount
            )}
          </h6>
          <h6>
            Within 48 Hours: {CurrencyCode()}{" "}
            {CurrencyExchange(
              pax?.FareInfo?.at(
                1
              )?.RuleInfo?.ChargesRules?.VoluntaryRefunds?.Penalty?.at(1)?.$
                ?.Amount
            )}
          </h6>
          <h6>
            No Show: {CurrencyCode()}{" "}
            {CurrencyExchange(
              pax?.FareInfo?.at(
                1
              )?.RuleInfo?.ChargesRules?.VoluntaryRefunds?.Penalty?.at(0)?.$
                ?.Amount
            )}
          </h6>
        </div>
      </div> */}
    </div>
  );
};

export default Airblue;
