import React, { useEffect, useState } from "react";
import AirBlueLogo from "../../../../assets/images/airlineLogo/PA.png";
import moment from "moment";
//import icons
import {
  MdOutlineRestaurant,
  MdSwapHoriz,
  MdCheck,
  MdRemoveRedEye,
  MdClose,
  MdOutlineFlightTakeoff,
  MdOutlineFlightLand,
} from "react-icons/md";
import { BsBagCheck, BsPerson, BsHandbag } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { getSectorsName } from "../../../../Utils/FlightSector";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { LightTooltip } from "../OneWaySearchResult/LightTooltip";
import {
  setExpandFlightDetails,
  setReturnFlight,
  set_Airline_filter_drp,
  set_All_Prices_Array,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { ExclusiveSetors } from "../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import AirblueSegments from "./AirblueSegments";
import AirBlueFares from "./AirBlueFares";

function Airblue({ data, index, isExpanded, toggleDetail, selectedFlight }) {

  let outbound1stfare = [...(data?.outbound || [])].sort(
    (a, b) =>
      Number(a?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$?.Amount) -
      Number(b?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$?.Amount)
  )?.[0];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("Fare Options");
  const [boundToggle, setBoundToggle] = useState("outbound");
  const [selectedFare, setSelectedFare] = useState(outbound1stfare);

  const { airBlue } = useSelector(
    (state) => state.FlightSearch
  );

  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  // get values from local storage
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const locDeparture = searchValues?.locationDep;
  const locationArrival = searchValues?.locationArrival;

  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "PA"
  );

  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "PA");

  const outflightSegment =
    data?.outbound?.at(0)?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption
      ?.FlightSegment;

  const inflightSegment = data?.inbound?.at(0)?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption
    ?.FlightSegment;

  //flight segment
  const outboundflightSegment =
    data?.outbound?.at(0)?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption
      ?.FlightSegment;

  const inboundflightSegment =
    data?.inbound?.at(0)?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption
      ?.FlightSegment;

  let returnFlightArray = [...(data?.inbound || [])].sort(
    (a, b) =>
      a?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$?.Amount -
      b?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$?.Amount
  )?.at(0);


  const outboundArray = selectedFare?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
  const inboundArray = returnFlightArray?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;

  const outBoundPaxesPrice = Array.isArray(outboundArray) ? outboundArray : [outboundArray];
  const inboundPaxesPrice = Array.isArray(inboundArray) ? inboundArray : [inboundArray];

  // calculate pricing
  const lowestTotalFare = (fare, forCheckout) => {
    const selectedFareArray = fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
    const selectedPassengerFare = Array.isArray(selectedFareArray) ? selectedFareArray : [selectedFareArray];

    // Handle checkout fare only if forCheckout is provided and valid
    let forCheckoutFare = selectedFlight?.fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
    forCheckoutFare = Array.isArray(forCheckoutFare) ? forCheckoutFare : [forCheckoutFare];

    // Adult fares
    let adultBaseFare = Number(selectedPassengerFare?.at(0)?.PassengerFare?.BaseFare?.$?.Amount);
    let adultTaxes = Number(selectedPassengerFare?.at(0)?.PassengerFare?.TotalFare?.$?.Amount) - Number(selectedPassengerFare?.at(0)?.PassengerFare?.BaseFare?.$?.Amount);

    let returnAdultBaseFare = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(0)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(0)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(0)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    let returnAdultTaxes = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(0)?.PassengerFare?.TotalFare?.$?.Amount) - Number(forCheckoutFare?.at(0)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(0)?.PassengerFare?.TotalFare?.$?.Amount) - Number(inboundPaxesPrice?.at(0)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(0)?.PassengerFare?.TotalFare?.$?.Amount) - Number(outBoundPaxesPrice?.at(0)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    // Child fares
    let childBaseFare = Number(selectedPassengerFare?.at(1)?.PassengerFare?.BaseFare?.$?.Amount);
    let childTaxes = Number(selectedPassengerFare?.at(1)?.PassengerFare?.TotalFare?.$?.Amount) - Number(selectedPassengerFare?.at(1)?.PassengerFare?.BaseFare?.$?.Amount);

    let returnChildBaseFare = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(1)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(1)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(1)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    let returnChildTaxes = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(1)?.PassengerFare?.TotalFare?.$?.Amount) - Number(forCheckoutFare?.at(1)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(1)?.PassengerFare?.TotalFare?.$?.Amount) - Number(inboundPaxesPrice?.at(1)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(1)?.PassengerFare?.TotalFare?.$?.Amount) - Number(outBoundPaxesPrice?.at(1)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    // Infant fares
    let infantBaseFare = Number(selectedPassengerFare?.at(2)?.PassengerFare?.BaseFare?.$?.Amount);
    let infantTaxes = Number(selectedPassengerFare?.at(2)?.PassengerFare?.TotalFare?.$?.Amount) - Number(selectedPassengerFare?.at(2)?.PassengerFare?.BaseFare?.$?.Amount);

    let returnInfantBaseFare = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(2)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(2)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(2)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    let returnInfantTaxes = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(2)?.PassengerFare?.TotalFare?.$?.Amount) - Number(forCheckoutFare?.at(2)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(2)?.PassengerFare?.TotalFare?.$?.Amount) - Number(inboundPaxesPrice?.at(2)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(2)?.PassengerFare?.TotalFare?.$?.Amount) - Number(outBoundPaxesPrice?.at(2)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    // Calculate the total fare
    let newFare = (pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(Number(adultBaseFare) + Number(returnAdultBaseFare))),
      Number(CurrencyExchange(adultTaxes + returnAdultTaxes)),
      getSectorsName(locDeparture, locationArrival),
      ExclusiveSetors(searchValues, pricing)
    )?.total_fare * searchValues?.adultsCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(childBaseFare) + Number(returnChildBaseFare))),
        Number(CurrencyExchange(childTaxes + returnChildTaxes)),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(infantBaseFare) + Number(returnInfantBaseFare))),
        Number(CurrencyExchange(infantTaxes + returnInfantTaxes)),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.infantNo || 0);

    return TwoDecimalPoint(newFare);
  };

  const discountTotalFare = (fare, forCheckout) => {
    const selectedFareArray = fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
    const selectedPassengerFare = Array.isArray(selectedFareArray) ? selectedFareArray : [selectedFareArray];

    // Handle checkout fare only if forCheckout is provided and valid
    let forCheckoutFare = selectedFlight?.fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
    forCheckoutFare = Array.isArray(forCheckoutFare) ? forCheckoutFare : [forCheckoutFare];

    // Adult fares
    let adultBaseFare = Number(selectedPassengerFare?.at(0)?.PassengerFare?.BaseFare?.$?.Amount);
    let adultTaxes = Number(selectedPassengerFare?.at(0)?.PassengerFare?.TotalFare?.$?.Amount) - Number(selectedPassengerFare?.at(0)?.PassengerFare?.BaseFare?.$?.Amount);

    let returnAdultBaseFare = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(0)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(0)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(0)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    let returnAdultTaxes = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(0)?.PassengerFare?.TotalFare?.$?.Amount) - Number(forCheckoutFare?.at(0)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(0)?.PassengerFare?.TotalFare?.$?.Amount) - Number(inboundPaxesPrice?.at(0)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(0)?.PassengerFare?.TotalFare?.$?.Amount) - Number(outBoundPaxesPrice?.at(0)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    // Child fares
    let childBaseFare = Number(selectedPassengerFare?.at(1)?.PassengerFare?.BaseFare?.$?.Amount);
    let childTaxes = Number(selectedPassengerFare?.at(1)?.PassengerFare?.TotalFare?.$?.Amount) - Number(selectedPassengerFare?.at(1)?.PassengerFare?.BaseFare?.$?.Amount);

    let returnChildBaseFare = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(1)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(1)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(1)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    let returnChildTaxes = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(1)?.PassengerFare?.TotalFare?.$?.Amount) - Number(forCheckoutFare?.at(1)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(1)?.PassengerFare?.TotalFare?.$?.Amount) - Number(inboundPaxesPrice?.at(1)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(1)?.PassengerFare?.TotalFare?.$?.Amount) - Number(outBoundPaxesPrice?.at(1)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    // Infant fares
    let infantBaseFare = Number(selectedPassengerFare?.at(2)?.PassengerFare?.BaseFare?.$?.Amount);
    let infantTaxes = Number(selectedPassengerFare?.at(2)?.PassengerFare?.TotalFare?.$?.Amount) - Number(selectedPassengerFare?.at(2)?.PassengerFare?.BaseFare?.$?.Amount);

    let returnInfantBaseFare = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(2)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(2)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(2)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    let returnInfantTaxes = selectedFlight && forCheckout === "forCheckout"
      ? Number(forCheckoutFare?.at(2)?.PassengerFare?.TotalFare?.$?.Amount) - Number(forCheckoutFare?.at(2)?.PassengerFare?.BaseFare?.$?.Amount)
      : !selectedFlight
        ? (boundToggle === "outbound" ? Number(inboundPaxesPrice?.at(2)?.PassengerFare?.TotalFare?.$?.Amount) - Number(inboundPaxesPrice?.at(2)?.PassengerFare?.BaseFare?.$?.Amount) : Number(outBoundPaxesPrice?.at(2)?.PassengerFare?.TotalFare?.$?.Amount) - Number(outBoundPaxesPrice?.at(2)?.PassengerFare?.BaseFare?.$?.Amount))
        : 0;

    // Calculate the total fare
    let newFare = (pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(Number(adultBaseFare) + Number(returnAdultBaseFare))),
      Number(CurrencyExchange(adultTaxes + returnAdultTaxes)),
      getSectorsName(locDeparture, locationArrival),
      ExclusiveSetors(searchValues, pricing)
    )?.sp * searchValues?.adultsCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(childBaseFare) + Number(returnChildBaseFare))),
        Number(CurrencyExchange(childTaxes + returnChildTaxes)),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(infantBaseFare) + Number(returnInfantBaseFare))),
        Number(CurrencyExchange(infantTaxes + returnInfantTaxes)),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.infantNo || 0);

    if (newFare > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
    }
  };



  /// calculate flight duration
  const timeDurations = (depTime, arrTime) => {
    const departureDateTime = new Date(depTime);
    const arrivalDateTime = new Date(arrTime);
    const durationInMilliseconds = arrivalDateTime - departureDateTime; // Calculate the correct duration

    // Convert the duration to hours and minutes
    const hours = Math.floor(durationInMilliseconds / 3600000); // 1 hour = 3600000 milliseconds
    const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds

    // Format hours and minutes with leading zeros if necessary
    const flightDuration = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    return flightDuration;
  };

  //////// single pax fare
  const singlePaxFare = (fare, returnFare, pax) => {

    let outboundBaseFare = Number(fare?.PassengerFare?.BaseFare?.$?.Amount);
    let outboundTaxes = Number(fare?.PassengerFare?.TotalFare?.$?.Amount) - Number(fare?.PassengerFare?.BaseFare?.$?.Amount);

    let returenBaseFare = Number(returnFare?.PassengerFare?.BaseFare?.$?.Amount || 0);
    let returenfareTaxes = Number(returnFare?.PassengerFare?.TotalFare?.$?.Amount || 0) - Number(returnFare?.PassengerFare?.BaseFare?.$?.Amount || 0);

    let newFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(outboundBaseFare) + Number(returenBaseFare))),
        Number(CurrencyExchange(Number(outboundTaxes) + Number(returenfareTaxes))),
        getSectorsName(locDeparture, locationArrival)
      ).total_fare * pax || 0;

    return TwoDecimalPoint(newFare);
  };

  /////////////////// scroll page when clik on the view detail button
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  ///////////////// find the return flight from the airblue availability ////////////////
  const getAirblueFlight = new Map();
  // Iterate through the PricedItinerary items
  airBlue?.PricedItinerary?.forEach((item) => {
    const flightNumber =
      item?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption
        ?.FlightSegment?.$?.FlightNumber;
    const depCode =
      item?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption
        ?.FlightSegment?.DepartureAirport?.$?.LocationCode;

    // Check if depCode matches locArrCode
    if (depCode === locationArrival?.split("-")[0]) {
      // If the FlightNumber is already in the map, push the item to the existing array
      if (getAirblueFlight.has(flightNumber)) {
        getAirblueFlight.get(flightNumber).push(item);
      } else {
        // If the FlightNumber is not in the map, create a new array with the item
        getAirblueFlight.set(flightNumber, [item]);
      }
    }
  });

  /// calculate combine fare
  // const combineFare = (current) => {
  //   let total = returnFlightArray
  //     ? current +
  //     lowestTotalFare(
  //       selectedFlight ? selectedFlight?.fare : returnFlightArray?.at(0)
  //     )
  //     : current;
  //   return TwoDecimalPoint(total);
  // };

  //DiscountPrice
  // const DiscountPrice = (fare, pax) => {
  //   let paxFare = fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns?.PTC_FareBreakdown;
  //   paxFare = Array.isArray(paxFare) ? paxFare : [paxFare]

  //   const getBaseFareAndTaxes = (fare) => ({ baseFare: Number(fare?.PassengerFare?.BaseFare?.$?.Amount), taxes: Number(fare?.PassengerFare?.TotalFare?.$?.Amount) - Number(fare?.PassengerFare?.BaseFare?.$?.Amount) })
  //   const getNoFare = () => ({ baseFare: 0, taxes: 0 })


  //   let adultFare = getBaseFareAndTaxes(paxFare?.at(0));
  //   let childFare = searchValues?.childCount > 0 ? getBaseFareAndTaxes(paxFare?.at(1)) : getNoFare();
  //   let infantFare = searchValues?.infantNo > 0 ? getBaseFareAndTaxes(paxFare?.at(paxFare?.length - 1)) : getNoFare();

  //   let newFare = (pricingairline(
  //     airline,
  //     pricing,
  //     Number(CurrencyExchange(adultFare?.baseFare)),
  //     Number(CurrencyExchange(adultFare?.taxes)),
  //     getSectorsName(locDeparture, locationArrival),
  //     ExclusiveSetors(searchValues, pricing)
  //   )?.sp * searchValues?.adultsCount) +
  //     (pricingairline(
  //       airline,
  //       pricing,
  //       Number(CurrencyExchange(childFare?.baseFare)),
  //       Number(CurrencyExchange(childFare?.taxes)),
  //       getSectorsName(locDeparture, locationArrival),
  //       ExclusiveSetors(searchValues, pricing)
  //     )?.sp * searchValues?.childCount) +
  //     (pricingairline(
  //       airline,
  //       pricing,
  //       Number(CurrencyExchange(infantFare?.baseFare)),
  //       Number(CurrencyExchange(infantFare?.taxes)),
  //       getSectorsName(locDeparture, locationArrival),
  //       ExclusiveSetors(searchValues, pricing)
  //     )?.sp * searchValues?.infantNo)
  //   return TwoDecimalPoint(newFare);
  // };
  //combine discount

  // const combineDiscount = (current) => {
  //   let total = returnFlightArray
  //     ? current +
  //     DiscountPrice(
  //       selectedFlight ? selectedFlight?.fare : returnFlightArray?.at(0)
  //     )
  //     : current;
  //   if (total > 0) {
  //     return `Off ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
  //   } return ""
  // };

  const combineBaseFare = (current, alternate) => {
    let totalFare = ~~current?.PassengerFare?.TotalFare?.$?.Amount + ~~alternate?.PassengerFare?.TotalFare?.$?.Amount;
    let totalBF = ~~current?.PassengerFare?.BaseFare?.$?.Amount + ~~alternate?.PassengerFare?.BaseFare?.$?.Amount;
    let taxes = ~~totalFare - ~~totalBF;
    return ({ basic: totalBF, taxes, totalFare });
  };

  const getTaxes = (current, outbound) => {
    const data = [current, outbound].flatMap(({ PassengerFare }) => {
      let taxes = PassengerFare?.Taxes?.Tax;
      let fees = PassengerFare?.Fees?.Fee;
      taxes = Array.isArray(taxes) ? taxes : [taxes];
      fees = fees ? (Array.isArray(fees) ? fees : [fees]) : [];

      const getAllTaxes = taxes.map((tax) => ({
        code: tax?.$?.TaxCode,
        amount: ~~tax?.$?.Amount,
      }));
      const getAllFees = fees.map((fee) => ({
        code: fee?.$?.FeeCode,
        amount: ~~fee?.$?.Amount,
      }));
      return [...[...getAllTaxes, ...getAllFees]];
    })
    const combinedAmounts = data.reduce((acc, item) => {
      if (acc[item.code]) {
        acc[item.code] += item.amount;
      } else {
        acc[item.code] = item.amount;
      }
      return acc;
    }, {});

    return Object.keys(combinedAmounts).map(code => ({
      code: code,
      amount: combinedAmounts[code]
    }));
  };

  /// USE EFFECT
  // useEffect(() => {
  //   dispatch(set_Airline_filter_drp("PA"));
  //   dispatch(
  //     set_All_Prices_Array(
  //       Number(combineFare(lowestTotalFare(newFareArray[0])))
  //     )
  //   );
  // }, []);

  const outBoundformateDate = outboundflightSegment?.$?.DepartureDateTime;
  const inBoundformateDate = inboundflightSegment?.$?.DepartureDateTime;
  const outformattedDate = moment(outBoundformateDate)
    .format("ddd DD-MMM-YY")
    .toUpperCase();
  // Formatting inbound date
  const informattedDate = moment(inBoundformateDate)
    .format("ddd DD-MMM-YY")
    .toUpperCase();

  return (
    <>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`airBlue${index}`}
      >
        <div
          className="shadow result_box_body cursorpointer"
          onClick={() => {
            toggleDetail(index);
            setTimeout(() => {
              !isExpanded && scrollToElement(`airBlue${index}`);
            }, 200);
          }}
        >
          <div
            className='shadow cursorpointer d-flex justify-content-center align-items-center' onClick={() => toggleDetail(index)}
          >
            <div className="col-9">
              <AirblueSegments timeDurations={timeDurations} flightSegment={outboundflightSegment} segment="outbound" />
              <AirblueSegments timeDurations={timeDurations} flightSegment={inboundflightSegment} segment="inbound" />
            </div>
            <div className="col-3 left-shadow text-center align-self-stretch pt-5">
              <div
                className={`d-flex flex-column align-items-center justify-content-center result_box_body pt-4`}
              >
                <>
                  <div className="d-flex align-items-center gap-3">
                    <div className="h4">
                      <h6 className=" text-danger">
                        {discountTotalFare(selectedFlight ? selectedFlight?.outbound : boundToggle === "outbound" ? outbound1stfare : returnFlightArray, "forCheckout")}
                      </h6>
                      {CurrencyCode()}{" "}
                      {
                        lowestTotalFare(selectedFlight ? selectedFlight?.outbound : boundToggle === "outbound" ? outbound1stfare : returnFlightArray, "forCheckout")
                      }
                    </div>
                    <img
                      src={"/NEW_LOGO/PA.png"}
                      alt=""
                      width={40}
                      height={40}
                      className="mb-2"
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      toggleDetail(index);
                    }}
                    className="button_style w-75 mb-2 cursorpointer mt-2"
                    type="button"
                  >
                    <div>
                      <MenuOpenIcon className="me-1" />
                      Flight Detail
                    </div>
                  </button>
                </>
              </div>
            </div>
          </div>
        </div>

        {isExpanded ? (
          <div className="p-3">

            <div className="d-flex mb-4 mt-2 flight_details_tabs">
              {["Fare Options", "Flight Details"].map((item, index) => {
                const active = selectedTab === item;
                return (
                  <button
                    key={index}
                    onClick={() => setSelectedTab(item)}
                    className={active ? "active" : ""}
                  >
                    {item}
                  </button>
                );
              })}
            </div>

            <div className="d-flex align-items-center justify-content-between mt-4 mb-2">
              <div
                className={`${boundToggle === "outbound"
                  ? "button_acitve active-box"
                  : "button_style"
                  } rounded-md p-2 w-50 cursorpointer me-2`}
                onClick={() => setBoundToggle("outbound")}
              >
                <div className="d-flex gap-4">
                  <div className="font-medium">
                    {`${outflightSegment?.DepartureAirport?.$?.LocationCode} - ${outflightSegment?.ArrivalAirport?.$?.LocationCode}`}
                  </div>
                  <div className="font-medium">
                    {outformattedDate}
                  </div>
                </div>
              </div>

              <div
                className={`${boundToggle === "inbound"
                  ? "button_acitve active-box text-white"
                  : "button_style"
                  } rounded-md p-2 w-50 cursorpointer ms-2`}
                onClick={() => setBoundToggle("inbound")}
              >
                <div className="d-flex gap-4">
                  <div className="font-medium">
                    {`${inflightSegment?.DepartureAirport?.$?.LocationCode} - ${inflightSegment?.ArrivalAirport?.$?.LocationCode}`}
                  </div>
                  <div className="font-medium">
                    {informattedDate}
                  </div>
                </div>
              </div>
            </div>

            <div className="search_engin_result_box py-3 bg-white h-auto">
              <div>
                {selectedTab === "Flight Details" && (
                  <div>
                    {
                      boundToggle === "outbound" ?
                        <AirblueSegments timeDurations={timeDurations} flightSegment={outboundflightSegment} segment="outbound" details />
                        :
                        <AirblueSegments timeDurations={timeDurations} flightSegment={inboundflightSegment} segment="inbound" details />
                    }
                  </div>
                )}
              </div>
              <div>
                {selectedTab === "Fare Options" && (
                  <div className="p-4">
                    <AirBlueFares
                      data={data}
                      fares={data?.[boundToggle]}
                      lowestTotalFare={lowestTotalFare}
                      selectedFare={selectedFare}
                      setSelectedFare={setSelectedFare}
                      singlePaxFare={singlePaxFare}
                      returnFlightArray={returnFlightArray}
                      combineBaseFare={combineBaseFare}
                      boundToggle={boundToggle}
                      setBoundToggle={setBoundToggle}
                      getTaxes={getTaxes}
                      outboundflightSegment={outboundflightSegment}
                      timeDurations={timeDurations}
                      selectedFlight={selectedFlight}
                      discountTotalFare={discountTotalFare}
                    />
                  </div>
                )}
              </div>
              {/* headings */}

              {/* {(!selectedFare
                  ? data?.sort(
                    (a, b) =>
                      a?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$
                        ?.Amount -
                      b?.AirItineraryPricingInfo?.ItinTotalFare?.TotalFare?.$
                        ?.Amount
                  )
                  : data
                )
                  ?.filter((item) => {
                    const paxFare = Array.isArray(
                      item?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                        ?.PTC_FareBreakdown
                    )
                      ? item?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                        ?.PTC_FareBreakdown
                      : [
                        item?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                          ?.PTC_FareBreakdown,
                      ];

                    if (selectedFare === "Value") {
                      return !paxFare[0]?.FareInfo[1]?.PassengerFare
                        ?.FareBaggageAllowance;
                    } else if (selectedFare === "Flexi") {
                      const baggageQuantity =
                        paxFare[0]?.FareInfo[1]?.PassengerFare
                          ?.FareBaggageAllowance?.$?.UnitOfMeasureQuantity;
                      return baggageQuantity === "20";
                    } else if (selectedFare === "Extra") {
                      const baggageQuantity =
                        paxFare[0]?.FareInfo[1]?.PassengerFare
                          ?.FareBaggageAllowance?.$?.UnitOfMeasureQuantity;
                      return baggageQuantity === "30";
                    } else {
                      return true;
                    }
                  })
                  ?.map((fare, index) => {
                    let paxFare = Array.isArray(
                      fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                        ?.PTC_FareBreakdown
                    )
                      ? fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                        ?.PTC_FareBreakdown
                      : [
                        fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns
                          ?.PTC_FareBreakdown,
                      ];
                    let returnFARE = selectedFlight
                      ? Array.isArray(
                        selectedFlight?.fare?.AirItineraryPricingInfo
                          ?.PTC_FareBreakdowns?.PTC_FareBreakdown
                      )
                        ? selectedFlight?.fare?.AirItineraryPricingInfo
                          ?.PTC_FareBreakdowns?.PTC_FareBreakdown
                        : [
                          selectedFlight?.fare?.AirItineraryPricingInfo
                            ?.PTC_FareBreakdowns?.PTC_FareBreakdown,
                        ]
                      : Array.isArray(
                        returnFlightArray[0]?.AirItineraryPricingInfo
                          ?.PTC_FareBreakdowns?.PTC_FareBreakdown
                      )
                        ? returnFlightArray[0]?.AirItineraryPricingInfo
                          ?.PTC_FareBreakdowns?.PTC_FareBreakdown
                        : [
                          returnFlightArray[0]?.AirItineraryPricingInfo
                            ?.PTC_FareBreakdowns?.PTC_FareBreakdown,
                        ];
                    return (
                      <div key={index}>
                        <div className="fs-4 theme-text-color mb-3 fw-bold">
                          {!paxFare[0]?.FareInfo[1]?.PassengerFare
                            ?.FareBaggageAllowance
                            ? "Value"
                            : paxFare[0]?.FareInfo[1]?.PassengerFare
                              ?.FareBaggageAllowance?.$
                              ?.UnitOfMeasureQuantity === "20"
                              ? "Flexi"
                              : "Extra"}
                        </div>
                        <div className="flight-detail-headings">
                          <MdCheck size={25} color="green" />
                          <span>Upto 7 kg</span>
                        </div>
                        <div className="flight-detail-headings">
                          {!paxFare[0]?.FareInfo[1]?.PassengerFare
                            ?.FareBaggageAllowance ? (
                            <>
                              <MdClose size={25} color="red" />
                              <span>0 Kg</span>
                            </>
                          ) : paxFare[0]?.FareInfo[1]?.PassengerFare
                            ?.FareBaggageAllowance?.$
                            ?.UnitOfMeasureQuantity === "20" ? (
                            <>
                              <MdCheck size={25} color="green" />
                              <span>20 kg (1 Pcs)</span>
                            </>
                          ) : paxFare[0]?.FareInfo[1]?.PassengerFare
                            ?.FareBaggageAllowance?.$
                            ?.UnitOfMeasureQuantity === "30" ? (
                            <>
                              <MdCheck size={25} color="green" />
                              <span>30 kg (1 Pcs)</span>
                            </>
                          ) : null}
                        </div>
                        <div className="flight-detail-headings">
                          <MdCheck size={25} color="green" />
                          <span>Allowed</span>
                        </div>
                        <div className="flight-detail-headings">
                          <CgMenuGridR size={25} color="#38025c" />
                          <span>System Allocated</span>
                        </div>

                        <div className="flight-detail-headings">
                          <LightTooltip
                            title={
                              <div className="p-3">
                                <h4>Refund & Exchange</h4>
                                <h6>
                                  Penalty will be charged per passenger segment.
                                </h6>
                                <p className="text-danger font-italic">
                                  for further clarification contact with Airline
                                  Or Call our support team.
                                </p>

                                <div className="d-flex justify-content-between align-item-start w-fit mt-4">
                                  <div className="me-5">
                                    <h4>Exchange</h4>
                                    <h6>
                                      Before 48 Hours: {CurrencyCode()}{" "}
                                      {CurrencyExchange(
                                        paxFare[0]?.FareInfo[1]?.RuleInfo
                                          ?.ChargesRules?.VoluntaryChanges
                                          ?.Penalty[2]?.$?.Amount
                                      )}
                                    </h6>
                                    <h6>
                                      Within 48 Hours: {CurrencyCode()}{" "}
                                      {CurrencyExchange(
                                        paxFare[0]?.FareInfo[1]?.RuleInfo
                                          ?.ChargesRules?.VoluntaryChanges
                                          ?.Penalty[1]?.$?.Amount
                                      )}
                                    </h6>
                                    <h6>
                                      No Show: {CurrencyCode()}{" "}
                                      {CurrencyExchange(
                                        paxFare[0]?.FareInfo[1]?.RuleInfo
                                          ?.ChargesRules?.VoluntaryChanges
                                          ?.Penalty[0]?.$?.Amount
                                      )}
                                    </h6>
                                  </div>
                                  <div>
                                    <h4>Refund</h4>
                                    <h6>
                                      Before 48 Hours: {CurrencyCode()}{" "}
                                      {CurrencyExchange(
                                        paxFare[0]?.FareInfo[1]?.RuleInfo
                                          ?.ChargesRules?.VoluntaryRefunds
                                          ?.Penalty[2]?.$?.Amount
                                      )}
                                    </h6>
                                    <h6>
                                      Within 48 Hours: {CurrencyCode()}{" "}
                                      {CurrencyExchange(
                                        paxFare[0]?.FareInfo[1]?.RuleInfo
                                          ?.ChargesRules?.VoluntaryRefunds
                                          ?.Penalty[1]?.$?.Amount
                                      )}
                                    </h6>
                                    <h6>
                                      No Show: {CurrencyCode()}{" "}
                                      {CurrencyExchange(
                                        paxFare[0]?.FareInfo[1]?.RuleInfo
                                          ?.ChargesRules?.VoluntaryRefunds
                                          ?.Penalty[0]?.$?.Amount
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            }
                            placement="top-start"
                          >
                            <div style={{ cursor: "pointer" }}>
                              <MdRemoveRedEye size={25} color="#262829" />
                              <span>View</span>
                            </div>
                          </LightTooltip>
                        </div>

                        {!selectedFare ? (
                          <div className="pt-3 px-2">
                            <LightTooltip
                              title={
                                <div className="p-3">
                                  <div className="d-flex justify-content-between align-items-end">
                                    <div className="d-flex me-4">
                                      <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                      <h5>* {searchValues?.adultsCount}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                      {singlePaxFare(
                                        paxFare[0],
                                        returnFARE[0],
                                        searchValues?.adultsCount
                                      )}
                                    </h6>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-end my-2">
                                    <div className="d-flex">
                                      <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                      <h5>* {searchValues?.childCount}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                      {singlePaxFare(
                                        paxFare[1],
                                        returnFARE[1],
                                        searchValues?.childCount
                                      )}
                                    </h6>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-end">
                                    <div className="d-flex">
                                      <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                      <h5>* {searchValues?.infantNo}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                      {singlePaxFare(
                                        paxFare[2],
                                        returnFARE[2],
                                        searchValues?.infantNo
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              }
                              placement="top"
                            >
                              <button
                                type="button"
                                className="button_style px-4"
                              onClick={() => {
                                const baggage = {
                                  20: `20 KG (1 Pcs)`,
                                  30: `30 KG (1 Pcs)`,
                                };
                                if (selectedFlight) {
                                  dispatch(
                                    setReturnFlight({
                                      flight: data,
                                      fare: fare,
                                      price: combineFare(lowestTotalFare(fare)),
                                      ttl_agent_fare: TwoDecimalPoint(CurrencyExchangeToPKR(combineFare(lowestTotalFare(fare)))),
                                      ttlagent_curr_fare: combineFare(lowestTotalFare(fare)),
                                      // ttl_supplier_fare: fare.price + alternateFare?.price,
                                      // ttlsup_curr_fare: TwoDecimalPoint(CurrencyExchange(fare.price + alternateFare?.price)),
                                      adultBaseFare: combineBaseFare(paxFare[0], returnFARE[0], 1).basic,
                                      adultTaxes: combineBaseFare(paxFare[0], returnFARE[0], 1).taxes,
                                      childBaseFare: searchValues?.childCount === 0 ? 0 : ~~combineBaseFare(paxFare[1], returnFARE[1], 1).basic,
                                      childTaxes: searchValues?.childCount === 0 ? 0 : ~~combineBaseFare(paxFare[1], returnFARE[1], 1).taxes,
                                      infantBaseFare: searchValues?.infantNo === 0 ? 0 : ~~combineBaseFare(paxFare?.[paxFare?.length - 1], returnFARE?.[returnFARE?.length - 1], 1).basic,
                                      infantTaxes: searchValues?.infantNo === 0 ? 0 : ~~combineBaseFare(paxFare?.[paxFare?.length - 1], returnFARE?.[returnFARE?.length - 1], 1).taxes,
                                      adlt_ttl_d_curr: CurrencyExchangeToPKR(singlePaxFare(paxFare?.at(0), returnFARE?.at(0), 1)),
                                      chld_ttl_d_curr: searchValues?.childCount === 0 ? 0 : CurrencyExchangeToPKR(~~singlePaxFare(paxFare[1], returnFARE[1], 1)),
                                      inft_ttl_d_curr: searchValues?.infantNo === 0 ? 0 : CurrencyExchangeToPKR(~~singlePaxFare(paxFare?.[paxFare?.length - 1], returnFARE?.[returnFARE?.length - 1], 1)),
                                      ADLT_TAXES: getTaxes(paxFare[0], returnFARE[0]),
                                      CHLD_TAXES: searchValues?.childCount === 0 ? [] : getTaxes(paxFare[1], returnFARE[1]),
                                      INFT_TAXES: searchValues?.infantNo === 0 ? [] : getTaxes(paxFare?.[paxFare?.length - 1], returnFARE?.[returnFARE?.length - 1]),

                                      ADLT_FARE: paxFare?.at(0),
                                      CHLD_FARE: searchValues?.childCount === 0 ? {} : paxFare?.at(1),
                                      INFT_FARE: searchValues?.infantNo === 0 ? {} : paxFare?.at(paxFare?.length - 1),
                                      baggageName: !paxFare[0]?.FareInfo[1]
                                        ?.PassengerFare?.FareBaggageAllowance
                                        ? "Value"
                                        : paxFare[0]?.FareInfo[1]
                                          ?.PassengerFare
                                          ?.FareBaggageAllowance?.$
                                          ?.UnitOfMeasureQuantity === "20"
                                          ? "Flexi"
                                          : "Extra",
                                      flightNumber: `PA ${flightSegment?.$?.FlightNumber}`,
                                      depDate: flightSegment?.$?.DepartureDateTime?.split(
                                        "T"
                                      )?.at(0),
                                      depTime:
                                        flightSegment?.$?.DepartureDateTime?.split(
                                          "T"
                                        )[1]?.slice(0, 5),
                                      arrDate: moment(
                                        new Date(
                                          flightSegment?.$?.ArrivalDateTime?.split(
                                            "T"
                                          )[0]
                                        )
                                      ).format("DD-MMM-YY"),
                                      arrTime:
                                        flightSegment?.$?.ArrivalDateTime?.split(
                                          "T"
                                        )[1]?.slice(0, 5),
                                      // flightDuration: flightDuration,
                                      logo: "AirBlue",
                                      code: "PA",
                                      booking: {
                                        gds: "AIRBLUE", // should be gds code
                                        pnr: "", // returned in pricing response
                                        agent_name: data[0]?.agents_name,

                                        segments: [
                                          {
                                            paxes: paxFare,
                                            departure_date:
                                              flightSegment?.$?.DepartureDateTime?.split(
                                                "T"
                                              )?.at(0),
                                            baggage:
                                              baggage[
                                              paxFare[0]?.FareInfo[1]
                                                ?.PassengerFare
                                                ?.FareBaggageAllowance?.$
                                                ?.UnitOfMeasureQuantity
                                              ] || "0 Kg (0 Pcs)",
                                            departure_time:
                                              flightSegment?.$?.DepartureDateTime?.split(
                                                "T"
                                              )
                                                ?.at(1)
                                                ?.slice(0, 5),
                                            arrival_date:
                                              flightSegment?.$?.ArrivalDateTime?.split(
                                                "T"
                                              )?.at(0),
                                            arrival_time:
                                              flightSegment?.$?.ArrivalDateTime?.split(
                                                "T"
                                              )
                                                ?.at(1)
                                                ?.slice(0, 5),
                                            origin_terminal: "",
                                            desti_terminal: "",
                                            flight_number:
                                              flightSegment?.$?.FlightNumber,
                                            status: flightSegment?.$?.Status,
                                            res_book_desig_code:
                                              flightSegment?.$
                                                ?.ResBookDesigCode,
                                            rph: flightSegment?.$?.RPH,
                                            des_location:
                                              searchValues?.locationDep,
                                            dest_code:
                                              searchValues?.locationDep
                                                ?.split("-")
                                                ?.at(0),
                                            marketing_airline:
                                              flightSegment?.MarketingAirline
                                                ?.$?.Code,
                                            equipment_code:
                                              flightSegment?.Equipment?.$
                                                ?.AirEquipType, // only mandatory for sabre
                                            operatingairline_code:
                                              flightSegment?.OperatingAirline
                                                ?.$?.Code,
                                            orig_location:
                                              searchValues?.locationArrival,
                                            orig_code:
                                              searchValues?.locationArrival
                                                ?.split("-")
                                                ?.at(0),
                                            marriage_grp: "",
                                            cabin_class:
                                              searchValues?.ticket_class,
                                            segment_type: getSectorsName(
                                              locDeparture,
                                              locationArrival
                                            ), // dom | siti | soto | group
                                          },
                                        ],
                                        tp_ur_code: "",
                                        platting_carrier: "PA",
                                        tp_ssr_verssion: 0,
                                        tp_trace_id: "",
                                      },
                                    })
                                  );
                                  navigate("/check-out");
                                } else {
                                  dispatch(
                                    setselectedFlight({
                                      flight: data,
                                      fare: fare,
                                      price: combineFare(lowestTotalFare(fare)),
                                      ttl_agent_fare: TwoDecimalPoint(CurrencyExchangeToPKR(combineFare(lowestTotalFare(fare)))),
                                      ttlagent_curr_fare: combineFare(lowestTotalFare(fare)),
                                      // ttl_supplier_fare: fare.price + alternateFare?.price,
                                      // ttlsup_curr_fare: TwoDecimalPoint(CurrencyExchange(fare.price + alternateFare?.price)),
                                      adultBaseFare: combineBaseFare(paxFare[0], returnFARE[0], 1).basic,
                                      adultTaxes: combineBaseFare(paxFare[0], returnFARE[0], 1).taxes,
                                      childBaseFare: searchValues?.childCount === 0 ? 0 : ~~combineBaseFare(paxFare[1], returnFARE[1], 1).basic,
                                      childTaxes: searchValues?.childCount === 0 ? 0 : ~~combineBaseFare(paxFare[1], returnFARE[1], 1).taxes,
                                      infantBaseFare: searchValues?.infantNo === 0 ? 0 : ~~combineBaseFare(paxFare?.[paxFare?.length - 1], returnFARE?.[returnFARE?.length - 1], 1).basic,
                                      infantTaxes: searchValues?.infantNo === 0 ? 0 : ~~combineBaseFare(paxFare?.[paxFare?.length - 1], returnFARE?.[returnFARE?.length - 1], 1).taxes,
                                      adlt_ttl_d_curr: CurrencyExchangeToPKR(singlePaxFare(paxFare?.at(0), returnFARE?.at(0), 1)),
                                      chld_ttl_d_curr: searchValues?.childCount === 0 ? 0 : CurrencyExchangeToPKR(~~singlePaxFare(paxFare[1], returnFARE[1], 1)),
                                      inft_ttl_d_curr: searchValues?.infantNo === 0 ? 0 : CurrencyExchangeToPKR(~~singlePaxFare(paxFare?.[paxFare?.length - 1], returnFARE?.[returnFARE?.length - 1], 1)),
                                      ADLT_TAXES: getTaxes(paxFare[0], returnFARE[0]),
                                      CHLD_TAXES: searchValues?.childCount === 0 ? [] : getTaxes(paxFare[1], returnFARE[1]),
                                      INFT_TAXES: searchValues?.infantNo === 0 ? [] : getTaxes(paxFare?.[paxFare?.length - 1], returnFARE?.[returnFARE?.length - 1]),
                                      baggageName:
                                        index === 0
                                          ? "Value"
                                          : index === 1
                                            ? "Flexi"
                                            : "Extra",
                                      flightNumber: `PA ${flightSegment?.$?.FlightNumber}`,
                                      depDate: moment(
                                        new Date(
                                          flightSegment?.$?.DepartureDateTime?.split(
                                            "T"
                                          )[0]
                                        )
                                      ).format("DD-MMM-YY"),
                                      depTime:
                                        flightSegment?.$?.DepartureDateTime?.split(
                                          "T"
                                        )[1]?.slice(0, 5),
                                      arrDate: moment(
                                        new Date(
                                          flightSegment?.$?.ArrivalDateTime?.split(
                                            "T"
                                          )[0]
                                        )
                                      ).format("DD-MMM-YY"),
                                      arrTime:
                                        flightSegment?.$?.ArrivalDateTime?.split(
                                          "T"
                                        )[1]?.slice(0, 5),
                                      // flightDuration: flightDuration,
                                      logo: "AirBlue",
                                      code: "PA",
                                      booking: {
                                        gds: "AIRBLUE", // should be gds code
                                        pnr: "", // returned in pricing response
                                        agent_name: data[0]?.agents_name,

                                        segments: [
                                          {
                                            paxes: paxFare,
                                            departure_date:
                                              flightSegment?.$?.DepartureDateTime?.split(
                                                "T"
                                              )?.at(0),
                                            baggage:
                                              baggage[
                                              paxFare[0]?.FareInfo[1]
                                                ?.PassengerFare
                                                ?.FareBaggageAllowance?.$
                                                ?.UnitOfMeasureQuantity
                                              ] || "0 Kg (0 Pcs)",
                                            departure_time:
                                              flightSegment?.$?.DepartureDateTime?.split(
                                                "T"
                                              )
                                                ?.at(1)
                                                ?.slice(0, 5),
                                            arrival_date:
                                              flightSegment?.$?.ArrivalDateTime?.split(
                                                "T"
                                              )?.at(0),
                                            arrival_time:
                                              flightSegment?.$?.ArrivalDateTime?.split(
                                                "T"
                                              )
                                                ?.at(1)
                                                ?.slice(0, 5),
                                            origin_terminal: "",
                                            desti_terminal: "",
                                            flight_number:
                                              flightSegment?.$?.FlightNumber,
                                            status: flightSegment?.$?.Status,
                                            res_book_desig_code:
                                              flightSegment?.$
                                                ?.ResBookDesigCode,
                                            rph: flightSegment?.$?.RPH,
                                            des_location:
                                              searchValues?.locationArrival,
                                            dest_code: locationArrival
                                              ?.split("-")
                                              ?.at(0),
                                            marketing_airline:
                                              flightSegment?.MarketingAirline
                                                ?.$?.Code,
                                            equipment_code:
                                              flightSegment?.Equipment?.$
                                                ?.AirEquipType, // only mandatory for sabre
                                            operatingairline_code:
                                              flightSegment?.OperatingAirline
                                                ?.$?.Code,
                                            orig_location:
                                              searchValues?.locationDep,
                                            orig_code: locDeparture
                                              ?.split("-")
                                              ?.at(0),
                                            marriage_grp: "",
                                            cabin_class:
                                              searchValues?.ticket_class,
                                            segment_type: getSectorsName(
                                              locDeparture,
                                              locationArrival
                                            ), // dom | siti | soto | group
                                          },
                                        ],
                                        tp_ur_code: "",
                                        platting_carrier: "PA",
                                        tp_ssr_verssion: 0,
                                        tp_trace_id: "",
                                      },
                                    })
                                  );
                                }
                                dispatch(setExpandFlightDetails(null));
                              }}
                              >
                                {CurrencyCode()}{" "}
                                {combineFare(lowestTotalFare(fare))}
                              </button>
                            </LightTooltip>
                          </div>
                        ) : null}
                      </div>
                    );
                  })} */}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Airblue;
