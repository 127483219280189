import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import * as yup from "yup";
import DeleteModal from "../../DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetSingleAmadeusApiCred,
  asyncGetAmadeusApiCredentials,
  asyncDeleteAmadeusCredential,
  asyncPostAmadeusApiCredentials,
  asyncUpdateAmadeusCredential,
  editAmadeusCredentialNull,
} from "../../../redux/features/setupFeature/ApiCredential/AmadeusCredentialSlice";
import { ChangeStatus } from "../../../Utils/ChangeStatus";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { dropMenuProp } from "../../../Utils/MenuProps";
import BtnLoader from '../../AppForm/BtnLoader';
import { asyncGetNewSupplier } from '../../../redux/features/supplier/AddNewSupplier';
import { options } from '../../../Utils/ColumnOptions';
function Amadeus() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //use selector
  const { AmadeusCredentialsData, loading, editAmadeusCredentials } = useSelector(
    (state) => state.amaduesCredential
  );
  const { newSupplier } = useSelector((state) => state.supplier);
  //use state
  const [ShowModel, setShowModel] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  //use effect functions
  useEffect(() => {
    dispatch(asyncGetAmadeusApiCredentials());
  }, [dispatch]);

  //  table columns
  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          const isChecked = Boolean(value); // Ensure value is treated as a boolean
          return (
            <div className="checkbox-rect">
              <input
                value={isChecked}
                checked={isChecked}
                type="checkbox"
                id={"with_sar_" + id}
                onChange={(event) => {
                  const newValue = !isChecked;
                  updateValue(newValue);
                  ChangeStatus(
                    "/api/v1/b2b/amadeus-credential/status",
                    newValue,
                    id,
                    "status"
                  );
                }}
              />
              <label htmlFor={"with_sar_" + id} className="h6"></label>
            </div>
          );
        },
      },
    }
    ,
    {
      label: "Agent Name",
      name: "agent_name",
    },
    {
      label: "Amadeus ID",
      name: "amadeus_id",
    },
    {
      label: "Amadeus Secret",
      name: "amadeus_secret",
    },
    {
      label: "Amadeus URL",
      name: "amadeus_url",
    },
    {
      name: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <div>
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  window.scroll({ top: 0 });
                  dispatch(asyncGetSingleAmadeusApiCred(id));
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setShowModel(true);
                  setRowData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-thin fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];
  //initital value
  const initialValues = {
    agent_name: editAmadeusCredentials?.agent_name || "",
    amadeus_id: editAmadeusCredentials?.amadeus_id || "",
    amadeus_secret: editAmadeusCredentials?.amadeus_secret || "",
    amadeus_url: editAmadeusCredentials?.amadeus_url || "",
    ticket_status: editAmadeusCredentials?.ticket_status || 1,
  };

  useEffect(() => {
    if (editAmadeusCredentials) {
      const initialSupplier = newSupplier.find(supplier => supplier.supp_name === editAmadeusCredentials.agent_name);
      setSelectedSupplier(initialSupplier || null);
    }
  }, [editAmadeusCredentials, newSupplier]);


  //validation schemas
  const validationSchema = yup.object().shape({
    agent_name: yup.string().required("Agent Name is required"),
    amadeus_id: yup.string().required("Pf Agent Name is required"),
    amadeus_secret: yup.string().required("Agent Password is required"),
    amadeus_url: yup.string().required("Service url is required"),
  });

  return (
    <div>
      <div>
        <div className="mx-5 my-5 bg-white rounded-2">
          <div className="d-flex align-items-center bg_primary text-white py-3">
            <div className="col-5">
              <span
                className="cursorpointer fs-5 w-content"
                onClick={() => {
                  dispatch(editAmadeusCredentialNull());

                  navigate("/api-credentials");
                }}
              >
                <MdArrowBackIosNew /> Back
              </span>
            </div>
            <div className="box_title px-2">Add / Edit Amadeus</div>
          </div>

          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              let id = editAmadeusCredentials?.id;
              if (editAmadeusCredentials) {
                dispatch(asyncUpdateAmadeusCredential({ id, values }));
                dispatch(editAmadeusCredentialNull());
              } else {
                dispatch(asyncPostAmadeusApiCredentials(values));
              }
              resetForm({});
              setSelectedSupplier(null)

            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <div className="row px-4 pb-3 align-items-end">
                    <div className="col-2 mt-4 ">
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={newSupplier}
                        getOptionLabel={(option) => option.supp_name}
                        value={selectedSupplier}
                        getOptionSelected={(option, value) => option.supp_name === value.supp_name}
                        onChange={(event, newValue) => {
                          setSelectedSupplier(newValue)
                          if (newValue) {
                            setFieldValue("supplier", newValue);
                            setFieldValue("agent_name", newValue.supp_name);
                          } else {
                            setFieldValue("supplier", "");
                            setFieldValue("agent_name", "");

                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            variant="standard"
                            label="Select Agent Name"
                          />
                        )}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="agent_name"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <TextField
                        label="Amadeus ID"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="amadeus_id"
                        value={values.amadeus_id}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("amadeus_id", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="amadeus_id"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <TextField
                        label="Amadeus Secret"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="amadeus_secret"
                        value={values.amadeus_secret}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("amadeus_secret", value);
                        }}
                      />

                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="amadeus_secret"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <TextField
                        label="Base URL"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="amadeus_url"
                        value={values.amadeus_url}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="amadeus_url"
                      />
                    </div>
                    <div className="col-2">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id={`ticket_status`}>
                          Ticket Status
                        </InputLabel>
                        <Select
                          id={`ticket_status`}
                          value={values.ticket_status}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("ticket_status", value);
                          }}
                          MenuProps={dropMenuProp}
                          label={"Ticket Status"}
                        >
                          <MenuItem value={1}>YES</MenuItem>
                          <MenuItem value={0}>NO</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-12 text-center mt-5 pb-4">
                    <button className="button_style px-5 me-3" type="submit">
                      {loading ? (
                        <BtnLoader />
                      ) : editAmadeusCredentials ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </button>
                    {editAmadeusCredentials && (
                      <button
                        type="button"
                        className="button_style px-5 bg-danger"
                        onClick={() => {
                          dispatch(editAmadeusCredentialNull());
                          setSelectedSupplier(null)
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/*// Credensiol list  ////////*/}

        <div className="mx-5 shadow-sm rounded-2 my-4 bg-white">
          <p className="otawix-heading box_title py-2">
            Amadeus Credentials List
          </p>
          <div className="p-4">
            <DeleteModal
              show={ShowModel}
              setShow={setShowModel}
              onClick={(e) => {
                dispatch(asyncDeleteAmadeusCredential(rowData[0]));
                setShowModel(false);
              }}
            />
            <MUIDataTable
              className="muidatatable"
              title={""}
              data={AmadeusCredentialsData}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Amadeus;