import React from "react";
import { AirblueRE } from "../OneWaySearchResult/Airblue";
import { useSelector } from "react-redux";
import PiaRE from "./PiaRE";
import SereneRE from "./SereneRE";
import SialRE from "./SialRE";
import SabreRefund from "./SabreRefund";
import FlyJinnahRE from "./FlyJinnahRE";

function RefundAndExchange({ gds }) {
  const { selectedFlight } = useSelector((state) => state.FlightSearch);
  return (
    <div className="">
      <div className="d-flex bg_primary w-100 align-items-center  text-white p-2 rounded">
        <h4>Refund & Exchange</h4>
      </div>
      <h6 className="mt-2">Penalty will be charged per passenger segment.</h6>
      <div className="text-danger font-italic">
        for further clarification contact with Airline Or Call our support team.
      </div>
      <div className="text-danger font-italic mt-2">
        After 25 days of departure ticket is Non-Refundable.
      </div>

      <h6 className="mt-3">Contact with airline or call our support team </h6>

      {/*  pia */}
      {/* {(selectedFlight?.logo === "HITIT" || gds === "HITIT") && <PiaRE />} */}
      {/*  //////// AIRBLUE */}
      {/* {(selectedFlight?.logo === "AirBlue" || gds === "AIRBLUE") && (
        <AirblueRE
          pax={
            selectedFlight?.fare?.AirItineraryPricingInfo?.PTC_FareBreakdowns
              ?.PTC_FareBreakdown
          }
        />
      )} */}
      {/*  serene */}
      {/* {(selectedFlight?.logo === "Serene" || gds === "SERENE") && <SereneRE />}
      {(selectedFlight?.logo === "Sial" || gds === "AIRSIAL") && <SialRE />}
      {(selectedFlight?.logo === "FlyJinnah" || gds === "FlyJinnah") && (
        <FlyJinnahRE />
      )} */}
      {/* {(selectedFlight?.logo === "Sabre" || gds === "SABRE") && <SabreRefund />} */}
    </div>
  );
}

export default RefundAndExchange;
