import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"; // Import axios
import { NODEURL } from "../ApiConfig"; // Import the base API URL

// Initial state
const initialState = {
  data: [],               // Account structure data
  openBalance: "form",
  opening:"",    // State for trail balance
  isLoading: false,       // Loading status
  error: null,            // Error message
};

// Async thunk to fetch account structure
export const fetchOpeningBalance = createAsyncThunk(
  "openingBalance/fetchOpeningBalance",
  async ({ ope,fromDate, toDate }, { rejectWithValue }) => {
    try {
      // Ensure that NODEURL is a string base URL and not an Axios instance
      const response = await NODEURL.post(
        `api/v1/b2b/account/balanceSheet`,
        { ope,fromDate, toDate }
      );
      return response.data; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle error
    }
  }
);

// Slice for account structure
const openingBalanceSlice = createSlice({
  name: "openingBalance",
  initialState,
  reducers: {
    // Action to set trail balance state
    setOpeningBalance: (state, action) => {
      state.openBalance = action.payload; // Correct state mutation
    },
    setOpening: (state, action) => {
      state.opening = action.payload; // Correct state mutation
    },
    resetOpening: (state) => {
      state.opening = "";      // Reset opening to an empty string
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpeningBalance.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchOpeningBalance.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload; // Update the state data with payload
        state.error = null;
      })
      .addCase(fetchOpeningBalance.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload; // Update the state error with the payload
      });
  },
});

// Export actions and reducer
export const { setOpeningBalance,setOpening,resetOpening } = openingBalanceSlice.actions; // Corrected export
export default openingBalanceSlice.reducer;
