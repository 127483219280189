import React, { useEffect, useRef, useState } from "react";
import { asyncGetAdmin } from "../../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  resetOpening,
  setOpeningBalance,
} from "../../../../redux/features/OpeningBalance/openingBalance";
import moment from "moment";
import { apiUrl } from "../../../../Utils/configUrl";
import iata from "../../../../assets/images/iata.png";
import ReactToPrint from "react-to-print";
import BtnLoader from "../../../AppForm/BtnLoader";
const BalanceTable = ({ accountData, fromDate, toDate, dropdownValue }) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { opening } = useSelector((state) => state?.openingBalance);
  const [loading, setLoading] = useState(false);
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);
  const { getAdminData } = useSelector((state) => state.newAgency);
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const firstColor = adminAgencyData?.first_color;
  useEffect(() => {
    dispatch(asyncGetAdmin({ id: 1 }));
  }, [dispatch]);

  function groupedAccounts(accounts) {
    const grouped = [];
    const totals = {
      assets: 0,
      liabilities: 0,
      capital: 0,
    };

    // Helper function to find and add child accounts to their parent
    function addChildren(parent, allAccounts) {
      const children = allAccounts.filter(
        (account) => account.acc_parent === parent.acc_id
      );
      children.forEach((child) => {
        grouped.push(child);
        addChildren(child, allAccounts); // Recursively add children's children
        // Update total for the parent category
        if (parent.acc_title === "ASSETS") {
          totals.assets +=
            child.activityDebitAmount + child.activityCreditAmount;
        } else if (parent.acc_title === "LIABILITIES") {
          totals.liabilities +=
            child.activityDebitAmount + child.activityCreditAmount;
        } else if (parent.acc_title === "CAPITAL") {
          totals.capital +=
            child.activityDebitAmount + child.activityCreditAmount;
        }
      });
    }

    // Filter accounts to exclude 'EXPENSES' and 'INCOME'
    const filteredAccounts = accounts?.filter((account) => {
      return (
        !account.acc_title.includes("EXPENSES") &&
        !account.acc_title.includes("INCOME")
      );
    });

    // Start by adding root-level accounts (those with no acc_parent)
    filteredAccounts
      ?.filter((account) => account.acc_parent === 0)
      ?.forEach((account) => {
        grouped.push(account);
        addChildren(account, accounts);
      });

    // Add total rows for each parent category
    if (totals.assets >= 0 || totals.assets === "0") {
      grouped.push({
        acc_title: "ASSETS",
        totalAmount: totals.assets,
        isTotal: true,
      });
    }
    if (totals.liabilities >= 0) {
      grouped.push({
        acc_title: "LIABILITIES",
        totalAmount: totals.liabilities,
        isTotal: true,
      });
    }
    if (totals.capital >= 0) {
      grouped.push({
        acc_title: "CAPITAL",
        totalAmount: totals.capital,
        isTotal: true,
      });
    }
    return grouped;
  }

  function getIndentationLevel(account) {
    const level = account.acc_level_code.split("-").length;
    return level * 10; // Adjust the multiplier for desired indentation width
  }
  const handleTitleClick = (account) => {
    const queryParams = new URLSearchParams({
      acc_title: account.acc_title,
      fromDate: fromDate?.toISOString(),
      toDate: toDate?.toISOString(),
      op: opening,
      getAdminData: getAdminData.logo,
    }).toString();

    const url = `/accounts/${account.acc_id}?${queryParams}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const findLastChild = (accountId) => {
    // Find children of the given account
    const children = groupedAccounts(accountData?.accountTotals)
      .filter((account) => account.acc_nature !== "SUBSIDIARY")
      .filter((account) => account.acc_parent === accountId);

    // If there are no children, return null
    if (!children || children.length === 0) {
      return null;
    }

    // Recursively find the last child
    return (
      findLastChild(children[children.length - 1].acc_id) ||
      children[children.length - 1]
    );
  };

  const handleBack = (event) => {
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch(resetOpening());
      dispatch(setOpeningBalance("form"));
    }, 1000);
  };

  return (
    <div
      className="d-flex  flex-column gap-4 bg-white "
      style={{ width: "100%" }}
    >
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />
          {!loading ? (
            <div>
              <button
                onClick={handleBack}
                className="bg_primary px-4 py-1 rounded text-white"
              >
                Back
              </button>
            </div>
          ) : (
            <div className="text-center py-4">
              <button className="button_style px-4">
                <BtnLoader />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-column gap-3  mt-4" ref={componentRef}>
        <div className="d-flex justify-content-around align-items-center">
          <div style={{ width: "220px" }}>
            <img
              src={`${apiUrl}/${getAdminData?.logo}`}
              alt="logo"
              style={{ width: "80%", height: "auto" }}
            />
          </div>
          <div
            className="d-flex fw-bold fs-6  flex-column gap-2 align-items-center"
            style={{ width: "1200px" }}
          >
            <span className=" text-black">
              {" "}
              {userData?.agents_comp?.agent_name}
            </span>
            <span className="text-black">
              {" "}
              {userData?.agents_comp?.address
                ?.split("]")
                ?.slice(0, 3)
                ?.join(" ")}{" "}
            </span>
            <span className=" text-black"> Email: {userData?.email}</span>
            <span className=" text-black"> Contact:{userData?.mobile_no}</span>
            <span className=" text-black">
              {" "}
              From: {moment(fromDate)?.format("DD-MMM-YY").toUpperCase()}
            </span>
            <span className=" text-black">
              {" "}
              To: {moment(toDate)?.format("DD-MMM-YY").toUpperCase()}
            </span>
          </div>
          <div style={{ width: "200px" }}>
            <img
              src={iata}
              alt="iata"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white fw-bold my-2"> Balance Sheet</span>
        </div>
        {/* {isSubmitted && ( */}
        <div className="d-flex justify-content-center align-items-center">
          <div
            //   className="container d-flex justify-content-end mt-1"
            className="d-flex flex-column gap-3  mt-4 align-items-center"
            style={{ width: "97%", height: "20%" }}
          >
            <table
              className="table table-bordered  table-sm table-striped"
              style={{ width: "100%", height: "20%", fontSize: "0.8rem" }}
            >
              <thead>
                {/* <tr>
                  <th
                    style={{
                      //   border: "1px solid black",
                      padding: "8px",
                      textAlign: "center",
                      width: "300px",
                    }}
                  >
                    Account
                  </th>

                  {(dropdownValue === "BALANCE" ||
                    dropdownValue === "All Type") && (
                    <>
                      <th
                        colSpan={2}
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        {`For (2024)`}
                      </th>
                    </>
                  )}
                </tr>
                <tr className="align-item-center">
                  <th
                    style={{
                      padding: "8px",
                      width: "400px",
                    }}
                  >
                    Name
                  </th>

                  {(dropdownValue === "BALANCE" ||
                    dropdownValue === "All Type") && (
                    <>
                      <th
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                          width: "200px",
                        }}
                      >
                        price
                      </th>
                    </>
                  )}
                </tr> */}
              </thead>

              <tbody>
                {groupedAccounts(accountData?.accountTotals)
                  .filter((account) => account.acc_nature !== "SUBSIDIARY")
                  .map((account, index, accountsArray) => {
                    const subs = account.acc_nature === "SUBSIDIARY";
                    const hasSubsidiaryChild = accountData?.accountTotals?.some(
                      (childAccount) =>
                        childAccount.acc_parent === account.acc_id &&
                        childAccount.acc_nature === "SUBSIDIARY"
                    );

                    
                    const assetsId = accountData?.accountTotals?.find(
                      (account) => account.acc_title === "ASSETS"
                    ).acc_id;
                    const liabilitiesId = accountData?.accountTotals?.find(
                      (account) => account.acc_title === "LIABILITIES"
                    ).acc_id;
                    const capitalId = accountData?.accountTotals?.find(
                      (account) => account.acc_title === "CAPITAL"
                    ).acc_id;

                    const lastChildOfAssets = findLastChild(assetsId);
                    const lastChildOfLiabilities = findLastChild(liabilitiesId);
                    const lastChildOfCapital = findLastChild(capitalId);

                    const accountRow = (
                      <tr key={index}>
                        <td
                          className="hover"
                          style={{
                            paddingLeft: account.isTotal
                              ? "0px"
                              : `${getIndentationLevel(account)}px`,
                            fontWeight:
                              account.acc_level === 1
                                ? "bold"
                                : "normal"
                                ? "normal"
                                : "bold",
                            cursor: hasSubsidiaryChild ? "pointer" : "default",
                            transition: "font-size 0.3s ease",
                            color: hasSubsidiaryChild ? "blue" : "normal",
                          }}
                          onClick={
                            hasSubsidiaryChild
                              ? () => handleTitleClick(account)
                              : null
                          }
                          // onMouseEnter={(e) => {
                          //   if (hasSubsidiaryChild) {
                          //     e.target.style.fontSize = "0.9rem";
                          //   }
                          // }}
                          // onMouseLeave={(e) => {
                          //   if (hasSubsidiaryChild) {
                          //     e.target.style.fontSize = "0.8rem";
                          //   }
                          // }}
                        >
                          {!account.isTotal ? account.acc_title : ""}
                        </td>

                        {/* Closing Debit and Credit */}
                        {(dropdownValue === "BALANCE" ||
                          dropdownValue === "All Type") && (
                          <td
                            style={{
                              padding: "2px",
                              textAlign: "right",
                              width: "100px",
                              fontWeight: account.isTotal
                                ? "bold"
                                : subs
                                ? "normal"
                                : "bold",
                            }}
                          >
                            {!account.isTotal
                              ? account.activityDebitAmount +
                                account.activityCreditAmount
                              : ""}
                          </td>
                        )}
                      </tr>
                    );

                    // Check if the current account is the last child of ASSETS
                    if (account.acc_id === lastChildOfAssets.acc_id) {
                      // Render the total row right after the last child
                      return (
                        <React.Fragment key={index}>
                          {accountRow}
                          {/* Add the total row for ASSETS */}
                          <tr key="assets-total">
                            <td
                              className="hover"
                              style={{
                                paddingLeft: "0px",
                                fontWeight: "bold",
                                // fontSize: "1.2rem",
                                borderTop: "2px solid black",
                                borderBottom: "2px solid black",
                              }}
                            >
                              ASSETS TOTAL
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "right",
                                width: "100px",
                                fontWeight: "bold",
                                // fontSize: "1.2rem", 
                                borderTop: "2px solid black",
                                borderBottom: "2px solid black",
                              }}
                            >
                              {
                                groupedAccounts(accountData?.accountTotals)
                                  ?.filter(
                                    (acc) =>
                                      acc.acc_title === "ASSETS" && acc.isTotal
                                  )
                                  ?.at(0)?.totalAmount
                              }
                            </td>
                          </tr>
                          <tr>
                            <td style={{ height: "30px" }} colSpan="2"></td>
                          </tr>
                        </React.Fragment>
                      );
                    }

                    // Check if the current account is the last child of LIABILITIES
                    if (account.acc_id === lastChildOfLiabilities?.acc_id) {
                      // Render the total row right after the last child of LIABILITIES
                      return (
                        <React.Fragment key={index}>
                          {accountRow}
                          <tr key="liabilities-total">
                            <td
                              style={{
                                paddingLeft: "0px",
                                fontWeight: "bold",
                                // fontSize: "1.2rem",
                                borderTop: "2px solid black",
                                borderBottom: "2px solid black",
                              }}
                            >
                              LIABILITIES TOTAL
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "right",
                                width: "100px",
                                fontWeight: "bold",
                                // fontSize: "1.2rem",
                                borderTop: "2px solid black",
                                borderBottom: "2px solid black",
                              }}
                            >
                              {
                                groupedAccounts(accountData?.accountTotals)
                                  ?.filter(
                                    (acc) =>
                                      acc.acc_title === "LIABILITIES" &&
                                      acc.isTotal
                                  )
                                  ?.at(0)?.totalAmount
                              }
                            </td>
                          </tr>
                          <tr>
                            <td style={{ height: "30px" }} colSpan={2}></td>
                          </tr>
                        </React.Fragment>
                      );
                    }

                    // Check if the current account is the last child of CAPITAL
                    if (account.acc_id === lastChildOfCapital?.acc_id) {
                      // Render the total row right after the last child of CAPITAL
                      return (
                        <React.Fragment key={index}>
                          {accountRow}
                          <tr key="capital-total">
                            <td
                              style={{
                                paddingLeft: "0px",
                                fontWeight: "bold",
                                // fontSize: "1.2rem",
                                borderTop: "2px solid black",
                                borderBottom: "2px solid black",
                              }}
                            >
                              CAPITAL TOTAL
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "right",
                                width: "100px",
                                fontWeight: "bold",
                                // fontSize: "1.2rem",
                                borderTop: "2px solid black",
                                borderBottom: "2px solid black",
                              }}
                            >
                              {groupedAccounts(accountData?.accountTotals)
                                    ?.filter(
                                      (acc) =>
                                        acc.acc_title === "CAPITAL" &&
                                        acc.isTotal === true
                                    )
                                    ?.at(0)?.totalAmount
                                
                                }
                            </td>
                          </tr>
                          
                        </React.Fragment>
                      );
                    }
                    return accountRow;
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceTable;
