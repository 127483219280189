import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"; // Import axios
import { NODEURL } from "../ApiConfig"; // Import the base API URL

// Initial state
const initialState = {
  data: [],               // Account structure data
  opentrailBalance: "form",// State for trail balance
  isLoading: false,        // Loading status
  error: null,             // Error message
};

// Async thunk to fetch account structure
export const fetchAccountStructure = createAsyncThunk(
  "accStructure/fetchAccountStructure",
  async ({ ope, act, fromDate, toDate }, { rejectWithValue }) => {
    console.log("ope",ope,act,fromDate,toDate);
    try {
      const response = await NODEURL.post(
        `api/v1/b2b/account/getAccountStructure`,
        { ope, act, fromDate, toDate }
      );
      return response.data; // Returning the data from the response
    } catch (error) {
      // Handling errors
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice for account structure
const accStructureSlice = createSlice({
  name: "accStructure",
  initialState,
  reducers: {
    // Action to set trail balance state
    setOpenTrailBalance: (state, action) => {
      state.opentrailBalance = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle pending state for async thunk
    builder.addCase(fetchAccountStructure.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    // Handle fulfilled state for async thunk
    builder.addCase(fetchAccountStructure.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload; // Update the data with payload
      state.error = null;
    });
    // Handle rejected state for async thunk
    builder.addCase(fetchAccountStructure.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload; // Set error payload
    });
  },
});

// Export actions and reducer
export const { setOpenTrailBalance } = accStructureSlice.actions;
export default accStructureSlice.reducer;
