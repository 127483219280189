import { Formik, Form, FieldArray } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { asyncPostVisaAttachFile } from "../../../redux/features/visa/VisaInvoiceSlice";
import BtnLoader from "../../AppForm/BtnLoader";

const VisaAttachment = ({ bookingData, setActive_Tab }) => {
  const dispatch = useDispatch();
  const [paxData, setPaxData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPaxData(
      bookingData?.VisaBookingModels?.at(0)?.pax_tables?.map((p) => ({
        booking_id: bookingData?.id,
        pax_id: p?.pax_id,
        first_name: p?.fName,
        last_name: p?.lname,
        visa_attachment: p?.visa_attachment, // Existing attachment
        new_attachment: null, // To hold any new file the user uploads
      }))
    );
  }, [bookingData]);

  const handleFileUpload = (e, index, setFieldValue) => {
    const file = e.currentTarget.files[0];
    if (file) {
      setFieldValue(`paxData.${index}.new_attachment`, file);
    }
  };

  return (
    <Formik
      initialValues={{ paxData }}
      enableReinitialize={true}
      onSubmit={(values) => {
        const formData = new FormData();
        formData.append("paxData", JSON.stringify(values.paxData));

        values.paxData.forEach((pax, index) => {
          if (pax.new_attachment) {
            formData.append(`paxData[${index}].attachment`, pax.new_attachment);
          }
        });
        for (let [key, value] of formData.entries()) {
          console.log(key, value);
        }
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          setActive_Tab("");
          dispatch(asyncPostVisaAttachFile(formData));
        }, 3000);
      }}
      render={({ values, setFieldValue }) => (
        <Form>
          <FieldArray
            name="paxData"
            render={(arrayHelpers) => (
              <div className="d-flex border-bottom flex-column gap-4 py-4">
                {values.paxData && values?.paxData.length > 0
                  ? values.paxData.map((pax, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ width: 200, marginTop: 10 }}>
                            <p className="h6 ps-2">
                              {pax?.first_name + " " + pax?.last_name}
                            </p>
                          </div>
                          <div style={{ width: 240 }}>
                            <input
                              className="inputfile"
                              accept="application/pdf"
                              id={`attachment-${index}`}
                              type="file"
                              onChange={(e) =>
                                handleFileUpload(e, index, setFieldValue)
                              }
                            />
                            <label htmlFor={`attachment-${index}`}>
                              {values.paxData[index].new_attachment
                                ? values.paxData[index].new_attachment.name // Show new file's name
                                : values.paxData[index].visa_attachment ||
                                  "Choose Visa File"}{" "}
                              {/* Show the old file's name if no new file is uploaded */}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}

                <div>
                  <div className="d-flex justify-content-around">
                    <button
                      className="px-3 py-2 btn btn-danger"
                      type="button"
                      onClick={() => setActive_Tab("")}
                    >
                      Cancel
                    </button>
                    <button className="px-4 py-2 selectbtn_style" type="submit">
                      {isLoading ? <BtnLoader /> : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          />
        </Form>
      )}
    />
  );
};

export default VisaAttachment;
