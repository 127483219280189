import React, { useEffect, useLayoutEffect, useRef } from "react";
import AppNavigation from "./AppNavigation";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAdminAgency } from "./redux/features/setupRestPages/agentsSlice";
import { apiUrl } from "./Utils/configUrl";
import {
  asyncGetAllUserPermission,
  asyncGetAllUserTypePermission,
} from "./redux/features/agency/rolesPermissionSlice";
import { useNavigate } from "react-router-dom";
import { asyncUserLogout } from "./redux/features/userLogin/UserSlice";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);
  const timer = useRef(null);

  const firstColor = adminAgencyData?.first_color;
  const secondColor = adminAgencyData?.second_color;

  const root = document.querySelector(":root");
  root.style.setProperty("--primary-color", firstColor);
  root.style.setProperty("--secondary-color", secondColor);

  // GET USER PERMITION, MAIN AGENCY DATA
  useLayoutEffect(() => {
    dispatch(asyncGetAllUserTypePermission());
    dispatch(asyncGetAllUserPermission());
    dispatch(asyncGetAdminAgency());
  }, [dispatch]);
  // SET COLORS, FAV ICON, TITTLE
  useEffect(() => {
    //set colors (primary and secondary colors)
    document.documentElement.style.setProperty(
      "--primary-color",
      adminAgencyData?.first_color
    );
    document.documentElement.style.setProperty(
      "--second-color",
      adminAgencyData?.second_color
    );
    // change fav icon
    const link = document.querySelector('link[rel="icon"]');
    link.setAttribute("href", `${apiUrl}/${adminAgencyData?.fav_icon}`);

    document.title = adminAgencyData?.agent_name || "OTAWIX SOLUTION";
  }, [adminAgencyData]);


  //   ///////////////////////////////////////////////////////////////

  //   useEffect(() => {
  //     const isLoggedIn = localStorage.getItem("loginUser");
  //     if (!isLoggedIn) {
  //       handleLogout();
  //       return;
  //     }
  // // ////////for login model/////////////////////////
  // //     let tabCount = parseInt(localStorage.getItem("tabCount")) || 0;
  // //     if (tabCount === 0) {
  // //       tabCount = 1;
  // //       localStorage.setItem("tabCount", tabCount.toString());
  // //     }

  // //     let loginTime = parseInt(localStorage.getItem("tabLoad")) || 0;
  // //     if (!loginTime) {
  // //       localStorage.setItem("tabLoad", Date.now() + 60 * 1000);
  // //     }

  // //     ////////for login model/////////////////////////

  //     // Initialize or increment tab count
  //     let tabCount = parseInt(localStorage.getItem("tabCount"));
  //     tabCount += 1;
  //     localStorage.setItem("tabCount", tabCount.toString());

  //     let loginTime = parseInt(localStorage.getItem("tabLoad"));
  //     if (!loginTime) {
  //       localStorage.setItem("tabLoad", Date.now() + 60 * 1000);
  //     }

  //     const handleBeforeUnload = () => {
  //       let currentCount = parseInt(localStorage.getItem("tabCount"));
  //       let isLoginTime = parseInt(localStorage.getItem("tabLoad")) || 1;

  //       currentCount -= 1;
  //       localStorage.setItem("tabCount", currentCount.toString());
  //       if (currentCount < 1) {
  //         alert(isLoginTime, Date.now())
  //         if (isLoginTime < Date.now()) {
  //           localStorage.setItem("tabLoad", Date.now() + 60 * 1000);
  //         } else {
  //           handleLogout();
  //         }
  //       }
  //     };

  //     window.addEventListener("beforeunload", handleBeforeUnload);

  //     return () => {
  //       window.removeEventListener("beforeunload", handleBeforeUnload);

  //       let currentCount = parseInt(localStorage.getItem("tabCount")) || 1;
  //       currentCount -= 1;
  //       if (currentCount < 1) {
  //         currentCount = 0;
  //       }
  //       localStorage.setItem("tabCount", currentCount.toString());
  //     };
  //   }, [window.removeEventListener]);

  //   const handleLogout = () => {
  //     localStorage.removeItem("loginUser");
  //     localStorage.removeItem("model");
  //     localStorage.removeItem("timer");
  //     localStorage.removeItem("tabCount");
  //     localStorage.removeItem("tabLoad");
  //     localStorage.removeItem("currency");
  //     localStorage.removeItem("flightSearchData");
  //     document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     // Dispatch logout action
  //     dispatch(asyncUserLogout(navigate));
  //   };

  //   /////////////////////////////////////////////////////////////////////////////

  // DISABLE INSPECT ELEMENT


  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyPress = (e) => {
      if (
        e.key === "F12" ||
        (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "J"))
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      handleLogout();
    }, 10 * 60 * 1000); // 10 minutes in milliseconds
  };

  const handleLogout = () => {
    localStorage.removeItem("model");
    localStorage.removeItem("loginUser");
    localStorage.removeItem("currency");
    localStorage.removeItem("flightSearchData");
    dispatch(asyncUserLogout(navigate));
  };

  useEffect(() => {
    const events = ["mousemove", "keydown", "mousedown", "touchstart", "click"];
    const resetUserTimer = () => resetTimer();
    events.forEach((event) => window.addEventListener(event, resetUserTimer));
    resetTimer(); // initialize the timer when the component mounts
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetUserTimer)
      );
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return <AppNavigation />;
};

export default App;
