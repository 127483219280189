import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import FlyJinnahReturnCard from "./FlyJinnahReturnCard";
import { useLocation } from "react-router-dom";
import { CurrencyCode, CurrencyExchange } from "../../../../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";
import { pricingairline } from "../../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../../Utils/FlightSector";
import { ExclusiveSetors } from "../../../../../Utils/exclusiveSector";
import { set_Airline_filter_drp, set_All_Prices_Array } from "../../../../../redux/features/home/FlightSearchSlice";

import { NODEURL } from "../../../../../redux/features/ApiConfig";
import FlyJinnahFares from "./FlyJinnahFares"
import { ConnectedFlightFlyjinnah } from "./ConnectedFlightFlyjinnah";
const FlyJinnahReturn = ({
  flyjinnahItem,
  selectedFare,
  index,
  toggleDetail,
  isExpanded,
  selOutbound,
  selInBound

}) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );


  const { selectedFlight } = useSelector(
    (state) => state.FlightSearch
  );

  const [flyJinnahFares, setFlyJinnahFares] = useState({});
  const [cookie, setCookie] = useState("");
  const [tid, setTid] = useState("");

  const [selectedTab, setSelectedTab] = useState("Fare Options");

  const extractFares = (pricedItineraries) => {
    let arr = pricedItineraries?.["ns1:AirItinerary"]?.["ns1:OriginDestinationOptions"]?.["ns1:AABundledServiceExt"]
    arr = arr ? (Array.isArray(arr) ? arr : [arr]) : [];
    return arr?.flatMap((itinerary) => {
      const bundleService = Array.isArray(itinerary?.["ns1:bundledService"]) ? itinerary?.["ns1:bundledService"] : [itinerary?.["ns1:bundledService"]]
      return bundleService
        ?.filter((fare) => fare["ns1:bundledServiceName"] !== "SaverFare CMB")
        .map((fare) => ({
          ...fare,
          applicableOnd: itinerary?.$?.applicableOnd,
        }))
    });
  };

  const faresArray = extractFares(flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["ns1:PricedItineraries"]?.["ns1:PricedItinerary"]);

  //booking body
  const segments =
    flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["ns1:PricedItineraries"]?.[
    "ns1:PricedItinerary"
    ]?.["ns1:AirItinerary"]?.["ns1:OriginDestinationOptions"];
  const ptcBreakdowns =
    flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["ns1:PricedItineraries"]?.[
    "ns1:PricedItinerary"
    ]?.["ns1:AirItineraryPricingInfo"]?.["ns1:PTC_FareBreakdowns"]?.[
    "ns1:PTC_FareBreakdown"
    ];

  let rpH;

  if (Array.isArray(segments?.["ns1:OriginDestinationOption"])) {
    rpH = segments?.["ns1:OriginDestinationOption"].map(segd => segd?.["ns1:FlightSegment"]?.$?.["RPH"]);
  } else if (segments) {
    rpH = segments?.["ns1:OriginDestinationOption"]?.["ns1:FlightSegment"]?.$?.["RPH"];
  }


  //use selector redux
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  //local storage data
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "9P"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "9P");


  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  const lowestTotalFare = (faree, bundleFee = 0) => {
    let paxFare = Array.isArray(faree) ? faree : [faree]
    const getBaseFareAndTaxes = (fare, bundleFee = 0) => ({ baseFare: Number(fare?.["ns1:PassengerFare"]?.["ns1:BaseFare"]?.$?.Amount), taxes: Number(fare?.["ns1:PassengerFare"]?.["ns1:TotalFare"]?.$?.Amount) - Number(fare?.["ns1:PassengerFare"]?.["ns1:BaseFare"]?.$?.Amount) + bundleFee})
    const getNoFare = () => ({ baseFare: 0, taxes: 0 })


    let adultFare = getBaseFareAndTaxes(paxFare?.at(0), bundleFee);
    let childFare = searchValues?.childCount > 0 ? getBaseFareAndTaxes(paxFare?.at(1), bundleFee) : getNoFare();
    let infantFare = searchValues?.infantNo > 0 ? getBaseFareAndTaxes(paxFare?.at(paxFare?.length - 1)) : getNoFare();

    let newFare = (pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(adultFare?.baseFare)),
      Number(CurrencyExchange(adultFare?.taxes)),
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(searchValues, pricing)
    )?.total_fare * searchValues?.adultsCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(childFare?.baseFare)),
        Number(CurrencyExchange(childFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.childCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(infantFare?.baseFare)),
        Number(CurrencyExchange(infantFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.infantNo)


    return `${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
  };

  //discount
  const DiscountPrice = (faree) => {
    let paxFare = Array.isArray(faree) ? faree : [faree]
    const getBaseFareAndTaxes = (fare) => ({ baseFare: Number(fare?.["ns1:PassengerFare"]?.["ns1:BaseFare"]?.$?.Amount), taxes: Number(fare?.["ns1:PassengerFare"]?.["ns1:TotalFare"]?.$?.Amount) - Number(fare?.["ns1:PassengerFare"]?.["ns1:BaseFare"]?.$?.Amount) })
    const getNoFare = () => ({ baseFare: 0, taxes: 0 })


    let adultFare = getBaseFareAndTaxes(paxFare?.at(0));
    let childFare = searchValues?.childCount > 0 ? getBaseFareAndTaxes(paxFare?.at(1)) : getNoFare();
    let infantFare = searchValues?.infantNo > 0 ? getBaseFareAndTaxes(paxFare?.at(paxFare?.length - 1)) : getNoFare();

    let newFare = (pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(adultFare?.baseFare)),
      Number(CurrencyExchange(adultFare?.taxes)),
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(searchValues, pricing)
    )?.sp * searchValues?.adultsCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(childFare?.baseFare)),
        Number(CurrencyExchange(childFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.childCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(infantFare?.baseFare)),
        Number(CurrencyExchange(infantFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.infantNo)

    if (newFare > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
    } return ""
  };

  // get Single

  // //////////////// get single pax fare
  const singlePaxFare = (fare, pax, farePrice) => {
    farePrice = farePrice * pax;
    const totalFare = fare?.["ns1:PassengerFare"]?.["ns1:TotalFare"]?.$?.Amount;
    const basicFare = fare?.["ns1:PassengerFare"]?.["ns1:BaseFare"]?.$?.Amount;
    const taxes = Number(totalFare) - Number(basicFare)
    let newFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(basicFare))),
        Number(CurrencyExchange(Number(taxes))),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * pax || 0;
    return TwoDecimalPoint(newFare + CurrencyExchange(farePrice));
  };

  const getPaxTaxes = (singlePax) => {
    let taxes = singlePax?.["ns1:PassengerFare"]?.["ns1:Taxes"]?.["ns1:Tax"];
    let fees = singlePax?.["ns1:PassengerFare"]?.["ns1:Fees"]?.["ns1:Fee"];
    taxes = Array.isArray(taxes) ? taxes : [taxes];
    fees = fees
      ? Array.isArray(fees)
        ? fees
        : [fees]
      : [];

    const getAllTaxes = taxes.map((tax) => ({
      code: tax?.$?.TaxCode,
      amount: ~~tax?.$?.Amount,
    }));
    const getAllFees = fees.map((fee) => ({
      code: fee?.$?.FeeCode,
      amount: ~~fee?.$?.Amount,
    }));

    return [...getAllTaxes, ...getAllFees];
  };

  // //////////// convert time to required formate
  const formatDuration = (journeyDuration) => {
    const durationRegex = /PT(\d+)H(\d+)M(\d+)\.(\d+)S/;
    const match = journeyDuration?.match(durationRegex);

    if (match) {
      const hours = parseInt(match[1], 10);
      const minutes = parseInt(match[2], 10);
      const formattedDuration = moment()
        .startOf("day")
        .add({ hours, minutes })
        .format("HH:mm");
      return formattedDuration;
    } else {

      return null; // or some default value
    }
  };

  // // get city name
  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };

  const timeDuration = (time1, time2) => {
    if (!time1 || !time2) {
      return null;
    }
    const depMoment = moment(time1);
    const arrMoment = moment(time2);
    const duration = moment.duration(arrMoment.diff(depMoment));
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  /////////////////// scroll to show detail
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  /// USE EFFECT
  useEffect(() => {
    dispatch(set_Airline_filter_drp("9P"));
    dispatch(set_All_Prices_Array(Number("100290")));
  }, []);
  const GetFlyJinnahFaresDetails = async (bundleIdOutbound, transaction_identifier, bundleIdInbound) => {
    const faresPayload = {
      agent_name: "FLYJINNAH",
      seg: "Return",

      segmentsOI: flyjinnahItem?.itenaries,

      Adt_Quantity: searchValues?.adultsCount,
      Chd_Quantity: searchValues?.childCount,
      // FlightNumber: flyjinnahItem?.flightSegments?.[0]?.flightNumber,
      Inf_Quantity: searchValues?.infantNo,
      bundleIdOutbound,
      bundleIdInbound,
      cookie: cookie || "",
      TransactionIdentifier: tid || "",
    };
    try {
      const { data } = await NODEURL.post(`/api/v1/b2b/fly-jinnah-pnr`, faresPayload);

      data?.data?.["ns1:OTA_AirPriceRS"] ? setFlyJinnahFares(data.data || {}) : setFlyJinnahFares({});
      if (!tid) setTid(data.data?.["ns1:OTA_AirPriceRS"]?.["$"]?.["TransactionIdentifier"])
      if (!cookie) setCookie(data?.data?.cookie)
      return data?.data
    } catch (error) {
      console.error("Error fetching Fly Jinnah fares:", error);
    }
  };

  const renderCount = useRef(0);
  renderCount.current += 1;
  useEffect(() => {
    // GetFlyJinnahFaresDetails();
    if (location.pathname === '/search-results') {
      GetFlyJinnahFaresDetails();
    }

  }, []);

  const fare_req_data = flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.$;
  const fareBreakdowns =
    flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["ns1:PricedItineraries"]?.[
    "ns1:PricedItinerary"
    ]?.["ns1:AirItineraryPricingInfo"]?.["ns1:PTC_FareBreakdowns"]?.[
    "ns1:PTC_FareBreakdown"
    ];


  ////////////////////////////////////////


  // Function to extract baggage information from description


  const getPaxFare = (fare) => {
    const totalFare = fare?.["ns1:PassengerFare"]?.["ns1:TotalFare"]?.$?.Amount;
    const basicFare = fare?.["ns1:PassengerFare"]?.["ns1:BaseFare"]?.$?.Amount;
    const taxes = Number(totalFare) - Number(basicFare)

    return ({ basicFare, taxes, totalFare })
  }

  return (
    <div className="search_engin_result_box flight_card_shadow bg-white" key={`flyjinnah${index}`}>
      <div
        className="shadow cursorpointer"
        key={`flyjinnah${index}`}
        onClick={() => {
          toggleDetail(index);
        }}
      >
        {flyjinnahItem?.itenaries?.map((itinery, index) => {

          return (
            <div key={index}>

              <FlyJinnahReturnCard
                totalDiscount={DiscountPrice}
                fareBreakdowns={fareBreakdowns}
                lowestTotalFare={lowestTotalFare}
                timeDuration={timeDuration}
                segments={itinery?.flightSegments}
                itinery={itinery}
                flyjinnahItem={flyjinnahItem}
                index={index}
                scrollToElement={scrollToElement}
                selectedFare={selectedFare}
                // flightDuration={flightDuration}
                flyJinnahFares={flyJinnahFares}
                isExpanded={isExpanded}
                toggleDetail={toggleDetail}
                cookie={cookie}
                selectedFlight={selectedFlight}
              />
            </div>
          );
        })}
      </div>



      {isExpanded ? (
        <>
          <div className="p-3">
            <div className="d-flex mb-4 mt-2 flight_details_tabs">
              {["Fare Options", "Flight Details"].map((item, index) => {
                const active = selectedTab === item;
                return (
                  <button
                    key={index}
                    onClick={() => setSelectedTab(item)}
                    className={active ? "active" : ""}
                  >
                    {item}
                  </button>
                );
              })}
            </div>

            <div>
              {selectedTab === "Flight Details" && (
                <div className="row rounded-2 search_engin_result_box bg-white p-4">
                  {flyjinnahItem?.itenaries?.map((itinery, index) => {
                    return (
                      <div
                        key={index}
                        className={`${index === 0 ? "border-bottom pb-4" : "pt-4"
                          }`}
                      >
                        {itinery?.flightSegments?.map((item, inde) => {
                          return (
                            <div key={index}>
                              <ConnectedFlightFlyjinnah
                                //   timeDuration={timeDuration}
                                item={item}
                                inde={inde}
                                data={flyjinnahItem}


                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}

                </div>
              )}
            </div>

            <div>
              {selectedTab === "Fare Options" && (
                <div className="row rounded-2 search_engin_result_box bg-white p-4">

                  <>
                    <FlyJinnahFares
                      flyjinnahItem={flyjinnahItem}
                      selectedFlight={selectedFlight}
                      selectedFare={selectedFare}
                      lowestTotalFare={lowestTotalFare}
                      fareBreakdowns={fareBreakdowns}
                      ptcBreakdowns={ptcBreakdowns}
                      flyJinnahFares={flyJinnahFares}
                      pricing={pricing}
                      airline={airline}
                      singlePaxFare={singlePaxFare}
                      getPaxTaxes={getPaxTaxes}
                      timeDuration={timeDuration}
                      searchValues={searchValues}
                      GetFlyJinnahFaresDetails={GetFlyJinnahFaresDetails}
                      faresArray={faresArray}
                      getPaxFare={getPaxFare}
                      segments={segments}
                      rpH={rpH}
                      cookie={cookie}
                      transaction_identifier={tid}
                      selOutbound={selOutbound}
                      selInBound={selInBound}
                    />
                  </>

                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default FlyJinnahReturn;
