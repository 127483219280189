import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  user: null,
  saleReportData: [],
  loading: false,
  error: null,
  changeInvoice: "Invoice",
  SaleReportloading:false,
  dailySaleInvoice:"dailyInvoice"
};

// getting hotel data

export const asyncGetDailySaleReportData = createAsyncThunk(
  "asyncGetHotelData/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v2/b2b/get-daily-sale-report`);
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncGetSaleReportData = createAsyncThunk(
  "asyncGetSaleReportData/get",
  async (data, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams(data).toString();
      const response = await NODEURL.get(
        `/api/v1/b2b/get-sale-report?${queryParams}`
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export const SaleReportSlice = createSlice({
  name: "SaleReportSlice",
  initialState,
  reducers: {
    setChangeInvoice(state, action) {
      state.changeInvoice = action.payload; // Set visibility based on the passed value (true/false)
    },

    
  },
  
  extraReducers: (builder) => {
    // getting  data
    // builder.addCase(asyncGetDailySaleReportData.pending, (state, { payload }) => {
    //   state.loading = true;
    //   state.error = null;
    // });
    // builder.addCase(asyncGetDailySaleReportData.fulfilled, (state, { payload }) => {
    //   state.loading = false;
    //   state.error = null;
    //   state.hotelData = payload;
    // });
    // builder.addCase(asyncGetDailySaleReportData.rejected, (state, { payload }) => {
    //   state.loading = false;
    //   state.error = payload;
    // });

    // getting  data
    builder.addCase(asyncGetSaleReportData.pending, (state, { payload }) => {
      state.SaleReportloading = true;
      state.error = null;
    });
    builder.addCase(asyncGetSaleReportData.fulfilled, (state, { payload }) => {
      state.SaleReportloading = false;
      state.error = null;
      state.saleReportData = payload;
    });
    builder.addCase(asyncGetSaleReportData.rejected, (state, { payload }) => {
      state.SaleReportloading = false;
      state.error = payload;
    });
  },
});

export const { setChangeInvoice } = SaleReportSlice.actions;
// export const { setChangeComponet } = Statement.actions;

export default SaleReportSlice.reducer;
