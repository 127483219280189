import moment from "moment";
import { BsFillPersonFill } from "react-icons/bs";


const VisaPassengerDetails = ({ bookingData }) => {
    const obj = {
      Pending: " text-primary ",
      Issued: " text-success ",
      Refunded: "text-danger ",
    };
  return (
    <div className="booked_items">
      <div className="d-flex bg_primary w-100 align-items-center  text-white p-2 rounded">
        <div>
          <BsFillPersonFill size={26} />
        </div>
        <p
          className="h5 d-flex gap-4"
          style={{ lineHeight: 1, marginLeft: 6, paddingTop: 5 }}
        >
          <span> Passengers data</span>
          {/* {data?.booking?.book_type === "flight-group" &&
          user?.user_id === 1 &&
          data?.booking?.doc_status !== "Issued" ? (
            <span
              className="cursor-pointer"
              style={{ cursor: "pointer" }}
              onClick={(e) => setPaxState(true)}
            >
              <FaRegEdit />
            </span>
          ) : null} */}
        </p>
      </div>
      <div className="booked_items_table">
        <div className="d-flex justify-content-between align-items-center border-bottom">
          <div className="booked_tabel_items h6">Type</div>
          <div className="booked_tabel_items h6">Title</div>
          <div className="booked_tabel_items h6">First Name</div>
          <div className="booked_tabel_items h6">Last Name</div>
          <div className="booked_tabel_items h6">DOB</div>
          <div className="booked_tabel_items h6">Passport #</div>
        </div>
        {bookingData?.VisaBookingModels?.at(0)?.pax_tables?.length &&
          bookingData?.VisaBookingModels?.at(0)?.pax_tables?.map(
            (pax, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center border-bottom"
              >
                <div className="booked_tabel_items d-flex gap-2 mt-2">
                  <p>{pax?.pax_type}</p>
                  {pax?.cn_number?.split("-")?.at(0) === "CN" ? (
                    <p
                      className="text-danger "
                      style={{ cursor: "pointer" }}
                      // onClick={(e) => {
                      //   dispatch(getAsyncSingleCreditNote({ id: pax?.cn_number }))
                      //     .then((res) => {
                      //       const searchedData = res?.payload;
                      //       const booking_id = searchedData?.acc_voucher_trans
                      //         ?.at(0)
                      //         ?.trans_description?.split("/")
                      //         ?.at(0);
                      //       const tkt_number = searchedData?.acc_voucher_trans
                      //         ?.at(0)
                      //         ?.trans_description?.split("/")
                      //         ?.at(4);
                      //       const voucherId = searchedData?.voucher_id;

                      //       navigate("/manual-credit-new", {
                      //         state: {
                      //           voucherId: booking_id,
                      //           // postVoucher: postVoucher,
                      //           tkt_voucher_no: tkt_number,
                      //           voucher_id: voucherId,
                      //           voucher_no: pax?.cn_number,
                      //         },
                      //       });
                      //       console.log("res", res);
                      //     })
                      //     .catch((err) => console.log(err));
                      // }}
                    >
                      ({pax?.cn_number})
                    </p>
                  ) : null}
                </div>
                <div
                  className={`${
                    obj[bookingData?.doc_status]
                  } booked_tabel_items booktablebody`}
                >
                  {pax?.pax_title}
                </div>
                <div
                  className={`${
                    obj[bookingData?.doc_status]
                  } booked_tabel_items booktablebody`}
                >
                  {pax?.fName}
                </div>
                <div
                  className={`${
                    obj[bookingData?.doc_status]
                  } booked_tabel_items booktablebody`}
                >
                  {pax?.lname}
                </div>
                <div className="booked_tabel_items booktablebody">
                  {moment(pax?.dob?.split("T")[0]).format("DD-MMM-YYYY")}
                </div>
                <div className="booked_tabel_items booktablebody">
                  {pax?.pspt_cnic}
                </div>
                
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default VisaPassengerDetails