import { useEffect, useState } from "react";
import { apiUrl, appImgUrl } from "../../../Utils/configUrl";
import { asyncGetAdmin } from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { useDispatch, useSelector } from "react-redux";
import VisaPaxRecord from "./VisaPaxRecord";


const VisaInvoice = ({ bookingData, user, bankDetails, supplierRulesData }) => {
  const dispatch = useDispatch();
  const [invoiceNumber, setinvoiceNumber] = useState(
    bookingData?.VisaBookingModels?.length &&
      bookingData?.VisaBookingModels?.at(0)?.pax_tables?.at(0)?.inv_number
  );
  const [total, setTotal] = useState({
    baseDfltC:
      bookingData?.VisaBookingModels?.length &&
      bookingData?.VisaBookingModels?.at(0)?.pax_tables?.at(0)
        ?.ttl_agt_pay_d_cur,
    baseSlctC:
      bookingData?.VisaBookingModels?.length &&
      bookingData?.VisaBookingModels?.at(0)?.pax_tables?.at(0)
        ?.ttl_agt_pay_s_cur,
  });
  const { getAdminData } = useSelector((state) => state.newAgency);

  useEffect(() => {
    dispatch(asyncGetAdmin({ id: 1 }));
  }, []);
  return (
    <div className="justify-content-center mt-4 px-5">
      <div className="my-5 px-2">
        {/* /////LOGO ADN INVOICE////// */}
        <div class="d-flex justify-content-between align-items-center border-bottom py-2">
          {/* {mainAgency?.map((agency, index) => ( */}
          <>
            <div>
              <img
                src={`${appImgUrl}/${getAdminData?.logo}`}
                alt="travel-agency"
                style={{ width: "300px", height: "100px" }}
              />
            </div>

            <div className="d-flex">
              <div className="d-flex flex-column " style={{ width: 300 }}>
                <h4 className="theme-text-color" style={{ fontSize: "20px" }}>
                  Address
                </h4>
                <span
                  className="booktablebody"
                  style={{ fontSize: "20px", color: "#000000" }}
                >
                  {getAdminData?.address?.split("]")[0]}
                </span>
              </div>

              <div
                className="d-flex flex-column"
                style={{
                  width: 300,
                  borderLeft: "2px solid black",
                  paddingLeft: "20px",
                }}
              >
                <h4 className="theme-text-color" style={{ fontSize: "20px" }}>
                  Phone
                </h4>

                <div className="d-flex flex-column">
                  <span
                    className="booktablebody"
                    style={{ fontSize: "20px", color: "#000000" }}
                  >
                    {" "}
                    {getAdminData?.mobile_no}
                  </span>
                  <span
                    className="my-2 booktablebody"
                    style={{ fontSize: "20px", color: "#000000" }}
                  >
                    {getAdminData?.email}
                  </span>
                </div>
              </div>
            </div>
          </>
          {/* ))} */}
        </div>
        {/* /////INVOICE////// */}
        <div
          className="d-flex justify-content-between px-4 py-5  border-bottom"
          style={{ color: "#000000" }}
        >
          <div className="d-flex flex-column ">
            <h4 className="theme-text-color">Invoice To:</h4>
            <span
              className="booktablebody"
              style={{ fontSize: "20px", color: "#000000" }}
            >
              {bookingData?.acc_agents?.agents_comp?.at(0)?.agent_name}
            </span>
            <span
              className="my-2 booktablebody"
              style={{ fontSize: "20px", color: "#000000" }}
            >
              {
                bookingData?.acc_agents?.agents_comp
                  ?.at(0)
                  ?.address?.split("]")[0]
              }
            </span>
            <span
              className="booktablebody"
              style={{ fontSize: "20px", color: "#000000" }}
            >
              {bookingData?.acc_agents?.agents_comp?.at(0)?.mobile_no}
            </span>
          </div>

          <div className="" style={{ width: 400 }}>
            <h4 className="theme-text-color">Service Invoice:</h4>
            <table className="table table-bordered border-dark align-items-center fixed">
              <thead
                className="bg_primary text-white "
                style={{ fontSize: "20px" }}
              >
                <tr>
                  <th scope="col">Invoice Number</th>
                  <th scope="col">Invoice Date</th>
                </tr>
              </thead>
              <tbody
                className="text-dark "
                style={{ fontSize: "20px", color: "#000000" }}
              >
                <tr>
                  <td style={{ width: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      {bookingData?.VisaBookingModels?.length &&
                        bookingData?.VisaBookingModels?.at(0)?.pax_tables?.map(
                          (invNumber) => (
                            <span
                              style={{
                                cursor: "pointer",
                                textDecoration: `${
                                  invNumber?.inv_number === invoiceNumber
                                    ? "underline"
                                    : "none"
                                }`,
                              }}
                              onClick={(e) => {
                                setinvoiceNumber(invNumber?.inv_number);
                                setTotal({
                                  baseDfltC: invNumber?.ttl_agt_pay_d_cur,
                                  baseSlctC: invNumber?.ttl_agt_pay_s_cur,
                                });
                              }}
                            >
                              {invNumber?.inv_number}
                            </span>
                          )
                        )}
                    </div>
                  </td>
                  <td tyle={{ width: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      {bookingData?.voucherData?.length &&
                        bookingData?.voucherData?.map((invDate) => (
                          <span>
                            {invDate?.approved_date
                              ? invDate?.approved_date
                              : "Not approved"}
                          </span>
                        ))}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* /////RATE////// */}
        <div className="p-4">
          <VisaPaxRecord
            bookingData={bookingData}
            user={user}
            invoiceNumber={invoiceNumber}
            total={total}
          />
        </div>
        {/* /////PAYMENT METHOD////// */}

        <div className="p-4" style={{ width: 800 }}>
          <h4 className="theme-text-color">Payment Method:</h4>
          <table class="table table-bordered border-dark align-items-center ">
            <thead
              className="bg_primary text-white "
              style={{ fontSize: "20px" }}
            >
              <tr>
                <th scope="col">IBFT / By Chq</th>
                <th scope="col">Cash Pay in Head Office</th>
              </tr>
            </thead>
            <tbody
              className="text-dark"
              style={{ fontSize: "20px", color: "#000000" }}
            >
              <tr>
                <td className="d-flex flex-column gap-2 ">
                  {bankDetails
                    ?.filter(
                      (item) => item?.bank_status === 1 && item?.collection
                    )
                    ?.map((bank, index) => (
                      <span key={index}>
                        {bank?.name}
                        {"-"}
                        {bank?.account_iban_no}
                      </span>
                    ))}
                </td>
                <td>
                  <span>{getAdminData?.address?.split("]").at(0)}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* /////TERM AND  CONDITON////// */}

        <div className="p-4" style={{ width: 500 }}>
          <h4 className="theme-text-color">Term and Condition:</h4>
          {supplierRulesData?.map((supp, index) => (
            <div className="d-flex gap-2   ">
              <img
                style={{ width: 50, height: 50 }}
                src={`${apiUrl}/${supp?.image}`}
              />
              <div className="d-flex flex-column ">
                <h6>{supp?.heading}</h6>
                <p>{supp?.description}</p>
              </div>
            </div>
          ))}
        </div>
        {/* /////PRINT INVOICE////// */}

        <div className="d-flex flex-column justify-content-center align-items-center my-3">
          <span
            className="booktablebody"
            style={{ fontSize: "20px", color: "#000000" }}
          >
            This is a computer generated document & does not need a signature
            and Stamp
          </span>
          <button
            className="bg_primary  mt-3 "
            style={{ fontSize: "20px", color: "white", padding: 8 }}
          >
            Print invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default VisaInvoice