import { useNavigate, useParams } from "react-router-dom";
import iata from "../../../../assets/images/iata.png";
// import logo from "../../../../assets/images/zairaa.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
// import { AsyncGetAllVoucher } from "../../../../redux/features/Voucher/VoucherSlice";
// import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import moment from "moment";
import ReactToPrint from "react-to-print";
// import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import { setChangeBankandCash } from "../../../../redux/features/Finance/Account/ChartOfAccountSlice";
import { apiUrl } from "../../../../Utils/configUrl";
// import { apiUrl } from "../../../../Utils/configUrl";
const BankAndCashStatements = ({
  data,
  agentData,
  voucher,
  setChangeComponet,
  fromDate,
  toDate,
  statement,
  userData,
  allwaysCredit,
  report,
  text,
  summary,
  getAdminData,
  editVoucherPermission,
  setBankData,
  bankData,
}) => {
  const mutableData = [...data];
  // Reverse the mutable copy
  const reversedData = mutableData.reverse();
  const dispatch = useDispatch();
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);

  const componentRef = useRef();
  const firstColor = adminAgencyData?.first_color;

  return (
    <div className="d-flex  flex-column gap-4 bg-white">
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />

          <button
            onClick={(e) => {
              dispatch(setChangeBankandCash(report));
              setBankData({ ...bankData, acc_id: [] });
            }}
            className="bg_primary px-4 py-1 rounded text-white"
          >
            Back
          </button>
        </div>
      </div>
      {/* bottom setion */}
      <div className="d-flex flex-column gap-3  mt-4" ref={componentRef}>
        {/* logo */}
        <div className="d-flex justify-content-around align-items-center">
          <div style={{ width: "300px" }}>
            <img
              src={`${apiUrl}/${getAdminData}`}
              alt="logo"
              style={{ width: "70%", height: "auto" }}
            />
          </div>

          <div style={{ width: "200px" }}>
            <img
              src={iata}
              alt="iata"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white fw-bold my-2">
            Bank / Cash Transactions
          </span>
        </div>

        {reversedData?.map((statements, index) => {
          console.log("statements", statements);
          let closingBalance = statements?.totalBalance;
          return (
            <div
              className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
              style={{
                marginBottom: "80px",
              }}
              key={index}
            >
              <>
                <div className="w-100 d-flex flex-column">
                  {/* {statements?.transactions?.map((accData,index) => { */}
                  <div className="mb-4">
                    <table className="table table-bordered w-100">
                      <thead>
                        <tr>
                          <th colSpan={5}>{statements?.title}</th>
                          <th>BALANCE</th>
                          <th>{closingBalance?.toFixed(2)}</th>
                        </tr>
                        <tr>
                          <th style={{ width: "10%" }}>DATE</th>
                          <th style={{ width: "7%" }}>VN</th>
                          <th style={{ width: "30%" }}>DESC / REMARKS</th>
                          <th style={{ width: "14%" }}>RECEIVED FROM (DR)</th>
                          <th style={{ width: "14%" }}>PAID TO (CR)</th>
                          <th style={{ width: "7%" }}>PKR</th>
                          <th style={{ width: "7%" }}>BALANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statements?.transactions?.length && statements?.transactions?.map(
                          (item, index) => {
                            closingBalance +=
                              Number(item?.d_cur_debit || 0) -
                              Number(item?.d_cur_credit || 0);

                            return (
                              <tr
                                key={index}
                                className={
                                  item?.approved_date ? "text-primary" : ""
                                }
                              >
                                <td>{item?.saved_date}</td>
                                <td>{item?.voucher_no}</td>
                                <td>{item?.trans_description}</td>
                                <td>
                                  {item?.voucher_type_id === 1
                                    ? Number(item?.d_cur_debit) > 0
                                      ? item?.acc_account?.acc_title
                                      : null
                                    : item?.voucher_type_id === 3
                                    ? item?.trans_description?.split("/")?.at(1)
                                    : null}
                                </td>
                                <td>
                                  {item?.voucher_type_id === 1
                                    ? Number(item?.d_cur_credit) > 0
                                      ? item?.acc_account?.acc_title
                                      : null
                                    : item?.voucher_type_id === 2
                                    ? item?.trans_description?.split("/")?.at(2)
                                    : null}
                                </td>
                                <td>
                                  {Number(item?.d_cur_debit) > 0
                                    ? item?.d_cur_debit
                                    : item?.d_cur_credit}
                                </td>
                                <td>{closingBalance?.toFixed(2)}</td>
                              </tr>
                            );
                          }
                        )}
                        <tr>
                          <td colSpan={6} className="text-end fw-bold fs-6">
                            CLOSING BALANCE
                          </td>
                          <td className="fw-bold fs-6">{closingBalance?.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* })}  */}
                </div>
              </>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BankAndCashStatements;
