import React, { useEffect, useState } from "react";
import FlyJinnahReturn from "./FlyJinnahReturn";
import { set_Airline_filter_drp } from "../../../../../redux/features/home/FlightSearchSlice";
import { useDispatch } from "react-redux";

const FlyJinnahSegmentCard = ({
  flyjinnahItem,selectedFare,
  index,isExpanded,
  toggleDetail,
  selectedFlight,
  selOutbound,
  selInBound
}) => {
  const [showOtherOption, setShowOtherOption] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      set_Airline_filter_drp(
        flyjinnahItem?.flightSegments?.at(0)?.flightNumber?.slice(0, 2)
      )
    );
  }, []);
 
  
  return (
    <div
      className="search_engin_result_box flight_card_shadow bg-white"
      id={`flyjinnah${index}`}
    >
      <FlyJinnahReturn
        flyjinnahItem={flyjinnahItem}
         selectedFlight={selectedFlight}
         isExpanded={isExpanded}
        index={index}
        // index={"flyjinnah" + index}
        // isExpanded={expandFlightDetail === "flyjinnah" + index}
        toggleDetail={toggleDetail}
        showOtherOption={showOtherOption}
        setShowOtherOption={setShowOtherOption}
        selectedFare={selectedFare}
        selOutbound={selOutbound}
       selInBound={selInBound}
      />
    </div>
  );
};

export default FlyJinnahSegmentCard;
