import React, { useEffect } from "react";
import "../Styles/Footer.css";
import { useSelector } from "react-redux";

const Footer = () => {
  const { agentsData } = useSelector((state) => state.agentsCategories);

  return (
    <footer className="py-3 footer px-5">
      <div className=""> &#169; {agentsData && agentsData[0]?.footer}</div>
      <div className="">SMAWIX V5.0</div>
    </footer>
  );
};

export default Footer;
