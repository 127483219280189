import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTPForm, NODEURL } from "../ApiConfig";

const initialState = {

  loading: false,
  error: null,
};


export const asyncPostVisaData = createAsyncThunk(
  "asyncPostVisaData/post",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/visa_booking`, body);
      return await response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const asyncUpdateVisaData = createAsyncThunk(
  "asyncUpdateVisaData/patch",
  async ({ id, body }, { rejectWithValue, dispatch }) => {
    console.log("body", body, id);
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/visa_booking/${id}`,
        body
      );
      return await response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const asyncPostVisaAttachFile = createAsyncThunk(
  "asyncPostVisaAttachFile/post",
  async (formData, { rejectWithValue, dispatch }) => {
    console.log("formData", formData);
    try {
      const response = await HTTPForm.post(
        `/api/v1/b2b/visa_attach_file`,
        formData
      );
      return await response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);




export const VisaInvoiceSlice = createSlice({
  name: "VisaInvoiceSlice",
  initialState,

  reducers: {
    editNull: (state) => {
      state.editvisaType = null;
    },
  },

  extraReducers: (builder) => {
  

    // posting visaType data
    builder.addCase(asyncPostVisaData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostVisaData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.visaType = payload;
    });
    builder.addCase(asyncPostVisaData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    
  },
});

export const { editNull } = VisaInvoiceSlice.actions;

export default VisaInvoiceSlice.reducer;
