import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import {
  BsBagCheck,
  BsFillPersonFill,
  BsHandbag,
  BsPerson,
} from "react-icons/bs";
import { RiInformationFill } from "react-icons/ri";
import { IoIosAirplane } from "react-icons/io";
import ReactDatePicker from "react-datepicker";
import airsial from "../../assets/images/Airsial.jpeg";
import pia from "../../assets/images/PIA.jpeg";
import serene from "../../assets/images/Serene.jpeg";
import moment from "moment";
import { apiUrl } from "../../Utils/configUrl";
import RefundAndExchange from "../Reservation/FlightsTab/RefundExchange/RefundAndExchange";
import {
  MdCheck,
  MdOutlineFlightLand,
  MdOutlineFlightTakeoff,
  MdOutlineRestaurant,
  MdRemoveRedEye,
  MdSwapHoriz,
} from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";
import { HiEye } from "react-icons/hi";
import { LightTooltip } from "../Reservation/FlightsTab/OneWaySearchResult/LightTooltip";
import { CurrencyCode, CurrencyExchange } from "../../Utils/currencyExchange";
import { useDispatch } from "react-redux";
import { getAsyncBookingDetails } from "../../redux/features/booking/bookingDetailsSlice";
import { FaRegEdit } from "react-icons/fa";
import PassengerDataWithoutOrWithEdit from "./PassengerDataWithoutOrWithEdit";

function General({ booking, supplierRulesData, setActive_Tab, id, user, ids }) {
  const dispatch = useDispatch();
  // useEffect(()=>{
  //   dispatch(getAsyncBookingDetails({ id:id }));

  // },[id])
  return (
    <div className="d-flex flex-column justify-content-center mt-4">
      {/* <ButtonsGroup /> */}
      {booking ? (
        <>
          <BookedItem data={booking} />
          <PassengerData
            data={booking}
            id={id}
            setActive_Tab={setActive_Tab}
            user={user}
          />
          <Flight data={booking} />
          <div className="booked_items mt-4 bg-white borderradius shadow-md p-3">
            <RefundAndExchange
              gds={booking?.booking?.FlightBookingModels?.at(0)?.gds}
            />
          </div>
          <RemarksSSR data={booking} supplierRulesData={supplierRulesData} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

const BookedItem = ({ data }) => {
  const { booking } = data;
  const parts=booking?.remarks.slice(2)
  return (
    <div className="booked_items">
      <div className="d-flex justify-content-between align-items-center ">
        <div className=" w-100">
          {/* <ImBook /> Booked items */}
          <div className="d-flex bg_primary w-100 align-items-center  text-white p-2 rounded">
            <p className="h5" style={{ lineHeight: 1 }}>
              Booking Type:
            </p>

            <div className=" ">
              <p className="ms-2 h6 text-uppercase " style={{ lineHeight: 1 }}>
                {booking?.book_type}
              </p>
              <p className="ms-2 h6 text-uppercase " style={{ lineHeight: 1 }}>
                (
                {booking?.FlightBookingModels &&
                  booking?.FlightBookingModels[0]?.flight_group_insert
                    ?.group_name}
                )
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <p className="h5" style={{ lineHeight: 1 }}>
              Date Time:
            </p>
            <p className="ms-2 h6" style={{ lineHeight: 1 }}>
              {moment(booking?.bookdatetime?.split("T")[0]).format(
                "ddd DD-MMM-YY"
              ) +
                " " +
                booking?.bookdatetime?.split("T")[1]?.slice(0, 5)}
            </p>
          </div>
        </div>
        {/* <div className=" d-flex align-items-center">
          <p className="h5">Agent Group:</p>
          <p className="ms-2 h6">ZairaaMain Office</p>
        </div> */}
      </div>

      <div className="booked_items_table">
        <div className="d-flex  justify-content-between align-items-center border-bottom">
          <div className="booked_tabel_items h6">Booking ID</div>
          <div className="booked_tabel_items h6">PNR(CRR-PNR)</div>
          <div className="booked_tabel_items h6">Status</div>
          <div className="booked_tabel_items h6">Document</div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="booked_tabel_items booktablebody">{booking?.id}</div>
          <div className="booked_tabel_items booktablebody">
            {booking?.FlightBookingModels &&
              booking?.FlightBookingModels[0]?.pnr}
          </div>

          <div
            className={
              booking?.book_status === "Cancelled"
                ? "text-danger booked_tabel_items booktablebody mt-5"
                : " booked_tabel_items booktablebody mt-3"
            }
          >
            <p>{booking?.book_status}</p>
            <span style={{ fontSize: "0.85rem", color: "black" }}>
              {booking?.book_status === "Cancelled" &&
              booking?.auto_manual === 0 ? (
                <div>
                  {booking?.remarks.split("_").map((item, index) => {
                    if (index === 0) return null;
                    return (
                      <div key={index} style={{ marginBottom: "5px" }}>
                        {item}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </span>
          </div>

          <div className="booked_tabel_items booktablebody">
            {booking?.doc_status}
          </div>
        </div>
      </div>
    </div>
  );
};

const PassengerData = ({ data, setActive_Tab, id, user }) => {
  const obj = {
    Pending: " text-primary ",
    Issued: " text-success ",
    Refunded: "text-danger ",
  };
  // const { doc_status } = data?.booking
  const [paxState, setPaxState] = useState(false);

  return (
    <div className="booked_items">
      <div className="d-flex bg_primary w-100 align-items-center  text-white p-2 rounded">
        <div>
          <BsFillPersonFill size={26} />
        </div>
        <p
          className="h5 d-flex gap-4"
          style={{ lineHeight: 1, marginLeft: 6, paddingTop: 5 }}
        >
          <span> Passengers data</span>
          {data?.booking?.book_type === "flight-group" &&
          user?.user_id === 1 &&
          data?.booking?.doc_status !== "Issued" ? (
            <span
              className="cursor-pointer"
              style={{ cursor: "pointer" }}
              onClick={(e) => setPaxState(true)}
            >
              <FaRegEdit />
            </span>
          ) : null}
        </p>
      </div>

      <PassengerDataWithoutOrWithEdit
        obj={obj}
        booking_id={id}
        data={data}
        setPaxState={setPaxState}
        paxState={paxState}
        setActive_Tab={setActive_Tab}
      />
      {/*</Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
    </div>
  );
};

const Flight = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(null);
  const obj = {
    PF: airsial,
    PK: pia,
    ER: serene,
  };

  const calculatTimeDuration = (dep_time, arr_time) => {
    const depTimeStr = dep_time;
    const arrTimeStr = arr_time;

    // Convert time strings to Date objects
    const depTime = new Date(`2000-01-01 ${depTimeStr}`);
    const arrTime = new Date(`2000-01-01 ${arrTimeStr}`);

    // Calculate the time difference in milliseconds
    const durationInMilliseconds = arrTime - depTime;

    // Convert milliseconds to hours and minutes
    const durationInHours = Math.floor(
      durationInMilliseconds / (1000 * 60 * 60)
    );
    const durationInMinutes = Math.floor(
      (durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    const formattedHours = durationInHours.toString().padStart(2, "0");
    const formattedMinutes = durationInMinutes.toString().padStart(2, "0");

    return `${formattedHours} : ${formattedMinutes}`;
  };

  return (
    <>
      {data?.booking?.FlightBookingModels &&
        data?.booking?.FlightBookingModels?.at(0)?.FlightSegmentModels?.map(
          (fltSegment, index) => {
            return (
              <div className="booked_items" key={index}>
                <div
                  className="shadow cursorpointer"
                  onClick={() => {
                    setIsExpanded(isExpanded === index ? null : index);
                  }}
                >
                  <div className="row align-items-center justify-content-center result_box_body">
                    <div className="col-2">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <img
                          src={`/NEW_LOGO/${fltSegment?.flt}.png`}
                          alt="SereneLogo"
                          width={80}
                        />
                        <div style={{ paddingLeft: 4 }}>
                          {fltSegment?.flt + " " + fltSegment?.flt_no}{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-7">
                      <div className="d-flex justify-content-evenly align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="pe-3">
                            <MdOutlineFlightTakeoff
                              size={30}
                              className="color_primary"
                            />
                          </div>
                          <div>
                            <h6>
                              {moment(fltSegment?.dep_date).format(
                                "ddd, DD-MMM-YY"
                              )}
                            </h6>
                            <h6 className="my-3">{fltSegment?.dep_time} </h6>
                            <h6>
                              {fltSegment?.origin?.split("-")?.at(0) +
                                "-" +
                                fltSegment?.origin
                                  ?.split("-")
                                  ?.at(2)
                                  ?.toUpperCase()}
                            </h6>
                          </div>
                        </div>

                        <div>
                          <h6 className="text-center">
                            {calculatTimeDuration(
                              fltSegment?.dep_time,
                              fltSegment?.arr_time
                            )}
                          </h6>
                          <div className="border_rounded mb-2" />
                          <h6 className="mx-3">Non-Stop</h6>
                        </div>

                        <div className="d-flex align-items-center">
                          <div>
                            <div className="px-3">
                              <MdOutlineFlightLand
                                size={30}
                                className="color_primary"
                              />
                            </div>
                          </div>

                          <div>
                            <h6>
                              {moment(fltSegment?.arr_date).format(
                                "ddd DD-MMM-YY"
                              )}
                            </h6>
                            <h6 className="my-3">{fltSegment?.arr_time} </h6>
                            <h6>
                              {fltSegment?.destination?.split("-")?.at(0) +
                                "-" +
                                fltSegment?.destination
                                  ?.split("-")
                                  ?.at(2)
                                  ?.toUpperCase()}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-3 shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body">
                      <HiEye className="color_primary" size={40} />
                    </div>
                  </div>
                </div>
                {isExpanded === index ? (
                  <div className="search_engin_result_box py-3 px-5 bg-white h-auto">
                    <div className={`d-flex gap-5 align-items-start px-5`}>
                      {/* headings */}
                      <div>
                        <div className="flight-detail-headings" style={{paddingTop:  fltSegment?.cabin_class ? 30 : 0}}></div>
                        <div className="flight-detail-headings">
                          <BsHandbag size={25} className="color_primary" />
                          <span>Hand Carry Bags</span>
                        </div>
                        <div className="flight-detail-headings">
                          <BsBagCheck size={25} className="color_primary" />
                          <span>Checked Bags</span>
                        </div>
                        <div className="flight-detail-headings">
                          <MdOutlineRestaurant
                            size={25}
                            className="color_primary"
                          />
                          <span>Meals</span>
                        </div>
                        <div className="flight-detail-headings">
                          <BsPerson size={25} className="color_primary" />
                          <span>Seat Selection</span>
                        </div>
                        {/* <div className="flight-detail-headings">
                        <MdSwapHoriz size={25} className="color_primary" />
                        <span>Refund & Exchanges</span>
                      </div> */}
                      </div>

                      <div>
                        {/* <div className="fs-4 theme-text-color mb-3 fw-bold">
                          {fare?.sub_class_desc}
                        </div> */}
                        <div className="fs-5 theme-text-color mt-3 mb-2 fw-bold">
                          {fltSegment?.cabin_class}
                        </div>
                        <div className="flight-detail-headings">
                          <MdCheck size={25} color="green" />
                          <span>Upto 7 kg</span>
                        </div>
                        <div className="flight-detail-headings">
                          <>
                            <MdCheck size={25} color="green" />
                            <span>
                              {data?.booking?.FlightBookingModels?.at(0)
                                ?.flight_pax_baggages?.at(index)
                                ?.baggage?.split(",")
                                ?.at(0)}
                            </span>
                          </>
                        </div>
                        <div className="flight-detail-headings">
                          <MdCheck size={25} color="green" />
                          <span>System Allowed</span>
                        </div>
                        <div className="flight-detail-headings">
                          <CgMenuGridR size={25} color="#38025c" />
                          <span>System Allocated</span>
                        </div>
                        {/* <div className="flight-detail-headings">
                        <LightTooltip
                          title={
                            <div className="p-3">
                              <h4>Refund & Exchange</h4>
                              <h6>
                                Penalty will be charged per passenger segment.
                              </h6>
                              <div className="text-danger font-italic">
                                for further clarification contact with Airline
                                Or Call our support team.
                              </div>
                              <div className="text-danger font-italic mt-2">
                                After 25 days of departure ticket is
                                Non-Refundable.
                              </div>

                              <div className="d-flex justify-content-between align-item-start w-fit mt-4">
                                <div className="me-5">
                                  <h4>Exchange</h4>
                                  <h6>
                                    Before 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      1500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      2500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 24 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      3500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 03 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      4500
                                    )}`}
                                  </h6>
                                </div>
                                <div>
                                  <h4>Refund</h4>
                                  <h6>
                                    Before 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      1500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      2500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 24 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      3500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 03 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      4500
                                    )}`}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          }
                          placement="top-start"
                        >
                          <div style={{ cursor: "pointer" }}>
                            <MdRemoveRedEye size={25} color="#262829" />
                            <span>View</span>
                          </div>
                        </LightTooltip>
                      </div> */}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            );
          }
        )}
    </>
  );
};

const RemarksSSR = ({ data, supplierRulesData }) => {
  return (
    <div className="booked_items">
      {/* <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" className="w-100">
          <Accordion.Header>
            <div className="h5">
              <RiInformationFill /> Remarks and SSR
            </div>
          </Accordion.Header>
          <Accordion.Body> */}
      <div className="d-flex bg_primary w-100 align-items-center  text-white p-2 rounded">
        <div>
          <RiInformationFill size={28} />
        </div>
        <p
          className="h5"
          style={{ lineHeight: 1, marginLeft: 6, paddingTop: 5 }}
        >
          Remarks and SSR
        </p>
      </div>
      <div className="booked_items_table">
        {/* <div className="d-flex justify-content-between align-items-center">
          <div className="h6">Remarks:</div>
        </div> */}
        {/* <div className="remarks-ssr">
          <h6 className="d-flex justify-content-between align-items-center py-1">
            Refund Charges
          </h6>

          {supplierRulesData?.map((supp, index) => (
            <div className="d-flex gap-2   ">
              <img
                style={{ width: 50, height: 50 }}
                src={`${apiUrl}/${supp?.image}`}
              />
              <div className="d-flex flex-column ">
                <h6>{supp?.heading}</h6>
                <p>{supp?.description}</p>
              </div>
            </div>
          ))}
        </div> */}
        <div className="d-flex justify-content-between align-items-center "></div>
        <div className="remarks-ssr">
          <div className="d-flex justify-content-between align-items-center py-1 text-danger">
            TicketTimeLimit:{" "}
            {data?.booking?.FlightBookingModels &&
              moment(
                data?.booking?.FlightBookingModels[0]?.flight_SSRs[0]?.date_time?.split(
                  "T"
                )[0]
              )?.format("ddd DD-MMM-YY")}{" "}
            {data?.booking?.FlightBookingModels &&
              data?.booking?.FlightBookingModels[0]?.flight_SSRs[0]?.date_time
                ?.split(" ")[1]
                ?.slice(0, 5)}
          </div>
          {data?.booking?.FlightBookingModels[0]?.flight_SSRs?.map(
            (ssr, index) =>
              ssr?.text ? (
                <div
                  className={`d-flex justify-content-between align-items-center py-1 ${
                    index === 0 ? `text-primary` : `text-danger`
                  }`}
                >
                  Text: {ssr.text}
                </div>
              ) : (
                <></>
              )
          )}
        </div>
      </div>
      {/* </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
    </div>
  );
};

const ReIssueFlight = ({ index }) => {
  return (
    <div className="">
      <h6>{index}. Flight</h6>
      <div className="row align-items-end">
        <div className="col-md-3">
          <TextField variant="standard" label="From Where" className="w-100" />
        </div>
        <div className="col-md-3">
          <ReactDatePicker
            minDate={new Date()}
            className="date_picker"
            placeholderText="Departure Date"
            monthsShown={2}
          />
        </div>
        <div className="col-md-3">
          <input
            type="time"
            placeholder="Departure Time"
            className="inputs w-100 "
          />
        </div>
        <div className="col-md-3">
          <TextField
            variant="standard"
            label="AirLine Code PK,EK"
            className="w-100"
          />
        </div>
      </div>
      <div className="row align-items-end">
        <div className="col-md-3 px-3">
          <TextField variant="standard" label="To Where" className="w-100" />
        </div>
        <div className="col-md-3">
          <ReactDatePicker
            minDate={new Date()}
            className="date_picker"
            placeholderText="Arrival Date"
            monthsShown={2}
          />
        </div>
        <div className="col-md-3">
          <input
            type="time"
            placeholder="Departure Time"
            className="inputs w-100 "
          />
        </div>
        <div className="col-md-3">
          <TextField
            variant="standard"
            label="AirLine No e.g 220"
            className="w-100"
          />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default General;
