import React, { useEffect, useState } from "react";
import { CurrencyCode } from "../../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../../Utils/FixedTwoDecimal";
import { CurrencyExchange } from "@mui/icons-material";

const VisaFare = ({ bookingData, FarePermission, user }) => {
  const [viewStatement, setViewStatement] = useState();
 
  const { totalPricePKR, totalPriceOtherCurrency } =
    bookingData?.VisaBookingModels?.at(0)?.pax_tables?.reduce(
      (acc, curr) => {
        acc.totalPricePKR += Number(curr?.ttl_agt_pay_d_cur);
        acc.totalPriceOtherCurrency += Number(curr?.ttl_agt_pay_s_cur);
        return acc;
      },
      { totalPricePKR: 0, totalPriceOtherCurrency: 0 }
    );

  return (
    <>
      {/* Only For Admin */}
      
      <h4 className="theme-text-color mt-4">Description:</h4>
      <table className="table table-bordered border-dark align-items-center">
        <thead className="bg_primary text-white" style={{ fontSize: "20px" }}>
          <tr>
            <th style={{ width: 200 }}>Passenger</th>
            <th scope="col" style={{ width: 200 }}>
              Country
            </th>
            <th scope="col" style={{ width: 200 }}>
              Type
            </th>
            <th style={{ width: 200 }}>Rate</th>
          </tr>
        </thead>
        <tbody className="text-dark" style={{ color: "#000000" }}>
          {bookingData?.VisaBookingModels?.at(0)?.pax_tables?.map(
            (item, index) => (
              <tr key={index}>
                <td>
                  {item?.pax_type}-{item?.fName + " " + item?.lname}
                </td>
                <td>
                  <div className="d-flex flex-column gap-2">
                    <span>
                      {
                        bookingData?.VisaBookingModels?.at(0)?.VisaCountryModel
                          ?.name
                      }
                    </span>
                  </div>
                </td>
                <td>
                  <div className="d-flex flex-column gap-2">
                    <span>
                      {
                        bookingData?.VisaBookingModels?.at(0)?.VisaTypesModel
                          ?.visa_type
                      }
                    </span>
                  </div>
                </td>
                <td className="d-flex flex-column gap-2 text-primary">
                  {bookingData?.currency?.curr_code +
                    " " +
                    TwoDecimalPoint(item?.ttl_agt_pay_s_cur)}
                  <span className="text-dark">
                    PKR {TwoDecimalPoint(item?.ttl_agt_pay_d_cur)}
                  </span>
                </td>
              </tr>
            )
          )}

          <tr>
            <td
              colSpan="3"
              style={{
                textAlign: "right",
                fontWeight: 700,
                fontSize: "20px",
              }}
            >
              Grand Total
            </td>
            <td
              style={{
                fontWeight: 700,
                fontSize: "20px",
              }}
              className="d-flex flex-column gap-2 "
            >
              <span className="text-primary">
                {" "}
                {bookingData?.currency?.curr_code +
                  " " +
                  TwoDecimalPoint(totalPriceOtherCurrency)}
              </span>
              <span> PKR {TwoDecimalPoint(totalPricePKR)}</span>
            </td>
          </tr>
        </tbody>
      </table>
     
    </>
  );
};
export default VisaFare;
