import React, { useEffect, useState } from "react";
import MUIDataTable, { TableToolbar } from "mui-datatables";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import BookingFilter from "./BookingFilter";
import {
  getAllAsyncBookings,
  setAllBookingNull,
  setSingleBookingNull,
} from "../../redux/features/booking/bookingDetailsSlice";

function BookingTable({
  allBooking,
  supplier,
  newAgencyRegData,
  user,
  status,
  flightGroup,
  currencyData,
  rowsPerPage,
  setRowsPerPage,
  currentPage,
  setCurrentPage,
  dispatch,
  totalReords,
  bookingFilter,
}) {
  const navigate = useNavigate();
  const loginUser = JSON.parse(localStorage.getItem("loginUser"));

  const obj = {
    Pending: (
      <p className="bg-primary text-white p-1" style={{ lineHeight: 1 }}>
        PEND
      </p>
    ),
    Issued: (
      <p className="bg-success text-white p-1 mt-1" style={{ lineHeight: 1 }}>
        TKTD
      </p>
    ),
    Refunded: (
      <p
        className="bg-danger text-white p-1"
        style={{ lineHeight: 1, marginTop: 5 }}
      >
        RFND
      </p>
    ),
  };

  const actionsObject = {
    label: "Action",
    name: "Action",
    options: {
      customBodyRender: () => {
        return (
          <div className="" style={{ zIndex: -10 }}>
            <button
              type="button"
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => {
                window.scroll({ top: 0, behavior: "smooth" });
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
            <button
              type="button"
              className="btn-danger rounded edit-delete-icon-button"
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  };
  const columns = [
    {
      label: "Id",
      name: "id",
      options: {
        display: "false",
      },
    },
    {
      label: "Raw Book Type (Hidden)", 
      name: "book_type",
      options: {
        display: false, 
      },
    },
    {
      label: "Date",
      name: "bookdatetime",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <div className="ms-2 d-flex gap-3 align-items-center">
                <p> {moment(value?.split("T")[0]).format("ddd DD-MMM-YY")}</p>
              </div>
            </div>
          );
        },
      },
    },
    {
      label: "Book Type",
      name: "book_type",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // const bookingRow = allBooking?.find(({ id }) => id === value);
          const bookingRow = allBooking?.find(
            ({ id }) => id === tableMeta.rowData[0]
          ); // Find booking by ID

          const objGds = {
            flight: "-" + bookingRow?.FlightBookingModels?.at(0)?.gds,
            "flight-group": "-" + bookingRow?.FlightBookingModels?.at(0)?.gds,
            visa:
              "-" +
              bookingRow?.FlightBookingModels?.at(0)?.VisaCountryModel?.name,
          };

          return (
            <div s>
              <div className="ms-2 d-flex gap-3 align-items-center">
                <p
                  className="text-primary p-1 text-uppercase"
                  style={{ lineHeight: 1 }}
                >
                  {
                    // bookingRow?.book_type === "gds"?
                    `${bookingRow?.book_type + objGds[bookingRow?.book_type]}`
                    // : bookingRow?.book_type
                  }
                </p>
              </div>
            </div>
          );
        },
      },
    },
    {
      label: `Booking ID | PNR  ${user?.user_id === 1 ? "| Supplier" : ""}`,

      name: "id",
      options: {
        customBodyRender: (value) => {
          const bookingRow = allBooking?.find(({ id }) => id === value);
          return (
            <div>
              <div className="ms-2 d-flex gap-3 align-items-center">
                <p className="text-primary p-1" style={{ lineHeight: 1 }}>
                  {value}
                </p>
                <p className="text-success p-1 mt-1" style={{ lineHeight: 1 }}>
                  {bookingRow?.FlightBookingModels &&
                    bookingRow?.FlightBookingModels[0]?.pnr}
                </p>
                {user?.user_id === 1 ? (
                  <p
                    className="text-danger p-1"
                    style={{ lineHeight: 1, marginTop: 5 }}
                  >
                    {
                      supplier?.find(
                        (suppl, index) =>
                          suppl?.acc_id === bookingRow?.supplier_acc_id
                      )?.supp_name
                    }
                  </p>
                ) : null}
              </div>
            </div>
          );
        },
      },
    },
    {
      label: "Passengers",
      name: "id",
      options: {
        customBodyRender: (value) => {
          const bookingRow = allBooking?.find(({ id }) => id === value);

          return (
            <div className="d-flex">
              <div className=" w-100">
                {bookingRow?.FlightBookingModels &&
                  bookingRow?.FlightBookingModels[0]?.pax_tables?.map(
                    (item, index) => (
                      <div className="d-flex justify-content-between py-1">
                        <p>{item?.fName + " " + item?.lname}</p>
                        <p style={{ lineHeight: 1 }}>
                          <p style={{ lineHeight: 1 }}>
                            {item?.tkt_number &&
                            item?.cn_number?.split("-")?.at(0) === "CN"
                              ? obj["Refunded"]
                              : item?.tkt_number
                              ? obj["Issued"]
                              : obj["Pending"]}
                          </p>
                          {}
                        </p>
                      </div>
                    )
                  )}
              </div>
            </div>
          );
        },
      },
    },
    {
      label: "AgentName",
      name: "id",
      options: {
        customBodyRender: (value) => {
          const bookingRow = allBooking?.find(({ id }) => id === value);
          return (
            <div className="d-flex ">
              <div className="ms-2">
                {
                  newAgencyRegData?.find(
                    (item, index) => item?.acc_id === bookingRow?.agent_acc_id
                  )?.agent_name
                }
              </div>
            </div>
          );
        },
      },
    },
    {
      label: "Amount",
      name: "id",
      options: {
        customBodyRender: (value) => {
          const bookingRow = allBooking?.find(({ id }) => id === value);
          const currency = currencyData?.find(
            (it) => it.currency_id === ~~bookingRow?.curr
          );
          const IsArray = bookingRow?.FlightBookingModels?.at(0)?.pax_tables;
          const result = IsArray?.reduce(
            (acc, currentValue) => {
              acc.sum += Number(currentValue?.ttl_agt_pay_d_cur || 0);
              acc.totalSCurrency += Number(
                currentValue?.ttl_agt_pay_s_cur || 0
              );
              return acc;
            },
            { sum: 0, totalSCurrency: 0 }
          );
          return (
            <div>
              <div className="ms-2 d-flex gap-3 align-items-center flex-column">
                {user?.agent_id === 1 ? (
                  <>
                    <p>
                      {currency?.curr_code +
                        " " +
                        TwoDecimalPoint(result?.totalSCurrency)}
                    </p>
                    <p>{"PKR" + " " + result?.sum}</p>
                  </>
                ) : (
                  <p>
                    {" "}
                    {user?.agents_comp?.agent_group?.currency?.curr_code}{" "}
                    {TwoDecimalPoint(result?.totalSCurrency)}
                  </p>
                )}
              </div>
            </div>
          );
        },
      },
    },

    {
      label: "Booking Status",
      name: "id",
      options: {
        customBodyRender: (value) => {
          const bookingRow = allBooking?.find(({ id }) => id === value);

          return (
            <div className="d-flex align-items-center s">
              <div className="ms-2 d-flex gap-2  w-100 flex-column">
                <p
                  className={
                    bookingRow?.book_status === "Cancelled" ? "text-danger" : ""
                  }
                >
                  {bookingRow?.book_status}
                </p>
                {bookingRow?.book_type === "flight" ? (
                  <p className="text-danger">
                    {moment(
                      bookingRow?.FlightBookingModels?.at(0)
                        ?.flight_SSRs?.at(0)
                        ?.date_time?.split("T")
                        ?.at(0)
                    ).format("ddd DD-MMM-YY")}{" "}
                    {bookingRow?.FlightBookingModels?.at(0)
                      ?.flight_SSRs?.at(0)
                      ?.date_time?.split(" ")[1]
                      ?.slice(0, 5)}
                  </p>
                ) : null}
              </div>
            </div>
          );
        },
      },
    },
    // {
    //   label: "Document Status",
    //   name: "doc_status",
    // },
    ...(loginUser?.agent_id === 1 ? [actionsObject] : []),
  ];
  const [page, setPage] = useState(currentPage);
  const options = {
    filter: false,
    search: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    rowsPointer: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    serverSide: true, // Enable server-side pagination
    count: totalReords, // Set this to the total number of records from the database
    rowsPerPageOptions: [25, 50, 75, 100], // Adjust as needed
    displayRowCheckbox: false,
    rowsPerPage: rowsPerPage,
    page: currentPage,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setCurrentPage(tableState.page);
          dispatch(setAllBookingNull());
          setTimeout(() => {
            fetchData(tableState.page, rowsPerPage, bookingFilter);
          }, 3000);
          // Fetch data based on the new page
          break;
        case "changeRowsPerPage":
          setRowsPerPage(tableState.rowsPerPage);
          setCurrentPage(0); // Reset to first page when rows per page change
          dispatch(setAllBookingNull());
          setTimeout(() => {
            fetchData(0, tableState.rowsPerPage, bookingFilter); // Fetch data based on the new rows per page
          }, 3000);
          break;
        default:
          break;
      }
    },
    onRowClick: (rowData) => {
      dispatch(setSingleBookingNull(null))
      const id = rowData[0];
      let type = rowData[1]
      navigate("/booking-details", { state: { id,type } });
    },
    textLabels: {
      body: {
        noMatch: "Loading...", 
      },
    },
  };

  const fetchData = (page, rowsPerPage, bookingFilter) => {
    // Replace with your actual API call logic
    dispatch(
      getAllAsyncBookings({
        id: user?.agents_comp?.acc_id,
        currentPage: page,
        rowsPerPage: rowsPerPage,
        bookingFilter,
      })
    );
    // dispatch(fetchBookingData({ ,  })); // Adjust the page number based on your API (1-indexed vs 0-indexed)
  };

  // const options = {
  //   filter: false,
  //   search: false,
  //   Selection: false,
  //   print: false,
  //   download: false,
  //   viewColumns: false,
  //   displayRowCheckbox: false,
  //   rowsPointer: true,
  //   selectableRows: "none",
  //   rowsPerPageOptions: [3, 5, 8, 11], // Adjust as needed
  // rowsPerPage;
  //   pagination: true,
  //   count: 9, // Total number of records
  //   page,
  //   ,
  //   onChangePage: (newPage) => {
  //     setPage(newPage);
  //     setCurrentPage(newPage);
  //     dispatch(setAllBookingNull());
  //   },
  //   onChangeRowsPerPage: (newRowsPerPage) => {
  //     setRowsPerPage(newRowsPerPage);
  //     setCurrentPage(0);
  //   },

  //   onRowClick: (rowData) => {
  //     const id = rowData[0];
  //     navigate("/booking-details", { state: { id } });
  //   },
  // };
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    // fetchData(currentPage, rowsPerPage);
    // dispatch(
    //   getAllAsyncBookings({
    //     id: user?.agents_comp?.acc_id,
    //     rowsPerPage: rowsPerPage,
    //     currentPage: currentPage,
    //   })
    // );
    // setTimeout(() => {
    //   setToggle(!toggle);
    // }, 10000);
  }, [toggle, dispatch]);

  return (
    <div className="px-2">
      <MUIDataTable
        className="muidatatable cursorpointer"
        data={allBooking}
        columns={columns}
        options={options}
      />
    </div>
  );
}

export default BookingTable;

//  const allFilterBookingData = data?.filter((item) => {
//    // Filter by service_type
//    const serviceTypeMatch = bookingFilter?.service_type
//      ? item?.book_type?.toLowerCase() ===
//        bookingFilter.service_type.toLowerCase()
//      : true;

//    // Filter by date range (fromDate and toDate)
//    const fromDateMatch = bookingFilter?.fromDate
//      ? item?.bookdatetime?.split("T")?.at(0) >=
//        moment(bookingFilter.fromDate).format("YYYY-MM-DD")
//      : true;
//    const toDateMatch = bookingFilter?.toDate
//      ? item?.bookdatetime?.split("T")?.at(0) <=
//        moment(bookingFilter.toDate).format("YYYY-MM-DD")
//      : true;

//    // Filter by agent_acc_id
//    const agentAccIdMatch = bookingFilter?.agent_acc_id
//      ? item?.agent_acc_id === bookingFilter.agent_acc_id
//      : true;

//    // Filter by supplier_acc_id
//    const supplierAccIdMatch = bookingFilter?.supplier_acc_id
//      ? item?.supplier_acc_id === bookingFilter.supplier_acc_id
//      : true;

//    // Filter by booking_id
//    const bookingIdMatch = bookingFilter?.booking_id
//      ? item?.id === Number(bookingFilter.booking_id)
//      : true;

//    // Filter by pax_name
//    const paxNameMatch = bookingFilter?.pax_name
//      ? item?.FlightBookingModels?.at(0)?.pax_tables?.some((px) =>
//          (px?.fName?.toLowerCase() + " " + px?.lname?.toLowerCase()).includes(
//            bookingFilter.pax_name.toLowerCase()
//          )
//        )
//      : true;

//    // Filter by status
//    const statusMatch = bookingFilter?.status
//      ? item?.doc_status?.toLowerCase() === bookingFilter.status.toLowerCase()
//      : true;

//    // Combine all conditions
//    return (
//      serviceTypeMatch &&
//      fromDateMatch &&
//      toDateMatch &&
//      agentAccIdMatch &&
//      supplierAccIdMatch &&
//      bookingIdMatch &&
//      paxNameMatch &&
//      statusMatch
//    );
//  });
