import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../../CustomDrp";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetMainAccountData,
  asyncGetSubAccountData,
} from "../../../../redux/features/Finance/Account/ChartOfAccountSlice";
import { AsyncGetAllVoucher } from "../../../../redux/features/Voucher/VoucherSlice";
import { BlobProvider } from "@react-pdf/renderer";
import AgentStatmentPdf from "../../../Pdf/AgentStatmentPdf";
import moment from "moment";
import { asyncGetAdmin, asyncSearchAgencyData } from "../../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { useNavigate } from "react-router-dom";
import AgentsStatementPDF from "./AgentsStatementPDF";
import {
  AsyncCreateAgentStatment,
  setChangeComponet,
  setMessageVisibility,
} from "../../../../redux/features/Statement/StatementSlice";
import BtnLoader from "../../../AppForm/BtnLoader";

function JournalLedger() {
  const { allVoucher } = useSelector((state) => state?.voucherEntries);
  const { searchAgency, getAdminData } = useSelector(
    (state) => state.newAgency
  );
  
  const allVoucherData = allVoucher?.data?.data;
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const dispatch = useDispatch();
  useState(() => {
    dispatch(AsyncGetAllVoucher());
    dispatch(asyncGetMainAccountData());
  }, []);
  const dropDownArray = [
    { placehlder: "Account Level" },
    {
      placehlder: "Account Level To Print",
    },
  ];
  const navigate = useNavigate();
  const [accId, setAccid] = useState();
  const [toggle, setToggle] = useState({});
  const [account, setAccount] = useState([]);
  const [search, setSearch] = useState();
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const { agentStatement, changeComponent } = useSelector(
    (state) => state.Statement
  );
  const [voucherDate, setvoucherDate] = useState({
    startDate: "",
    endData: "",
  });
  const [voucher, setVoucher] = useState({
    salin: 4,
    vch: [1, 2, 3],
    curr: "PKR",
    cn: 5,
    op: "",
  });
  const [subToggle, setSubToggle] = useState({});
  
  const [agentData, setAgentData] = useState({});
  const [agent, setAgent] = useState("");
  useEffect(() => {
    let value = agent;
    dispatch(AsyncGetAllVoucher());
    dispatch(asyncSearchAgencyData(value))
      .then((res) => {
        setAgentData(res?.payload?.at(0));
      })
      .catch((err) => console.log(err));
          dispatch(asyncGetAdmin({ id: 1 }));

  }, [dispatch, agent]);
  const isVoucherNotEmpty = () => {
    return (
      voucher.salin !== "" ||
      (voucher.vch && voucher.vch.length > 0) ||
      voucher.cn !== "" ||
      voucher.op !== ""
    );
  };
  const hasVoucherData = isVoucherNotEmpty();
  const [message, setMessage] = useState(true);
const { userPermission } = useSelector((state) => state?.roleAndPermissions);
const editVoucherPermission = ![1, 6, 7].includes(userData.role_id)
  ? userPermission?.find(
      (it) =>
        it?.user_id === userData?.user_id &&
        it?.page_level?.permission_url === "/voucher-edit"
    )
  : true;
  return (
    <div>
      {changeComponent === "search" && (
        <div className="Full_height">
          <div className="manual_invoices mx-auto">
            <div className="manual_invoices_header">
              <div>Journal Ledger</div>
            </div>

            <div className="row py-3 px-2 pb-5 px-md-4 d-flex align-items-center">
              <div className="d-flex    mt-4 ">
                <div className="w-50 rounded  text-white px-2 py-2 fs-6">
                  <ReactDatePicker
                    placeholderText="From Date"
                    selected={fromDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      setFromDate(date);
                    }}
                    maxDate={new Date()}
                    className="date_picker "
                  />
                </div>
                <div className="w-50 rounded  text-white px-2 py-2 fs-6">
                  <ReactDatePicker
                    placeholderText="To Date"
                    selected={toDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      setToDate(date);
                    }}
                    maxDate={new Date()}
                    className="date_picker "
                  />
                </div>
              </div>
              {dropDownArray?.map((item, index) => (
                <div className="col-12 col-md-6 ">
                  <CustomDropdown
                    arry={[
                      "Assets",
                      "Liabilities",
                      "Capital",
                      "Income",
                      "Expances",
                    ]}
                    index={index}
                    placehlder={item?.placehlder}
                    item={item}
                    toggle={toggle}
                    setToggle={setToggle}
                    account={account}
                    setAccount={setAccount}
                    search={search}
                    setSearch={setSearch}
                    accId={accId}
                    setAccid={setAccid}
                    setAgent={setAgent}
                  />
                </div>
              ))}
              <div class="checkbox-rect d-flex  justify-content-center mt-4  w-full ">
                <input
                  type="checkbox"
                  id="add_opening_balence"
                  name="check"
                  className="w-full"
                  checked={voucher?.op === "opb"}
                  onChange={() =>
                    setVoucher({
                      ...voucher,
                      op: voucher?.op === "opb" ? "" : "opb",
                    })
                  }
                />
                <label for="add_opening_balence" className="h6">
                  Opening Balence
                </label>
              </div>
              {message ? (
                <div className="text-center py-4">
                  <button
                    className="button_style px-4"
                    type="submit"
                    disabled={
                      accId === undefined ||
                      accId === 1 ||
                      accId === 2 ||
                      accId === 3 ||
                      accId === 4 ||
                      accId === 5
                    }
                    onClick={(e) => {
                      const body = {
                        ...voucher,
                        fromDate: new Date(
                          moment(fromDate).format("YYYY-MM-DD")
                        ),
                        toDate: new Date(moment(toDate).format("YYYY-MM-DD")),
                        accID: accId,
                      };
                      dispatch(AsyncCreateAgentStatment({ body }));
                      setMessage(false);
                      setTimeout(() => {
                        dispatch(setChangeComponet("data"));
                        setMessage(true);
                      }, 3000);
                    }}
                  >
                    Agent Statement
                  </button>
                </div>
              ) : (
                <div className="text-center py-4">
                  <button className="button_style px-4">
                    <BtnLoader />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {agentStatement?.succcess && changeComponent === "data" && (
        <AgentsStatementPDF
          agentData={account?.at(1)?.title}
          fromDate={moment(voucherDate?.startDate).format("DD-MMM-YY")}
          toDate={moment(voucherDate?.endData).format("DD-MMM-YY")}
          statement="agent"
          data={
            // voucher?.op === "opb"
            //   ? [
            //       {
            //         acc_voucher_trans: [
            //           {
            //             d_cur_credit: 0,
            //             d_cur_debit: agentStatement?.total,
            //             trans_description: "Opening Balance",
            //           },
            //         ],
            //       },
            //       ...agentStatement?.filterData,
            //     ]
            //   :
            [
              {
                acc_voucher_trans: [
                  {
                    d_cur_credit: 0,
                    d_cur_debit:
                      voucher?.op === "opb" ? agentStatement?.total : 0,
                    trans_description: "Opening Balance",
                  },
                ],
              },
              ...agentStatement?.filterData,
            ]
          }
          getAdminData={getAdminData?.logo}
          userData={userData}
          voucher={voucher}
          setChangeComponet={setChangeComponet}
          allwaysCredit={
            account?.at(0)?.title === "LIABILITIES" ||
            account?.at(0)?.title === "INCOME"
          }
          editVoucherPermission={editVoucherPermission}
        />
      )}
    </div>
  );
}

export default JournalLedger;
