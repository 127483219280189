import { useNavigate, useParams } from "react-router-dom";
import iata from "../../assets/images/iata.png";
// import logo from "../../../../assets/images/zairaa.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
// import { AsyncGetAllVoucher } from "../../../../redux/features/Voucher/VoucherSlice";
// import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { setChangeInvoice } from "../../redux/features/Finance/Sale/SaleReportSlice";
import { apiUrl } from "../../Utils/configUrl";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import { setOpenAgentOutStanding } from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { setSupplierPaybleComponent } from "../../redux/features/supplier/AddNewSupplier";
const SupplierListPdf = ({
  data,
  agentData,
  voucher,
  setChangeComponet,
  fromDate,
  toDate,
  statement,
  userData,
  allwaysCredit,
  ouStanding,
  text,
  summary,
  getAdminData,
  editVoucherPermission,
  payble,
}) => {
  const dispatch = useDispatch();
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);

  const componentRef = useRef();
  const firstColor = adminAgencyData?.first_color;
  return (
    <div className="d-flex  flex-column gap-4 bg-white">
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />

          <button
            onClick={(e) => dispatch(setSupplierPaybleComponent(payble))}
            className="bg_primary px-4 py-1 rounded text-white"
          >
            Back
          </button>
        </div>
      </div>
      {/* bottom setion */}
      <div className="d-flex flex-column gap-3  mt-4" ref={componentRef}>
        <div
          className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white fw-bold my-2">
            {" "}
            Supplier Reports{" "}
          </span>
        </div>

        <div className="d-flex flex-column justify-content-center  align-items-center mx-1  ">
          <div style={{ width: "100%" }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>SUPPLIER NAME</th>
                  <th>TYPE</th>
                  <th>CONTACT NO</th>
                  <th>EMAIL</th>
                  <th>ADRESS</th>
                  <th>STATUS</th>
                  <th>CURRENT BALANCE</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data?.map((supplier, ind) => (
                    <tr key={ind}>
                      <>
                        <td>{ind + 1}</td>
                        <td>{supplier?.supplierData?.supp_name}</td>
                        <td>
                          {supplier?.supplierData?.supplier_category?.cat_name}
                        </td>
                        <td>{supplier?.supplierData?.ctc_no}</td>
                        <td>
                          {supplier?.supplierData?.ctc_email?.toUpperCase()}
                        </td>
                        <td>{supplier?.supplierData?.ctc_address}</td>
                        <td>
                          {supplier?.supplierData?.status === 1
                            ? "ACTIVE"
                            : "INACTIVE"}
                        </td>{" "}
                        <td>{supplier?.total?.toFixed(2)}</td>
                      </>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={8}
                      style={{ textAlign: "center", fontSize: "20px" }}
                    >
                      <p>Data Not Found</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierListPdf;

// const SupplierListPdf = ({first}) => {
//   const tableData = [
//     {
//       SNO: "1 ",
//       SUPPLIERNAME: "Airblue limited ",
//       TYPE: "Flights  ",
//       CONTACT: "1234567890 ",
//       CURRENTBALANCE: "510448",
//       STATUS: "Active",
//     },
//     {
//       SNO: "2 ",
//       SUPPLIERNAME: "Airblue limited ",
//       TYPE: "Umrah  ",
//       CONTACT: "1234567890 ",
//       CURRENTBALANCE: "448",
//       STATUS: "Active",
//     },
//     {
//       SNO: "3",
//       SUPPLIERNAME: "Airblue limited ",
//       TYPE: "Flights  ",
//       CONTACT: "1234567890 ",
//       CURRENTBALANCE: "512",
//       STATUS: "Active",
//     },
//     {
//       SNO: "4",
//       SUPPLIERNAME: "Airblue limited ",
//       TYPE: "Umrah  ",
//       CONTACT: "1234567890 ",
//       CURRENTBALANCE: "20448",
//       STATUS: "Active",
//     },
//     {
//       SNO: "5",
//       SUPPLIERNAME: "Airblue limited ",
//       TYPE: "Flights  ",
//       CONTACT: "1234567890 ",
//       CURRENTBALANCE: "51248",
//       STATUS: "Active",
//     },
//   ];
//   const borderColor = "#000000";

//   const styles = StyleSheet.create({
//     containerheader: {
//       flexDirection: "row",
//       borderBottomColor: borderColor,
//       borderBottomWidth: 1,
//       borderTopColor: borderColor,
//       borderTopWidth: 1,
//       fontSize: 9,
//       marginTop: 10,
//     },
//     containerbody: {
//       flexDirection: "row",
//       borderBottomColor: borderColor,
//       borderBottomWidth: 1,
//       fontSize: 9,
//     },
//     SNO: {
//       width: "10%",
//       padding: 3,
//       borderRightColor: borderColor,
//       borderRightWidth: 1,
//       borderLeftColor: borderColor,
//       borderLeftWidth: 1,
//     },
//     SUPPLIERNAME: {
//       width: "20%",
//       padding: 3,
//       borderRightColor: borderColor,
//       borderRightWidth: 1,
//     },
//     TYPE: {
//       width: "15%",
//       padding: 3,
//       borderRightColor: borderColor,
//       borderRightWidth: 1,
//     },
//     CONTACT: {
//       width: "20%",
//       padding: 3,
//       borderRightColor: borderColor,
//       borderRightWidth: 1,
//     },
//     CURRENTBALANCE: {
//       width: "20%",
//       padding: 3,
//       borderRightColor: borderColor,
//       borderRightWidth: 1,
//     },
//     STATUS: {
//       width: "15%",
//       padding: 3,
//       borderRightColor: borderColor,
//       borderRightWidth: 1,
//     },
//   });

//   return (
//     <Document>
//       <Page size="A4" style={{ padding: 10 }}>
//         <View style={{ display: "flex", flexDirection: "row" }}>
//           <View
//             style={{
//               width: "20%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Image src={logo} style={{ width: "80%", height: "40px" }} />
//           </View>
//           <View
//             style={{
//               width: "60%",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Text style={{ fontStyle: "bold", fontSize: 16, paddingTop: 20 }}>
//               OTAWIX Solution Pvt Ltd
//             </Text>
//             <Text style={{ fontSize: 13, marginTop: 4 }}>
//               Address: Egerton Road, Lahore
//             </Text>
//             <Text style={{ fontSize: 13, marginTop: 4 }}>
//               Email: otawix.solution@gmail.com
//             </Text>
//             <Text style={{ fontSize: 13, marginTop: 4 }}>
//               Contact: 0333-123567
//             </Text>
//           </View>
//           <View
//             style={{
//               width: "20%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Image src={logo} style={{ width: "80%", height: "40px" }} />
//           </View>
//         </View>

//         <View
//           style={{
//             backgroundColor: "#B0B0B0",
//             padding: 4,
//             marginTop: 20,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Text style={{ color: "#FFFFFF", fontSize: 14 }}>
//             Supplier Statement
//           </Text>
//         </View>

//         <View
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Text style={{ fontSize: 14, marginTop: 10, fontStyle: "bold" }}>
//             Supplier List
//           </Text>
//         </View>

//         <View style={styles.containerheader}>
//           <Text style={styles.SNO}>S.NO</Text>
//           <Text style={styles.SUPPLIERNAME}>SUPPLIER NAME</Text>
//           <Text style={styles.TYPE}>TYPE</Text>
//           <Text style={styles.CONTACT}>CONTACT NO</Text>
//           <Text style={styles.CURRENTBALANCE}>CURRENT BALANCE</Text>
//           <Text style={styles.STATUS}>STATUS</Text>
//         </View>
//         <View>
//           {first === "Active" ? tableData.map((data, index) => {
//             return (
//               <View style={styles.containerbody} key={index}>
//                 <Text style={styles.SNO}>{data?.SNO}</Text>
//                 <Text style={styles.SUPPLIERNAME}>{data?.SUPPLIERNAME}</Text>
//                 <Text style={styles.TYPE}>{data?.TYPE}</Text>
//                 <Text style={styles.CONTACT}>{data?.CONTACT}</Text>
//                 <Text style={styles.CURRENTBALANCE}>
//                   {data?.CURRENTBALANCE}
//                 </Text>
//                 <Text style={styles.STATUS}>{data?.STATUS}</Text>
//               </View>
//             );
//           }): null}
//         </View>
//         <View
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             borderBottomColor: borderColor,
//             borderBottomWidth: 1,
//             fontSize: 9,
//           }}
//         >
//           <Text
//             style={{
//               flex: 1,
//               padding: 3,
//               borderLeftColor: borderColor,
//               borderLeftWidth: 1,
//               borderRightColor: borderColor,
//               borderRightWidth: 1,
//               textAlign: "right",
//             }}
//           >
//             Total
//           </Text>
//           <Text
//             style={{
//               width: "201px",
//               padding: 3,
//               borderRightColor: borderColor,
//               borderRightWidth: 1,
//             }}
//           >
//             2235496
//           </Text>
//         </View>
//       </Page>
//     </Document>
//   );
// };

// export default SupplierListPdf;
