import React, { useEffect, useState } from "react";
import FlightBookingDetails from "../components/BookingDetailsComponent/FlightBookingDetails";
import { useLocation } from "react-router-dom";
import VisaBookingDetails from "../components/BookingDetailsComponent/VisaBookingDetails";

function BookingDetails() {
  const location = useLocation();
  const type = location?.state?.type ;
  const obj = {
    flight: <FlightBookingDetails />,
    "flight-group": <FlightBookingDetails />,
    visa: <VisaBookingDetails />,
  };
  return <div className="">{obj[type]}</div>;
}

export default BookingDetails;
