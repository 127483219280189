import { useEffect, useState } from "react";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../Utils/currencyExchange";
import { Field, FieldArray, Form, Formik } from "formik";
import { Fullscreen } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import {
  GetFareLogs,
  getAsyncBookingDetails,
  updateAsynicupdatePaxMainTableCreateBookingLogs,
} from "../../redux/features/booking/bookingDetailsSlice";
import BtnLoader from "../AppForm/BtnLoader";

const TotalPaxRecord = ({
  user,
  PaxData,
  totalPrice,
  agentGroup,
  agencyData,
  setShowPax,
  showPax,
  mainBooking,
  setActive_Tab,
  FarePermission,
}) => {
  const [viewStatement, setViewStatement] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const DisplayingErrorMessagesSchema = Yup.object().shape({
    description: Yup.string().required("Description Required"),
  });

  useEffect(() => {
    if (user?.user_id === 1) {
      setViewStatement("admin");
    } else if (FarePermission?.user_id === user?.user_id) {
      setViewStatement("admin");
    } else {
      switch (user?.agents_comp?.view_statement) {
        case "Only Total":
          setViewStatement("Only Total");
          break;
        case "WithOut Discount/PSF":
          setViewStatement("WithOut Discount/PSF");
          break;
        default:
          setViewStatement("Full Statement");
          break;
      }
    }
  }, [user]);
  const [paxEdit, setPaxEdit] = useState([]);
  const [bsFare, setBsFare] = useState();
  const [bsFareS, setBsFareS] = useState();
  const [ttBsFare, setTlBsFare] = useState();
  const dispatch = useDispatch();
  const [disPsf, setDisPsf] = useState({});
  useEffect(() => {
    setPaxEdit(
      PaxData?.map((p, ind) => ({
        pax_id: p?.pax_id,
        pax_type: p?.pax_type,
        baseFare_s_cur: p?.baseFare_s_cur,
        baseFare_d_cur: p?.baseFare_d_cur,
        pax_title: p?.pax_title,
        first_name: p?.fName,
        last_name: p?.lname,
        agt_psf_d_cur: p?.agt_psf_d_cur,
        agt_psf_s_cur: p?.agt_psf_s_cur,
        roe: mainBooking?.roe,
        id: mainBooking?.id,
        agt_sp_s_cur: p?.agt_sp_s_cur,
        agt_sp_d_cur: p?.agt_sp_d_cur,
        ttl_tax_d_cur: p?.ttl_tax_d_cur,
        ttl_tax_s_cur: p?.ttl_tax_s_cur,
        ttl_agt_pay_s_cur: p?.ttl_agt_pay_s_cur,
        ttl_agt_pay_d_cur: p?.ttl_agt_pay_d_cur,
        disPsf: p?.agt_psf_d_cur > 0 ? "psf" : "dis",
        disPsfPrice: p?.agt_psf_d_cur > 0 ? p?.agt_psf_d_cur : p?.agt_sp_d_cur,
        description: "",
      }))
    );
    const initialDisPsf = PaxData?.reduce((acc, p, index) => {
      acc[index] = p?.agt_psf_d_cur > 0 ? "psf" : "dis"; // Default logic to set "psf" or "dis"
      return acc;
    }, {});

    setDisPsf(initialDisPsf);
  }, [PaxData]);

  return (
    <>
      {/* Only For Admin */}
      {viewStatement === "admin" ? (
        showPax ? (
          <table class="table table-bordered border-dark align-items-center ">
            <thead
              className="bg_primary text-white "
              style={{ fontSize: "20px" }}
            >
              <tr>
                <th colSpan="" style={{ width: 200 }}>
                  Passenger
                </th>

                <th scope="col" style={{ width: 200 }}>
                  Fare
                </th>
                <th scope="col" style={{ width: 200 }}>
                  Taxes
                </th>
                <th scope="col" style={{ width: 200 }}>
                  Total
                </th>
                <th scope="col" style={{ width: 200 }}>
                  DIS/PSF
                </th>

                <th colSpan={" "} style={{ width: 200 }}>
                  Receivable
                </th>
              </tr>
            </thead>
            <tbody className="text-dark " style={{ color: "#000000" }}>
              {PaxData?.map((item, index) => (
                <tr>
                  <th colSpan={""}>
                    {item?.pax_type}-{item?.fName + " " + item?.lname}
                  </th>

                  <>
                    <th>
                      <div className="d-flex flex-column  gap-2">
                        <span className="text-primary">
                          {" "}
                          {CurrencyCode()} {item?.baseFare_s_cur}
                        </span>
                        <span> PKR {item?.baseFare_d_cur}</span>
                      </div>
                    </th>
                    {/* taixs */}
                    <th>
                      <div className="d-flex flex-column  gap-2">
                        <span className="text-primary">
                          {" "}
                          {CurrencyCode()}{" "}
                          {TwoDecimalPoint(
                            Number(item?.ttl_tax_s_cur) +
                              Number(item?.agt_gst_s_cur) +
                              Number(item?.agt_wht_s_cur)
                          )}
                        </span>
                        <span>
                          {" "}
                          PKR{" "}
                          {Number(item?.ttl_tax_d_cur) +
                            Number(item?.agt_gst_d_cur) +
                            Number(item?.agt_wht_d_cur)}
                        </span>
                      </div>
                    </th>

                    <th>
                      <div className="d-flex flex-column  gap-2">
                        <span className="text-primary">
                          {" "}
                          {CurrencyCode()}{" "}
                          {TwoDecimalPoint(
                            Math.abs(
                              Number(item?.baseFare_s_cur) +
                                Number(item?.ttl_tax_s_cur) +
                                Number(item?.agt_gst_s_cur) +
                                Number(item?.agt_wht_s_cur)
                            )
                          )}
                        </span>
                        <span>
                          {" "}
                          PKR{" "}
                          {TwoDecimalPoint(
                            Math.abs(
                              Number(item?.baseFare_d_cur) +
                                Number(item?.ttl_tax_d_cur) +
                                Number(item?.agt_gst_d_cur) +
                                Number(item?.agt_wht_d_cur)
                            )
                          )}
                        </span>
                      </div>
                    </th>
                    {/* PSF or DIS */}
                    <th>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <span className="text-primary">
                          {" "}
                          {CurrencyCode()}{" "}
                          {TwoDecimalPoint(Math.abs(Number(item?.ttl_agt_pay_s_cur) - (Number(item?.baseFare_s_cur) + Number(item?.ttl_tax_s_cur))))}
                        </span>
                        <span>
                          {" "}
                          PKR{" "}
                          {TwoDecimalPoint(Math.abs(Number(item?.ttl_agt_pay_d_cur) - (Number(item?.baseFare_d_cur) + Number(item?.ttl_tax_d_cur))))}
                        </span>
                      </div>
                    </th>
                  </>

                  <th
                    colSpan={""}
                    className="d-flex flex-column gap-2 text-primary"
                  >
                    {CurrencyCode() +
                      " " +
                      TwoDecimalPoint(item?.ttl_agt_pay_s_cur)}
                    <span className="text-dark">
                      PKR {TwoDecimalPoint(item?.ttl_agt_pay_d_cur)}
                    </span>
                  </th>
                </tr>
              ))}

              <tr>
                <td
                  colSpan="5"
                  style={{
                    textAlign: "right",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  Grand Total
                </td>
                <td
                  scope="col"
                  style={{ fontWeight: 700, fontSize: "20px" }}
                  className="d-flex flex-column gap-2 text-primary"
                >
                  {CurrencyCode()}{" "}
                  {TwoDecimalPoint(CurrencyExchange(totalPrice))}
                  <span className="text-dark">
                    PKR {TwoDecimalPoint(totalPrice)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div>
            <Formik
              initialValues={{
                paxEdit,
              }}
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={(values) => {
                const body = {
                  description: values?.description,
                  paxEdit: values?.paxEdit?.map((item, index) => {
                    const agt_psf_d_cur =
                      item?.disPsf === "psf" ? item?.disPsfPrice : "0";
                    const agt_sp_d_cur =
                      item?.disPsf === "dis" ? item?.disPsfPrice : "0";

                    return {
                      ...item,
                      agt_psf_d_cur,
                      agt_sp_d_cur,
                    };
                  }),
                };
                // setIsLoading(true);
                // dispatch(
                //   updateAsynicupdatePaxMainTableCreateBookingLogs({
                //     id: values?.paxEdit?.at(0)?.id,
                //     body: { ...body, ttBsFare },
                //   })
                // )
                //   .then(() => {
                //     // Once the update is successful, fetch the updated booking details
                //     dispatch(
                //       getAsyncBookingDetails({ id: values?.paxEdit?.at(0)?.id })
                //     )
                //       .then(() => {
                //         setTimeout(() => {
                //           setIsLoading(false);
                //           dispatch(
                //             GetFareLogs({ id: values?.paxEdit?.at(0)?.id })
                //           );
                //           setShowPax(true);
                //         }, 2500);
                //       })
                //       .catch((err) => {
                //         console.error(
                //           "Error fetching updated booking details:",
                //           err
                //         );
                //       });
                //   })
                //   .catch((err) => {
                //     console.error("Error updating passenger:", err);
                //   });
              }}
            >
              {({ errors, values, setFieldValue }) => {
                return (
                  <Form>
                    <FieldArray name="paxEdit">
                      {({ push, remove }) => (
                        <table class="table table-bordered border-dark align-items-center ">
                          <thead
                            className="bg_primary text-white "
                            style={{ fontSize: "20px" }}
                          >
                            <tr>
                              <th colSpan={""} style={{ width: 200 }}>
                                Passenger
                              </th>

                              <>
                                <th scope="col" style={{ width: 200 }}>
                                  Fare
                                </th>
                                <th scope="col" style={{ width: 200 }}>
                                  Taxes
                                </th>
                                <th scope="col" style={{ width: 200 }}>
                                  Total
                                </th>
                                <th scope="col" style={{ width: 200 }}>
                                  DIS/PSF
                                </th>
                              </>

                              <th colSpan={" "} style={{ width: 200 }}>
                                Receivable
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="text-dark "
                            style={{ color: "#000000" }}
                          >
                            {values.paxEdit.map((pax, index) => {
                              const grandTotal = values?.paxEdit?.reduce(
                                (acc, pax) => {
                                  const baseFare =
                                    Number(pax.baseFare_d_cur) || 0;
                                  const disPsfPrice =
                                    Number(pax?.disPsfPrice) || 0;
                                  const ttTax = Number(pax?.ttl_tax_d_cur) || 0;
                                  if (pax?.disPsf === "psf") {
                                    return (
                                      acc + (baseFare + ttTax + disPsfPrice)
                                    );
                                  } else {
                                    return (
                                      acc + (baseFare + ttTax - disPsfPrice)
                                    );
                                  }
                                },
                                0
                              );
                              const grandTotalS = values?.paxEdit?.reduce(
                                (acc, pax) => {
                                  const baseFareS =
                                    Number(pax.baseFare_s_cur) || 0;
                                  const disPsfPriceS =
                                    Number(pax?.disPsfPrice) /
                                      Number(pax?.roe) || 0;
                                  const ttTax = Number(pax?.ttl_tax_s_cur) || 0;
                                  if (pax?.disPsf === "psf") {
                                    return (
                                      acc + (baseFareS + ttTax + disPsfPriceS)
                                    );
                                  } else {
                                    return (
                                      acc + (baseFareS + ttTax - disPsfPriceS)
                                    );
                                  }
                                },
                                0
                              );
                              const tt = values?.paxEdit.reduce(
                                (result, pax) => {
                                  const x = Number(pax?.baseFare_d_cur) || 0;

                                  return result + x;
                                },
                                0
                              );
                              setBsFareS(TwoDecimalPoint(grandTotalS));
                              setTlBsFare(tt);
                              setBsFare(grandTotal);
                              return (
                                <tr key={index}>
                                  <th>
                                    <p>
                                      {pax?.pax_type +
                                        "-" +
                                        pax.first_name +
                                        " " +
                                        pax.last_name}
                                    </p>
                                  </th>
                                  <th>
                                    <div className="d-flex flex-column  gap-2">
                                      <span className="text-primary">
                                        {" "}
                                        {CurrencyCode()}{" "}
                                        {TwoDecimalPoint(
                                          values?.paxEdit[index]
                                            ?.baseFare_d_cur / pax.roe
                                        )}
                                      </span>
                                      <span>
                                        PKR{" "}
                                        {values?.paxEdit[index]?.baseFare_d_cur}
                                      </span>
                                    </div>
                                  </th>
                                  {/* Taxes */}
                                  <th>
                                    <div className="d-flex flex-column  gap-2">
                                      <th>
                                        <div className="d-flex flex-column  gap-2">
                                          <span className="text-primary">
                                            {" "}
                                            {CurrencyCode()}{" "}
                                            {TwoDecimalPoint(
                                              Number(
                                                values?.paxEdit[index]
                                                  ?.ttl_tax_s_cur
                                              )
                                            )}
                                          </span>
                                          <span>
                                            {" "}
                                            PKR{" "}
                                            {Number(
                                              values?.paxEdit[index]
                                                .ttl_tax_d_cur
                                            )}
                                          </span>
                                        </div>
                                      </th>
                                    </div>
                                  </th>
                                  {/* total */}
                                  <th>
                                    <div className="d-flex flex-column  gap-2">
                                      <span className="text-primary">
                                        {CurrencyCode()}
                                        {TwoDecimalPoint(
                                          Number(
                                            values?.paxEdit[index]
                                              ?.baseFare_s_cur
                                          ) +
                                            Number(
                                              values?.paxEdit[index]
                                                ?.ttl_tax_s_cur
                                            )
                                        )}
                                      </span>
                                      <p>
                                        PKR{" "}
                                        {TwoDecimalPoint(
                                          values?.paxEdit[index]?.baseFare_d_cur
                                        ) +
                                          TwoDecimalPoint(
                                            values?.paxEdit[index]
                                              ?.ttl_tax_d_cur
                                          )}
                                      </p>
                                    </div>
                                  </th>
                                  {/* DIS/PSF */}

                                  <th>
                                    <div className="d-flex flex-column  ">
                                      <div className="d-flex gap-4 pb-4">
                                        <input
                                          style={{
                                            width: "22px",
                                            height: "22px",
                                          }}
                                          type="radio"
                                          id={`pist-${index}`}
                                          name={`option-${index}`}
                                          checked={pax?.disPsf === "psf"}
                                          value="cheque"
                                          onChange={() => {
                                            setFieldValue(
                                              `paxEdit.${index}.disPsf`,
                                              "psf"
                                            );
                                          }}
                                        />
                                        <label for="without_statement">
                                          PSF
                                        </label>
                                        <input
                                          style={{
                                            width: "22px",
                                            height: "22px",
                                          }}
                                          type="radio"
                                          id={`pist-${index}`}
                                          name={`option-${index}`}
                                          value="cheque"
                                          checked={pax?.disPsf === "dis"}
                                          onChange={() => {
                                            setFieldValue(
                                              `paxEdit.${index}.disPsf`,
                                              "dis"
                                            );
                                          }}
                                        />
                                        <label for="without_statement">
                                          DIS
                                        </label>
                                      </div>
                                      <div className="d-flex flex-column  ">
                                        <span className="text-primary">
                                          {" "}
                                          {CurrencyCode()}{" "}
                                          {TwoDecimalPoint(
                                            Number(pax?.disPsfPrice) /
                                              Number(pax?.roe)
                                          )}
                                        </span>
                                        <span>
                                          {" "}
                                          <TextField
                                            variant="standard"
                                            name="agt_psf_d_cur"
                                            className="w-80 "
                                            style={{ width: 150 }}
                                            value={
                                              values?.paxEdit[index]
                                                ?.disPsfPrice
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                `paxEdit.${index}.disPsfPrice`,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </span>
                                      </div>
                                      {/* <div className="flex ">
                                        {pax?.disPsf === "psf" ? (
                                          <div className="d-flex flex-column  ">
                                            <span className="text-primary">
                                              {" "}
                                              {CurrencyCode()}{" "}
                                              {pax?.agt_psf_s_cur}
                                            </span>
                                            <span>
                                              {" "}
                                              <TextField
                                                variant="standard"
                                                name="agt_psf_d_cur"
                                                className="w-80 "
                                                style={{ width: 150 }}
                                                value={
                                                  values?.paxEdit[index]
                                                    ?.agt_psf_d_cur
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `paxEdit.${index}.agt_psf_d_cur`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="d-flex flex-column ">
                                            <span className="text-primary">
                                              {" "}
                                              {CurrencyCode()}{" "}
                                              {pax?.agt_sp_s_cur}
                                            </span>
                                            <span>
                                              {" "}
                                              <TextField
                                                variant="standard"
                                                name="agt_sp_d_cur"
                                                className="w-80 "
                                                style={{ width: 150 }}
                                                value={
                                                  values?.paxEdit[index]
                                                    ?.agt_sp_d_cur
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `paxEdit.${index}.agt_sp_d_cur`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </span>
                                          </div>
                                        )}
                                      </div> */}
                                    </div>
                                  </th>

                                  <th className="d-flex flex-column gap-2 text-primary">
                                    {pax?.disPsf === "psf"
                                      ? CurrencyCode() +
                                        " " +
                                        TwoDecimalPoint(
                                          Number(
                                            values?.paxEdit[index]
                                              ?.baseFare_s_cur
                                          ) +
                                            Number(
                                              values?.paxEdit[index]
                                                ?.ttl_tax_s_cur
                                            ) +
                                            Number(
                                              values?.paxEdit[index]
                                                ?.disPsfPrice
                                            ) /
                                              pax?.roe
                                        )
                                      : CurrencyCode() +
                                        " " +
                                        TwoDecimalPoint(
                                          (Number(
                                            values?.paxEdit[index]
                                              ?.baseFare_d_cur
                                          ) +
                                            Number(
                                              values?.paxEdit[index]
                                                ?.ttl_tax_d_cur
                                            ) -
                                            Number(
                                              values?.paxEdit[index]
                                                ?.disPsfPrice
                                            )) /
                                            pax?.roe
                                        )}

                                    {pax?.disPsf === "psf" ? (
                                      <span className="text-dark">
                                        PKR{" "}
                                        {Number(
                                          values?.paxEdit[index]?.baseFare_d_cur
                                        ) +
                                          Number(
                                            values?.paxEdit[index]
                                              ?.ttl_tax_d_cur
                                          ) +
                                          Number(
                                            values?.paxEdit[index]?.disPsfPrice
                                          )}
                                      </span>
                                    ) : (
                                      <span className="text-dark">
                                        PKR{" "}
                                        {Number(
                                          values?.paxEdit[index]?.baseFare_d_cur
                                        ) +
                                          Number(
                                            values?.paxEdit[index]
                                              ?.ttl_tax_d_cur
                                          ) -
                                          Number(
                                            values?.paxEdit[index]?.disPsfPrice
                                          )}
                                      </span>
                                    )}
                                  </th>
                                </tr>
                              );
                            })}

                            <tr>
                              <td
                                colSpan="5"
                                style={{
                                  textAlign: "right",
                                  fontWeight: 700,
                                  fontSize: "20px",
                                }}
                              >
                                Grand Total
                              </td>
                              <td
                                scope="col"
                                style={{ fontWeight: 700, fontSize: "20px" }}
                                className="d-flex flex-column gap-2 text-primary"
                              >
                                {CurrencyCode()} {bsFareS}
                                <span className="text-dark">
                                  PKR {TwoDecimalPoint(bsFare)}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </FieldArray>
                    <div className="d-flex flex-column gap-2 align-items-center">
                      <div className="d-flex gap-4 justify-content-center   align-middle">
                        <p className="fs-4 text-secondry">Description:</p>
                        <TextField
                          variant="standard"
                          name="description"
                          className="w-80 "
                          style={{ width: 1000 }}
                          value={values?.description}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue(`description`, value.toUpperCase());
                          }}
                        />
                      </div>
                      <div>
                        {errors.description ? (
                          <div className="text-danger fs-5">
                            {errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="text-center mt-4">
                      <button
                        className="px-4 py-2 selectbtn_style "
                        type="submit"
                      >
                        {isLoading ? <BtnLoader /> : "Submit"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )
      ) : null}

      {/* Agent ,Full Statment*/}
      {viewStatement === "Full Statement" ? (
        <table class="table table-bordered border-dark align-paxs-center ">
          <thead
            className="bg_primary text-white "
            style={{ fontSize: "20px" }}
          >
            <tr>
              <th colSpan="5" style={{ width: 200 }}>
                Passenger
              </th>

              <th scope="col" style={{ width: 200 }}>
                Fare
              </th>
              <th scope="col" style={{ width: 200 }}>
                Taxes
              </th>
              <th scope="col" style={{ width: 200 }}>
                Total
              </th>
              <th scope="col" style={{ width: 200 }}>
                DIS/PSF
              </th>

              <th colSpan="5" style={{ width: 200 }}>
                Receivable
              </th>
            </tr>
          </thead>
          <tbody className="text-dark " style={{ color: "#000000" }}>
            {PaxData?.map((item, index) => (
              <tr>
                <th colSpan="5">
                  {item?.pax_type}-{item?.fName + " " + item?.lname}
                </th>

                <th>
                  <div className="d-flex flex-column  gap-2">
                    <span className="text-primary">
                      {" "}
                      {CurrencyCode()} {item?.baseFare_s_cur}
                    </span>
                  </div>
                </th>
                <th>
                  <div className="d-flex flex-column  gap-2">
                    <span className="text-primary">
                      {" "}
                      {CurrencyCode()}{" "}
                      {TwoDecimalPoint(
                        Number(item?.ttl_tax_s_cur) +
                          Number(item?.agt_gst_s_cur) +
                          Number(item?.agt_wht_s_cur)
                      )}
                    </span>
                  </div>
                </th>
                <th>
                  <div className="d-flex flex-column  gap-2">
                    <span className="text-primary">
                      {" "}
                      {CurrencyCode()}{" "}
                      {TwoDecimalPoint(
                        Number(item?.baseFare_s_cur) +
                          Number(item?.ttl_tax_s_cur) +
                          Number(item?.agt_gst_s_cur) +
                          Number(item?.agt_wht_s_cur)
                      )}
                    </span>
                  </div>
                </th>

                <th>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <span className="text-primary">
                          {" "}
                          {CurrencyCode()}{" "}
                          {TwoDecimalPoint(Math.abs(Number(item?.ttl_agt_pay_s_cur) - (Number(item?.baseFare_s_cur) + Number(item?.ttl_tax_s_cur))))}
                        </span>
                        <span>
                          {" "}
                          PKR{" "}
                          {TwoDecimalPoint(Math.abs(Number(item?.ttl_agt_pay_d_cur) - (Number(item?.baseFare_d_cur) + Number(item?.ttl_tax_d_cur))))}
                        </span>
                      </div>
                    </th>

                <th
                  colSpan="5"
                  className="d-flex flex-column gap-2 text-primary"
                >
                  {CurrencyCode() + " " + item?.ttl_agt_pay_s_cur}
                </th>
              </tr>
            ))}

            <tr>
              <td
                colSpan="9"
                style={{
                  textAlign: "right",
                  fontWeight: 700,
                  fontSize: "20px",
                }}
              >
                Grand Total
              </td>
              <td
                scope="col"
                style={{ fontWeight: 700, fontSize: "20px" }}
                className="d-flex flex-column gap-2 text-primary"
              >
                {CurrencyCode()} {TwoDecimalPoint(CurrencyExchange(totalPrice))}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}

      {/* Agent ,Only Total */}
      {viewStatement === "Only Total" ? (
        <table class="table table-bordered border-dark align-items-center ">
          <thead
            className="bg_primary text-white "
            style={{ fontSize: "20px" }}
          >
            <tr>
              <th colSpan="5" style={{ width: 200 }}>
                Passenger
              </th>

              <th colSpan="5" style={{ width: 200 }}>
                Receivable
              </th>
            </tr>
          </thead>
          <tbody className="text-dark " style={{ color: "#000000" }}>
            {PaxData?.map((item, index) => (
              <tr>
                <th colSpan="5">
                  {item?.pax_type}-{item?.fName + " " + item?.lname}
                </th>

                <th
                  colSpan="5"
                  className="d-flex flex-column gap-2 text-primary"
                >
                  {CurrencyCode() + " " + item?.ttl_agt_pay_s_cur}
                </th>
              </tr>
            ))}

            <tr>
              <td
                colSpan="5"
                style={{
                  textAlign: "right",
                  fontWeight: 700,
                  fontSize: "20px",
                }}
              >
                Grand Total
              </td>
              <td
                scope="col"
                style={{ fontWeight: 700, fontSize: "20px" }}
                className="d-flex flex-column gap-2 text-primary"
              >
                {CurrencyCode()} {TwoDecimalPoint(CurrencyExchange(totalPrice))}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}

      {/* Agent ,WithOut Discount/PSF */}
      {viewStatement === "WithOut Discount/PSF" ? (
        <table class="table table-bordered border-dark align-items-center ">
          <thead
            className="bg_primary text-white "
            style={{ fontSize: "20px" }}
          >
            <tr>
              <th colSpan="5" style={{ width: 200 }}>
                Passenger
              </th>

              <th scope="col" style={{ width: 200 }}>
                Fare
              </th>
              <th scope="col" style={{ width: 200 }}>
                Taxes
              </th>
              <th scope="col" style={{ width: 200 }}>
                Total
              </th>
              {/* <th scope="col" style={{ width: 200 }}>
                DIS/PSF
              </th> */}

              <th colSpan="5" style={{ width: 200 }}>
                Receivable
              </th>
            </tr>
          </thead>
          <tbody className="text-dark " style={{ color: "#000000" }}>
            {PaxData?.map((item, index) => (
              <tr>
                <th colSpan="5">
                  {item?.pax_type}-{item?.fName + " " + item?.lname}
                </th>

                <th>
                  <div className="d-flex flex-column  gap-2">
                    <span className="text-primary">
                      {" "}
                      {CurrencyCode()} {item?.baseFare_s_cur}
                    </span>
                  </div>
                </th>
                <th>
                  <div className="d-flex flex-column  gap-2">
                    <span className="text-primary">
                      {" "}
                      {CurrencyCode()}{" "}
                      {TwoDecimalPoint(
                        Number(item?.ttl_tax_s_cur) +
                          Number(item?.agt_gst_s_cur) +
                          Number(item?.agt_wht_s_cur) +
                          Number(item?.agt_psf_s_cur) -
                          Number(item?.agt_sp_s_cur)
                      )}
                    </span>
                  </div>
                </th>
                <th>
                  <div className="d-flex flex-column  gap-2">
                    <span className="text-primary">
                      {" "}
                      {CurrencyCode()}{" "}
                      {TwoDecimalPoint(
                        Number(item?.baseFare_s_cur) +
                          Number(item?.ttl_tax_s_cur) +
                          Number(item?.agt_gst_s_cur) +
                          Number(item?.agt_wht_s_cur) +
                          Number(item?.agt_psf_s_cur) -
                          Number(item?.agt_sp_s_cur)
                      )}
                    </span>
                  </div>
                </th>

                {/* <th>
                  {item?.agt_psf_d_cur > 0 ? (
                    <div className="d-flex flex-column gap-2">
                      <span className="text-primary">
                        {" "}
                        {CurrencyCode()} {item?.agt_psf_s_cur}
                      </span>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                      }}
                    >
                      <span className="text-primary">
                        {" "}
                        {CurrencyCode()} {item?.agt_sp_s_cur}
                      </span>
                    </div>
                  )}
                </th> */}

                <th
                  colSpan="5"
                  className="d-flex flex-column gap-2 text-primary"
                >
                  {CurrencyCode() + " " + item?.ttl_agt_pay_s_cur}
                </th>
              </tr>
            ))}

            <tr>
              <td
                colSpan="8"
                style={{
                  textAlign: "right",
                  fontWeight: 700,
                  fontSize: "20px",
                }}
              >
                Grand Total
              </td>
              <td
                scope="col"
                style={{ fontWeight: 700, fontSize: "20px" }}
                className="d-flex flex-column gap-2 text-primary"
              >
                {CurrencyCode()} {TwoDecimalPoint(CurrencyExchange(totalPrice))}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </>
  );
};

export default TotalPaxRecord;
