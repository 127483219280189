export const isAmadeusRefundable = (refundable_filter, amadeusItem) => {
  let obj = {
    ALL: true,
    REFUNDABLE: amadeusItem?.fareRules?.rules?.some(
      ({ category, maxPenaltyAmount }) =>
        category === "REFUND" && maxPenaltyAmount
    ),
    "NON-REFUNDABLE": amadeusItem?.fareRules?.rules?.some(
      ({ category, notApplicable }) => category === "REFUND" && notApplicable
    ),
    "PER-AIRLINE": !amadeusItem?.fareRules?.rules?.some(
      ({ category }) => category === "REFUND"
    ),
  };
  return obj[refundable_filter];
};
