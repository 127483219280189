import React, { useRef } from "react";
import moment from "moment";
import { apiUrl } from "../../Utils/configUrl";
import barcode from "../../assets/images/barcode.png";
import plan from "../../assets/images/plan.png";
import ReactToPrint from "react-to-print";
import { MdOutlineAirplanemodeActive } from "react-icons/md";
import Watermark from "./Watermark";

const FlightPdf = ({ pax, flight, baggage, firstColor }) => {
  const componentRef = useRef();
  const status=flight?.FlightBookingModels?.at(0)?.FlightSegmentRefunds?.at(0)?.seg_status
  
  return (
    <div className="flight-pdf">
      <ReactToPrint
        trigger={() => <button className="prnt_button ms-3 mt-3">Print</button>}
        content={() => componentRef.current}
      />

      <div style={{ padding: 8, minHeight: "297mm" }} ref={componentRef}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "20%" }}>
            <img
              src={`${apiUrl}/${flight?.acc_agents?.agents_comp[0]?.logo}`}
              alt="logo"
              style={{ width: "250px" }}
            />
          </div>
          <div style={{ width: "17%" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={barcode}
                alt="Barcode"
                style={{ width: 200, height: 100, paddingTop: 10 }}
              />
            </div>
            <div className="text-center" style={{ fontSize: 16 }}>
              {flight?.FlightBookingModels[0]?.vendor_locator ||
                flight?.FlightBookingModels[0]?.pnr}
            </div>
          </div>
        </div>

        <div style={{ marginTop: 15, textAlign: "center", fontSize: 20, fontWeight: "bold" }}>
          <span>{flight?.doc_status === "Pending" ? "Itinerary" : "E-Ticket"}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 12,
            backgroundColor: firstColor,
            borderRadius: "10px",
            color: "#ffffff",
            marginTop: 12,
          }}
        >
          <div style={{ width: "50%" }}>
            <div style={{ fontSize: 16, marginTop: 3 }}>Passenger</div>

            <div style={{ fontSize: 16, marginTop: 5 }}>PNR</div>

            <div style={{ fontSize: 16, marginTop: 5 }}>Date Of Birth</div>

            <div style={{ fontSize: 16, marginTop: 5 }}>Document-ID</div>

            <div style={{ fontSize: 16, marginTop: 5 }}>Ticket No</div>
          </div>
          <div style={{ width: "60%" }}>
            <div style={{ fontSize: 16, marginTop: 5 }}>{pax?.name}</div>

            <div style={{ fontSize: 16, marginTop: 5 }}>
              {flight?.FlightBookingModels[0]?.pnr}
            </div>

            <div style={{ fontSize: 16, marginTop: 5 }}>
              {moment(pax?.dob.split("T")[0]).format("ddd, DD-MMM-YY")}
            </div>

            <div style={{ fontSize: 16, marginTop: 5 }}>{pax?.pspt_cnic}</div>

            {/* <Watermark/> */}
            <div style={{ fontSize: 16, marginTop: 5 }}>{pax?.tkt_number} 
                <span className="px-2 fw-bold fs-5 text-danger">{status ==="cancel" ? <Watermark/> : ""}
                </span>
            </div>
          </div>
        </div>

        {flight?.FlightBookingModels?.at(0)?.FlightSegmentModels?.map(
          (flg, index) => {
            const currentSegBaggages = baggage?.filter(
              ({ flight_id }) => flight_id === flg?.flight_id
            );
            return (
              <div key={index} style={{ marginTop: 15 }}>
                <div
                  style={{
                    backgroundColor: firstColor,
                    color: "#ffffff",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    padding: 10,
                    fontWeight: "500",
                  }}
                >
                  <span style={{ fontSize: 16 }}>Flight {index + 1}</span>
                  <span style={{ fontSize: 16, paddingLeft: 2 }}>
                    - ({flg?.origin?.split("-")[0]}) to (
                    {flg?.destination?.split("-")[0]})
                  </span>
                  <span style={{ fontSize: 16, paddingLeft: 2 }}> {flg?.cabin_class ? "-" : ""} {flg?.cabin_class}</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div style={{ width: "30%" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>
                          AIRLINE{" "}
                        </div>
                        <div style={{ fontSize: 16, marginTop: 6 }}>
                          {flg?.flt}
                        </div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>
                          FLT NO{" "}
                        </div>
                        <div style={{ fontSize: 16, marginTop: 6 }}>
                          {flg?.flt_no}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "20%" }}>
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>
                      DEPART{" "}
                    </div>
                    <div style={{ fontSize: 16, marginTop: 6 }}>
                      {flg?.dep_time}
                    </div>
                    <div style={{ fontSize: 16, marginTop: 6 }}>
                      {flg?.origin?.split("-")[0]} -{" "}
                      {flg?.origin?.split("-")[2]}
                    </div>
                    <div style={{ fontSize: 16, marginTop: 6 }}>
                      {moment(flg?.dep_date).format("ddd, DD-MMM-YY")}
                    </div>

                    <div style={{ paddingTop: 6 }}>
                      <div style={{ fontSize: 16 }}>DEP TERMINAL </div>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      paddingLeft: 20
                    }}
                  >
                    <div
                      style={{
                        transform: "rotate(90deg)",
                        transformOrigin: "50% 50%",
                      }}
                    >
                      <MdOutlineAirplanemodeActive size={40} color={firstColor} />
                    </div>
                  </div>

                  <div style={{ width: "30%" }}>
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>
                      ARRIVE
                    </div>
                    <div style={{ fontSize: 16, marginTop: 6 }}>
                      {flg?.arr_time}
                    </div>
                    <div style={{ fontSize: 16, marginTop: 6 }}>
                      {flg?.destination?.split("-")[0]} -{" "}
                      {flg?.destination?.split("-")[2]}
                    </div>
                    <div style={{ fontSize: 16, marginTop: 6 }}>
                      {moment(flg?.arr_date).format("ddd, DD-MMM-YY")}
                    </div>

                    <div style={{ paddingTop: 6 }}>
                      <div style={{ fontSize: 16 }}>ARR TERMINAL </div>
                    </div>
                  </div>
                </div>

                {currentSegBaggages?.map(({ baggage }, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 5,
                      marginBottom: 5,
                      borderTop: "1px solid black",
                      paddingTop: 4,
                    }}
                  >
                    <div style={{ width: "30%" }}></div>
                    <div style={{ width: "16%" }}>
                      <div style={{ fontSize: 16, fontWeight: "bold" }}>
                        SEAT
                      </div>
                      <div style={{ marginTop: 4, fontSize: 16 }}>
                        System Allocated
                      </div>
                    </div>
                    <div style={{ width: "24%", paddingLeft: 15 }}>
                      <div style={{ fontSize: 16, fontWeight: "bold" }}>
                        BAG
                      </div>
                      <div style={{ marginTop: 4, fontSize: 16 }}>
                        {baggage}
                      </div>
                    </div>
                    <div style={{ width: "30%" }}>
                      <div style={{ fontSize: 16, fontWeight: "bold" }}>
                        MEAL
                      </div>
                      <div style={{ marginTop: 4, fontSize: 16 }}>
                        System Allocated
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default FlightPdf;

// import {
//   Document,
//   Page,
//   Text,
//   View,
//   Image,
//   StyleSheet,
//   PDFViewer,
// } from "@react-pdf/renderer";
// import logo from "../../assets/images/matchlesslogo.png";
// import pdfImage from "../../assets/images/plan.png";

// const FlightPdf = ({ pax, flight, baggage, firstColor }) => {
//   const [barcodeImage, setBarcodeImage] = useState(null);

//   useEffect(() => {
//     const canvas = document.createElement("canvas");
//     JsBarcode(canvas, barcodeData, { format: "CODE128" });
//     const barcodeDataURL = canvas.toDataURL();
//     setBarcodeImage(barcodeDataURL);
//   }, [barcodeData]);

//   return (
//     <Document>
//       <Page size="A4" style={{ padding: 8 }}>
//         <View
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           <View style={{ width: "20%" }}>
//             <Image src={logo} style={{ height: "40px" }} />
//           </View>
//           <View style={{ width: "17%" }}>
//             {/* {barcodeImage && <Image src={barcodeImage} />} */}
//             {flight?.FlightBookingModels[0]?.vendor_locator}
//           </View>
//         </View>

//         <View style={{ marginTop: 15 }}>
//           <Text>{pax?.tkt_number ? "" : "Itinerary / Reservation"}</Text>
//         </View>

//         <View
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             padding: 12,
//             backgroundColor: firstColor,
//             borderRadius: "10px",
//             color: "#ffffff",
//             marginTop: 12,
//           }}
//         >
//           <View style={{ width: "40%" }}>
//             <Text style={{ fontSize: 12, marginTop: 3 }}>Passenger</Text>
//             <Text style={{ fontSize: 12, marginTop: 3 }}>PNR</Text>
//             <Text style={{ fontSize: 12, marginTop: 3 }}>Date Of Birth</Text>
//             <Text style={{ fontSize: 12, marginTop: 3 }}>Document-ID</Text>
//             <Text style={{ fontSize: 12, marginTop: 3 }}>Ticket No</Text>
//           </View>
//           <View style={{ width: "60%" }}>
//             <Text style={{ fontSize: 12, marginTop: 3 }}>{pax?.name}</Text>
//             <Text style={{ fontSize: 12, marginTop: 3 }}>{flight?.FlightBookingModels[0]?.pnr}</Text>
//             <Text style={{ fontSize: 12, marginTop: 3 }}>
//               {moment(pax?.dob.split("T")[0]).format("ddd, DD-MMM-YY")}
//             </Text>
//             <Text style={{ fontSize: 12, marginTop: 3 }}>{pax?.pspt_cnic}</Text>
//             <Text style={{ fontSize: 12, marginTop: 3 }}>
//               {pax?.tkt_number}
//             </Text>
//           </View>
//         </View>

//         {flight?.FlightBookingModels?.at(0)?.FlightSegmentModels?.map((flg, index) => {
//           const currentSegBaggages = baggage?.filter(({flight_id}) => flight_id === flg?.flight_id)
//           return (
//             <View key={index} style={{ marginTop: 12 }}>
//               <View
//                 style={{
//                   backgroundColor: firstColor,
//                   color: "#ffffff",
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   borderTopLeftRadius: "10px",
//                   borderTopRightRadius: "10px",
//                   padding: 10,
//                 }}
//               >
//                 <Text style={{ fontSize: 13 }}>Flight {index + 1}</Text>
//                 <Text style={{ fontSize: 12, paddingLeft: 2 }}>
//                   - ({flg?.origin?.split("-")[0]}) to (
//                   {flg?.destination?.split("-")[0]})
//                 </Text>
//               </View>

//               <View
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   marginTop: 10,
//                   marginBottom: 10,
//                 }}
//               >
//                 <View style={{ width: "22%" }}>
//                   <View style={{ display: "flex", flexDirection: "row" }}>
//                     <View style={{ width: "50%" }}>
//                       <Text style={{ fontSize: 11 }}>AIRLINE </Text>
//                       <Text style={{ fontSize: 11, marginTop: 6 }}>
//                         {flg?.flt}
//                       </Text>
//                     </View>
//                     <View style={{ width: "50%" }}>
//                       <Text style={{ fontSize: 11 }}>FLT NO </Text>
//                       <Text style={{ fontSize: 11, marginTop: 6 }}>
//                         {flg?.flt_no}
//                       </Text>
//                     </View>
//                   </View>
//                 </View>

//                 <View style={{ width: "30%" }}>
//                   <Text style={{ fontSize: 11 }}>DEPART </Text>
//                   <Text style={{ fontSize: 12, marginTop: 6 }}>
//                     {flg?.dep_time}
//                   </Text>
//                   <Text style={{ fontSize: 11, marginTop: 6 }}>
//                     {flg?.origin?.split("-")[0]} - {flg?.origin?.split("-")[2]}
//                   </Text>
//                   <Text style={{ fontSize: 11, marginTop: 6 }}>
//                     {moment(flg?.dep_date).format("ddd, DD-MMM-YY")}
//                   </Text>

//                   <View style={{ paddingTop: 6 }}>
//                     <Text style={{ fontSize: 11 }}>DEP TERMINAL </Text>
//                   </View>
//                 </View>

//                 <View
//                   style={{
//                     width: "12%",
//                     display: "flex",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Image
//                     src={pdfImage}
//                     style={{
//                       transform: "rotate(90deg)",
//                       transformOrigin: "50% 50%",
//                       width: "50%",
//                     }}
//                   />
//                 </View>

//                 <View style={{ width: "35%" }}>
//                   <Text style={{ fontSize: 11 }}>ARRIVE</Text>
//                   <Text style={{ fontSize: 12, marginTop: 6 }}>
//                     {flg?.arr_time}
//                   </Text>
//                   <Text style={{ fontSize: 11, marginTop: 6 }}>
//                     {flg?.destination?.split("-")[0]} - {flg?.destination?.split("-")[2]}
//                   </Text>
//                   <Text style={{ fontSize: 11, marginTop: 6 }}>
//                     {moment(flg?.arr_date).format("ddd, DD-MMM-YY")}
//                   </Text>

//                   <View style={{ paddingTop: 6 }}>
//                     <Text style={{ fontSize: 11 }}>ARR TERMINAL </Text>
//                   </View>
//                 </View>
//               </View>

//               {currentSegBaggages?.map(({baggage}) => (<View
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   marginTop: 5,
//                   marginBottom: 5,
//                   borderTop: "1px",
//                   paddingTop: 4,
//                 }}
//               >
//                 <View style={{ width: "22%" }}></View>
//                 <View style={{ width: "22%" }}>
//                   <Text style={{ fontSize: 11 }}>SEAT</Text>
//                   <Text style={{ marginTop: 4, fontSize: 11 }}>
//                     System Allocated
//                   </Text>
//                 </View>
//                 <View style={{ width: "20%", paddingLeft: 15 }}>
//                   <Text style={{ fontSize: 11 }}>BAG</Text>
//                   <Text style={{ marginTop: 4, fontSize: 11 }}>{baggage}</Text>
//                 </View>
//                 <View style={{ width: "36%" }}>
//                   <Text style={{ fontSize: 11 }}>MEAL</Text>
//                   <Text style={{ marginTop: 4, fontSize: 11 }}>
//                     System Allocated
//                   </Text>
//                 </View>
//               </View>))}
//             </View>
//           );
//         })}
//       </Page>
//     </Document>
//   );
// };

// export default FlightPdf;
