// table columns
// export const columns = [
//   {
//     // index:0
//     name: "bg_id",
//     label: "agent_id",
//     options: {
//       display: false,
//     },
//   },
//   {
//     // index:1
//     name: "pricing_id",
//     label: "Pricing ID",
//     options: {
//       display: false,
//     },
//   },
//   {
//     // index:2
//     name: "com_intl_amount",
//     label: "com intl amount",
//     options: {
//       display: false,
//     },
//   },
//   {
//     // index:3
//     name: "com_dom_amount",
//     label: "",
//     options: {
//       display: false,
//     },
//   },
//   {
//     // index:4
//     name: "wop_amount",
//     label: "",
//     options: {
//       display: false,
//     },
//   },
//   {
//     // index:5
//     name: "soto_amount",
//     label: "",
//     options: {
//       display: false,
//     },
//   },
//   {
//     // index:6
//     name: "service_type",
//     label: "Services",
//   },
//   {
//     // index:7
//     name: "arr_id",
//     label: "Airline",
//     options: {
//       customBodyRender: (value, tableMeta) => {
//         let airline = addAirLinesData?.filter((d) => d.arr_id === value);
//         return <span>{airline[0]?.name}</span>;
//       },
//     },
//   },
//   {
//     // index:8
//     name: "com_intl",
//     label: "International PSF/Disc",
//     options: {
//       customBodyRender: (value, tableMeta) => {
//         let curr = agentGrpCurr.length !== 0 ? agentGrpCurr : bgCurrCode;
//         let lb = tableMeta?.rowData[9];
//         let am = tableMeta?.rowData[2] ? curr : "%";
//         return tableMeta.rowData[7] ? (
//           <span>
//             {lb === "Net Net"
//               ? lb + " / " + value
//               : lb + " / " + value + " " + am}
//           </span>
//         ) : (
//           ""
//         );
//       },
//     },
//   },
//   {
//     // index  9
//     name: "com_intl_option",
//     options: {
//       display: false,
//     },
//   },
//   {
//     // index:10
//     name: "wop",
//     label: "WO.Promo",
//     options: {
//       customBodyRender: (value, tableMeta) => {
//         let curr = agentGrpCurr.length !== 0 ? agentGrpCurr : bgCurrCode;
//         let lb = tableMeta?.rowData[11];
//         let am = tableMeta?.rowData[4] ? curr : "%";
//         return tableMeta.rowData[7] ? (
//           <span>
//             {lb === "Net Net"
//               ? lb + " / " + value
//               : lb + " / " + value + " " + am}
//           </span>
//         ) : (
//           ""
//         );
//       },
//     },
//   },
//   {
//     // index  11
//     name: "wop_option",
//     options: {
//       display: false,
//     },
//   },
//   {
//     // index:12
//     name: "com_dom",
//     label: "Domestic PSF/Disc",
//     options: {
//       customBodyRender: (value, tableMeta) => {
//         let curr = agentGrpCurr.length !== 0 ? agentGrpCurr : bgCurrCode;
//         let lb = tableMeta?.rowData[13];
//         let am = tableMeta?.rowData[3] ? curr : "%";
//         return (
//           tableMeta.rowData[7] && (
//             <span>
//               {lb === "Net Net"
//                 ? lb + " / " + value
//                 : lb + " / " + value + " " + am}
//             </span>
//           )
//         );
//       },
//     },
//   },

//   {
//     // index  13
//     name: "com_dom_option",
//     options: {
//       display: false,
//     },
//   },
//   {
//     // index:14
//     name: "soto",
//     label: "SOTO PSF/Disc",
//     options: {
//       customBodyRender: (value, tableMeta) => {
//         let curr = agentGrpCurr.length !== 0 ? agentGrpCurr : bgCurrCode;
//         let lb = tableMeta?.rowData[15];
//         let am = tableMeta?.rowData[5] ? curr : "%";
//         return (
//           tableMeta.rowData[7] && (
//             <span>
//               {lb === "Net Net"
//                 ? lb + " / " + value
//                 : lb + " / " + value + " " + am}
//             </span>
//           )
//         );
//       },
//     },
//   },
//   {
//     // index  15
//     name: "soto_option",
//     options: {
//       display: false,
//     },
//   },
//   {
//     // index:16
//     name: "wht",
//     label: "Customer WHT",
//     options: {
//       customBodyRender: (value, tableMeta) => {
//         return tableMeta.rowData[7] && (value === 0 ? value : value + " %");
//       },
//     },
//   },
//   {
//     // index:17
//     name: "gst",
//     label: "Customer GST",
//     options: {
//       customBodyRender: (value, tableMeta) => {
//         return tableMeta.rowData[7] && (value === 0 ? value : value + " %");
//       },
//     },
//   },
//   {
//     // index:18
//     name: "pax_type",
//     label: "Pax Type",
//   },
//   {
//     // index:19
//     name: "void",
//     label: "Void Charges",
//     options: {
//       customBodyRender: (value, tableMeta) => {
//         let curr = agentGrpCurr.length > 0 ? agentGrpCurr : bgCurrCode;
//         return (
//           tableMeta.rowData[7] && (value === 0 ? value : value + " " + curr)
//         );
//       },
//     },
//   },
//   {
//     // index:20
//     name: "refund",
//     label: "Refund Charges",
//     options: {
//       customBodyRender: (value, tableMeta) => {
//         let curr = agentGrpCurr.length > 0 ? agentGrpCurr : bgCurrCode;
//         return (
//           tableMeta.rowData[7] && (value === 0 ? value : value + " " + curr)
//         );
//       },
//     },
//   },
//   {
//     // index:21
//     label: "Actions",
//     name: "actions",
//     options: {
//       customBodyRender: (value, tableMeta) => {
//         const pricing_id = tableMeta?.rowData[1];
//         return (
//           <div className="d-flex">
//             {(editPermission !== undefined || user?.user_id === 1) &&
//             tableMeta?.rowData[6] === "Flight" ? (
//               <button
//                 className="btn-success rounded edit-delete-icon-button me-1"
//                 onClick={() => {
//                   window.scroll({ top: 0, behavior: "smooth" });
//                   dispatch(asyncEditAirlinePricing(pricing_id));
//                 }}
//               >
//                 <i className="fa fa-thin fa-pencil"></i>
//               </button>
//             ) : null}
//             {deletePermission !== undefined || user?.user_id === 1 ? (
//               <button
//                 className="btn-danger rounded edit-delete-icon-button "
//                 onClick={() => {
//                   setShowModel(true);
//                   setPricingId(pricing_id);
//                 }}
//               >
//                 <i className="fa fa-thin fa-trash"></i>
//               </button>
//             ) : null}
//           </div>
//         );
//       },
//     },
//   },
// ];

//  table options
export const options = {
  filter: false,
  Selection: false,
  print: false,
  download: false,
  viewColumns: false,
  displayRowCheckbox: false,
  selectableRows: "none",
};

// static array for service type Selection
export const typeArray = [
  {
    id: 1,
    name: "Flight",
  },
  {
    id: 2,
    name: "Import (PNR)",
  },
  {
    id: 3,
    name: "Visa",
  },
  {
    id: 4,
    name: "Hotel",
  },
  {
    id: 5,
    name: "Insurance",
  },
  {
    id: 6,
    name: "Transfer",
  },
  {
    id: 7,
    name: "Activity",
  },
  {
    id: 8,
    name: "Umrah",
  },
];

export const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

// static array for options dropdown
export const optionArray = ["Net Net", "PSF", "Discount"];
export const optionArrayInv = ["Net Net", "PSF", "Disc-Given"];

export const optionArraySubsidaryInv = ["NET", "PSF", "Disc-Given"];

// drop down paperProps
export const menuProps = {
  PaperProps: {
    sx: {
      width: "inherit",
    },
  },
};
