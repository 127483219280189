import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import {
  MdCheck,
  MdOutlineRestaurant,
  MdRemoveRedEye,
  MdSwapHoriz,
} from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { LightTooltip } from "./LightTooltip";
import HititItneries from "./HititItneries";
import HititConnectedFlighs from "./HititConnectedFlighs";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaSuitcase,
  FaSuitcaseRolling,
} from "react-icons/fa";
import {
  codeShareHITIT,
  ExclusiveSetors,
} from "../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import { at } from "lodash";
import {
  setExpandFlightDetails,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import BtnLoader from "../../../AppForm/BtnLoader";
import Slider from "react-slick";

function Pia({ piadata, index, isExpanded, toggleDetail, selectedFare }) {
  const [selectedTab, setSelectedTab] = useState("Fare Options");
  const [loader, setLoader] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const brandfare = piadata?.fareComponentGroupList?.fareComponentList?.length
    ? piadata?.fareComponentGroupList?.fareComponentList
    : [piadata?.fareComponentGroupList?.fareComponentList];

  const brandedFare = selectedFare ? [selectedFare?.fare] : brandfare;


  const CustomPrevArrow = (props) => {
    if (brandedFare?.length <= 4) {
      return null;
    }
    return (
      <div
        onClick={props.onClick}
        className="position-absolute top-50 translate-middle-y cursor-pointer custom-left-position custom-text-color"
      >
        <FaChevronCircleLeft size={30} />
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    if (brandedFare?.length <= 4) {
      return null;
    }
    return (
      <div
        onClick={props.onClick}
        className="position-absolute top-50 translate-middle-y cursor-pointer custom-right-position custom-text-color custom-z-index"
      >
        <FaChevronCircleRight size={30} />
      </div>
    );
  };

  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: brandedFare?.length > 3 ? 4 : brandedFare?.length,
    slidesToScroll: 1,
    centerMode: false,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: brandedFare?.length > 3 ? 4 : brandedFare?.length,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const passInfo = brandfare?.at(0)?.passengerFareInfoList?.length
    ? brandfare?.at(0)?.passengerFareInfoList
    : [brandfare?.at(0)?.passengerFareInfoList];

  const selectedSegment = brandedFare?.at(0)?.passengerFareInfoList?.length
    ? brandedFare?.at(0)?.passengerFareInfoList
    : [brandedFare?.at(0)?.passengerFareInfoList];

  const passInfolist = selectedFare ? selectedSegment : passInfo;

  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  // get values from local storage
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));

  const currency = JSON.parse(localStorage.getItem("currency"));
  // airline pricing

  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.name === "PIA-HITIT"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it?.name === "PIA-HITIT");

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  // calculate duration
  const timeDuration = (time1, time2) => {
    if (!time1 || !time2) {
      return null;
    }
    const depMoment = moment(time1);
    const arrMoment = moment(time2);
    const duration = moment.duration(arrMoment.diff(depMoment));
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const boundList = piadata?.fareComponentGroupList?.boundList || [];

  // Flatten all availFlightSegmentList arrays into a single array
  const avblfltSeg = boundList.flatMap((bound) =>
    Array.isArray(bound.availFlightSegmentList)
      ? bound.availFlightSegmentList
      : [bound.availFlightSegmentList]
  );

  const totalPaxFare = (fare) => {
    const newFare = Array.isArray(fare) ? fare : [fare];

    const fareinfoList = newFare?.at(0)?.fareInfoList?.length
      ? newFare?.at(0)?.fareInfoList
      : [newFare?.at(0)?.fareInfoList];
    let total =
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(0)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(0)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(0)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.total_fare * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(1)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(1)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(1)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.total_fare * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(2)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(2)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(2)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.total_fare * searchValues?.infantNo || 0);

    return `${CurrencyCode()} ${TwoDecimalPoint(total)}`;
  };


  const discountPrice = (fare) => {
    const newFare = Array.isArray(fare) ? fare : [fare];

    const fareinfoList = newFare?.at(0)?.fareInfoList?.length
      ? newFare?.at(0)?.fareInfoList
      : [newFare?.at(0)?.fareInfoList];
    let total =
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(0)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(0)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(0)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.sp * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(1)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(1)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(1)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.sp * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(2)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(2)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(2)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.sp * searchValues?.infantNo || 0);

    if (total > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
    }

  };


  const singlePaxFare = (fare, pax) => {
    let total =
      pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(Math.round(fare?.pricingInfo?.equivBaseFare?.value))
        ),
        Number(
          CurrencyExchange(
            Math.round(
              fare?.pricingInfo?.totalFare?.amount?.value -
              fare?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fare?.fareInfoList[0]?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.total_fare * pax || 0;

    return `${CurrencyCode()} ${TwoDecimalPoint(total)}`;
  };

  const getPaxTaxes = (taxes) => {
    return taxes?.map((tax) => ({
      code: tax?.taxCode,
      amount: ~~tax?.taxAmount?.value,
    }));
  };

  const handleBooking = (fareInfo, segment, fareIndex) => {
    const fare = fareInfo?.passengerFareInfoList?.length
      ? fareInfo?.passengerFareInfoList
      : [fareInfo?.passengerFareInfoList];

    let bookingBody = {
      flight: piadata,
      fare: fareInfo,
      logo: "HITIT-MULTI",
      code: "PK",
      price: CurrencyExchangeToPKR(totalPaxFare(fare)?.split(" ")?.at(1) || 0),
      ttl_agent_fare: TwoDecimalPoint(
        CurrencyExchangeToPKR(totalPaxFare(fare)?.split(" ")?.at(1) || 0)
      ),
      ttlagent_curr_fare: Number(totalPaxFare(fare)?.split(" ")?.at(1) || 0),
      ttl_supplier_fare: 0,
      ttlsup_curr_fare: 0,

      adultBaseFare: fare?.at(0)?.pricingInfo?.equivBaseFare?.value,
      adultTaxes:
        Number(fare?.at(0)?.pricingInfo?.totalFare?.amount?.value) -
        Number(fare?.at(0)?.pricingInfo?.equivBaseFare?.value),
      childBaseFare:
        searchValues?.childCount === 0
          ? 0
          : fare?.at(1)?.pricingInfo?.equivBaseFare?.value,
      childTaxes:
        searchValues?.childCount === 0
          ? 0
          : Number(fare?.at(1)?.pricingInfo?.totalFare?.amount?.value) -
          Number(fare?.at(1)?.pricingInfo?.equivBaseFare?.value),
      infantBaseFare:
        searchValues?.infantNo === 0
          ? 0
          : fare?.at(fare?.length - 1)?.pricingInfo?.equivBaseFare?.value,
      infantTaxes:
        searchValues?.infantNo === 0
          ? 0
          : Number(
            fare?.at(fare?.length - 1)?.pricingInfo?.totalFare?.amount?.value
          ) -
          Number(
            fare?.at(fare?.length - 1)?.pricingInfo?.equivBaseFare?.value
          ),

      adlt_ttl_d_curr: CurrencyExchangeToPKR(
        Number(singlePaxFare(fare?.at(0), 1)?.split(" ")?.at(1))
      ),
      chld_ttl_d_curr:
        searchValues?.childCount === 0
          ? 0
          : CurrencyExchangeToPKR(
            Number(singlePaxFare(fare?.at(1), 1)?.split(" ")?.at(1))
          ),
      inft_ttl_d_curr:
        searchValues?.infantNo === 0
          ? 0
          : CurrencyExchangeToPKR(
            Number(
              singlePaxFare(fare?.at(fare?.passengerFareInfoList - 1), 1)
                ?.split(" ")
                ?.at(1)
            )
          ),

      baggageName: fare?.at(0)?.fareInfoList[0]?.fareGroupName,
      flightNumber: segment?.at(0)?.flightSegment?.flightNumber,
      depDate: segment
        ?.at(0)
        ?.flightSegment?.departureDateTime?.split("T")
        ?.at(0),
      depTime: segment
        ?.at(0)
        ?.flightSegment?.departureDateTime?.split("T")
        ?.at(1)
        ?.slice(0, 5),
      arrDate: segment
        ?.at(segment?.length - 1)
        ?.flightSegment?.arrivalDateTime?.split("T")
        ?.at(0),
      arrTime: segment
        ?.at(segment?.length - 1)
        ?.flightSegment?.arrivalDateTime?.split("T")
        ?.at(1)
        ?.slice(0, 5),
      flightDuration: timeDuration(
        segment?.at(0)?.flightSegment?.departureDateTime,
        segment?.at(segment?.length - 1)?.flightSegment?.arrivalDateTime
      ),
      ADLT_TAXES: getPaxTaxes(fare?.at(0)?.pricingInfo?.taxes?.taxList),
      CHLD_TAXES:
        searchValues?.childCount === 0
          ? []
          : getPaxTaxes(fare?.at(1)?.pricingInfo?.taxes?.taxList),
      INFT_TAXES:
        searchValues?.infantNo === 0
          ? []
          : getPaxTaxes(
            fare?.at(fare?.length - 1)?.pricingInfo?.taxes?.taxList
          ),

      ADLT_FARE: fare?.at(0),
      CHLD_FARE: searchValues?.childCount === 0 ? {} : fare?.at(1),
      INFT_FARE: searchValues?.infantNo === 0 ? {} : fare?.at(fare?.length - 1),

      booking: {
        gds: "HITIT",
        pnr: "",
        agent_name: piadata?.agent_name,
        segments: segment?.map(
          ({ flightSegment, ...segment }, segmentIndex) => {
            const requiredFare = fare?.at(0);

            const baggage = requiredFare?.fareInfoList[segmentIndex]?.fareBaggageAllowance?.maxAllowedWeight
              ? `${requiredFare?.fareInfoList[segmentIndex]?.fareBaggageAllowance?.maxAllowedWeight?.weight} KG`
              : `${requiredFare?.fareInfoList[segmentIndex]?.fareBaggageAllowance?.maxAllowedPieces} pieces ${requiredFare?.fareInfoList[segmentIndex]?.fareBaggageAllowance?.pieceDefinitions?.split(" ")?.at(1) || ''}`


            const getDate = (dateTime) => dateTime?.split("T")?.at(0);
            const getTime = (dateTime) =>
              dateTime?.split("T")?.at(1)?.slice(0, 5);
            return {
              boundList: {
                ...segment,
                flightSegment,
                bookingClassList: segment?.bookingClassList?.length
                  ? segment?.bookingClassList?.at(fareIndex)
                  : segment?.bookingClassList,
              },
              flight: "MT",
              status: "",
              fare: requiredFare,
              baggage: baggage,
              departure_date: getDate(flightSegment?.departureDateTime),
              departure_time: getTime(flightSegment?.departureDateTime),
              arrival_date: getDate(flightSegment?.arrivalDateTime),
              arrival_time: getTime(flightSegment?.arrivalDateTime),
              origin_terminal: "",
              desti_terminal: "",
              flight_number: flightSegment?.flightNumber,
              res_book_desig_code:
                requiredFare?.fareInfoList[segmentIndex]?.resBookDesigCode,
              des_location:
                flightSegment?.arrivalAirport?.cityInfo?.city?.locationCode +
                "- -" +
                flightSegment?.arrivalAirport?.cityInfo?.city?.locationName,
              dest_code:
                flightSegment?.arrivalAirport?.cityInfo?.city?.locationCode,
              marketing_airline: "PK",
              equipment_code: flightSegment?.equipment?.airEquipType,
              operatingairline_code: flightSegment?.operatingAirline ? flightSegment?.operatingAirline?.code : flightSegment?.airline?.code,
              orig_location:
                flightSegment?.departureAirport?.cityInfo?.city?.locationCode +
                "- -" +
                flightSegment?.departureAirport?.cityInfo?.city?.locationName,
              orig_code:
                flightSegment?.departureAirport?.cityInfo?.city?.locationCode,
              marriage_grp: "",
              cabin_class: requiredFare?.fareInfoList[segmentIndex]?.fareGroupName === "NILBAG" ? "ECO LIGHT" : requiredFare?.fareInfoList[segmentIndex]?.fareGroupName,
              segment_type: getSectorsName(
                flightSegment?.departureAirport?.cityInfo?.city?.locationCode,
                flightSegment?.arrivalAirport?.cityInfo?.city?.locationCode
              ),
            };
          }
        ),
        tp_ur_code: "",
        platting_carrier: "PK",
        tp_ssr_verssion: 0,
        tp_trace_id: "",
      },
    };

    dispatch(setselectedFlight(bookingBody));

    setTimeout(() => {
      navigate("/check-out");
      dispatch(setExpandFlightDetails(null));
    }, 2000);
  };

  return (
    <>
      <div className="search_engin_result_box flight_card_shadow bg-white">
        <div
          className="d-flex cursorpointer shadow"
          onClick={() => {
            toggleDetail(index);
          }}
        >
          <div style={{ width: "70%" }}>
            {piadata?.fareComponentGroupList?.boundList?.map(
              (seg, index, array) => {
                return (
                  <div
                    key={index}
                    style={{
                      borderBottom:
                        index === array?.length - 1
                          ? "none"
                          : "1px solid lightgray",
                    }}
                  >
                    <HititItneries
                      segment={
                        seg?.availFlightSegmentList?.length
                          ? seg?.availFlightSegmentList
                          : [seg?.availFlightSegmentList]
                      }
                      timeDuration={timeDuration}
                      array={array}
                      index={index}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className="left-shadow" style={{ width: "30%" }}>
            {piadata?.fareComponentGroupList?.fareComponentList ? (
              <div className="d-flex flex-column p-2">
                <Box display={"flex"} alignItems={"center"} pt={2}>
                  <div className="color_primary">
                    <FaSuitcaseRolling size={25} />
                  </div>
                  <h6 className="pt-2 ps-2">Hand Carry: 7KG</h6>
                </Box>

                <Box display={"flex"} alignItems={"start"} pt={2}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    width={125}
                    whiteSpace={"nowrap"}
                  >
                    <Box className="color_primary">
                      <FaSuitcase size={22} />
                    </Box>
                    <h6 className="pt-2 ps-2">Check Bag:</h6>
                  </Box>
                  <Box width={175} pl={0.5} pt={1}>
                    <div key={index}>
                      {avblfltSeg?.map((segmnt, segidx) => {
                        return (
                          <div key={segidx} className="d-flex">
                            <h6
                              className="w-50"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {`${segmnt?.flightSegment?.departureAirport?.locationCode} - ${segmnt?.flightSegment?.arrivalAirport?.locationCode} `}
                            </h6>
                            <h6
                              className="ps-2 w-50"
                              style={{ whiteSpace: "nowrap" }}
                            >{passInfolist?.at(0)?.fareInfoList[segidx]?.fareBaggageAllowance?.maxAllowedWeight
                              ? `${passInfolist?.at(0)?.fareInfoList[segidx]?.fareBaggageAllowance?.maxAllowedWeight?.weight} KG`
                              : `${passInfolist?.at(0)?.fareInfoList[segidx]?.fareBaggageAllowance?.maxAllowedPieces} pieces ${passInfolist?.at(0)?.fareInfoList[segidx]?.fareBaggageAllowance?.pieceDefinitions?.split(" ")?.at(1) || ''}`
                              }</h6>
                          </div>
                        );
                      })}
                    </div>
                  </Box>
                </Box>
                <Box py={2}>
                  <RefundExchange />
                </Box>

                <Box pt={1} display={"flex"} alignItems={"center"}>
                  <MdSwapHoriz size={30} className="color_primary" />
                  <h6 className="pt-1 ps-2 text-success">Refundable</h6>
                </Box>

                <div className="pt-4">
                  <Divider />
                </div>

                <div className="d-flex flex-column align-items-center">
                  <h6 className="pt-2 text-danger">{discountPrice(brandedFare?.at(0)?.passengerFareInfoList)}</h6>
                  <h4 className="pt-1">
                    {`${totalPaxFare(
                      brandedFare?.at(0)?.passengerFareInfoList
                    )}`}
                    <img
                      src="/NEW_LOGO/hitit.png"
                      alt=""
                      width={40}
                      height={40}
                      className="ms-1"
                    />
                  </h4>

                  <button
                    onClick={() => toggleDetail(index)}
                    className="button_style w-75 mt-4"
                    type="button"
                  >
                    <div>
                      <MenuOpenIcon className="me-1" />
                      Flight Detail
                    </div>
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <h6 className="text-danger">Sold Out</h6>
                <button
                  onClick={() => toggleDetail(index)}
                  className="button_style w-75 mt-4"
                  type="button"
                >
                  <div>
                    <MenuOpenIcon className="me-1" />
                    Flight Detail
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>

        {isExpanded ? (
          <>
            <div className="p-3">
              <div className="d-flex mb-4 mt-2 flight_details_tabs">
                {["Fare Options", "Flight Details"].map((item, index) => {
                  const active = selectedTab === item;
                  return (
                    <button
                      key={index}
                      onClick={() => setSelectedTab(item)}
                      className={active ? "active" : ""}
                    >
                      {item}
                    </button>
                  );
                })}
              </div>

              <div>
                {selectedTab === "Flight Details" && (
                  <div className="row rounded-2 search_engin_result_box bg-white p-4">
                    {piadata?.fareComponentGroupList?.boundList?.map(
                      (bounditem, boundindex, boundArray) => {
                        return (
                          <div>
                            <HititConnectedFlighs
                              segment={
                                bounditem?.availFlightSegmentList?.length
                                  ? bounditem?.availFlightSegmentList
                                  : [bounditem?.availFlightSegmentList]
                              }
                              index={boundindex}
                              boundArray={boundArray}
                              timeDuration={timeDuration}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>

              <div>
                {selectedTab === "Fare Options" && (
                  <>
                    <div className="row rounded-2 search_engin_result_box bg-white p-4">
                      <Slider {...settings}>
                        {brandedFare?.map((brandedfare, index) => {
                          const passangerFareInfoList = brandedfare
                            ?.passengerFareInfoList?.length
                            ? brandedfare?.passengerFareInfoList
                            : [brandedfare?.passengerFareInfoList];
                          return (
                            <div className="col-3" key={index}>
                              <div className="d-flex flex-column">
                                <div className="fs-5 theme-text-color mb-4 fw-bold">
                                  {passangerFareInfoList
                                    ?.at(0)
                                    ?.fareInfoList?.map(
                                      (infolist, infoIndex) => {
                                        return (
                                          <div
                                            key={infoIndex}
                                            className="pt-2 d-flex aligns-items-center"
                                          >
                                            <div className="w-50">
                                              {`${avblfltSeg[infoIndex]?.flightSegment?.departureAirport?.locationCode} - ${avblfltSeg[infoIndex]?.flightSegment?.arrivalAirport?.locationCode}: `}
                                            </div>{" "}
                                            <div className="w-50">
                                              {infolist?.fareGroupName}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  pt={2}
                                >
                                  <div className="color_primary">
                                    <FaSuitcaseRolling size={25} />
                                  </div>
                                  <h6 className="pt-2 ps-2">Hand Carry: 7KG</h6>
                                </Box>

                                <Box
                                  display={"flex"}
                                  alignItems={"start"}
                                  pt={2}
                                >
                                  <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    width={125}
                                  >
                                    <Box className="color_primary">
                                      <FaSuitcase size={22} />
                                    </Box>
                                    <h6 className="pt-2 ps-2">Check Bag:</h6>
                                  </Box>
                                  <Box pt={1}>
                                    {passangerFareInfoList
                                      ?.at(0)
                                      ?.fareInfoList?.map(
                                        (infolist, infoIdx) => {
                                          return (
                                            <h6
                                              key={infoIdx}
                                            >{infolist?.fareBaggageAllowance?.maxAllowedWeight
                                              ? `${infolist?.fareBaggageAllowance?.maxAllowedWeight?.weight} KG`
                                              : `${infolist?.fareBaggageAllowance?.maxAllowedPieces} pieces ${infolist?.fareBaggageAllowance?.pieceDefinitions?.split(" ")?.at(1) || ''}`
                                              }</h6>


                                          );
                                        }
                                      )}
                                  </Box>
                                </Box>

                                <Box display={"flex"}
                                  alignItems={"center"}
                                  pt={2}
                                >
                                  <MdOutlineRestaurant
                                    size={25}
                                    className="color_primary"
                                  />
                                  <h6 className="ps-2 pt-2">Meal: Allowed</h6>
                                </Box>

                                <Box display={"flex"}
                                  alignItems={"center"}
                                  pt={2}>
                                  <Box>
                                    <CgMenuGridR size={25} className="color_primary" />
                                  </Box>
                                  <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                                </Box>

                                <Box
                                  pt={1}
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <MdSwapHoriz
                                    size={30}
                                    className="color_primary"
                                  />
                                  <h6 className="pt-1 ps-2 text-success">
                                    Refundable
                                  </h6>
                                </Box>

                                <div className="py-3">
                                  <RefundExchange />
                                </div>

                                <LightTooltip
                                  title={
                                    <div className="p-3">
                                      <div className="d-flex justify-content-between align-items-end">
                                        <div className="d-flex me-4">
                                          <h5 style={{ minWidth: "3rem" }}>
                                            ADT
                                          </h5>
                                          <h5>* {searchValues?.adultsCount}</h5>
                                        </div>
                                        <h6 style={{ minWidth: "7rem" }}>
                                          {singlePaxFare(
                                            passangerFareInfoList?.at(0),
                                            1
                                          )}
                                        </h6>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-end my-2">
                                        <div className="d-flex">
                                          <h5 style={{ minWidth: "3rem" }}>
                                            CHD
                                          </h5>
                                          <h5>* {searchValues?.childCount}</h5>
                                        </div>
                                        <h6 style={{ minWidth: "7rem" }}>
                                          {singlePaxFare(
                                            passangerFareInfoList?.at(1),
                                            searchValues?.childCount > 0 ? 1 : 0
                                          )}
                                        </h6>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-end">
                                        <div className="d-flex">
                                          <h5 style={{ minWidth: "3rem" }}>
                                            INF
                                          </h5>
                                          <h5>* {searchValues?.infantNo}</h5>
                                        </div>
                                        <h6 style={{ minWidth: "7rem" }}>
                                          {singlePaxFare(
                                            passangerFareInfoList?.at(
                                              passangerFareInfoList?.length - 1
                                            ),
                                            searchValues?.infantNo > 0 ? 1 : 0
                                          )}
                                        </h6>
                                      </div>
                                    </div>
                                  }
                                  placement="top"
                                >
                                  <h6 className="text-danger mt-1 ms-4">{discountPrice(brandedfare?.passengerFareInfoList)}</h6>
                                  <button
                                    onClick={() => {
                                      if (!selectedFare) {
                                        handleBooking(
                                          brandedfare,
                                          avblfltSeg,
                                          index
                                        );
                                        setLoader(index);
                                      }
                                    }}
                                    className="button_style w-75 mt-1"
                                    style={{ whiteSpace: "nowrap" }}
                                    type="button"
                                    disabled={
                                      !piadata?.fareComponentGroupList
                                        ?.fareComponentList
                                    }
                                  >
                                    {loader === index ? (
                                      <BtnLoader />
                                    ) : (
                                      <>
                                        {totalPaxFare(
                                          brandedfare?.passengerFareInfoList
                                        )}
                                      </>
                                    )}
                                  </button>
                                </LightTooltip>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default Pia;

// fare total price button component and tooltip
function FareTotalPriceButton({ fare }) {
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  // local storage
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const currency = JSON.parse(localStorage.getItem("currency"));

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationDep;

  const passengerFareInfoList = Array.isArray(fare?.passengerFareInfoList)
    ? fare?.passengerFareInfoList
    : [fare?.passengerFareInfoList];
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "PK"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "PK");

  return (
    <LightTooltip
      title={
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-end">
            <div className="d-flex me-4">
              <h5 style={{ minWidth: "3rem" }}>ADT</h5>
              <h5>* {searchValues?.adultsCount}</h5>
            </div>
            <h6 style={{ minWidth: "7rem" }}>
              {CurrencyExchange(
                pricingairline(
                  airline,
                  pricing,
                  Math.round(
                    passengerFareInfoList[0]?.pricingInfo?.equivBaseFare?.value
                  ),
                  Math.round(
                    ~~passengerFareInfoList?.at(0)?.pricingInfo?.totalFare
                      ?.amount?.value -
                    ~~passengerFareInfoList?.at(0)?.pricingInfo?.equivBaseFare
                      ?.value
                  ),
                  getSectorsName(locDeparture, locArrival)
                )?.total_fare * searchValues?.adultsCount || 0,
                currency
              )}
            </h6>
          </div>
          <div className="d-flex justify-content-between align-items-end my-2">
            <div className="d-flex">
              <h5 style={{ minWidth: "3rem" }}>CHD</h5>
              <h5>* {searchValues?.childCount}</h5>
            </div>
            <h6 style={{ minWidth: "7rem" }}>
              {CurrencyExchange(
                pricingairline(
                  airline,
                  pricing,
                  Math.round(
                    passengerFareInfoList[1]?.pricingInfo?.equivBaseFare?.value
                  ),
                  Math.round(
                    ~~passengerFareInfoList?.at(1)?.pricingInfo?.totalFare
                      ?.amount?.value -
                    ~~passengerFareInfoList?.at(1)?.pricingInfo?.equivBaseFare
                      ?.value
                  ),
                  getSectorsName(locDeparture, locArrival)
                )?.total_fare * searchValues?.childCount || 0,
                currency
              )}
            </h6>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <div className="d-flex">
              <h5 style={{ minWidth: "3rem" }}>INF</h5>
              <h5>* {searchValues?.infantNo}</h5>
            </div>
            <h6 style={{ minWidth: "7rem" }}>
              {CurrencyExchange(
                pricingairline(
                  airline,
                  pricing,
                  Math.round(
                    passengerFareInfoList[2]?.pricingInfo?.equivBaseFare?.value
                  ),
                  Math.round(
                    ~~passengerFareInfoList?.at(2)?.pricingInfo?.totalFare
                      ?.amount?.value -
                    ~~passengerFareInfoList?.at(2)?.pricingInfo?.equivBaseFare
                      ?.value
                  ),
                  getSectorsName(locDeparture, locArrival)
                )?.total_fare * searchValues?.infantNo || 0,
                currency
              )}
            </h6>
          </div>
        </div>
      }
      placement="top"
    >
      <button type="button" className="button_style px-4">
        {CurrencyExchange(
          (pricingairline(
            airline,
            pricing,
            Math.round(
              passengerFareInfoList[0]?.pricingInfo?.equivBaseFare?.value
            ),
            Math.round(
              ~~passengerFareInfoList?.at(0)?.pricingInfo?.totalFare?.amount
                ?.value -
              ~~passengerFareInfoList?.at(0)?.pricingInfo?.equivBaseFare
                ?.value
            ),
            getSectorsName(locDeparture, locArrival)
          )?.total_fare * searchValues?.adultsCount || 0) +
          (pricingairline(
            airline,
            pricing,
            Math.round(
              passengerFareInfoList[1]?.pricingInfo?.equivBaseFare?.value
            ),
            Math.round(
              ~~passengerFareInfoList?.at(1)?.pricingInfo?.totalFare?.amount
                ?.value -
              ~~passengerFareInfoList?.at(1)?.pricingInfo?.equivBaseFare
                ?.value
            ),
            getSectorsName(locDeparture, locArrival)
          )?.total_fare * searchValues?.childCount || 0) +
          (pricingairline(
            airline,
            pricing,
            Math.round(
              passengerFareInfoList[2]?.pricingInfo?.equivBaseFare?.value
            ),
            Math.round(
              ~~passengerFareInfoList?.at(2)?.pricingInfo?.totalFare?.amount
                ?.value -
              ~~passengerFareInfoList?.at(2)?.pricingInfo?.equivBaseFare
                ?.value
            ),
            getSectorsName(locDeparture, locArrival)
          )?.total_fare * searchValues?.infantNo || 0),
          currency
        )}
      </button>
    </LightTooltip>
  );
}

// refund and exchange
const RefundExchange = ({ currency }) => {
  return (
    <LightTooltip
      title={
        <div>
          <h4>Refund & Exchange</h4>
          <div className="text-danger font-italic">
            for further clarification contact with Airline Or Call our support
            team.
          </div>
          <div className="text-danger font-italic mt-2">
            After 25 days of departure ticket is Non-Refundable.
          </div>

          <h6 className="mt-3">
            contact with airline or call our support team
          </h6>

          {/* <div className="d-flex pt-3">
            <div>
              <h4>Conditions</h4>
              <h6>More than 48 hours to departure</h6>
              <h6>Within 48 hours To 4 hours to Departure</h6>
              <h6>Less than 4 hours after departure</h6>
              <h6>No show</h6>
            </div>
            <div className="mx-4">
              <h4>COB</h4>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2000)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
            </div>
            <div>
              <h4>Refund</h4>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
            </div>
          </div> */}
        </div>
      }
      placement="top"
    >
      <Box display={"flex"} alignItems={"center"}>
        <MdRemoveRedEye size={30} className="color_primary" />
        <h6 className="ps-2 pt-2">Refund & Exchange</h6>
      </Box>
    </LightTooltip>
  );
};
