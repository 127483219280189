import React, { useEffect, useRef, useState } from "react";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import moment from "moment";
import {
  TwoDecimalPoint,
  timeDuration,
} from "../../../../Utils/FixedTwoDecimal";
import {
  MdOutlineRestaurant,
  MdSwapHoriz,
  MdCheck,
  MdRemoveRedEye,
  MdOutlineFlightTakeoff,
  MdOutlineFlightLand,
  MdFlight,
} from "react-icons/md";
import { BsBagCheck, BsPerson, BsHandbag } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";

import { pricingairline } from "../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { useDispatch, useSelector } from "react-redux";
import { LightTooltip } from "./LightTooltip";
import {
  set_Airline_filter_drp,
  set_All_Prices_Array,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { ExclusiveSetors } from "../../../../Utils/exclusiveSector";
import { NODEURL } from "../../../../redux/features/ApiConfig";
import BtnLoader from "../../../AppForm/BtnLoader";

function FlyJinnahCard({
  data,
  index,
  isExpanded,
  toggleDetail,
  selectedFare,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const [showContent, setShowContent] = useState("Fare Options");
  const [active, setActive] = useState("showFares");
  const [loading, setIsLoading] = useState(false);

  // const { selectedFlight, flyJinnahFares } = useSelector(
  //   (state) => state.FlightSearch
  // );
  const [flyJinnahFares, setFlyJinnahFares] = useState({});

  ////////////////////////
  let token = flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["$"]?.["EchoToken"];
  let TransactionIdentifier =
    flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["$"]?.["TransactionIdentifier"];

  ///////////////////////
  const arr =
    flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["ns1:PricedItineraries"]?.[
      "ns1:PricedItinerary"
    ]?.["ns1:AirItinerary"]?.["ns1:OriginDestinationOptions"]?.[
      "ns1:AABundledServiceExt"
    ]?.["ns1:bundledService"];
  let arrr = Array.isArray(arr) ? arr : [arr];
  const faresArray = arr
    ? arrr?.filter(
        (fare) => fare?.["ns1:bundledServiceName"] !== "SaverFare CMB"
      )
    : [];

  //booking body
  const segments =
    flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["ns1:PricedItineraries"]?.[
      "ns1:PricedItinerary"
    ]?.["ns1:AirItinerary"]?.["ns1:OriginDestinationOptions"];
  const ptcBreakdowns =
    flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["ns1:PricedItineraries"]?.[
      "ns1:PricedItinerary"
    ]?.["ns1:AirItineraryPricingInfo"]?.["ns1:PTC_FareBreakdowns"]?.[
      "ns1:PTC_FareBreakdown"
    ];

  let rpH;

  if (Array.isArray(segments?.["ns1:OriginDestinationOption"])) {
    rpH = segments?.["ns1:OriginDestinationOption"].map(
      (segd) => segd?.["ns1:FlightSegment"]?.$?.["RPH"]
    );
  } else if (segments) {
    rpH =
      segments?.["ns1:OriginDestinationOption"]?.["ns1:FlightSegment"]?.$?.[
        "RPH"
      ];
  }

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  //local storage data
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "9P"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "9P");

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;
  const flightSegments = data?.flightSegments;
  /// calculate flight duration
  const arrivalDateTime = new Date(
    flightSegments[flightSegments?.length - 1]?.arrivalDateTimeLocal
  );
  const departureDateTime = new Date(flightSegments[0]?.departureDateTimeLocal);
  const durationInMilliseconds = arrivalDateTime - departureDateTime;
  // Convert the duration to hours and minutes
  const hours = Math.floor(durationInMilliseconds / 3600000); // 1 hour = 3600000 milliseconds
  const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds
  // Format hours and minutes with leading zeros
  const flightDuration = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;
  ///////////////////////////////////////
  const origin = flightSegments?.[0]?.origin?.airportCode;
  const destination = flightSegments?.[0]?.destination?.airportCode;
  /////////////////////////

  const lowestTotalFare = (farePrice) => {
    const price =
      flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["ns1:PricedItineraries"]?.[
        "ns1:PricedItinerary"
      ]?.["ns1:AirItineraryPricingInfo"]?.["ns1:ItinTotalFare"];
    let baseFare = price?.["ns1:BaseFare"]?.$?.Amount;
    let taxes =
      ~~price?.["ns1:TotalFare"]?.$?.Amount -
      ~~price?.["ns1:BaseFare"]?.$?.Amount;

    let newFare = pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(baseFare)),
      Number(CurrencyExchange(taxes)),
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(searchValues, pricing)
    )?.total_fare;
    return `${CurrencyCode()} ${TwoDecimalPoint(
      newFare + CurrencyExchange(farePrice)
    )}`;
  };

  //discount
  const DiscountPrice = (faree) => {
    let paxFare = Array.isArray(faree) ? faree : [faree];

    const getBaseFareAndTaxes = (fare) => ({
      baseFare: Number(
        fare?.["ns1:PassengerFare"]?.["ns1:BaseFare"]?.$?.Amount
      ),
      taxes:
        Number(fare?.["ns1:PassengerFare"]?.["ns1:TotalFare"]?.$?.Amount) -
        Number(fare?.["ns1:PassengerFare"]?.["ns1:BaseFare"]?.$?.Amount),
    });
    const getNoFare = () => ({ baseFare: 0, taxes: 0 });

    let adultFare = getBaseFareAndTaxes(paxFare?.at(0));
    let childFare =
      searchValues?.childCount > 0
        ? getBaseFareAndTaxes(paxFare?.at(1))
        : getNoFare();
    let infantFare =
      searchValues?.infantNo > 0
        ? getBaseFareAndTaxes(paxFare?.at(paxFare?.length - 1))
        : getNoFare();

    let newFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(adultFare?.baseFare)),
        Number(CurrencyExchange(adultFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp *
        searchValues?.adultsCount +
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(childFare?.baseFare)),
        Number(CurrencyExchange(childFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp *
        searchValues?.childCount +
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(infantFare?.baseFare)),
        Number(CurrencyExchange(infantFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp *
        searchValues?.infantNo;

    if (newFare > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
    }
    return "";
  };

  // get Single

  // //////////////// get single pax fare
  const singlePaxFare = (fare, pax, farePrice) => {
    farePrice = farePrice * pax;
    const totalFare = fare?.["ns1:PassengerFare"]?.["ns1:TotalFare"]?.$?.Amount;
    const basicFare = fare?.["ns1:PassengerFare"]?.["ns1:BaseFare"]?.$?.Amount;
    const taxes = Number(totalFare) - Number(basicFare);
    let newFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(basicFare))),
        Number(CurrencyExchange(Number(taxes))),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * pax || 0;
    return TwoDecimalPoint(newFare + CurrencyExchange(farePrice));
  };

  const getPaxTaxes = (singlePax) => {
    let taxes = singlePax?.["ns1:PassengerFare"]?.["ns1:Taxes"]?.["ns1:Tax"];
    let fees = singlePax?.["ns1:PassengerFare"]?.["ns1:Fees"]?.["ns1:Fee"];
    taxes = Array.isArray(taxes) ? taxes : [taxes];
    fees = fees ? (Array.isArray(fees) ? fees : [fees]) : [];

    const getAllTaxes = taxes.map((tax) => ({
      code: tax?.$?.TaxCode,
      amount: ~~tax?.$?.Amount,
    }));
    const getAllFees = fees.map((fee) => ({
      code: fee?.$?.FeeCode,
      amount: ~~fee?.$?.Amount,
    }));

    return [...getAllTaxes, ...getAllFees];
  };

  // //////////// convert time to required formate
  const formatDuration = (journeyDuration) => {
    const durationRegex = /PT(\d+)H(\d+)M(\d+)\.(\d+)S/;
    const match = journeyDuration?.match(durationRegex);

    if (match) {
      const hours = parseInt(match[1], 10);
      const minutes = parseInt(match[2], 10);
      const formattedDuration = moment()
        .startOf("day")
        .add({ hours, minutes })
        .format("hh:mm");
      return formattedDuration;
    } else {
      return null; // or some default value
    }
  };

  // // get city name
  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };
  ///////////////////
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  /// USE EFFECT
  useEffect(() => {
    dispatch(
      set_Airline_filter_drp(
        data?.flightSegments?.at(0)?.flightNumber?.slice(0, 2)
      )
    );
    dispatch(set_All_Prices_Array(Number("100290")));
  }, []);
  const GetFlyJinnahFaresDetails = async (
    bundleIdOutbound,
    transaction_identifier
  ) => {
    // if (!bundleIdOutbound || !transaction_identifier) return;

    const faresPayload = {
      agent_name: "FLYJINNAH",
      seg: "OneWay",
      segmentsOI: data?.flightSegments?.map((segment) => ({
        ArrivalDateTime: segment.arrivalDateTimeLocal,
        DepartureDateTime: segment.departureDateTimeLocal,
        FlightNumber: segment.flightNumber,
        dep_LocationCode: segment.origin.airportCode,
        arr_LocationCode: segment.destination.airportCode,
      })),
      Adt_Quantity: searchValues?.adultsCount,
      Chd_Quantity: searchValues?.childCount,
      FlightNumber: data?.flightSegments?.[0]?.flightNumber,
      Inf_Quantity: searchValues?.infantNo,
      bundleIdOutbound: bundleIdOutbound,
      cookie: flyJinnahFares?.cookie,
      TransactionIdentifier: transaction_identifier,
    };
    try {
      const { data } = await NODEURL.post(
        `/api/v1/b2b/fly-jinnah-pnr`,
        faresPayload
      );
      data?.data?.["ns1:OTA_AirPriceRS"]
        ? setFlyJinnahFares(data.data || {})
        : setFlyJinnahFares({});
    } catch (error) {
      console.error("Error fetching Fly Jinnah fares:", error);
    }
  };
  const renderCount = useRef(0);
  renderCount.current += 1;
  useEffect(() => {
    // GetFlyJinnahFaresDetails();
    if (location.pathname === "/search-results") {
      GetFlyJinnahFaresDetails();
    }
  }, []);

  const fare_req_data = flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.$;
  const fareBreakdowns =
    flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["ns1:PricedItineraries"]?.[
      "ns1:PricedItinerary"
    ]?.["ns1:AirItineraryPricingInfo"]?.["ns1:PTC_FareBreakdowns"]?.[
      "ns1:PTC_FareBreakdown"
    ];

  ////////////////////////////////////////

  // Function to extract baggage information from description
  const extractBaggageInfo = (description) => {
    const carryOnBaggageMatch = description?.match(
      /(\d+\s*Kg\s*Carry(-| )?on Baggage|\d+\s*Kg\s*Carry(-| )?on|\d+\s*Kg\s*Hand Baggage)/i
    );
    const checkedBaggageMatch = description?.match(
      /(\d+\s*Kg\s*Checked Baggage|Checked Baggage:\s*\d+\s*Kg)/i
    );

    let checkedBaggage = "No Baggage";
    if (checkedBaggageMatch) {
      // If checked baggage match found, split the string and extract the numeric value and 'Kg'
      const baggageParts = checkedBaggageMatch?.[0]
        .replace(/Checked\s*Baggage\s*:\s*|\s*Checked\s*Baggage/, "")
        .split(/\s+/);
      checkedBaggage = baggageParts?.join(" ");
      // Join the parts back together, including the numeric value and 'Kg'
    }

    return {
      carryOnBaggage: carryOnBaggageMatch ? carryOnBaggageMatch?.[0] : "N/A",
      checkedBaggage: checkedBaggage,
      checkedBaggageMatch: checkedBaggageMatch
        ? checkedBaggageMatch?.[0]
        : "No Baggage",
    };
  };

  const getPaxFare = (fare) => {
    const totalFare = fare?.["ns1:PassengerFare"]?.["ns1:TotalFare"]?.$?.Amount;
    const basicFare = fare?.["ns1:PassengerFare"]?.["ns1:BaseFare"]?.$?.Amount;
    const taxes = Number(totalFare) - Number(basicFare);

    return { basicFare, taxes, totalFare };
  };

  const handleFlightDetails = () => {
    setShowContent("Flight details");
    setActive("flightDetails");
  };

  const handleShowFares = () => {
    setShowContent("Fare Options");
    setActive("showFares");
  };
  const baggage = {
    Value: "23 KG",
    Extra: "46 KG",
    Ultimate: "46 KG",
    Basic: "No Baggage",
  };
  const notAllowed = {
    Value: "Allowed",
    Extra: "Allowed",
    Ultimate: "Allowed",
    Basic: "Not Allowed",
  };

  return (
    <>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`flyjinnah${index}`}
      >
        <div
          className="shadow cursorpointer"
          onClick={() => {
            toggleDetail(index);
            // GetFlyJinnahFaresDetails();
          }}
        >
          <div className="row align-items-center">
            <div className="col-2">
              <div className="px-2">
                <div className="text-center">
                  <img
                    src={`/NEW_LOGO/${data?.flightSegments
                      ?.at(0)
                      ?.flightNumber?.slice(0, 2)}.png`}
                    alt="9P"
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <p className="text-center">
                  {/* {data?.flightSegments
                    ?.at(0)
                    ?.flightNumber?.replace(/P/, "P ")} */}
                  {data?.flightSegments
                    ?.map((segment) => segment.flightNumber.replace(/P/, "P "))
                    ?.join(" - ")}
                </p>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightTakeoff
                      size={30}
                      className="color_primary"
                    />
                  </div>
                  <div>
                    <h6>
                      {moment(
                        data?.flightSegments?.at(0)?.departureDateTimeLocal
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {" "}
                      {moment(
                        data?.flightSegments?.at(0)?.departureDateTimeLocal
                      ).format("hh:mm")}
                    </h6>
                    <h6>
                      {/* {selectedFlight?.origin} */}
                      {data?.flightSegments?.at(0)?.origin?.airportCode}-{" "}
                      {getCity(locDeparture)}
                    </h6>
                  </div>
                </div>
                <div>
                  <h6 className="text-center">{flightDuration}</h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">
                    {data.flightSegments[2]
                      ? "Two Stop"
                      : data.flightSegments[1]
                      ? `One Stop`
                      : "Non-Stop"}
                  </h6>
                  <h6 className="text-center">
                    {/* {amadeusItem?.itineraries?.map(
                    (itinery) =>
                      itinery?.segments?.length > 1 && (
                        <h6 className="text-center">
                          {itinery?.segments
                            ?.slice(1)
                            ?.map((seg) => seg?.departure?.iataCode)?.join(", ")}
                        </h6>
                      )
                  )} */}
                    {data.flightSegments.length > 1
                      ? data.flightSegments
                          ?.slice(0, 1)
                          ?.map((it) => `${it?.destination?.airportCode} `)
                          ?.join("- ")
                      : null}
                  </h6>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightLand size={30} className="color_primary" />
                  </div>

                  <div>
                    <h6>
                      {moment(
                        data?.flightSegments[data?.flightSegments?.length - 1]
                          ?.arrivalDateTimeLocal
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {moment(
                        data?.flightSegments[data?.flightSegments?.length - 1]
                          ?.arrivalDateTimeLocal
                      ).format("hh:mm")}
                    </h6>
                    <h6>
                      {/* {selectedFlight?.destination} */}
                      {
                        data?.flightSegments[data?.flightSegments?.length - 1]
                          ?.destination?.airportCode
                      }{" "}
                      - {getCity(locArrival)}
                    </h6>
                  </div>
                </div>
              </div>{" "}
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body">
                {selectedFare ? (
                  <HiEye className="color_primary" size={40} />
                ) : (
                  <>
                    <h6 className="text-danger">
                      {DiscountPrice(ptcBreakdowns)}
                    </h6>
                    <div className="d-flex align-items-end gap-3">
                      <h4>
                        {flyJinnahFares
                          ? lowestTotalFare(0)
                          : CurrencyCode() +
                            " " +
                            TwoDecimalPoint(data?.cabinPrices[0]?.price)}
                      </h4>
                      <img
                        src={`/NEW_LOGO/${data?.flightSegments[
                          data?.flightSegments?.length - 1
                        ]?.flightNumber?.slice(0, 2)}.png`}
                        alt="9p"
                        width={40}
                        height={40}
                      />
                    </div>
                    {/* <div className="fw-bold fs-5">{data?.agent_name}</div> */}
                    <button
                      onClick={() => toggleDetail(index)}
                      className="button_style w-75 my-3"
                      type="button"
                    >
                      <div>
                        <MenuOpenIcon className="me-1" />
                        Flight Detail
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {isExpanded ? (
          <div className="p-3">
            <div className="d-flex mb-4 mt-2 flight_details_tabs">
              <button
                className={active === "showFares" ? "active" : ""}
                onClick={handleShowFares}
              >
                Fare Options
              </button>
              <button
                className={active === "flightDetails" ? "active" : ""}
                onClick={handleFlightDetails}
              >
                Flight details
              </button>
            </div>
            {showContent === "Flight details" && (
              <div className="border-2">
                {/* <ConnectedFlight
              data={data}
              flyJinnahFares={flyJinnahFares}
              locDeparture={locDeparture}
              locArrival={locArrival}
                flightDuration={flightDuration}
              /> */}
                {data?.flightSegments?.map((item, inde) => {
                  return (
                    <ConnectedFlight
                      item={item}
                      inde={inde}
                      data={data}
                      // flyJinnahFares={flyJinnahFares}
                      // locDeparture={locDeparture}
                      // locArrival={locArrival}
                      //   flightDuration={flightDuration}
                    />
                  );
                })}
              </div>
            )}

            {showContent === "Fare Options" && (
              <div className="search_engin_result_box py-3 bg-white h-auto">
                <div
                  className={`d-flex ${
                    selectedFare
                      ? "justify-content-start"
                      : "justify-content-between"
                  } align-items-start px-5`}
                >
                  {/* headings */}
                  <div className={`${selectedFare ? "me-5 pe-5" : ""}`}>
                    <div className="mb-4 pb-3"></div>
                    <div className="flight-detail-headings">
                      <BsHandbag size={25} className="color_primary" />
                      <span>Hand Carry Bags</span>
                    </div>
                    <div className="flight-detail-headings">
                      <BsBagCheck size={25} className="color_primary" />
                      <span>Checked Bags</span>
                    </div>
                    <div className="flight-detail-headings">
                      <MdOutlineRestaurant
                        size={25}
                        className="color_primary"
                      />
                      <span>Meals</span>
                    </div>
                    <div className="flight-detail-headings">
                      <BsPerson size={25} className="color_primary" />
                      <span>Seat Selection</span>
                    </div>
                    <div className="flight-detail-headings">
                      <MdSwapHoriz size={25} className="color_primary" />
                      <span>Refund & Exchanges</span>
                    </div>
                  </div>

                  {Array.isArray(faresArray) &&
                    [
                      {
                        "ns1:bunldedServiceId": "",
                        "ns1:bundledServiceName": "Basic",
                        "ns1:perPaxBundledFee": "0",
                        "ns1:bookingClasses": "",
                        "ns1:description": "",
                      },
                      ...faresArray,
                    ]?.map((fare, index) => {
                      const paxFare = Array.isArray(ptcBreakdowns)
                        ? ptcBreakdowns
                        : [ptcBreakdowns];

                      return (
                        <div key={index}>
                          <div className="fs-4 theme-text-color mb-3 fw-bold">
                            {fare?.["ns1:bundledServiceName"]}
                          </div>
                          <div className="flight-detail-headings">
                            <MdCheck size={25} color="green" />
                            <span>Upto 10 kg</span>
                          </div>
                          <div className="flight-detail-headings">
                            <>
                              <MdCheck size={25} color="green" />
                              <span>
                                {/* {checkedBaggage
                                  ? checkedBaggage
                                  : checkedBaggageMatch} */}
                                {baggage[fare?.["ns1:bundledServiceName"]]}
                              </span>
                            </>
                          </div>
                          <div className="flight-detail-headings">
                            <MdCheck size={25} color="green" />
                            <span>
                              {notAllowed[fare?.["ns1:bundledServiceName"]]}
                            </span>
                          </div>
                          <div className="flight-detail-headings">
                            <CgMenuGridR size={25} color="#38025c" />
                            <span>System Allocated</span>
                          </div>
                          {/* refund and exchange dialog  */}
                          <div className="flight-detail-headings">
                            <LightTooltip
                              title={
                                <div className="p-3">
                                  <h4>Refund & Exchange</h4>
                                  <h6>
                                    Penalty will be charged per passenger
                                    segment.
                                  </h6>
                                  <div className="text-danger font-italic">
                                    for further clarification contact with
                                    Airline Or Call our support team.
                                  </div>
                                  <div className="text-danger font-italic mt-2">
                                    After 25 days of departure ticket is
                                    Non-Refundable.
                                  </div>

                                  <h6 className="mt-3">
                                    Call our support team to verify the Exchange
                                    & Refund Charges
                                  </h6>

                                  {/* <div className="d-flex justify-content-between align-item-start w-fit mt-4">
                                    <div className="me-5">
                                      <h4>Exchange</h4>
                                      <h6>
                                        Before 48 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          1500
                                        )}`}
                                      </h6>
                                      <h6>
                                        Within 48 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          2500
                                        )}`}
                                      </h6>
                                      <h6>
                                        Within 24 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          3500
                                        )}`}
                                      </h6>
                                      <h6>
                                        Within 03 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          4500
                                        )}`}
                                      </h6>
                                    </div>
                                    <div>
                                      <h4>Refund</h4>
                                      <h6>
                                        Before 48 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          1500
                                        )}`}
                                      </h6>
                                      <h6>
                                        Within 48 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          2500
                                        )}`}
                                      </h6>
                                      <h6>
                                        Within 24 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          3500
                                        )}`}
                                      </h6>
                                      <h6>
                                        Within 03 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          4500
                                        )}`}
                                      </h6>
                                    </div>
                                  </div> */}
                                </div>
                              }
                              placement="top-start"
                            >
                              <div style={{ cursor: "pointer" }}>
                                <MdRemoveRedEye size={25} color="#262829" />
                                <span>View</span>
                              </div>
                            </LightTooltip>
                          </div>
                          {/* ///////////// fare button and tooltip */}
                          {!selectedFare ? (
                            <div className="pt-3 px-2">
                              <LightTooltip
                                title={
                                  <div className="p-3">
                                    <div className="d-flex justify-content-between align-items-end">
                                      <div className="d-flex me-4">
                                        <h5 style={{ minWidth: "3rem" }}>
                                          ADT
                                        </h5>
                                        <h5>* {searchValues?.adultsCount}</h5>
                                      </div>
                                      <h6 style={{ minWidth: "7rem" }}>
                                        {CurrencyCode()}{" "}
                                        {singlePaxFare(
                                          paxFare[0],
                                          searchValues?.adultsCount,
                                          ~~fare?.["ns1:perPaxBundledFee"]
                                        )}
                                      </h6>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-end my-2">
                                      <div className="d-flex">
                                        <h5 style={{ minWidth: "3rem" }}>
                                          CHD
                                        </h5>
                                        <h5>* {searchValues?.childCount}</h5>
                                      </div>
                                      <h6 style={{ minWidth: "7rem" }}>
                                        {CurrencyCode()}{" "}
                                        {singlePaxFare(
                                          paxFare[1],
                                          searchValues?.childCount,
                                          ~~fare?.["ns1:perPaxBundledFee"]
                                        )}
                                      </h6>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-end">
                                      <div className="d-flex">
                                        <h5 style={{ minWidth: "3rem" }}>
                                          INF
                                        </h5>
                                        <h5>* {searchValues?.infantNo}</h5>
                                      </div>
                                      <h6 style={{ minWidth: "7rem" }}>
                                        {CurrencyCode()}{" "}
                                        {singlePaxFare(
                                          searchValues?.infantNo !== 0
                                            ? paxFare[paxFare?.length - 1]
                                            : {},
                                          searchValues?.infantNo,
                                          0
                                        )}
                                      </h6>
                                    </div>
                                  </div>
                                }
                                placement="top"
                              >
                                {loading ? (
                                  <div className="button_style px-4">
                                    <BtnLoader />
                                  </div>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      className="button_style px-4"
                                      onClick={async () => {
                                        if (!selectedFare) {
                                          const getPaxData = (paxType) => {
                                            let breakdown = Array.isArray(
                                              fareBreakdowns
                                            )
                                              ? fareBreakdowns
                                              : [fareBreakdowns];
                                            return breakdown?.find(
                                              (pax) =>
                                                pax?.[
                                                  "ns1:PassengerTypeQuantity"
                                                ]?.$?.Code === paxType
                                            );
                                          };

                                          const ADLT_FARE = getPaxData("ADT");
                                          const CHLD_FARE = getPaxData("CHD");
                                          const INFT_FARE = getPaxData("INF");
                                          const farePrice =
                                            ~~fare?.["ns1:perPaxBundledFee"] *
                                            (searchValues?.adultsCount +
                                              searchValues?.childCount);
                                          const lowestFare =
                                            lowestTotalFare(farePrice);

                                          setIsLoading(true);

                                          await GetFlyJinnahFaresDetails(
                                            fare["ns1:bunldedServiceId"],
                                            TransactionIdentifier
                                          );

                                          let pricedSegments = Array.isArray(
                                            segments?.[
                                              "ns1:OriginDestinationOption"
                                            ]
                                          )
                                            ? segments?.[
                                                "ns1:OriginDestinationOption"
                                              ]
                                            : [
                                                segments?.[
                                                  "ns1:OriginDestinationOption"
                                                ],
                                              ];

                                          dispatch(
                                            setselectedFlight({
                                              flight: data,
                                              fare: fare,

                                              price: lowestFare,
                                              ttl_agent_fare: TwoDecimalPoint(
                                                CurrencyExchangeToPKR(
                                                  Number(
                                                    lowestFare
                                                      ?.split(" ")
                                                      ?.at(1) || 0
                                                  )
                                                )
                                              ),
                                              ttlagent_curr_fare: Number(
                                                lowestFare?.split(" ")?.at(1) ||
                                                  0
                                              ),
                                              ttl_supplier_fare:
                                                TwoDecimalPoint(
                                                  fare?.["ns1:PassengerFare"]?.[
                                                    "ns1:TotalFare"
                                                  ]?.$?.Amount + farePrice
                                                ),
                                              ttlsup_curr_fare: TwoDecimalPoint(
                                                CurrencyExchange(
                                                  fare?.["ns1:PassengerFare"]?.[
                                                    "ns1:TotalFare"
                                                  ]?.$?.Amount + farePrice
                                                )
                                              ),
                                              adultTaxes:
                                                getPaxFare(ADLT_FARE)?.taxes +
                                                ~~fare?.[
                                                  "ns1:perPaxBundledFee"
                                                ] *
                                                  searchValues?.adultsCount,
                                              childTaxes:
                                                getPaxFare(CHLD_FARE)?.taxes +
                                                ~~fare?.[
                                                  "ns1:perPaxBundledFee"
                                                ] *
                                                  searchValues?.childCount,
                                              infantTaxes:
                                                getPaxFare(INFT_FARE)?.taxes,

                                              adultBaseFare:
                                                getPaxFare(ADLT_FARE)
                                                  ?.basicFare,
                                              childBaseFare:
                                                getPaxFare(CHLD_FARE)
                                                  ?.basicFare,
                                              infantBaseFare:
                                                getPaxFare(INFT_FARE)
                                                  ?.basicFare,

                                              // adultBaseFare: CurrencyExchangeToPKR(singlePaxFare(paxFare[0], searchValues?.adultsCount, ~~fare?.["ns1:perPaxBundledFee"])),
                                              // childBaseFare: CurrencyExchangeToPKR(singlePaxFare(paxFare[1] || {}, searchValues?.childCount, ~~fare?.["ns1:perPaxBundledFee"])),
                                              // infantBaseFare: CurrencyExchangeToPKR(singlePaxFare(searchValues?.infantNo !== 0 ? paxFare[paxFare?.length - 1] : {}, searchValues?.infantNo, 0)),

                                              ADLT_FARE,
                                              CHLD_FARE,
                                              INFT_FARE,

                                              ADLT_TAXES:
                                                getPaxTaxes(ADLT_FARE),
                                              CHLD_TAXES:
                                                getPaxTaxes(CHLD_FARE),
                                              INFT_TAXES:
                                                getPaxTaxes(INFT_FARE),

                                              adlt_ttl_d_curr:
                                                CurrencyExchangeToPKR(
                                                  singlePaxFare(
                                                    paxFare[0],
                                                    searchValues?.adultsCount,
                                                    ~~fare?.[
                                                      "ns1:perPaxBundledFee"
                                                    ]
                                                  )
                                                ),
                                              chld_ttl_d_curr:
                                                CurrencyExchangeToPKR(
                                                  singlePaxFare(
                                                    paxFare[1] || {},
                                                    searchValues?.childCount,
                                                    ~~fare?.[
                                                      "ns1:perPaxBundledFee"
                                                    ]
                                                  )
                                                ),
                                              inft_ttl_d_curr:
                                                CurrencyExchangeToPKR(
                                                  singlePaxFare(
                                                    searchValues?.infantNo !== 0
                                                      ? paxFare[
                                                          paxFare?.length - 1
                                                        ]
                                                      : {},
                                                    searchValues?.infantNo,
                                                    0
                                                  )
                                                ),

                                              flightNumber:
                                                flightSegments[0].flightNumber
                                                  ?.replace(/9P/g, "")
                                                  ?.replace(/G9/g, ""),
                                              flightDuration: flightDuration,
                                              arrTime:
                                                data?.flightSegments[0].arrivalDateTimeLocal
                                                  ?.split("T")[1]
                                                  ?.slice(0, 5),
                                              arrDate: moment(
                                                new Date(
                                                  data?.flightSegments[0].arrivalDateTimeLocal.split(
                                                    "T"
                                                  )[0]
                                                )
                                              ).format("DD-MMM-YY"),
                                              depDate: moment(
                                                new Date(
                                                  data?.flightSegments[0].departureDateTimeLocal.split(
                                                    "T"
                                                  )[0]
                                                )
                                              ).format("DD-MMM-YY"),
                                              depTime:
                                                data?.flightSegments[0].departureDateTimeLocal
                                                  ?.split("T")[1]
                                                  ?.slice(0, 5),
                                              agent_name: data.agent_name,
                                              transaction_identifier:
                                                TransactionIdentifier,
                                              destination: destination,
                                              // rph: segments?.["ns1:OriginDestinationOption"]?.["ns1:FlightSegment"]?.$?.["RPH"],
                                              rph: rpH,
                                              cookie: flyJinnahFares?.cookie,
                                              origin: origin,
                                              token_info: token,
                                              baggageName:
                                                fare?.[
                                                  "ns1:bundledServiceName"
                                                ],
                                              logo: "FlyJinnah",
                                              code: "9P",
                                              bundleIdOutbound:
                                                fare["ns1:bunldedServiceId"],
                                              booking: {
                                                gds: "FLYJINNAH", // FlyJinnah GDS code
                                                pnr: "",
                                                seg_r: "OneWay",
                                                agent_name: data.agent_name,
                                                segments:
                                                  data?.flightSegments?.map(
                                                    (segment, index) => ({
                                                      segments:
                                                        pricedSegments[index],
                                                      trip_type: "outbound",
                                                      departure_date: moment(
                                                        new Date(
                                                          segment.departureDateTimeLocal.split(
                                                            "T"
                                                          )[0]
                                                        )
                                                      ).format("YYYY-MM-DD"),
                                                      // baggage: `${fare?.weight} KG (${fare?.piece} Pcs)`,
                                                      baggage:
                                                        baggage[
                                                          fare?.[
                                                            "ns1:bundledServiceName"
                                                          ]
                                                        ],
                                                      logo: "FlyJinnah",
                                                      departure_time:
                                                        segment.departureDateTimeLocal
                                                          ?.split("T")[1]
                                                          ?.slice(0, 5),
                                                      arrival_date: moment(
                                                        new Date(
                                                          segment.arrivalDateTimeLocal.split(
                                                            "T"
                                                          )[0]
                                                        )
                                                      ).format("YYYY-MM-DD"),
                                                      arrival_time:
                                                        segment.arrivalDateTimeLocal
                                                          ?.split("T")[1]
                                                          ?.slice(0, 5),
                                                      origin_terminal: "",
                                                      desti_terminal: "",
                                                      flight_number:
                                                        segment.flightNumber?.slice(
                                                          2
                                                        ),
                                                      rph: pricedSegments[
                                                        index
                                                      ]?.["ns1:FlightSegment"]
                                                        ?.$?.RPH,
                                                      number_in_party: "", // Assuming number in party
                                                      res_book_desig_code: "", // Assuming reservation booking designation code
                                                      status: "NN",
                                                      des_location:
                                                        searchValues?.locationArrival,
                                                      dest_code:
                                                        segment.destination,
                                                      marketing_airline:
                                                        segment?.flightNumber.slice(
                                                          0,
                                                          2
                                                        ),
                                                      orig_location:
                                                        searchValues?.locationDep,
                                                      orig_code: segment.origin,
                                                      marriage_grp: "", // Assuming marriage group
                                                      cabin_class:
                                                        fare?.[
                                                          "ns1:bundledServiceName"
                                                        ],
                                                      segment_type:
                                                        getSectorsName(
                                                          origin,
                                                          destination
                                                        ), // Assuming getSectorsName function handles sector type
                                                    })
                                                  ),
                                                tp_ur_code: "",
                                                platting_carrier: "9P",
                                                tp_ssr_verssion: 0,
                                                tp_trace_id: "",
                                                fare_req_data: fare_req_data,
                                                TransactionIdentifier:
                                                  TransactionIdentifier,
                                              },
                                            })
                                          );
                                          navigate("/check-out");
                                        }
                                      }}
                                    >
                                      {flyJinnahFares
                                        ? lowestTotalFare(
                                            ~~fare?.["ns1:perPaxBundledFee"] *
                                              (searchValues?.adultsCount +
                                                searchValues?.childCount)
                                          )
                                        : CurrencyCode() +
                                          " " +
                                          data?.cabinPrices[0]?.price +
                                          ~~fare?.["ns1:perPaxBundledFee"]}
                                    </button>
                                  </>
                                )}
                              </LightTooltip>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            <div></div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default FlyJinnahCard;

const ConnectedFlight = ({
  data,
  item,
  inde,
  flyJinnahFares,
  locDeparture,
  flightDuration,
  locArrival,
}) => {
  const [airport_name, setairport_name] = useState("");
  const [arr_airport_name, setArrairport_name] = useState("");

  //api call for fatching airport name
  useEffect(() => {
    const fetchDataDep = async () => {
      try {
        let airportCode = item?.origin?.airportCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        // Handle the data here, for example, set it in the state
        setairport_name(data);
      } catch (error) {
        // Handle errors here
      }
    };

    const fetchDataArr = async () => {
      try {
        let airportCode = item?.destination?.airportCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        // Handle the data here, for example, set it in the state
        setArrairport_name(data);
      } catch (error) {
        // Handle errors here
      }
    };

    fetchDataDep();
    fetchDataArr();
  }, []);

  return (
    <div key={inde}>
      <div className="row align-items-center">
        <div className="col-2">
          <div className="px-2">
            <div className="text-center">
              <img
                src={`/NEW_LOGO/${item?.flightNumber.slice(0, 2)}.png`}
                alt="9P"
                style={{
                  width: "90px",
                  height: "90px",
                  objectFit: "contain",
                }}
              />
            </div>
            <p className="text-center">
              <h6 className="mt-3 ms-2">{`${item?.flightNumber}`}</h6>
              <div className="mt-2">
                Operated by : {item?.flightNumber.slice(0, 2)}
              </div>
            </p>
          </div>
        </div>

        <div className="col-7">
          <div className="d-flex justify-content-evenly align-items-center">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <MdOutlineFlightTakeoff size={30} className="color_primary" />
              </div>
              <div>
                <h6>
                  {moment(
                    item?.departureDateTimeLocal?.split("T")?.at(0)
                  ).format("ddd DD-MMM-YY")}
                </h6>
                <h6 className="my-3">
                  {" "}
                  {moment(item?.departureDateTimeLocal).format("hh:mm")}
                </h6>
                <h6>
                  {item?.origin?.airportCode} - {airport_name}
                </h6>
                <h6>
                  {item?.origin?.terminal && (
                    <h6 className="color_primary">
                      (Terminal-{item?.origin?.terminal})
                    </h6>
                  )}
                </h6>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-center">
                <MdFlight className="color_primary fs-5 rotate-right" />
                <h6 className="text-center">Trip Time</h6>
              </div>
              <div className="border_rounded mb-2" />
              <h6 className="text-center ms-4">
                {timeDuration(
                  item?.departureDateTimeLocal,
                  item?.arrivalDateTimeLocal
                )}
              </h6>
            </div>

            <div className="col-5 d-flex align-items-center ps-4">
              <div className="me-3">
                <MdOutlineFlightLand size={30} className="color_primary" />
              </div>
              <div>
                <h6>
                  {moment(item?.arrivalDateTimeLocal?.split("T")?.at(0)).format(
                    "ddd DD-MMM-YY"
                  )}
                </h6>
                <h6 className="my-3">
                  {moment(item?.arrivalDateTimeLocal).format("hh:mm")}
                </h6>
                <h6>
                  {item?.destination?.airportCode} - {arr_airport_name}
                </h6>
                {item?.destination?.terminal && (
                  <h6 className="color_primary">
                    (Terminal-{item?.destination?.terminal})
                  </h6>
                )}
              </div>
            </div>
          </div>{" "}
        </div>
        {inde < data?.flightSegments?.length - 1 && (
          <div className="col-12 d-flex justify-content-center align-items-center my-4">
            <div className="border_rounded w-25" />
            <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
              <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
              <h6 className="pt-2">
                Layover time:{" "}
                <span className="fs-5">
                  {" "}
                  {timeDuration(
                    item?.arrivalDateTimeLocal,
                    data?.flightSegments[data?.flightSegments?.length - 1]
                      ?.departureDateTimeLocal
                  )}
                </span>
              </h6>
            </div>
            <div className="border_rounded w-25" />
          </div>
        )}
      </div>
    </div>
  );
};
