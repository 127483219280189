import React, { useEffect } from "react";
import Table from "react-bootstrap/esm/Table";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";

const VisaSummary = ({ values, setFieldValue }) => {
  const isPkr = values.paxDetail?.at(0).cur_label === "PKR" ? true : false;
  const ROE = values.paxDetail?.at(0).roe;
  //total adult
  const totalAdults = values?.paxDetail?.filter(
    (passenger) => passenger.pax_type === "ADULT"
  );
  //total child
  const totalChild = values?.paxDetail?.filter(
    (passenger) => passenger.pax_type === "CHILD"
  );
  //total infant
  const totalInfant = values?.paxDetail?.filter(
    (passenger) => passenger.pax_type === "INFANT"
  );

  //find total amount
  const totalAmount = (paxArray) => {
    let supplier = paxArray?.reduce(
      (sum, passenger) => sum + Number(passenger.pkr_pay),
      0
    );
    let agent = paxArray?.reduce(
      (sum, passenger) => sum + Number(passenger.pkr_rec),
      0
    );

    return { supplier_total: supplier, agent_total: agent };
  };

  // commulative total
  const agentTotal =
    totalAmount(totalAdults).agent_total +
    totalAmount(totalChild).agent_total +
    totalAmount(totalInfant).agent_total;
  const supplierTotal =
    totalAmount(totalAdults).supplier_total +
    totalAmount(totalChild).supplier_total +
    totalAmount(totalInfant).supplier_total;

  // psf disc
  const adtPsfDisc =
    totalAmount(totalAdults).supplier_total -
    totalAmount(totalAdults).agent_total;
  const chdPsfDisc =
    totalAmount(totalChild).supplier_total -
    totalAmount(totalChild).agent_total;
  const infPsfDisc =
    totalAmount(totalInfant).supplier_total -
    totalAmount(totalInfant).agent_total;

  useEffect(() => {
    setFieldValue("totalAgent", agentTotal);
  }, [agentTotal]);

  return (
    <div className="manual_invoice_footer p-4 px-0">
      <div className="row">
        <div className="col-4">
          <Table bordered hover>
            <thead>
              <tr>
                <th colSpan={6}>
                  <h5 className="text-center py-1">Visa Summary</h5>
                </th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th colSpan={2}>
                  <h6>Agent</h6>
                </th>
                <th colSpan={2}>
                  <h6>Supplier</h6>
                </th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th className="fixed_width_column_ins">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>{!isPkr && values.paxDetail?.at(0).cur_label}</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>{!isPkr && values.paxDetail?.at(0).cur_label}</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Visa Value</td>
                <td className="col4rem">{totalAdults.length} * ADT</td>
                <td>{totalAmount(totalAdults).agent_total}</td>
                <td>{!isPkr && totalAmount(totalAdults).agent_total / ROE}</td>
                <td>{totalAmount(totalAdults).supplier_total}</td>
                <td>
                  {!isPkr && totalAmount(totalAdults).supplier_total / ROE}
                </td>
              </tr>

              <tr>
                <td>Visa Value</td>
                <td>{totalChild.length} * CHD</td>
                <td>{totalAmount(totalChild).agent_total}</td>
                <td>{!isPkr && totalAmount(totalChild).agent_total / ROE}</td>
                <td>{totalAmount(totalChild).supplier_total}</td>
                <td>
                  {!isPkr && totalAmount(totalChild).supplier_total / ROE}
                </td>
              </tr>

              <tr>
                <td>Visa Value</td>
                <td>{totalInfant.length} * INF</td>
                <td>{totalAmount(totalInfant).agent_total}</td>
                <td>{!isPkr && totalAmount(totalInfant).agent_total / ROE}</td>
                <td>{totalAmount(totalInfant).supplier_total}</td>
                <td>
                  {!isPkr && totalAmount(totalInfant).supplier_total / ROE}
                </td>
              </tr>
              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Total</td>
                <td></td>
                <td>{agentTotal}</td>
                <td>{!isPkr && TwoDecimalPoint(agentTotal / ROE)}</td>
                <td>{supplierTotal}</td>
                <td>{!isPkr && TwoDecimalPoint(supplierTotal / ROE)}</td>
              </tr>
            </tbody>
          </Table>
        </div>

        {/* Discount PSF  */}

        <div className="col-4">
          <Table bordered hover>
            <thead>
              <tr>
                <th colSpan={6}>
                  <h5 className="text-center py-1">Discount / PSF</h5>
                </th>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <th colSpan={2}>
                  <h6>Agent</h6>
                </th>
                <th colSpan={2}>
                  <h6>Supplier</h6>
                </th>
              </tr>
              <tr>
                <th></th>
                <th style={{ minWidth: "2rem" }}></th>
                <th className="fixed_width_column_ins">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>{!isPkr && values.paxDetail?.at(0).cur_label}</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>{!isPkr && values.paxDetail?.at(0).cur_label}</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>DISC</td>
                <td className="col4rem">{totalAdults.length} * ADT</td>
                <td>{adtPsfDisc > 0 ? adtPsfDisc : null}</td>
                <td>{!isPkr && adtPsfDisc > 0 ? adtPsfDisc / ROE : null}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>PSF</td>
                <td className="col4rem">{totalAdults.length} * ADT</td>
                <td>{adtPsfDisc < 0 ? Math.abs(adtPsfDisc) : null}</td>
                <td>
                  {!isPkr && adtPsfDisc < 0 ? Math.abs(adtPsfDisc) / ROE : null}
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>DISC</td>
                <td>{totalChild.length} * CHD</td>
                <td>{chdPsfDisc > 0 ? chdPsfDisc : null}</td>
                <td>{!isPkr && chdPsfDisc > 0 ? chdPsfDisc / ROE : null}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>PSF</td>
                <td>{totalChild.length} * CHD</td>
                <td>{chdPsfDisc < 0 ? Math.abs(chdPsfDisc) : null}</td>
                <td>
                  {!isPkr && chdPsfDisc < 0 ? Math.abs(chdPsfDisc) / ROE : null}
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>DISC</td>
                <td>{totalInfant.length} * INF</td>
                <td>{infPsfDisc > 0 ? infPsfDisc : null}</td>
                <td>{!isPkr && infPsfDisc > 0 ? infPsfDisc / ROE : null}</td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>PSF</td>
                <td>{totalInfant.length} * INF</td>
                <td>{infPsfDisc < 0 ? Math.abs(infPsfDisc) : null}</td>
                <td>
                  {!isPkr && infPsfDisc < 0 ? Math.abs(infPsfDisc) / ROE : null}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Total</td>
                <td></td>
                <td>{Math.abs(adtPsfDisc + chdPsfDisc + infPsfDisc)}</td>
                <td>
                  {!isPkr &&
                    Math.abs(adtPsfDisc + chdPsfDisc + infPsfDisc) / ROE}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>

        {/* total section */}
        <div className="col-4">
          <Table bordered hover>
            <thead>
              <tr>
                <th colSpan={4}>
                  <h5 className="text-center py-1">Total Summary(PKR)</h5>
                </th>
              </tr>
              <tr>
                <th className="w-25"></th>
                <th className="w-25"></th>
                <th className="w-25">
                  <h6>Agent</h6>{" "}
                </th>
                <th className="w-25">
                  <h6>Supplier</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total Value</td>
                <td style={{ minWidth: "2rem" }}></td>
                <td>{agentTotal}</td>
                <td>{supplierTotal}</td>
              </tr>

              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Total</td>
                <td></td>
                <td>{agentTotal}</td>
                <td>{supplierTotal}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default VisaSummary;
