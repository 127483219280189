import VisaBookingDetails from "../../BookingDetailsComponent/VisaBookingDetails";
import VisaBookedItem from "./VisaBookedItem";
import VisaPassengerDetails from "./VisaPassengerDetails";

const VisaDetails = ({ bookingData }) => {
  return (
    <div className="d-flex flex-column justify-content-center mt-4">
      <VisaBookedItem bookingData={bookingData} />
      <VisaPassengerDetails
        bookingData={bookingData}
      />
    </div>
  );
};

export default VisaDetails;
