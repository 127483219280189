import React, { useState } from "react";
import moment from "moment";
import {
  CurrencyCode,
  CurrencyExchange,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { useSelector } from "react-redux";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { ExclusiveSetors } from "../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import AirsialSegments from "./AirsialSegments";
import AirsialSegmentsDetails from "./AirsialSegmentsDetails";
import AirsialFares from "./AirsialFares";

/////////////// Sial /////////////
function AirsialData({ data, index, isExpanded, toggleDetail, selectedFlight }) {
  const [selectedTab, setSelectedTab] = useState("Fare Options");
  const [boundToggle, setBoundToggle] = useState("outBound");
  const [selectedFare, setSelectedFare] = useState(data?.outbound?.BAGGAGE_FARE?.at(0));

  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  //local storage data
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "PF"
  );

  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "PF");

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  // calculate the lowest fare price
  const lowestFarePrice = (fare, fares) => {
    // Check if fares is "inoutBond", and set selectedFareData to null if true
    const selectedFareData = fares === "inoutBond"
      ? null // Do not use selectedFareData if fares is "inoutBond"
      : (selectedFlight
        ? selectedFlight?.fare?.FARE_PAX_WISE
        : boundToggle === "inBound"
          ? selectedFare?.FARE_PAX_WISE
          : data?.inbound?.BAGGAGE_FARE[0]?.FARE_PAX_WISE);

    let newFare =
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(fare?.ADULT?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.ADULT?.BASIC_FARE))) : 0)),
        Number(CurrencyExchange(Number(fare?.ADULT?.TOTAL) - Number(fare?.ADULT?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.ADULT?.TOTAL) - Number(selectedFareData?.ADULT?.BASIC_FARE))) : 0)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(fare?.CHILD?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.CHILD?.BASIC_FARE))) : 0)),
        Number(CurrencyExchange(Number(fare?.CHILD?.TOTAL) - Number(fare?.CHILD?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.CHILD?.TOTAL) - Number(selectedFareData?.CHILD?.BASIC_FARE))) : 0)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(fare?.INFANT?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.INFANT?.BASIC_FARE))) : 0)),
        Number(CurrencyExchange(Number(fare?.INFANT?.TOTAL) - Number(fare?.INFANT?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.INFANT?.TOTAL) - Number(selectedFareData?.INFANT?.BASIC_FARE))) : 0)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.infantNo || 0);

    return TwoDecimalPoint(newFare);
  };


  const totalDiscount = (fare, fares) => {
    // Check if fares is "inoutBond", and set selectedFareData to null if true
    const selectedFareData = fares === "inoutBond"
      ? null // Do not use selectedFareData if fares is "inoutBond"
      : (selectedFlight
        ? selectedFlight?.fare?.FARE_PAX_WISE
        : boundToggle === "inBound"
          ? selectedFare?.FARE_PAX_WISE
          : data?.inbound?.BAGGAGE_FARE[0]?.FARE_PAX_WISE);

    let newFare =
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(fare?.ADULT?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.ADULT?.BASIC_FARE))) : 0)),
        Number(CurrencyExchange(Number(fare?.ADULT?.TOTAL) - Number(fare?.ADULT?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.ADULT?.TOTAL) - Number(selectedFareData?.ADULT?.BASIC_FARE))) : 0)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(fare?.CHILD?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.CHILD?.BASIC_FARE))) : 0)),
        Number(CurrencyExchange(Number(fare?.CHILD?.TOTAL) - Number(fare?.CHILD?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.CHILD?.TOTAL) - Number(selectedFareData?.CHILD?.BASIC_FARE))) : 0)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(fare?.INFANT?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.INFANT?.BASIC_FARE))) : 0)),
        Number(CurrencyExchange(Number(fare?.INFANT?.TOTAL) - Number(fare?.INFANT?.BASIC_FARE)) + (selectedFareData ? Number(CurrencyExchange(Number(selectedFareData?.INFANT?.TOTAL) - Number(selectedFareData?.INFANT?.BASIC_FARE))) : 0)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.infantNo || 0);

    if (newFare > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
    }

  };

  // Function to get discount for a single fare
  // const singleFareDiscount = (fare, paxCount) => {
  //   return (
  //     (pricingairline(
  //       airline,
  //       pricing,
  //       Number(CurrencyExchange(fare?.BASIC_FARE)),
  //       Number(CurrencyExchange(fare?.TOTAL - fare?.BASIC_FARE)),
  //       getSectorsName(locDeparture, locArrival),
  //       ExclusiveSetors(searchValues, pricing)
  //     )?.sp * paxCount) || 0
  //   );
  // };

  // // Function to combine discounts for outbound and inbound fares
  // const combineDiscount = (fare, returnFare) => {
  //   // console.log("fare",fare);
  //   let discount = 
  //     singleFareDiscount(fare?.ADULT, searchValues?.adultsCount) +
  //     singleFareDiscount(fare?.CHILD, searchValues?.childCount) +
  //     singleFareDiscount(fare?.INFANT, searchValues?.infantNo) +
  //     singleFareDiscount(returnFare?.ADULT, searchValues?.adultsCount) +
  //     singleFareDiscount(returnFare?.CHILD, searchValues?.childCount) +
  //     singleFareDiscount(returnFare?.INF, searchValues?.infantNo);

  //   return `Offer ${CurrencyCode()} ${TwoDecimalPoint(discount)}`;
  // };
  // const DiscountPrice = (fare) => {
  //   let newFare =
  //     (pricingairline(
  //       airline,
  //       pricing,
  //       Number(CurrencyExchange(fare?.ADULT?.BASIC_FARE)),
  //       Number(CurrencyExchange(fare?.ADULT?.TOTAL - fare?.ADULT?.BASIC_FARE)),
  //       getSectorsName(locDeparture, locArrival),
  //       ExclusiveSetors(searchValues, pricing)
  //     )?.sp * searchValues?.adultsCount || 0) +
  //     (pricingairline(
  //       airline,
  //       pricing,
  //       Number(CurrencyExchange(fare?.CHILD?.BASIC_FARE)),
  //       Number(CurrencyExchange(fare?.CHILD?.TOTAL - fare?.CHILD?.BASIC_FARE)),
  //       getSectorsName(locDeparture, locArrival),
  //       ExclusiveSetors(searchValues, pricing)
  //     )?.sp * searchValues?.childCount || 0) +
  //     (pricingairline(
  //       airline,
  //       pricing,
  //       Number(CurrencyExchange(fare?.INFANT?.BASIC_FARE)),
  //       Number(
  //         CurrencyExchange(fare?.INFANT?.TOTAL - fare?.INFANT?.BASIC_FARE)
  //       ),
  //       getSectorsName(locDeparture, locArrival),
  //       ExclusiveSetors(searchValues, pricing)
  //     )?.sp * searchValues?.infantNo || 0);
  //   return TwoDecimalPoint(newFare);
  // };

  //////////////// get single pax fare

  const singlePaxForTooltip = (fare, pax) => {
    let singleFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(fare?.BASIC_FARE))),
        Number(CurrencyExchange(Number(fare?.TOTAL) - Number(fare?.BASIC_FARE))),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * pax || 0;
    return TwoDecimalPoint(singleFare);
  }

  const singlePaxFare = (fare, returnFare, pax) => {
    let newFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(fare?.BASIC_FARE) + Number(returnFare?.BASIC_FARE))),
        Number(CurrencyExchange(Number(fare?.TOTAL) - Number(fare?.BASIC_FARE) + Number(returnFare?.TOTAL) - Number(returnFare?.BASIC_FARE))),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * pax || 0;
    return TwoDecimalPoint(newFare);
  };


  ////////// convert time to required formate
  function formatDuration(inputDuration) {
    // Use a custom regular expression to extract hours and minutes
    const regex = /(\d+)h (\d+)m/;
    const match = inputDuration?.match(regex);

    if (match) {
      const hours = parseInt(match[1]);
      const minutes = parseInt(match[2]);

      // Calculate the duration using Moment.js
      const duration = moment.duration({ hours, minutes });

      // Format the duration as 'hh:mm'
      const formattedDuration = `${duration
        .hours()
        .toString()
        .padStart(2, "0")}:${duration.minutes().toString().padStart(2, "0")}`;

      return formattedDuration;
    } else {
      return "Invalid input format"; // Handle invalid input
    }
  }

  // get city name
  // const getCity = (loc) => {
  //   let city = loc?.split("-")[2]?.toUpperCase();
  //   return city;
  // };

  ///////////////////
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  // find combine fare for outbound and inbound
  // const combineFare = (current) => {
  //   let total =
  //     current +
  //     lowestFarePrice(selectedFlight ? selectedFlight?.fare?.FARE_PAX_WISE : boundToggle === "inBound" ? selectedFare?.FARE_PAX_WISE : data?.inbound?.BAGGAGE_FARE[0]?.FARE_PAX_WISE);
  //   return TwoDecimalPoint(total);
  // };

  const outBoundformateDate = data?.outbound?.DEPARTURE_DATE;
  const outformattedDate = moment(outBoundformateDate, "DD-MM-YYYY")
    .format("ddd DD-MMM-YY")
    .toUpperCase();
  const inBoundformateDate = data?.inbound?.DEPARTURE_DATE;
  const informattedDate = moment(inBoundformateDate, "DD-MM-YYYY")
    .format("ddd DD-MMM-YY")
    .toUpperCase();


  //combine discount
  // const combineDiscountFare = (current) => {
  //   let total =
  //     current +
  //     DiscountPrice(
  //       selectedFlight
  //         ? selectedFlight?.fare?.FARE_PAX_WISE
  //         : sial?.inbound[0]?.BAGGAGE_FARE[0]?.FARE_PAX_WISE
  //     );
  //   if (total > 0) {
  //     return `Off ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
  //   } return ""

  // };

  const combineBaseFare = (fare, paxType) => {
    let currentFare = fare?.[paxType];
    let groupedFare = boundToggle === "inBound"
      ? selectedFare?.FARE_PAX_WISE?.[paxType]
      : data?.inbound?.BAGGAGE_FARE[0]?.FARE_PAX_WISE?.[paxType];
    return {
      basic: TwoDecimalPoint(Number(currentFare?.BASIC_FARE) + Number(groupedFare?.BASIC_FARE)),
      taxes: TwoDecimalPoint((currentFare?.TOTAL - currentFare?.BASIC_FARE) + (groupedFare?.TOTAL - groupedFare?.BASIC_FARE)),
    };
  };

  const supplierPrice = (fare) => {
    const adult = combineBaseFare(fare, "ADULT");
    const child = combineBaseFare(fare, "CHILD");
    const infant = combineBaseFare(fare, "INFANT");
    return (
      adult.basic + adult.taxes +
      ((child.basic + child.taxes) * searchValues.childCount) +
      ((infant.basic + infant.taxes) * searchValues.infantNo)
    )
  };

  const getTaxes = (fare, paxType) => {
    let groupedFare = boundToggle === "inBound"
      ? selectedFare?.FARE_PAX_WISE?.[paxType]
      : data?.inbound?.BAGGAGE_FARE[0]?.FARE_PAX_WISE?.[paxType];
    return [
      {
        code: "XT",
        amount: Number(fare?.TAX) + Number(groupedFare?.TAX),
      },
      {
        code: "XP",
        amount: Number(fare?.SURCHARGE) + Number(groupedFare?.SURCHARGE),
      },
      {
        code: "XP",
        amount: Number(fare?.FEES) + Number(groupedFare?.FEES),
      },
    ]
  }
  return (
    <>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`sial${index}`}
      >
        <div
          className='shadow cursorpointer d-flex justify-content-center align-items-center' onClick={() => toggleDetail(index)}
        >
          <div className="col-9">
            <AirsialSegments airsial={data} formatDuration={formatDuration} />
          </div>
          <div className="col-3 left-shadow text-center align-self-stretch pt-5">
            {
              !data?.outbound?.BAGGAGE_FARE
                ?
                <h6 className="d-flex flex-column align-items-center justify-content-center result_box_body pt-4 text-danger fs-4">Sold Out</h6>
                :
                <div
                  className={`d-flex flex-column align-items-center justify-content-center result_box_body pt-4`}
                >
                  <>
                    <h6 className="text-danger">
                      {totalDiscount(selectedFlight ? selectedFlight?.outbound?.FARE_PAX_WISE : boundToggle === "outBound" ? data?.outbound?.BAGGAGE_FARE[0]?.FARE_PAX_WISE : data?.inbound?.BAGGAGE_FARE[0]?.FARE_PAX_WISE)}
                    </h6>
                    <div className="d-flex align-items-center gap-3">
                      <div className="h4">
                        {CurrencyCode()}{" "}
                        {
                          lowestFarePrice(selectedFlight ? selectedFlight?.outbound?.FARE_PAX_WISE : boundToggle === "outBound" ? data?.outbound?.BAGGAGE_FARE[0]?.FARE_PAX_WISE : data?.inbound?.BAGGAGE_FARE[0]?.FARE_PAX_WISE)
                        }
                      </div>
                      <img
                        src={"/NEW_LOGO/PF.png"}
                        alt=""
                        width={40}
                        height={40}
                        className="mb-2"
                      />
                    </div>
                    <button
                      onClick={(e) => {
                        toggleDetail(index);
                      }}
                      className="button_style w-75 mb-2 cursorpointer mt-2"
                      type="button"
                    >
                      <div>
                        <MenuOpenIcon className="me-1" />
                        Flight Detail
                      </div>
                    </button>

                  </>
                </div>
            }
          </div>
        </div>


        {isExpanded && data?.outbound?.BAGGAGE_FARE ?
          <div className="p-3">
            <div className="d-flex mb-4 mt-2 flight_details_tabs">
              {["Fare Options", "Flight Details"].map((item, index) => {
                const active = selectedTab === item;
                return (
                  <button
                    key={index}
                    onClick={() => setSelectedTab(item)}
                    className={active ? "active" : ""}
                  >
                    {item}
                  </button>
                );
              })}
            </div>

            <div className="d-flex align-items-center justify-content-between mt-4 mb-2">
              <div
                className={`${boundToggle === "outBound"
                  ? "button_acitve active-box"
                  : "button_style"
                  } rounded-md p-2 w-50 cursorpointer me-2`}
                onClick={() => setBoundToggle("outBound")}
              >
                <div className="d-flex gap-4">
                  <div className="font-medium">
                    {`${data?.outbound?.ORGN} - ${data?.outbound?.DEST}`}
                  </div>
                  <div className="font-medium">{outformattedDate}</div>
                </div>
              </div>

              <div
                className={`${boundToggle === "inBound"
                  ? "button_acitve active-box text-white"
                  : "button_style"
                  } rounded-md p-2 w-50 cursorpointer ms-2`}
                onClick={() => setBoundToggle("inBound")}
              >
                <div className="d-flex gap-4">
                  <div className="font-medium">
                    {`${data?.inbound?.ORGN} - ${data?.inbound?.DEST}`}
                  </div>
                  <div className="font-medium">{informattedDate}</div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div>
                  {selectedTab === "Flight Details" && (
                    <div className="row rounded-2 search_engin_result_box bg-white p-4">
                      <AirsialSegmentsDetails segements={data?.[boundToggle?.toLowerCase()]} formatDuration={formatDuration} boundToggle={boundToggle} />
                    </div>
                  )}
                </div>

                <div>
                  {selectedTab === "Fare Options" && (
                    <div className="row rounded-2 search_engin_result_box bg-white p-4">
                      <AirsialFares
                        fares={data?.[boundToggle.toLowerCase()]}
                        data={data}
                        lowestFarePrice={lowestFarePrice}
                        singlePaxFare={singlePaxFare}
                        boundToggle={boundToggle}
                        setBoundToggle={setBoundToggle}
                        selectedFare={selectedFare}
                        setSelectedFare={setSelectedFare}
                        supplierPrice={supplierPrice}
                        combineBaseFare={combineBaseFare}
                        formatDuration={formatDuration}
                        getTaxes={getTaxes}
                        selectedFlight={selectedFlight}
                        singlePaxForTooltip={singlePaxForTooltip}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div> : null
        }
      </div>
    </>
  );
}

export default AirsialData;