import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  fetchAccountStructure,
  selectAccountData,
  setOpenTrailBalance,
} from "../../../../redux/features/booking/accStructure";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import TrialBalannceTable from "./TrialBalannceTable";
import BtnLoader from "../../../AppForm/BtnLoader";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const TrialBalence = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { data, opentrailBalance } = useSelector(
    (state) => state?.accStructure
  );

  const [dropdownValue, setDropdownValue] = useState("All Type");
  const [showActivity, setShowActivity] = useState(false);

  // State to track whether a date has been selected
  const [tData, setBankData] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });

  // State to track whether the form is submitted
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formattedFromDate = moment(tData.fromDate).format(
    "ddd DD-MMM-YY/HH:mm"
  );
  const formattedToDate = moment(tData.toDate).format("ddd DD-MMM-YY/HH:mm");

  const check = dropdownValue === "OPENING" ? "ACTIVITY" : "";
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const drp = dropdownValue === "OPENING" ? "ACTIVITY" : "";
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch(setOpenTrailBalance("tralBalanceData"));
      dispatch(
        fetchAccountStructure({
          ope: dropdownValue,
          act: drp,
          fromDate: formattedFromDate,
          toDate: formattedToDate,
        })
      );
    }, 2000);
  };

  function getIndentationLevel(account) {
    const level = account.acc_level_code.split("-").length;
    return level * 10; // Adjust the multiplier for desired indentation width
  }

  function groupedAccounts(accounts) {
    const grouped = [];

    // Helper function to find and add child accounts to their parent
    function addChildren(parent, allAccounts) {
      const children = allAccounts.filter(
        (account) => account.acc_parent === parent.acc_id
      );
      children.forEach((child) => {
        grouped.push(child);
        addChildren(child, allAccounts); // Recursively add children's children
      });
    }

    // Start by adding root-level accounts (those with no acc_parent)
    accounts
      ?.filter((account) => account.acc_parent === 0)
      ?.forEach((account) => {
        grouped.push(account);
        addChildren(account, accounts);
      });
    return grouped;
  }

  function calculateClosing(account) {
    // Calculate the total debit and credit from opening and activity
    const totalDebit =
      (account.debitAmount || 0) + (account.activityDebitAmount || 0);
    const totalCredit =
      (account.creditAmount || 0) + (account.activityCreditAmount || 0);

    // Calculate the difference
    const difference = totalDebit - totalCredit;

    // If the difference is positive, it goes to the debit, otherwise to the credit
    if (difference >= 0) {
      return {
        closingDebit: difference,
        closingCredit: 0,
      };
    } else {
      return {
        closingDebit: 0,
        closingCredit: Math.abs(difference), // Use absolute value for credit
      };
    }
  }
  const openingTotals = data?.accountTotals?.reduce(
    (totals, account) => {
      console.log(account);
      if (account.acc_parent === 0 && account.acc_level === 1) {
        totals.debit += account.debitAmount || 0;
        totals.credit += account.creditAmount || 0;
      }
      return totals;
    },
    { debit: 0, credit: 0 }
  );

  const activityTotals = data?.accountTotals?.reduce(
    (totals, account) => {
      // Only calculate totals if acc_parent is 0
      if (account.acc_parent === 0) {
        totals.debit += account.activityDebitAmount || 0;
        totals.credit += account.activityCreditAmount || 0;
      }
      return totals;
    },
    { debit: 0, credit: 0 }
  );

  const closingTotals = data?.accountTotals?.reduce(
    (totals, account) => {
      if (account.acc_parent === 0) {
        totals.debit += account.closingDebitAmount || 0;
        totals.credit += account.closingCreditAmount || 0;
      }
      return totals;
    },
    { debit: 0, credit: 0 }
  );

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setShowActivity(isChecked);

    // Set the dropdown value based on checkbox state
    if (isChecked) {
      setDropdownValue("ACTIVITY");
    }
    //  else {
    //   setDropdownValue("All Type"); // Default value when unchecked
    // }
  };

  const arry = [
    { id: 1, type: "All Type", name: "All Type" },
    { id: 2, type: "OPENING", name: "Opening" },
    { id: 3, type: "ACTIVITY", name: "Activity" },
    { id: 4, type: "CLOSING", name: "Closing" },
  ];
  return (
    <div className="Full_height" style={{ minHeight: "100vh" }}>
      {opentrailBalance === "form" && (
        <div className="manual_invoices mx-auto">
          <div className="manual_invoices_header">
            <div>Trial Balance</div>
          </div>
          <form
            className="container justify-content-center align-items-center py-3"
            onSubmit={handleSubmit}
          >
            <div className="d-flex gap-4">
              <div className="mb-4  w-50">
                {/* <label htmlFor="fromDate" className="form-label">From</label> */}
                <DatePicker
                  placeholderText="From"
                  selected={tData?.fromDate}
                  onChange={(date) => {
                    setBankData((prev) => ({
                      ...prev,
                      fromDate: date,
                    }));
                  }}
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()}
                  className="date_picker"
                />
              </div>
              <div className="mb-4 w-50">
                {/* <label htmlFor="toDate" className="form-label">To</label> */}
                <DatePicker
                  placeholderText="To"
                  selected={tData?.toDate}
                  onChange={(date) => {
                    setBankData((prev) => ({
                      ...prev,
                      toDate: date,
                    }));
                  }}
                  dateFormat="dd-MM-yyyy"
                  minDate={tData?.fromDate}
                  maxDate={new Date()}
                  className="date_picker"
                />
              </div>
            </div>

            <div className="col-6 pe-4">
              <FormControl variant="standard" className="w-100">
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name={dropdownValue}
                  value={dropdownValue} // Bind state to dropdown value
                  onChange={(e) => setDropdownValue(e.target.value)} 
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        maxWidth: "250px",
                        overflowY: "auto",
                      },
                    },
                  }}
                  label="Services"
                >
                  {arry?.map((list) => (
                    <MenuItem
                      value={list?.type}
                      sx={{ bgcolor: "#fff" }}
                      key={list?.id}
                    >
                      {list?.type?.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* <div className="mb-4 mt-4 " style={{ width: "250px",backgroundColor: "white" }}>
            <select
              className="form-select w-70 "
              id="dropdown"
              value={dropdownValue}
              onChange={(e) => setDropdownValue(e.target.value)} 
              style={{ backgroundColor: "white" }} 
            >
              <option value="All Type">All Type</option>
              <option value="OPENING">Opening</option>
              <option value="ACTIVITY">Activity</option>
              <option value="CLOSING">Closing</option>
            </select>
          </div> */}

            {/* <div className="checkbox-rect d-flex justify-content-center form-check mb-4 mt-5">
              <input
                type="checkbox"
                className="form-check-input"
                id="checkbox"
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="checkbox">
                Show Activity
              </label>
            </div> */}

            <div className="d-flex justify-content-center mt-4">
              {!loading ? (
                <div className="text-center py-4">
                  <button
                    type="submit"
                    className="button_style px-4 "
                    disabled={loading}
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <div className="text-center py-4">
                  <button className="button_style px-4">
                    <BtnLoader />
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      )}
      {opentrailBalance === "tralBalanceData" && (
        <TrialBalannceTable
          fromDate={tData.fromDate}
          toDate={tData.toDate}
          dropdownValue={dropdownValue}
          activityTotals={activityTotals}
          closingTotals={closingTotals}
          openingTotals={openingTotals}
          isSubmitted={isSubmitted}
          groupedAccounts={groupedAccounts}
          accountData={data}
          getIndentationLevel={getIndentationLevel}
          calculateClosing={calculateClosing}
        />
      )}
    </div>
  );
};

export default TrialBalence;

// {isSubmitted && (
//   <div
//     className="container d-flex justify-content-end mt-1"
//     style={{ height: "20%" }}
//   >
//     <table
//       className="table table-striped table-sm"
//       style={{ width: "70%", height: "20%", fontSize: "0.8rem" }}
//     >
//       <thead>
//         <tr>
//           <th
//             style={{
//               border: "1px solid black",
//               padding: "8px",
//               textAlign: "center",
//             }}
//           >
//             Account
//           </th>
//           {dropdownValue !== "ACTIVITY" && (
//             <>
//               <th
//                 colSpan={2}
//                 style={{
//                   border: "1px solid black",
//                   padding: "8px",
//                   textAlign: "center",
//                 }}
//               >
//                 Opening
//               </th>
//             </>
//           )}
//           {dropdownValue !== "OPENING" && (
//             <>
//               <th
//                 colSpan={2}
//                 style={{
//                   border: "1px solid black",
//                   padding: "8px",
//                   textAlign: "center",
//                 }}
//               >
//                 Activity
//               </th>
//             </>
//           )}
//           {dropdownValue === "CLOSING"  && (
//             <>
//               <th
//                 colSpan={2}
//                 style={{
//                   border: "1px solid black",
//                   padding: "8px",
//                   textAlign: "center",
//                 }}
//               >
//                 Balance
//               </th>
//             </>
//           )}
//         </tr>
//         <tr className="align-item-center">
//           <th
//             style={{
//               border: "1px solid black",
//               padding: "8px",
//               textAlign: "center",
//             }}
//           >
//             Name
//           </th>
//           {dropdownValue !== "ACTIVITY" && (
//             <>
//               <th
//                 style={{
//                   border: "1px solid black",
//                   padding: "8px",
//                   textAlign: "center",
//                 }}
//               >
//                 Debit
//               </th>
//               <th
//                 style={{
//                   border: "1px solid black",
//                   padding: "8px",
//                   textAlign: "center",
//                 }}
//               >
//                 Credit
//               </th>
//             </>
//           )}
//           {dropdownValue !== "OPENING" && (
//             <>
//               <th
//                 style={{
//                   border: "1px solid black",
//                   padding: "8px",
//                   textAlign: "center",
//                 }}
//               >
//                 Debit
//               </th>
//               <th
//                 style={{
//                   border: "1px solid black",
//                   padding: "8px",
//                   textAlign: "center",
//                 }}
//               >
//                 Credit
//               </th>
//             </>
//           )}
//           {dropdownValue === "CLOSING" && (
//             <>
//               <th
//                 style={{
//                   border: "1px solid black",
//                   padding: "8px",
//                   textAlign: "center",
//                 }}
//               >
//                 Debit
//               </th>
//               <th
//                 style={{
//                   border: "1px solid black",
//                   padding: "8px",
//                   textAlign: "center",
//                 }}
//               >
//                 Credit
//               </th>
//             </>
//           )}
//         </tr>

//       </thead>

//     <tbody>
//       {groupedAccounts(accountData?.accountTotals).map((account, index) => {
//         const { closingDebit, closingCredit } = calculateClosing(account);

//         return (
//           <tr key={index}>
//             <td
//               style={{
//                 border: "1px solid black",
//                 paddingLeft: `${getIndentationLevel(account)}px`,
//                 fontWeight:
//                   account.acc_nature === "SUBSIDIARY" ? "normal" : "bold",
//               }}
//             >
//               {account.acc_title}
//             </td>

//             {/* Opening Debit and Credit */}
//             {dropdownValue !== "ACTIVITY" && (
//               <>
//                 <td style={{ border: "1px solid black", padding: "2px" }}>
//                   {account.debitAmount}
//                 </td>
//                 <td style={{ border: "1px solid black", padding: "2px" }}>
//                   {account.creditAmount}
//                 </td>
//               </>
//             )}

//             {/* Activity Debit and Credit */}
//             {dropdownValue !== "OPENING" && (
//               <>
//                 <td style={{ border: "1px solid black", padding: "2px" }}>
//                   {account.activityDebitAmount}
//                 </td>
//                 <td style={{ border: "1px solid black", padding: "2px" }}>
//                   {account.activityCreditAmount}
//                 </td>
//               </>
//             )}

//             {/* Closing Debit and Credit */}
//             {dropdownValue === "CLOSING" && (
//               <>
//                 <td style={{ border: "1px solid black", padding: "2px" }}>
//                   {closingDebit}
//                 </td>
//                 <td style={{ border: "1px solid black", padding: "2px" }}>
//                   {closingCredit}
//                 </td>
//               </>
//             )}

//           </tr>

//         );
//       })}
//       {/* Totals Row */}
//     <tr>
//       <td style={{ border: "1px solid black", textAlign: "center", fontWeight: "bold" }}>
//         Total
//       </td>

//       {/* Opening Totals */}
//       {dropdownValue !== "ACTIVITY" && (
//         <>
//           <td style={{ border: "1px solid black", padding: "2px" }}>
//             {openingTotals?.debit}
//           </td>
//           <td style={{ border: "1px solid black", padding: "2px" }}>
//             {openingTotals?.credit}
//           </td>
//         </>
//       )}

//       {/* Activity Totals */}
//       {dropdownValue !== "OPENING" && (
//         <>
//           <td style={{ border: "1px solid black", padding: "2px" }}>
//             {activityTotals?.debit}
//           </td>
//           <td style={{ border: "1px solid black", padding: "2px" }}>
//             {activityTotals?.credit}
//           </td>
//         </>
//       )}

//       {/* Closing Totals */}
//       {dropdownValue === "CLOSING" && (
//         <>
//           <td style={{ border: "1px solid black", padding: "2px" }}>
//             {closingTotals?.debit}
//           </td>
//           <td style={{ border: "1px solid black", padding: "2px" }}>
//             {closingTotals?.credit}
//           </td>
//         </>
//       )}
//     </tr>
//     </tbody>

//     </table>
//   </div>
// )}
