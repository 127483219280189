import React, { useEffect } from "react";
import Table from "react-bootstrap/Table";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";

const FligthSummary = ({ data, setFieldValue }) => {
  // Rate oF exchange
  const ROE = Number(data?.fareValues?.at(0)?.roe);
  // if the selected currency is pkr
  const isPkr =
    data?.fareValues?.at(0)?.currency?.curr_code === "PKR" ? true : false;
  // selected currency code
  const currCode = data?.fareValues?.at(0)?.currency?.curr_code;
  //total adults
  const NoAdults = data?.fareValues?.filter(
    (passenger) => passenger.pax_type === "ADULT"
  );
  //total child
  const NoChild = data?.fareValues?.filter(
    (passenger) => passenger.pax_type === "CHILD"
  );
  //total infant
  const NoInfant = data?.fareValues?.filter(
    (passenger) => passenger.pax_type === "INFANT"
  );
  ////////////////////// ALL FUNCTIONS FARE SUMMARY //////////////////////
  // adults total fare
  const adultTotalFare = NoAdults?.reduce(
    (sum, passenger) => sum + Number(passenger.eq_b_fare),
    0
  );
  // child total fare
  const childTotalFare = NoChild?.reduce(
    (sum, passenger) => sum + Number(passenger.eq_b_fare),
    0
  );
  // infant total fare
  const infantTotalFare = NoInfant?.reduce(
    (sum, passenger) => sum + Number(passenger.eq_b_fare),
    0
  );
  // adult total taxes
  const AdulttotalTaxAmount = NoAdults?.reduce((sum, passenger) => {
    const passengerTaxAmount = passenger.text_pkr.reduce((taxSum, taxItem) => {
      // Use Number.isNaN to check for NaN data
      const taxAmount = Number(taxItem.tax_pkr_amount);
      return !Number.isNaN(taxAmount) ? taxSum + taxAmount : taxSum;
    }, 0);

    // Use Number.isNaN to check for NaN data
    return !Number.isNaN(passengerTaxAmount) ? sum + passengerTaxAmount : sum;
  }, 0);

  // child total taxes
  const ChildtotalTaxAmount = NoChild?.reduce((sum, passenger) => {
    const passengerTaxAmount = passenger.text_pkr.reduce((taxSum, taxItem) => {
      // Use Number.isNaN to check for NaN data
      const taxAmount = Number(taxItem.tax_pkr_amount);
      return !Number.isNaN(taxAmount) ? taxSum + taxAmount : taxSum;
    }, 0);

    // Use Number.isNaN to check for NaN data
    return !Number.isNaN(passengerTaxAmount) ? sum + passengerTaxAmount : sum;
  }, 0);

  // infant total taxes
  const InfanttotalTaxAmount = NoInfant?.reduce((sum, passenger) => {
    const passengerTaxAmount = passenger.text_pkr.reduce((taxSum, taxItem) => {
      // Use Number.isNaN to check for NaN data
      const taxAmount = Number(taxItem.tax_pkr_amount);
      return !Number.isNaN(taxAmount) ? taxSum + taxAmount : taxSum;
    }, 0);

    // Use Number.isNaN to check for NaN data
    return !Number.isNaN(passengerTaxAmount) ? sum + passengerTaxAmount : sum;
  }, 0);

  ///////////////////////// commulative total fare all pax
  const commulativeTotalFareAll = () => {
    const totalCurrFare = adultTotalFare + childTotalFare + infantTotalFare;
    const totalCurrTax =
      AdulttotalTaxAmount + ChildtotalTaxAmount + InfanttotalTaxAmount;
    const totalPkrFare = adultTotalFare + childTotalFare + infantTotalFare;
    const totalPkrTax =
      AdulttotalTaxAmount + ChildtotalTaxAmount + InfanttotalTaxAmount;
    return {
      inCur: TwoDecimalPoint(totalCurrFare / ROE + totalCurrTax / ROE),
      inPkr: TwoDecimalPoint(totalPkrFare + totalPkrTax),
    };
  };

  /////////////////////////// FUNCTIONS DISCOUNT AND PSF ///////////////////////////////////
  // adults psf discount
  const adltPfsDisc = () => {
    const agentPSF = NoAdults?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_psf),
      0
    );
    const suppPSF = NoAdults?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_psf),
      0
    );
    const suppDiscount = NoAdults?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_sp),
      0
    );
    const agentDiscount = NoAdults?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_sp),
      0
    );
    return {
      suppPSF: suppPSF / ROE,
      agentPSF: agentPSF / ROE,
      suppDiscount: suppDiscount / ROE,
      agentDiscount: agentDiscount / ROE,

      suppPSFpkr: suppPSF,
      agentPSFpkr: agentPSF,
      suppDiscountpkr: suppDiscount,
      agentDiscountpkr: agentDiscount,
    };
  };
  // child psf discount
  const CHDPfsDisc = () => {
    const agentPSF = NoChild?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_psf),
      0
    );
    const suppPSF = NoChild?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_psf),
      0
    );
    const suppDiscount = NoChild?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_sp),
      0
    );
    const agentDiscount = NoChild?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_sp),
      0
    );
    return {
      suppPSF: suppPSF / ROE,
      agentPSF: agentPSF / ROE,
      suppDiscount: suppDiscount / ROE,
      agentDiscount: agentDiscount / ROE,

      suppPSFpkr: suppPSF,
      agentPSFpkr: agentPSF,
      suppDiscountpkr: suppDiscount,
      agentDiscountpkr: agentDiscount,
    };
  };
  // infant psf discount
  const INFPfsDisc = () => {
    const agentPSF = NoInfant?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_psf),
      0
    );
    const suppPSF = NoInfant?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_psf),
      0
    );
    const suppDiscount = NoInfant?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_sp),
      0
    );
    const agentDiscount = NoInfant?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_sp),
      0
    );
    return {
      suppPSF: suppPSF / ROE,
      agentPSF: agentPSF / ROE,
      suppDiscount: suppDiscount / ROE,
      agentDiscount: agentDiscount / ROE,

      suppPSFpkr: suppPSF,
      agentPSFpkr: agentPSF,
      suppDiscountpkr: suppDiscount,
      agentDiscountpkr: agentDiscount,
    };
  };

  /// commulative PSF AND DISCOUNTS /////
  const commulativePSFDisc = () => {
    const agt_total_cur =
      adltPfsDisc().agentPSFpkr +
      adltPfsDisc().agentDiscountpkr +
      CHDPfsDisc().agentPSFpkr +
      CHDPfsDisc().agentDiscountpkr +
      INFPfsDisc().agentPSFpkr +
      INFPfsDisc().agentDiscountpkr;
    const supp_total_cur =
      adltPfsDisc().suppPSFpkr +
      adltPfsDisc().suppDiscountpkr +
      CHDPfsDisc().suppPSFpkr +
      CHDPfsDisc().suppDiscountpkr +
      INFPfsDisc().suppPSFpkr +
      INFPfsDisc().suppDiscountpkr;
    return {
      agent_pkr: agt_total_cur,
      agent_cur: agt_total_cur / ROE,
      supp_pkr: supp_total_cur,
      supp_cur: supp_total_cur / ROE,
    };
  };

  /////////////////////////// FUNCTIONS for GST AND WHT ///////////////////////////////////
  // adults gst wht
  const adltGSTWHT = () => {
    const agentGST = NoAdults?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_gst),
      0
    );
    const suppGST = NoAdults?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_gst),
      0
    );
    const suppWHT = NoAdults?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_wht),
      0
    );
    const agentWHT = NoAdults?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_wht),
      0
    );
    return {
      suppGST: suppGST / ROE,
      agentGST: agentGST / ROE,
      suppWHT: suppWHT / ROE,
      agentWHT: agentWHT / ROE,

      suppGSTpkr: suppGST,
      agentGSTpkr: agentGST,
      suppWHTpkr: suppWHT,
      agentWHTpkr: agentWHT,
    };
  };
  // child gst wht
  const CHDGSTWHT = () => {
    const agentGST = NoChild?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_gst),
      0
    );
    const suppGST = NoChild?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_gst),
      0
    );
    const suppWHT = NoChild?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_wht),
      0
    );
    const agentWHT = NoChild?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_wht),
      0
    );
    return {
      suppGST: suppGST / ROE,
      agentGST: agentGST / ROE,
      suppWHT: suppWHT / ROE,
      agentWHT: agentWHT / ROE,

      suppGSTpkr: suppGST,
      agentGSTpkr: agentGST,
      suppWHTpkr: suppWHT,
      agentWHTpkr: agentWHT,
    };
  };
  // infant gst wht
  const INFGSTWHT = () => {
    const agentGST = NoInfant?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_gst),
      0
    );
    const suppGST = NoInfant?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_gst),
      0
    );
    const suppWHT = NoInfant?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.s_wht),
      0
    );
    const agentWHT = NoInfant?.reduce(
      (sum, passenger) => sum + Number(passenger?.tax_pkr?.c_wht),
      0
    );
    return {
      suppGST: suppGST / ROE,
      agentGST: agentGST / ROE,
      suppWHT: suppWHT / ROE,
      agentWHT: agentWHT / ROE,

      suppGSTpkr: suppGST,
      agentGSTpkr: agentGST,
      suppWHTpkr: suppWHT,
      agentWHTpkr: agentWHT,
    };
  };

  ///////// commulative GST AND WHT /////////
  const commulativeGSTWHT = () => {
    const agt_total_cur =
      adltGSTWHT().agentGSTpkr +
      adltGSTWHT().agentWHTpkr +
      CHDGSTWHT().agentGSTpkr +
      CHDGSTWHT().agentWHTpkr +
      INFGSTWHT().agentGSTpkr +
      INFGSTWHT().agentWHTpkr;
    const supp_total_cur =
      adltGSTWHT().suppGSTpkr +
      adltGSTWHT().suppWHTpkr +
      CHDGSTWHT().suppGSTpkr +
      CHDGSTWHT().suppWHTpkr +
      INFGSTWHT().suppGSTpkr +
      INFGSTWHT().suppWHTpkr;
    return {
      agent_pkr: agt_total_cur,
      agent_cur: agt_total_cur / ROE,
      supp_pkr: supp_total_cur,
      supp_cur: supp_total_cur / ROE,
    };
  };

  ////////////////////////// total fare,Taxes,gds,wht,psf,sp for all pax ///////////////

  const totalFareAllPax = adultTotalFare + childTotalFare + infantTotalFare;
  const totalTaxesAllPax =
    AdulttotalTaxAmount + ChildtotalTaxAmount + InfanttotalTaxAmount;
  const totalPsf_supp =
    (adltPfsDisc().suppPSF + CHDPfsDisc().suppPSF + INFPfsDisc().suppPSF) * ROE;
  const totalSP_supp =
    (adltPfsDisc().suppDiscount +
      CHDPfsDisc().suppDiscount +
      INFPfsDisc().suppDiscount) *
    ROE;
  const totalPsf_agent =
    (adltPfsDisc().agentPSF + CHDPfsDisc().agentPSF + INFPfsDisc().agentPSF) *
    ROE;
  const totalWHT_agent =
    adltGSTWHT().agentWHTpkr +
    CHDGSTWHT().agentWHTpkr +
    INFGSTWHT().agentWHTpkr;
  const totalWHT_supp =
    adltGSTWHT().suppWHTpkr + CHDGSTWHT().suppWHTpkr + INFGSTWHT().suppWHTpkr;
  const totalGST_agent =
    adltGSTWHT().agentGSTpkr +
    CHDGSTWHT().agentGSTpkr +
    INFGSTWHT().agentGSTpkr;
  const totalGST_supp =
    adltGSTWHT().suppGSTpkr + CHDGSTWHT().suppGSTpkr + INFGSTWHT().suppGSTpkr;
  const totalSP_agent =
    (adltPfsDisc().agentDiscount +
      CHDPfsDisc().agentDiscount +
      INFPfsDisc().agentDiscount) *
    ROE;

  const finalTotalAgent =
    totalFareAllPax +
    totalTaxesAllPax +
    totalPsf_agent -
    totalSP_agent +
    totalWHT_agent +
    totalGST_agent + (data?.fareValues?.at(0)?.other_income_type === 'Disc-Given' ? (-data?.fareValues?.at(0)?.other_income) : data?.fareValues?.at(0)?.other_income);

  const finalTotalSupplier =
    totalFareAllPax +
    totalTaxesAllPax +
    totalPsf_supp -
    totalSP_supp +
    totalWHT_supp +
    totalGST_supp;

  useEffect(() => {
    setFieldValue("ttl_agent_fare_pkr", finalTotalAgent);
    setFieldValue("ttl_supplier_fare_pkr", finalTotalSupplier);
  }, [finalTotalAgent, finalTotalSupplier]);

  return (
    <div className="manual_invoice_footer p-4 px-0">
      <div className="row">
        <div className="col-3">
          {/* ***************************** Fare Summary ****************************** */}
          <Table bordered hover className="flight_table">
            <thead>
              <tr>
                <th colSpan={6}>
                  <h5 className="text-center py-1">Fare Summary</h5>
                </th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th colSpan={2}>
                  <h6>Agent</h6>
                </th>
                <th colSpan={2}>
                  <h6>Supplier</h6>
                </th>
              </tr>
              <tr>
                <th className=""></th>
                <th className=""></th>
                <th className="fixed_width_column">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column">
                  {!isPkr && <h6>{currCode}</h6>}
                </th>
                <th className="fixed_width_column">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column">
                  {!isPkr && <h6>{currCode}</h6>}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Fare</td>
                <td>{NoAdults?.length || 0} * ADT</td>
                <td>{TwoDecimalPoint(adultTotalFare)}</td>
                <td>{!isPkr && TwoDecimalPoint(adultTotalFare / ROE)}</td>
                <td>{TwoDecimalPoint(adultTotalFare)}</td>
                <td>{!isPkr && TwoDecimalPoint(adultTotalFare / ROE)}</td>
              </tr>
              <tr>
                <td>Taxes</td>
                <td>{NoAdults?.length || 0} * ADT</td>
                <td>{TwoDecimalPoint(AdulttotalTaxAmount)}</td>
                <td>{!isPkr && TwoDecimalPoint(AdulttotalTaxAmount / ROE)}</td>
                <td>{TwoDecimalPoint(AdulttotalTaxAmount)}</td>
                <td>{!isPkr && TwoDecimalPoint(AdulttotalTaxAmount / ROE)}</td>
              </tr>
              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Fare</td>
                <td>{NoChild?.length || 0} * CHD</td>
                <td>{TwoDecimalPoint(childTotalFare)}</td>
                <td>{!isPkr && TwoDecimalPoint(childTotalFare / ROE)}</td>
                <td>{TwoDecimalPoint(childTotalFare)}</td>
                <td>{!isPkr && TwoDecimalPoint(childTotalFare / ROE)}</td>
              </tr>
              <tr>
                <td>Taxes</td>
                <td>{NoChild?.length || 0} * CHD</td>
                <td>{TwoDecimalPoint(ChildtotalTaxAmount)}</td>
                <td>{!isPkr && TwoDecimalPoint(ChildtotalTaxAmount / ROE)}</td>
                <td>{TwoDecimalPoint(ChildtotalTaxAmount)}</td>
                <td>{!isPkr && TwoDecimalPoint(ChildtotalTaxAmount / ROE)}</td>
              </tr>
              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Fare</td>
                <td>{NoInfant?.length || 0} * INF</td>
                <td>{TwoDecimalPoint(infantTotalFare)}</td>
                <td>{!isPkr && TwoDecimalPoint(infantTotalFare / ROE)}</td>
                <td>{TwoDecimalPoint(infantTotalFare)}</td>
                <td>{!isPkr && TwoDecimalPoint(infantTotalFare / ROE)}</td>
              </tr>
              <tr>
                <td>Taxes</td>
                <td>{NoInfant?.length || 0} * INF</td>
                <td>{TwoDecimalPoint(InfanttotalTaxAmount)}</td>
                <td>{!isPkr && TwoDecimalPoint(InfanttotalTaxAmount / ROE)}</td>
                <td>{TwoDecimalPoint(InfanttotalTaxAmount)}</td>
                <td>{!isPkr && TwoDecimalPoint(InfanttotalTaxAmount / ROE)}</td>
              </tr>
              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Total</td>
                <td></td>
                <td>{commulativeTotalFareAll()?.inPkr}</td>
                <td>{!isPkr && commulativeTotalFareAll()?.inCur}</td>
                <td>{commulativeTotalFareAll()?.inPkr}</td>
                <td>{!isPkr && commulativeTotalFareAll()?.inCur}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* **************************** Discount Psf **************************** */}
        <div className="col-3">
          <Table bordered hover>
            <thead>
              <tr>
                <th colSpan={6}>
                  <h5 className="text-center py-1">Discount / PSF</h5>
                </th>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <th colSpan={2}>
                  <h6>Agent</h6>
                </th>
                <th colSpan={2}>
                  <h6>Supplier</h6>
                </th>
              </tr>
              <tr>
                <td className="fixed_width_column"></td>
                <td className="fixed_width_column"></td>
                <th className="fixed_width_column">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column">
                  {!isPkr && <h6>{currCode}</h6>}
                </th>
                <th className="fixed_width_column">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column">
                  {!isPkr && <h6>{currCode}</h6>}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PSF</td>
                <td>{NoAdults?.length || 0} * ADT</td>
                <td>{TwoDecimalPoint(adltPfsDisc().agentPSFpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(adltPfsDisc().agentPSF)}</td>
                <td>{TwoDecimalPoint(adltPfsDisc().suppPSFpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(adltPfsDisc().suppPSF)}</td>
              </tr>
              <tr>
                <td>DISC</td>
                <td>{NoAdults?.length || 0} * ADT</td>
                <td>{TwoDecimalPoint(adltPfsDisc().agentDiscountpkr)}</td>
                <td>
                  {!isPkr && TwoDecimalPoint(adltPfsDisc().agentDiscount)}
                </td>
                <td>{TwoDecimalPoint(adltPfsDisc().suppDiscountpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(adltPfsDisc().suppDiscount)}</td>
              </tr>
              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>PSF</td>
                <td style={{ minWidth: "3.4rem" }}>
                  {NoChild?.length || 0} * CHD
                </td>
                <td>{TwoDecimalPoint(CHDPfsDisc().agentPSFpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(CHDPfsDisc().agentPSF)}</td>
                <td>{TwoDecimalPoint(CHDPfsDisc().suppPSFpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(CHDPfsDisc().suppPSF)}</td>
              </tr>
              <tr>
                <td>DISC</td>
                <td style={{ minWidth: "3.4rem" }}>
                  {NoChild?.length || 0} * CHD
                </td>
                <td>{TwoDecimalPoint(CHDPfsDisc().agentDiscountpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(CHDPfsDisc().agentDiscount)}</td>
                <td>{TwoDecimalPoint(CHDPfsDisc().suppDiscountpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(CHDPfsDisc().suppDiscount)}</td>
              </tr>

              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>PSF</td>
                <td>{NoInfant?.length || 0} * INF</td>
                <td>{TwoDecimalPoint(INFPfsDisc().agentPSFpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(INFPfsDisc().agentPSF)}</td>
                <td>{TwoDecimalPoint(INFPfsDisc().suppPSFpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(INFPfsDisc().suppPSF)}</td>
              </tr>
              <tr>
                <td>DISC</td>
                <td>{NoInfant?.length || 0} * INF</td>
                <td>{TwoDecimalPoint(INFPfsDisc().agentDiscountpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(INFPfsDisc().agentDiscount)}</td>
                <td>{TwoDecimalPoint(INFPfsDisc().suppDiscountpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(INFPfsDisc().suppDiscount)}</td>
              </tr>
              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Total</td>
                <td></td>
                <td>{TwoDecimalPoint(commulativePSFDisc().agent_pkr)}</td>
                <td>
                  {!isPkr && TwoDecimalPoint(commulativePSFDisc().agent_cur)}
                </td>
                <td>{TwoDecimalPoint(commulativePSFDisc().supp_pkr)}</td>
                <td>
                  {!isPkr && TwoDecimalPoint(commulativePSFDisc().supp_cur)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* *********************** wht and gst *************************** */}
        <div className="col-3">
          <Table responsive bordered hover>
            <thead>
              <tr>
                <th colSpan={6}>
                  <h5 className="text-center py-1">GST / WHT</h5>
                </th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th colSpan={2}>
                  <h6>Agent</h6>{" "}
                </th>
                <th colSpan={2}>
                  <h6>Supplier</h6>
                </th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th className="fixed_width_column">
                  <h6>PKRr</h6>{" "}
                </th>
                <th className="fixed_width_column">
                  {!isPkr && <h6>{currCode}</h6>}
                </th>
                <th className="fixed_width_column">
                  <h6>PKR</h6>{" "}
                </th>
                <th className="fixed_width_column">
                  {!isPkr && <h6>{currCode}</h6>}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>GST</td>
                <td>{NoAdults?.length || 0} * ADT</td>
                <td>{TwoDecimalPoint(adltGSTWHT().agentGSTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(adltGSTWHT().agentGST)}</td>
                <td>{TwoDecimalPoint(adltGSTWHT().suppGSTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(adltGSTWHT().suppGST)}</td>
              </tr>
              <tr>
                <td>WHT</td>
                <td>{NoAdults?.length || 0} * ADT</td>
                <td>{TwoDecimalPoint(adltGSTWHT().agentWHTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(adltGSTWHT().agentWHT)}</td>
                <td>{TwoDecimalPoint(adltGSTWHT().suppWHTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(adltGSTWHT().suppWHT)}</td>
              </tr>
              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>GST</td>
                <td>{NoChild?.length || 0} * CHD</td>
                <td>{TwoDecimalPoint(CHDGSTWHT().agentGSTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(CHDGSTWHT().agentGST)}</td>
                <td>{TwoDecimalPoint(CHDGSTWHT().suppGSTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(CHDGSTWHT().suppGST)}</td>
              </tr>
              <tr>
                <td>WHT</td>
                <td>{NoChild?.length || 0} * CHD</td>
                <td>{TwoDecimalPoint(CHDGSTWHT().agentWHTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(CHDGSTWHT().agentWHT)}</td>
                <td>{TwoDecimalPoint(CHDGSTWHT().suppWHTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(CHDGSTWHT().suppWHT)}</td>
              </tr>
              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>GST</td>
                <td>{NoInfant?.length || 0} * INF</td>
                <td>{TwoDecimalPoint(INFGSTWHT().agentGSTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(INFGSTWHT().agentGST)}</td>
                <td>{TwoDecimalPoint(INFGSTWHT().suppGSTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(INFGSTWHT().suppGST)}</td>
              </tr>
              <tr>
                <td>WHT</td>
                <td>{NoInfant?.length || 0} * INF</td>
                <td>{TwoDecimalPoint(INFGSTWHT().agentWHTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(INFGSTWHT().agentWHT)}</td>
                <td>{TwoDecimalPoint(INFGSTWHT().suppWHTpkr)}</td>
                <td>{!isPkr && TwoDecimalPoint(INFGSTWHT().suppWHT)}</td>
              </tr>
              <tr>
                <td className="py-3"></td>
                <td></td>
                <td> </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Total</td>
                <td></td>
                <td>{TwoDecimalPoint(commulativeGSTWHT().agent_pkr)}</td>
                <td>
                  {!isPkr && TwoDecimalPoint(commulativeGSTWHT().agent_cur)}
                </td>
                <td>{TwoDecimalPoint(commulativeGSTWHT().supp_pkr)}</td>
                <td>
                  {!isPkr && TwoDecimalPoint(commulativeGSTWHT().supp_cur)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* *************************** total section table **************************** */}
        <div className="col-3">
          <Table bordered hover>
            <thead>
              <tr>
                <th colSpan={4}>
                  <h5 className="text-center py-1">Total Summary(PKR)</h5>
                </th>
              </tr>
              <tr>
                <th></th>
                {/* <th style={{ minWidth: "3rem" }}></th> */}
                <th>
                  <h6>Agent</h6>{" "}
                </th>
                <th>
                  <h6>Supplier</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Fare</td>
                {/* <td></td> */}
                <td>{TwoDecimalPoint(totalFareAllPax)}</td>
                <td>{TwoDecimalPoint(totalFareAllPax)}</td>
              </tr>

              <tr>
                <td>Taxes</td>
                {/* <td></td> */}
                <td>{TwoDecimalPoint(totalTaxesAllPax)}</td>
                <td>{TwoDecimalPoint(totalTaxesAllPax)}</td>
              </tr>
              <tr>
                <td>PSF</td>
                {/* <td></td> */}
                <td>{TwoDecimalPoint(totalPsf_agent)}</td>
                <td>{TwoDecimalPoint(totalPsf_supp)}</td>
              </tr>
              <tr>
                <td>DISC</td>
                {/* <td></td> */}
                <td>{TwoDecimalPoint(totalSP_agent)}</td>
                <td>{TwoDecimalPoint(totalSP_supp)}</td>
              </tr>
              <tr>
                <td>WHT</td>
                {/* <td></td> */}
                <td>{TwoDecimalPoint(totalWHT_agent)}</td>
                <td>{TwoDecimalPoint(totalWHT_supp)}</td>
              </tr>
              <tr>
                <td>GST</td>
                {/* <td></td> */}
                <td>{TwoDecimalPoint(totalGST_agent)}</td>
                <td>{TwoDecimalPoint(totalGST_supp)}</td>
              </tr>
              <tr>
                <td className="py-3">Other Income({data?.fareValues?.at(0)?.other_income_type})</td>
                {/* <td></td> */}
                <td>{data?.fareValues?.at(0)?.other_income}</td>
                <td></td>
              </tr>

              <tr>
                <td>Total</td>
                {/* <td></td> */}
                <td>{TwoDecimalPoint(finalTotalAgent)}</td>
                <td>{TwoDecimalPoint(finalTotalSupplier)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default FligthSummary;
