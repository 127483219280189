import { Autocomplete, TextField } from "@mui/material";

import React from "react";
import {
  asyncGetDestinationAirportsName,
  asyncGetOriginAirportsName,
} from "../../../redux/features/home/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { alphabet, menuProps, optionArray } from "./helper";
import ReactDatePicker from "react-datepicker";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

function ExclusiveRegion({ setFieldValue, values }) {
  const dispatch = useDispatch();

  const { originAirportList, destinatioAirportList } = useSelector(
    (state) => state.home
  );
  const fromSectors = originAirportList?.map((it) => it.code);

  const toSectors = destinatioAirportList?.map((it) => it.code);

  return (
    <div className="p-3 blue_border mt-3 rounded-2">
      <h4>Exclusive Flight Sectors</h4>
      <div className="row mt-4">
        <div className="col-3 p-2">
          <div className="black_border rounded p-3 pt-2">
            <h5>Origin</h5>
            <div className="p-3 px-2 pt-1">
              <Autocomplete
                multiple
                id="tags-standard"
                options={fromSectors}
                value={values.from}
                onChange={(event, newValue) => {
                  setFieldValue("from", newValue);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Origin (Region)"
                    placeholder="Search Region"
                    onChange={(e) => {
                      let value = e.target.value;
                      dispatch(asyncGetOriginAirportsName(value));
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="col-3 p-2">
          <div className="black_border rounded p-3 pt-2">
            <h5>Destination</h5>
            <div className="p-3 px-2 pt-1">
              <Autocomplete
                multiple
                id="tags-standard"
                options={toSectors}
                value={values.to}
                onChange={(event, newValue) => {
                  setFieldValue("to", newValue);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Destination (Region)"
                    placeholder="Search Region"
                    onChange={(e) => {
                      let value = e.target.value;
                      dispatch(asyncGetDestinationAirportsName(value));
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="col-2 p-2">
          <div className="black_border rounded p-3 pt-2">
            <h5>RBD Code</h5>
            <div className="p-3 px-2 pt-1">
              <Autocomplete
                multiple
                id="tags-standard"
                options={alphabet}
                value={values.rbd}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  setFieldValue("rbd", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Select RBD Code"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="col-2 p-2">
          <div className="black_border rounded p-3 pt-2">
            <h5>Departure Date</h5>
            <div className="p-3 px-2 pt-4">
              <ReactDatePicker
                placeholderText="Select Date"
                selected={values.travel_date}
                dateFormat="MMMM d, yyyy"
                onChange={(date) => {
                  setFieldValue("travel_date", date);
                }}
                className="date_picker"
              />
            </div>
          </div>
        </div>
        <div className="col-2 p-2">
          <div className="black_border rounded p-3 pt-2 pb-4">
            <h5>Price Options</h5>
            <div className="d-flex justify-content-between py-1 px-2">
              <div className="col-6">
                <FormControl variant="standard" className="w-100">
                  <InputLabel id="price_option">Option</InputLabel>
                  <Select
                    labelId="price_option"
                    id="price_option"
                    name="price_option"
                    value={values.price_option}
                    onChange={(e) => {
                      let value = e.target.value;
                      setFieldValue("price_option", value);
                      if (value === "Net Net") {
                        setFieldValue("price_amount", 0);
                      } else {
                        setFieldValue("price_amount", 1);
                      }
                    }}
                    MenuProps={menuProps}
                  >
                    {optionArray?.map((op, ind) => (
                      <MenuItem value={op} key={ind}>
                        {op}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-5">
                <TextField
                  variant="standard"
                  label={"In percent %"}
                  className="w-100"
                  autoComplete="off"
                  type="number"
                  name="price_amount"
                  value={values?.price_amount}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    setFieldValue("price_amount", e.target.value);
                  }}
                  disabled={values?.price_option === "Net Net" ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExclusiveRegion;
