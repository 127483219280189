import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { TextField, Box, Typography, ClickAwayListener } from "@mui/material";
import { Modal, Button } from "react-bootstrap";
import { IoMdEye } from "react-icons/io";

import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetCollectionData,
  asyncDeleteCollectionData,
  asyncConfirmBankStatus,
} from "../../redux/features/Payments/PaymentsAllSlices";
import BtnLoader from "../AppForm/BtnLoader";
import { Form, Formik, useFormik } from "formik";
import * as yup from "yup";
import AppToast from "../AppToast";
import ReactDatePicker from "react-datepicker";
import { asyncGetAgentGroup } from "../../redux/features/setupRestPages/agentsSlice";
import { asyncGetBankDetailData } from "../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { URL2, baseURL } from "../../redux/features/ApiConfig";
import { apiUrl } from "../../Utils/configUrl";
import moment from "moment";

const Index = () => {
  const dispatch = useDispatch();
  const { collectionData, loading } = useSelector((state) => state.allPayments);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const [showBankStatus, setShowBankStatus] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [deleteLoader, setDeletLoader] = useState(false);
  const [statusData, setStatusData] = useState(null);

  const agentId = user?.agents_comp?.acc_id;
  const { errorMessage } = useSelector((state) => state.allPayments);

  useEffect(() => {
    dispatch(asyncGetCollectionData(agentId));
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetBankDetailData());
  }, []);

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      name: "receipt_no",
      options: {
        display: false,
      },
    },

    {
      name: "createdAt",
      label: "Date",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>{moment(value?.split("T")[0]).format("ddd DD-MMM-YY")}</div>
          );
        },
      },
    },
    {
      name: "acc_account",
      label: "Agent Name || User Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const agentName = tableMeta.rowData[3]?.agents_comp[0]?.agent_name;
          const user_name = tableMeta.rowData[7];

          return (
            <div>
              {agentName} || {user_name?.f_name} {user_name?.l_name}{" "}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <button
              className="confirmBtn pt-2"
              onClick={() => {
                setShowBankStatus(true);
                setStatusData(tableMeta?.rowData);
              }}
            >
              {value}
            </button>
          );
        },
      },
    },

    {
      name: "cash_in_hand",
      options: {
        display: false,
      },
    },

    {
      name: "roe",
      options: {
        display: false,
      },
    },

    {
      name: "agents_user",
      options: {
        display: false,
      },
    },

    {
      name: "id",
      label: "Bank || Cash in Hand",
      options: {
        customBodyRender: (value, tableMeta) => {
          const row = collectionData?.find((bank, index) => bank?.id === value);
          return <div>{row?.cash_in_hand?.acc_title}</div>;
        },
      },
    },
    {
      name: "amount_asg_cur",
      label: "Amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          const amount = collectionData?.find(
            (bank, index) => bank?.amount_asg_cur === value
          );
          return (
            <div>
              <div className="text-primary fw-bold">{`${amount?.amount_asg_cur} ${amount?.cur}`}</div>
              {user?.agent_id === 1 && (
                <div className="mt-1">
                  {amount?.cur !== "PKR" && `${amount?.amount_d_cur} PKR`}
                </div>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "image",
      label: "Bank Receipt",
      options: {
        customBodyRender: (value, tableMeta) => {
          console.log("value", value);
          const receipt = tableMeta?.rowData[1];
          return (
            <>
              {value && (
                <Box width={60}>
                  <a
                    href={`${apiUrl}/${value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="eyeColor"
                  >
                    <IoMdEye size={40} />
                  </a>
                </Box>
              )}

              {receipt && <div>{receipt}</div>}
            </>
          );
        },
      },
    },

    user?.agent_id === 1
      ? {
          name: "Action",
          label: "Action",
          options: {
            customBodyRender: (value, tableMeta) => {
              const handleDelete = () => {
                setShowDeleteModal(tableMeta.rowIndex);
              };

              const handleCancel = () => {
                setShowDeleteModal(null);
              };

              const handleConfirmDelete = () => {
                setDeletLoader(true);
                dispatch(asyncDeleteCollectionData(tableMeta?.rowData[0]));
                setTimeout(() => {
                  setShowDeleteModal(null);
                  setDeletLoader(false);
                }, 1000);
              };

              return (
                <div className="position-relative">
                  <button
                    className="btn-danger rounded edit-delete-icon-button"
                    onClick={handleDelete}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>

                  {showDeleteModal === tableMeta?.rowIndex && (
                    <ClickAwayListener onClickAway={handleCancel}>
                      <Box
                        position={"absolute"}
                        borderRadius={1}
                        right={2}
                        bgcolor={"#ffffff"}
                        mt={0.5}
                        boxShadow={10}
                        p={2}
                        zIndex={999}
                      >
                        <Typography width={180}>
                          Are you sure, you want to Delete this Payment.
                        </Typography>
                        <Box
                          pt={1.5}
                          display={"flex"}
                          justifyContent={"space-between"}
                          gap={1}
                        >
                          <button
                            className="cancel_button bg-danger"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            className="delete_button"
                            onClick={handleConfirmDelete}
                          >
                            {deleteLoader ? <BtnLoader /> : "Confirm"}
                          </button>
                        </Box>
                      </Box>
                    </ClickAwayListener>
                  )}
                </div>
              );
            },
          },
        }
      : "",
  ];

  const options = {
    filter: false,
    Selection: false,
    print: false,
    sort: true,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    enableNestedDataAccess: ".",
  };

  return (
    <div className="pb-5 px-2 mt-5">
      <StatusModal
        show={showBankStatus}
        setShow={setShowBankStatus}
        statusData={statusData}
        user={user}
        errorMessage={errorMessage}
      />

      {/* <DeleteRecord
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        rowId={modalData && modalData[0]}
      /> */}

      <MUIDataTable
        className="muidatatable"
        title={"Collections"}
        // data={user?.agent_id === 1 ? collectionData : agentData}
        data={collectionData}
        columns={columns?.filter((column) => column !== "")}
        options={options}
      />
    </div>
  );
};

export default Index;

const StatusModal = ({ show, setShow, statusData, user, errorMessage }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  
  const initialValues = {
    nariation: statusData?.[1] || "",
    date: new Date(),
  };

  const basicSchema = yup.object().shape({
    nariation: yup.string().required("Required"),
    date: yup.date().required("Required"),
  });

  const handleSubmit = (values) => {
    
    const confirm_body = {
      id: statusData?.at(0),
      roe: statusData?.at(6),
      agent: statusData?.at(3),
      date: values?.date,
      price: statusData?.at(9),
      credit_acc_id: statusData?.at(3)?.acc_id,
      debit_acc_id: statusData?.at(5)?.acc_id,
      receipt_no: values?.nariation,
    };
    setIsLoading(true);
    dispatch(asyncConfirmBankStatus(confirm_body))
      .then(() => {
        setIsLoading(false);
        setShow(false);
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (errorMessage) {
      setHasError(true);
    }
  }, [errorMessage]);

  return (
    <Modal
      show={show && user?.agent_id === 1}
      onHide={() => setShow(false)}
      centered
      dialogClassName="modal-lg"
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={basicSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Modal.Header>
              <Modal.Title>Deposit Slip</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-12">
                <h6 className="col-12 text-center mt-3">
                  Do you really want to change this status?
                </h6>
                <div className="col-12 d-flex my-3">
                  <div className="col-6 px-3 mt-4">
                    <TextField
                      name="nariation"
                      label="Nariation"
                      fullWidth
                      variant="standard"
                      value={values?.nariation}
                      onChange={(e) => setFieldValue("nariation", e.target.value.toUpperCase())}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <ReactDatePicker
                      className="date_picker_passanger"
                      placeholderText="Date"
                      autoComplete="off"
                      name={`date`}
                      selected={moment(values?.date).toDate()}
                      onChange={(date) => setFieldValue(`date`, date)}
                      maxDate={new Date()}
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
                {hasError && (
                  <div className="col-12 text-center mt-3 text-danger">
                    {errorMessage}
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="modal-buttons-parent mx-auto">
                <Button
                  variant="danger modal-button"
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                <button type="submit" className="confirmBtn ms-3">
                  {isLoading ? <BtnLoader /> : "Confirm"}
                </button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};


const DeleteRecord = ({ show, setShow, rowId }) => {
  const [reason, setReason] = useState("");
  const { loading } = useSelector((state) => state.allPayments);

  const dispacth = useDispatch();

  return (
    <Modal show={show} onHide={(e) => setShow(false)} centered>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12 text-center mt-3">
            Reason for deleting this record *
          </div>
          <div className="col-12 text-center mt-3">
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              rows={6}
              className="col-11 mx-auto"
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button variant="danger modal-button" onClick={(e) => setShow(false)}>
            Close
          </Button>
          <button
            className="confirmBtn ms-3"
            onClick={(e) => {
              dispacth(asyncDeleteCollectionData({ rowId, reason }));
            }}
          >
            {loading ? <BtnLoader /> : "Delete"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
