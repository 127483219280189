import React, { useEffect, useLayoutEffect, useState } from "react";
import Filter from "./FIlter";
import AirSial from "./AirSial";
import Typography from "@mui/material/Typography";
import Serene from "./Serene";
import Pia from "./pia";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncFlightsSearchResults,
  clearFlightResult,
  setExpandFlightDetails,
  set_Filters_null,
} from "../../../../redux/features/home/FlightSearchSlice";
import FlightSkeleton from "./FlightSkeleton";
import FlightsTab from "../../FlightsTab";
import moment from "moment";
import {
  isAirblueSectors,
  isDomesticSectors,
  isSereneSectors,
  isSialSectors,
} from "../../../../Utils/FlightSector";
import {
  asyncGetAddAirlinesData,
  asyncGetAirlinePricing,
} from "../../../../redux/features/setupRestPages/Price_AirLine_SuppRole";
import { asyncGetCurrencyData } from "../../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { asyncGetAgentGroup } from "../../../../redux/features/setupRestPages/agentsSlice";
import { Accordion, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChangeAvailabiltyDate from "./ChangeAvailabiltyDate";
import Airblue from "./Airblue";
import SabreCard from "./SabreCard";
import { asyncGetNewSupplier } from "../../../../redux/features/supplier/AddNewSupplier";
import Sidebar from "./Sidebar";
import FlyJinnahCard from "./FlyJinnah";
import Amadeus from "./Amadeus";
import Timer from "../../checkout/Timer";
import SereneApi from "./SereneApi";
import { isAmadeusRefundable } from "../../../../Utils/amadeusRefFilter";
// import FlyJinnahCard from "./FlyJinnah";
// import { flyjinnah } from "./9p";

function OneWaySearchResult() {
  const dispatch = useDispatch();
  const [accordion, setAccordion] = useState(null);

  //use selector redux
  const {
    searchValues,
    amadeus,
    serene,
    sereneApi,
    sial,
    pia,
    airBlue,
    sabre,
    flyJinnah,
    sabreBrandFeatures,
    newDepDate,
    expandFlightDetail,
    stop_filters,
    gds_filter,
    refundable_filter,
    loading,
    airline_filter,
  } = useSelector((state) => state.FlightSearch);


  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { agentGroup } = useSelector((state) => state.agentsCategories);
  // get values from local storage
  const localsearchValues = JSON.parse(
    localStorage.getItem("flightSearchData")
  );

  //agent group
  const agent_grp = agentGroup?.find(
    (item) => item.agent_grp_id === localsearchValues?.agent?.agent_grp_id
  );

  // currency of the selected agent group
  const currency = currencyData?.find(
    (it) => it.currency_id === agent_grp?.currency_id
  );
  //set local storage
  localStorage.setItem(
    "currency",
    JSON.stringify(currency !== undefined ? currency : "")
  );
  //total travelor
  const totalTravelor =
    localsearchValues?.adultsCount +
    localsearchValues?.childCount +
    localsearchValues?.infantNo;

  let locDepCode = localsearchValues?.locationDep?.split("-")[0];
  let locArrCode = localsearchValues?.locationArrival?.split("-")[0];

  const getFlightByKey = new Map();
  airBlue?.PricedItinerary?.forEach((item) => {
    const flightNumber =
      item?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption
        ?.FlightSegment?.$?.FlightNumber;
    const agentName = item?.agents_name;

    const key = `${flightNumber}_${agentName}`;

    if (getFlightByKey.has(key)) {
      getFlightByKey.get(key).push(item);
    } else {
      getFlightByKey.set(key, [item]);
    }
  });
  //new airblue data
  const newAirblueArray = [...getFlightByKey.values()];

  // find gds sectors
  const gdsArray = localsearchValues?.gds?.filter((it) => {
    if (it === "Air sial" && isSialSectors(locDepCode, locArrCode)) {
      return "Air Sial"; // Filter out "Air Sial"
    } else if (it === "Serene" && isSereneSectors(locDepCode, locArrCode)) {
      return "Serene"; // Filter out "Serene"
    } else if (it === "Serene-Api" && isSereneSectors(locDepCode, locArrCode)) {
      return "Serene-Api"; // Filter out "Serene"
    } else if (it === "Air Blue" && isAirblueSectors(locDepCode, locArrCode)) {
      return "Air blue";
    } else if (it === "HITIT") {
      return "HITIT";
    } else if (it === "Sabre" && !isDomesticSectors(locDepCode, locArrCode)) {
      return "Sabre";
    } else if (it === "Amadeus") {
      return "Amadeus";
    } else if (it === "Sabre") {
      return "Sabre";
    } else if (it === "Fly Jinnah") {
      return "Fly Jinnah";
    }
    // Keep all other items
    return false;
  });

  const setGds = ["Air sial", "Serene", "Air Blue", "HITIT", "Fly Jinnah", "Amadeus"].some(
    (airline) => gdsArray?.includes(airline)
  );

  const [duration, setDuration] = useState(setGds ? 5 : 10);
  const [cancel, setCancel] = useState(false);
  const [timer, setTimer] = useState("");
  useEffect(() => {
    if (timer?.minutes <= 0 && timer?.seconds <= 0) {
      setCancel(!cancel);
    }
  }, [timer]);
  // set origin and des name
  const getOriginDesName = (loc) => {
    let code = loc.split("-")[0];
    let airport = loc.split("-")[1];
    return `${code}-${airport}`;
  };

  const toggleDetail = (index) => {
    if (expandFlightDetail === index) {
      // If the clicked item is already expanded, collapse it
      dispatch(setExpandFlightDetails(null));
    } else {
      // Expand the clicked item
      dispatch(setExpandFlightDetails(index));
    }
  };

  const otherOptiontoggle = (index) => {
    if (expandFlightDetail === index) {
      // If the clicked item is already expanded, collapse it
      dispatch(setExpandFlightDetails(null));
    } else {
      // Expand the clicked item
      dispatch(setExpandFlightDetails(index));
    }
  };
  //uselayout effect
  useLayoutEffect(() => {
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetAirlinePricing(1));
    dispatch(asyncGetCurrencyData());
    dispatch(asyncGetAddAirlinesData());
    dispatch(asyncGetNewSupplier());
  }, [dispatch, searchValues]);
  //use effect
  // flight search api call
  useEffect(() => {
    //clear all flight result states
    dispatch(clearFlightResult());
    dispatch(setExpandFlightDetails(null));
    dispatch(set_Filters_null());
    setAccordion(false);
    gdsArray?.forEach((element) => {
      let data = {
        departingOn: newDepDate ? newDepDate : localsearchValues?.departingOn,
        locationDep: getOriginDesName(localsearchValues?.locationDep),
        locationArrival: getOriginDesName(localsearchValues?.locationArrival),
        ReturningOn: localsearchValues?.ReturningOn,
        multi_des_from: localsearchValues?.multi_des_from,
        multi_des_to: localsearchValues?.multi_des_to,
        multi_des_from_date: localsearchValues?.multi_des_from_date,
        multi_des_ticket_class: localsearchValues?.multi_des_ticket_class,
        adultsCount: localsearchValues?.adultsCount,
        adult_ages: localsearchValues?.adult_ages,
        childCount: localsearchValues?.childCount,
        children_ages: localsearchValues?.children_ages,
        infantNo: localsearchValues?.infantNo,
        infant_ages: localsearchValues?.infant_ages,
        trip_type: localsearchValues?.trip_type,
        gds: element.replace(/\s/g, ""),
        ticket_class: localsearchValues?.ticket_class,
      };
      let tm = Date.now();
      localStorage.setItem("timer", JSON.stringify(tm));
      dispatch(asyncFlightsSearchResults(data));
    });
  }, [dispatch, searchValues, newDepDate, cancel]);

  let stops = ["NON STOP", "ONE STOP", "TWO STOP"];

  return (
    <div className="col-11 mx-auto pt-5">
      <div className="pb-4">
        <div className="d-flex">
          {/* search engin *****************************/}
          <div className="col-12 bg-white shadow-sm">
            <Accordion
              expanded={accordion}
              onChange={(event, isExpanded) =>
                setAccordion(isExpanded ? true : false)
              }
            >
              <div
                className="accordion_heading"
                onClick={() => setAccordion(!accordion)}
              >
                <Typography>Edit Search</Typography>
                <ExpandMoreIcon />
              </div>
              <AccordionDetails className="px-5">
                <FlightsTab searchData={localsearchValues} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row mt-2 mt-md-5 gap-4">
          <div className="search_engin_filter bg-white">
            <Sidebar />
          </div>

          {/* search results ***********************/}
          <div className="search_data">
            <div>
              <Filter />
            </div>
            <div className="py-3 d-flex  justify-content-between align-items-center">
              <div>
                <h5>
                  {`Select your Flight from ${localsearchValues?.locationDep?.split("-")[2]
                    } to ${localsearchValues?.locationArrival?.split("-")[2]}`}
                </h5>
                <h5>
                  {moment(
                    newDepDate ? newDepDate : localsearchValues?.departingOn,
                    "DD-MM-YYYY"
                  ).format("ddd, Do MMM")}
                  , {totalTravelor} Traveler(s)
                </h5>
              </div>

              {/* ///////////// change availabilty date///////// */}
              <div className="d-flex flex-column gap-2">
                <ChangeAvailabiltyDate />
                <p>
                  <Timer
                    duration={duration}
                    availabilityTime={JSON.parse(localStorage.getItem("timer"))}
                    setTimer={setTimer}
                    timer={timer}
                  />
                </p>
              </div>
            </div>

            {/* //////////////////Amadeus Card///////////// */}

            {amadeus === null && gdsArray?.includes("Amadeus") ? (
              <FlightSkeleton />
            ) : 
              ["Amadeus", "ALL"].includes(gds_filter) ?
              (
                amadeus?.outbound?.map((amadeusItem, index) => (
                  [amadeusItem?.validatingAirlineCodes?.at(0), "ALL"].includes(airline_filter) && 
                  [stops[amadeusItem?.itineraries?.at(0)?.segments?.length - 1], "ALL"].includes(stop_filters) &&
                  isAmadeusRefundable(refundable_filter, amadeusItem)             
                  ? (
                  <Amadeus
                    amadeusItem={amadeusItem}
                    key={index}
                    index={index}
                    toggleDetail={toggleDetail}
                    otherOptiontoggle={otherOptiontoggle}
                    expandFlightDetail={expandFlightDetail}
                  />) : (<></>)
                ))
              ) : null}
            {/* ///////////////// serene card ///////////////// */}
            {serene === null && gdsArray?.includes("Serene") ? (
              <FlightSkeleton />
            ) : ["NON STOP", "ALL"].includes(stop_filters) &&
              ["Serene", "ALL"].includes(gds_filter) &&
              ["ER", "ALL"].includes(airline_filter) &&
              ["REFUNDABLE", "ALL"].includes(refundable_filter) ? (
              serene?.routes
                ?.at(0)
                ?.flights?.map((item, index) => (
                  <Serene
                    serene={item}
                    key={index}
                    index={"serene" + index}
                    isExpanded={expandFlightDetail === "serene" + index}
                    toggleDetail={toggleDetail}
                  />
                ))
            ) : null}

            {/* ///////////// serene Api ///////////////// */}
            {sereneApi === null && gdsArray?.includes("Serene") ? (
              <FlightSkeleton />
            ) : ["NON STOP", "ALL"].includes(stop_filters) &&
              ["Serene", "ALL"].includes(gds_filter) &&
              ["ER", "ALL"].includes(airline_filter) &&
              ["REFUNDABLE", "ALL"].includes(refundable_filter) ? (
              sereneApi?.map((item, index) => (
                <SereneApi
                  serene={item}
                  key={index}
                  index={"serene" + index}
                  isExpanded={expandFlightDetail === "serene" + index}
                  toggleDetail={toggleDetail}
                />
              ))
            ) : null}

            {/* ///////////// sial card ///////////////// */}
            {sial === null && gdsArray?.includes("Air sial") ? (
              <FlightSkeleton />
            ) : ["NON STOP", "ALL"].includes(stop_filters) &&
              ["Air Sial", "ALL"].includes(gds_filter) &&
              ["PF", "ALL"].includes(airline_filter) && 
              ["REFUNDABLE", "ALL"].includes(refundable_filter) ? (
              sial?.map((item, index) => (
                <AirSial
                  data={item}
                  key={index}
                  index={"sial" + index}
                  isExpanded={expandFlightDetail === "sial" + index}
                  toggleDetail={toggleDetail}
                />
              ))
            ) : null}

            {/*  //////////////// airblue  ////////////////*/}
            {airBlue === null && gdsArray?.includes("Air Blue") ? (
              <FlightSkeleton />
            ) : ["NON STOP", "ALL"].includes(stop_filters) &&
              ["Airblue", "ALL"].includes(gds_filter) &&
              ["PA", "ALL"].includes(airline_filter) &&
              ["REFUNDABLE", "ALL"].includes(refundable_filter) ? (
              newAirblueArray?.map((item, index) => (
                <Airblue
                  data={item}
                  key={index}
                  index={"airblue" + index}
                  isExpanded={expandFlightDetail === "airblue" + index}
                  toggleDetail={toggleDetail}
                />
              ))
            ) : null}

            {/*  ////////////// pia card ///////////// */}
            {pia === null && gdsArray?.includes("HITIT") ? (
              <FlightSkeleton />
            ) : ["HITIT", "ALL"].includes(gds_filter) &&
              ["PK", "ALL"].includes(airline_filter) &&
              ["REFUNDABLE", "ALL"].includes(refundable_filter) ? (
              pia
                ?.filter((elem) => {
                  if (stop_filters === "ONE STOP") {
                    return (
                      elem.fareComponentGroupList.boundList
                        .availFlightSegmentList.length === 2
                    );
                  } else if (stop_filters === "TWO STOP") {
                    return (
                      elem.fareComponentGroupList.boundList
                        .availFlightSegmentList.length === 3
                    );
                  } else if (stop_filters === "NON STOP") {
                    return !Array.isArray(
                      elem.fareComponentGroupList.boundList
                        .availFlightSegmentList
                    );
                  } else {
                    return true;
                  }
                })
                ?.map((piaData, index) => (
                  <Pia
                    piadata={piaData}
                    key={index}
                    // index={index}
                    index={"HITIT" + index}
                    isExpanded={expandFlightDetail === "HITIT" + index}
                    toggleDetail={toggleDetail}
                  />
                ))
            ) : null}

            {/*  ////////// sabre card ////////////// */}
            {sabre === null && gdsArray?.includes("Sabre") ? (
              <FlightSkeleton />
            ) : (
              sabre?.map((item, index) => (
                <>
                  <SabreCard
                    data={item}
                    key={index}
                    index={index}
                    isExpanded={expandFlightDetail === index}
                    toggleDetail={toggleDetail}
                  />
                  {/* {item?.otherOptions.length > 0 && showMoreOption === index
                    ? item?.otherOptions?.map((otherOptions, ind) => (
                        <div className="mt-4">
                          <SabreCard
                            data={otherOptions}
                            key={ind + "ss"}
                            index={ind + "ss"}
                            isExpanded={expandFlightDetail === ind + "ss"}
                            toggleDetail={toggleDetail}
                          />
                        </div>
                      ))
                    : null} */}
                </>
              ))
            )}

            {/*  //////////////// Fly jinnah card  ////////////////*/}

            {flyJinnah === null && gdsArray?.includes("Fly Jinnah") ? (
              <FlightSkeleton />
            ) : (["Fly Jinnah", "ALL"].includes(gds_filter) && ["REFUNDABLE", "ALL"].includes(refundable_filter)
             ?
              flyJinnah?.outbound?.map((item, index) => (
                item?.flightSegments?.length > 0 && [item?.flightSegments?.at(0)?.flightNumber?.slice(0, 2), "ALL"].includes(airline_filter) &&
                [stops[item?.flightSegments?.length - 1], "ALL"].includes(stop_filters)
                 ? <div>
                  <FlyJinnahCard
                    data={item}
                    key={index}
                    index={"FlyJinnah" + index}
                    isExpanded={expandFlightDetail === "FlyJinnah" + index}
                    toggleDetail={toggleDetail}
                  />
                </div> : <></>
              )) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OneWaySearchResult;
