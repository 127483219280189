import React, { useState, useEffect } from "react";
import { TextField, Checkbox } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Flag from "react-world-flags";
import { countriesArray } from "./countries";
import BtnLoader from "../../AppForm/BtnLoader";

const SSREditTable = ({ paxData }) => {
  const [passengers, setPassengers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Initialize passengers state based on paxData
    const mappedPassengers = paxData?.map((pax) => ({
      id: pax.pax_id,
      passengerName: `${pax.fName} ${pax.lname}`,
      nationality: pax.nationality || "Pakistan", 
      psptIssueCountry: pax.psptIssueCountry  || "Pakistan" , 
      dob: new Date(pax.dob),
      psptExpiry: new Date(pax.doe),
      psptIssueDate: pax.pspt_cnic,
      isEditable: false,
    }));
    setPassengers(mappedPassengers);
  }, [paxData]);

  const handleCheckboxChange = (id) => {
    setPassengers(
      passengers?.map((passenger) =>
        passenger.id === id
          ? { ...passenger, isEditable: !passenger.isEditable }
          : passenger
      )
    );
  };

  const handleInputChange = (id, field, value) => {
    setPassengers(
      passengers?.map((passenger) =>
        passenger.id === id ? { ...passenger, [field]: value } : passenger
      )
    );
  };
  const handleSubmit = () => {
    const selectedPassengers = passengers?.filter(
      (passenger) => passenger.isEditable
    );
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      // dispatch(setOpenTrailBalance(""));
      
      console.log("Selected passengers for submission:", selectedPassengers);
    }, 2000);
  };
  

  return (
    <div>
    <h4 style={{ marginTop: "50px" }} className="theme-text-color">EDIT PSPT:</h4>
      <table
        cellPadding="10"
        style={{ width: "100%", }}
        className="table table-bordered border-dark align-items-center "
      >
        <thead className="bg_primary text-white "
            style={{ fontSize: "20px" }}>
          <tr>
            <th>Select</th>
            <th>Pax Name</th>
            <th className="px-1">Nationality</th>
            <th className="px-2">PSPT Issue Country</th>
            <th>Date of Birth</th>
            <th>PSPT Expiry</th>
            <th>PSPT No</th>
          </tr>
        </thead>
        <tbody className="text-dark "
            style={{ fontSize: "20px", color: "#000000" }}>
          {passengers?.map((passenger) => (
            <tr key={passenger.id}>
              <td>
                <Checkbox
                  checked={passenger.isEditable}
                  onChange={() => handleCheckboxChange(passenger.id)}
                />
              </td>
              <td className="pt-3">{passenger.passengerName}</td>
              
              {/* Nationality Dropdown */}
              <td className="px-1">
                <Select
                  value={countriesArray?.find(
                    (c) => c.label === passenger.nationality
                  )}
                  // defaultValue={colourOptions[2]}
                  onChange={(selectedOption) =>
                    handleInputChange(
                      passenger.id,
                      "nationality",
                      selectedOption.value
                    )
                  }
                  options={countriesArray}
                  isDisabled={!passenger.isEditable}
                  
                />
              </td>

              {/* Passport Issue Country Dropdown */}
              <td className="px-2">
                <Select
                  value={countriesArray?.find(
                    (c) => c.label === passenger.psptIssueCountry
                  )}
                  onChange={(selectedOption) =>
                    handleInputChange(
                      passenger.id,
                      "psptIssueCountry",
                      selectedOption.value
                    )
                  }
                  options={countriesArray}
                  variant="standard"
                  isDisabled={!passenger.isEditable}
                />
              </td>
              <td className="pt-3">
                <DatePicker
                  selected={passenger.dob}
                  onChange={(date) =>
                    handleInputChange(passenger.id, "dob", date)
                  }
                  disabled={!passenger.isEditable}
                  variant="standard"
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()}
                  className="date_pic"
                />
              </td>
              <td className="pt-3">
                <DatePicker
                  selected={passenger.psptExpiry}
                  onChange={(date) =>
                    handleInputChange(passenger.id, "psptExpiry", date)
                  }
                  disabled={!passenger.isEditable}
                  dateFormat="dd-MM-yyyy"
                  variant="standard"
                  className="date_pic"
                />
              </td>
              <td>
                <TextField
                  value={passenger.psptIssueDate}
                  onChange={(e) =>
                    handleInputChange(
                      passenger.id,
                      "psptIssueDate",
                      e.target.value
                    )
                  }
                  disabled={!passenger.isEditable}
                  variant="standard"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <button
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
        onClick={handleSubmit}
      >
        Submit Selected Passengers
      </button> */}
      <div className="d-flex justify-content-center mt-4">
              {!loading ? (
                <div className="text-center py-4">
                  <button
                    type="submit"
                    className="button_style px-4 "
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <div className="text-center py-4">
                  <button className="button_style px-4">
                    <BtnLoader />
                  </button>
                </div>
              )}
            </div>
    </div>
  );
};

export default SSREditTable;

// import React, { useState } from "react";
// import MUIDataTable from "mui-datatables";
// import { TextField, Checkbox } from "@mui/material";
// import CountryDropdown from "country-dropdown-with-flags-for-react";
// import { countriesArray } from "./countries";
// import Select from "react-select";
// import Flag from "react-world-flags";
// import { Formik } from "formik";

// const SSREditTable = ({ paxData, booking }) => {
//   const [selectedRows, setSelectedRows] = useState([]);

//   // Columns definition
//   const columns = ({paxes}, setFieldValue) => [
//     {
//       name: "checkbox",
//       label: "pax_id",
//       options: {
//         customBodyRender: (value, tableMeta, updateValue) => {
//           const isSelected = selectedRows.includes(tableMeta.rowIndex);
//           return (
//             <input
//               type="checkbox"
//               checked={isSelected}
//               onChange={(e) => {
//                 const updatedSelectedRows = [...selectedRows];
//                 const rowIndex = tableMeta.rowIndex;

//                 // Toggle selection
//                 if (e.target.checked) {
//                   updatedSelectedRows.push(rowIndex);
//                   // Add the passenger object to paxes on selection
//                   const selectedPax = paxData?.find(({ pax_id }) => pax_id === value);
//                   setFieldValue("paxes", [ selectedPax]);
//                 } else {
//                   // Remove from selected rows
//                   updatedSelectedRows.splice(updatedSelectedRows.indexOf(rowIndex), 1);
//                   const updatedPaxes = paxes.filter((_, idx) => idx !== rowIndex);
//                   setFieldValue("paxes", updatedPaxes);
//                 }
//                 setSelectedRows(updatedSelectedRows);
//               }}
//             />
//           );
//         },
//       },
//     },
//     {
//       name: "fName",
//       label: "First Name",
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value, tableMeta) => {
//           let name = `${value} ${tableMeta?.rowData[2]}`;
//           return <span>{name}</span>;
//         },
//       },
//     },
//     {
//       name: "lname",
//       label: "Last Name",
//       options: {
//         display: false,
//       },
//     },
//     {
//       name: "nationality",
//       label: "Nationality",
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value, tableMeta) => {
//           const isSelected = selectedRows.includes(tableMeta.rowIndex);
//           const countryOptions = countriesArray.map((country) => ({
//             label: country.name,
//             value: country.code,
//           }));
//           return isSelected ? (
//             <Select
//               options={countryOptions}
//               value={countriesArray.find((option) => option.value === value)}
//               onChange={(selectedOption) => {
//                 const updatedPaxData = [...paxData]; // Create a shallow copy of the paxData
//                 updatedPaxData[tableMeta.rowIndex] = { // Update the selected row
//                   ...updatedPaxData[tableMeta.rowIndex], // Retain other properties
//                   nationality: selectedOption.value, // Update nationality
//                 };
//                 setFieldValue("paxes", updatedPaxData); // Set the new updated data
//               }}
//             />
//           ) : (
//             <div style={{ display: "flex", alignItems: "center" }}>
//               <Flag
//                 code={value}
//                 style={{ width: 20, height: 15, marginRight: 5 }}
//               />
//               {countriesArray.find((country) => country.code === value)?.name}
//             </div>
//           );
//         },
//       },
//     },
//     {
//       name: "pspt_issue_country",
//       label: "PSPT Issue Country",
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value, tableMeta) => {
//           const isSelected = selectedRows.includes(tableMeta.rowIndex);
//           const countryOptions = countriesArray.map((country) => ({
//             label: country.name,
//             value: country.code,
//           }));
//           return isSelected ? (
//             <Select
//               options={countryOptions}
//               value={countriesArray.find((option) => option.value === value)}
//               onChange={(selectedOption) => {
//                 const updatedPaxData = [...paxData];
//                 updatedPaxData[tableMeta.rowIndex] = {
//                   ...updatedPaxData[tableMeta.rowIndex],
//                   pspt_issue_country: selectedOption.value,
//                 };
//                 setFieldValue("paxes", updatedPaxData);
//               }}
//             />
//           ) : (
//             <div style={{ display: "flex", alignItems: "center" }}>
//               <Flag
//                 code={value}
//                 style={{ width: 20, height: 15, marginRight: 5 }}
//               />
//               {countriesArray.find((country) => country.code === value)?.name}
//             </div>
//           );
//         },
//       },
//     },
//     {
//       name: "dob",
//       label: "Date of Birth",
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value) => {
//           return new Date(value).toLocaleDateString();
//         },
//       },
//     },
//     {
//       name: "pspt_expiry",
//       label: "PSPT Expiry Date",
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value) => {
//           return new Date(value).toLocaleDateString();
//         },
//       },
//     },
//     {
//       name: "pspt_cnic",
//       label: "PSPT No",
//       options: {
//         filter: true,
//         sort: true,
//       },
//     },
//   ];

//   const options = {
//     sortOrder: {
//       name: "time",
//       direction: "desc",
//     },
//     rowsPerPage: 50,
//     filter: false,
//     Selection: false,
//     print: false,
//     download: false,
//     viewColumns: false,
//     displayRowCheckbox: false,
//     selectableRows: "none",
//     enableNestedDataAccess: ".",
//     search: false, // Disable search
//   };
//   const onSubmit = (values) => {
//     // You can handle form submission logic here
//     // For example, send the form data (values) to an API or log it.
//     console.log("Form submitted with values:", values);
//     // Example of an API call
//     // apiSubmit(values).then(response => console.log(response)).catch(error => console.error(error));
//   };
//   return (
//     <Formik
//       initialValues={{
//         paxes: [],
//       }}
//       onSubmit={onSubmit}
//     >
//       {({ errors, touched, handleBlur, handleChange, values, setFieldValue,handleSubmit }) => (

//         <form onSubmit={handleSubmit}> {/* Bind the submit handler to the form */}
//           <MUIDataTable
//             className="muidatatable"
//             title={"Passenger Information"}
//             data={paxData}
//             columns={columns(values, setFieldValue)}
//             options={options}
//           />

//           {/* Add Submit Button */}
//           <button
//             type="submit"
//             variant="contained"
//             color="primary"
//             style={{ marginTop: "20px" }}
//           >
//             Submit
//           </button>
//         </form>
//       )}

//     </Formik>
//   );
// };

// export default SSREditTable;

// import React, { useState } from "react";
// import MUIDataTable from "mui-datatables";
// import { TextField, Checkbox } from "@mui/material";
// import CountryDropdown from "country-dropdown-with-flags-for-react";
// import { countriesArray } from "./countries";
// import Select from "react-select";
// import Flag from "react-world-flags";
// import { Formik } from "formik";

// const SSREditTable = ({ paxData, booking }) => {
//   const [selectedRows, setSelectedRows] = useState([]);
//   const columns = ({paxes}, setFieldValue) => [
//     {
//       name: "checkbox",
//       label: "pax_id",
//       options: {
//         customBodyRender: (value, tableMeta, updateValue) => {
//           const isSelected = selectedRows.includes(tableMeta.rowIndex);
//           return (
//             <input
//               type="checkbox"
//               checked={isSelected}
//               onChange={(e) => {
//                 let array = [...paxes];
//                 console.log("arrayy",array)
//                 if (e.target.checked) {
//                   let object = paxData?.find(({ pax_id }) => pax_id === value);
//                   array?.push(object);
//                 } else {
//                   array = paxes?.filter(({ pax_id }) => pax_id !== value);
//                 }
//                 setFieldValue("paxes", [...array]);
//               }}
//             />
//           );
//         },
//       },
//     },
//     {
//       name: "fName",
//       label: "First Name",
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value, tableMeta) => {
//           let name = `${value} ${tableMeta?.rowData[2]}`;
//           return <span>{name}</span>;
//         },
//       },
//     },
//     {
//       name: "lname",
//       label: "Last Name",
//       options: {
//         display: false,
//       },
//     },
//     // {
//     //   name: "nationality",
//     //   label: "Nationality",
//     //   options: {
//     //     filter: true,
//     //     sort: true,
//     //   },
//     // },
//     // {
//     //   name: "pspt_issue_country",
//     //   label: "PSPT Issue Country",
//     //   options: {
//     //     filter: true,
//     //     sort: true,
//     //   },
//     // },
//     {
//       name: "nationality",
//       label: "Nationality",
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value, tableMeta) => {
//           const isSelected = selectedRows.includes(tableMeta.rowIndex);
//           const countryOptions = countriesArray.map((country) => ({
//             label: country.name,
//             value: country.code,
//           }));
//           return isSelected ? (
//             <Select
//               options={countryOptions}
//               value={countriesArray.find((option) => option.value === value)}
//               onChange={(selectedOption) => {
//                 const updatedPaxData = [...paxData];
//                 updatedPaxData[tableMeta.rowIndex].nationality =
//                   selectedOption.value;
//                   setFieldValue("paxes", updatedPaxData);
//               }}
//             />
//           ) : (
//             <div style={{ display: "flex", alignItems: "center" }}>
//               <Flag
//                 code={value}
//                 style={{ width: 20, height: 15, marginRight: 5 }}
//               />
//               {countriesArray.find((country) => country.code === value)?.name}
//             </div>
//           );
//         },
//       },
//     },
//     {
//       name: "pspt_issue_country",
//       label: "PSPT Issue Country",
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value, tableMeta) => {
//           const isSelected = selectedRows.includes(tableMeta.rowIndex);
//           const countryOptions = countriesArray.map((country) => ({
//             label: country.name,
//             value: country.code,
//           }));
//           return isSelected ? (
//             <Select
//               options={countryOptions}
//               value={countriesArray.find((option) => option.value === value)}
//               onChange={(selectedOption) => {
//                 const updatedPaxData = [...paxData];
//                 updatedPaxData[tableMeta.rowIndex].pspt_issue_country =
//                   selectedOption.value;
//                   setFieldValue("paxes", updatedPaxData);
//               }}
//             />
//           ) : (
//             <div style={{ display: "flex", alignItems: "center" }}>
//               <Flag
//                 code={value}
//                 style={{ width: 20, height: 15, marginRight: 5 }}
//               />
//               {countriesArray.find((country) => country.code === value)?.name}
//             </div>
//           );
//         },
//       },
//     },
//     {
//       name: "dob",
//       label: "Date of Birth",
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value) => {
//           return new Date(value).toLocaleDateString();
//         },
//       },
//     },
//     {
//       name: "pspt_expiry",
//       label: "PSPT Expiry Date",
//       options: {
//         filter: true,
//         sort: true,
//         customBodyRender: (value) => {
//           return new Date(value).toLocaleDateString();
//         },
//       },
//     },

//     {
//       name: "pspt_cnic",
//       label: "PSPT No",
//       options: {
//         filter: true,
//         sort: true,
//       },
//     },
//   ];

//   const options = {
//     sortOrder: {
//       name: "time",
//       direction: "desc",
//     },
//     rowsPerPage: 50,
//     filter: false,
//     Selection: false,

//     print: false,
//     download: false,
//     viewColumns: false,
//     displayRowCheckbox: false,
//     selectableRows: "none",
//     enableNestedDataAccess: ".",
//     search: false, // Enable search
//   };

//   return (
//     <Formik
//       initialValues={{
//         paxes: []
//       }}
//       // validationSchema={basicSchema}
//       onSubmit={(values) => {
//         console.log(values);
//       }}
//     >
//       {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
//         <MUIDataTable
//           className="muidatatable"
//           title={"Passenger Information"}
//           data={paxData}
//           columns={columns(values, setFieldValue)}
//           options={options}
//         />
//       )}
//     </Formik>
//   );
// };

// export default SSREditTable;
