import React from 'react';
import './watermark.css'; 

const Watermark = () => {
  return (
    <div className="watermark">
      <p>Cancelled</p> 
    </div>
  );
};

export default Watermark;
