import React, { useEffect, useState } from "react";
import PiaLogo from "../../../../assets/images/airlineLogo/PK.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { BsBagCheck, BsHandbag, BsPerson } from "react-icons/bs";
import {
  MdCheck,
  MdFlight,
  MdOutlineFlightLand,
  MdOutlineFlightTakeoff,
  MdOutlineRestaurant,
  MdRemoveRedEye,
  MdSwapHoriz,
} from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { LightTooltip } from "./LightTooltip";
import {
  setExpandFlightDetails,
  set_Airline_filter_drp,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  ExclusiveSetors,
  codeShareHITIT,
} from "../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import { NODEURL } from "../../../../redux/features/ApiConfig";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import { Box } from "@mui/material";
import { FaChevronCircleLeft, FaChevronCircleRight, FaSuitcase, FaSuitcaseRolling } from "react-icons/fa";
import Slider from "react-slick";

function Pia({ piadata, index, isExpanded, toggleDetail, selectedFare }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Fare Options");
  const { selectedFlight, returnFlight } = useSelector(
    (state) => state.FlightSearch
  );
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const fareComponentList = Array.isArray(
    piadata?.fareComponentGroupList?.fareComponentList
  )
    ? piadata?.fareComponentGroupList?.fareComponentList
    : [piadata?.fareComponentGroupList?.fareComponentList];

  const boundList = returnFlight
    ? piadata?.fareComponentGroupList?.boundList[0]
    : piadata?.fareComponentGroupList?.boundList;
  // flight segment
  const avblfltSeg = Array.isArray(boundList?.availFlightSegmentList)
    ? boundList?.availFlightSegmentList
    : [boundList?.availFlightSegmentList];

  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  // get values from local storage
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.name === "PIA-HITIT"
  );

  const CustomPrevArrow = (props) => {
    if (fareComponentList?.length <= 4) {
      return null; // Do not render the arrow
    }
    return (
      <div
        onClick={props.onClick}
        className="position-absolute top-50 translate-middle-y cursor-pointer custom-left-position custom-text-color"
      >
        <FaChevronCircleLeft size={30} />
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    if (fareComponentList?.length <= 4) {
      return null; // Do not render the arrow
    }
    return (
      <div
        onClick={props.onClick}
        className="position-absolute top-50 translate-middle-y cursor-pointer custom-right-position custom-text-color custom-z-index"
      >
        <FaChevronCircleRight size={30} />
      </div>
    );
  };


  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: fareComponentList?.length > 3 ? 4 : fareComponentList?.length,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: fareComponentList?.length > 3 ? 4 : fareComponentList?.length,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //airline
  const airline = addAirLinesData?.find((it) => it?.name === "PIA-HITIT");

  // Convert minutes to hours and minutes using Moment.js
  const convertTime = (inputTime) => {
    // Parse the input time using Moment.js
    const duration = moment.duration(inputTime);
    // Extract hours and minutes
    const hours = duration.hours();
    const minutes = duration.minutes();
    // Format hours and minutes as 'hh:mm'
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };
  /////////////// seleted fare to array
  const selectedFareOutbound = [selectedFlight?.fare];

  //calculate lowest total fare
  const totalPaxFare = (fare) => {
    // passenger fare info list
    const newFare = Array.isArray(fare) ? fare : [fare];

    const fareinfoList = newFare?.at(0)?.fareInfoList?.length
      ? newFare?.at(0)?.fareInfoList
      : [newFare?.at(0)?.fareInfoList];

    let total =
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(0)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(0)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(0)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.total_fare * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(1)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(1)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(1)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.total_fare * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(2)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(2)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(2)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.total_fare * searchValues?.infantNo || 0);

    return `${CurrencyCode()} ${TwoDecimalPoint(total)}`;
  };
  //discount
  const DiscountPrice = (fare) => {
    // passenger fare info list
    const newFare = Array.isArray(fare) ? fare : [fare];
    const fareinfoList = newFare?.at(0)?.fareInfoList?.length
      ? newFare?.at(0)?.fareInfoList
      : [newFare?.at(0)?.fareInfoList];
    let total =
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(0)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(0)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(0)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.sp * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(1)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(1)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(1)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.sp * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(2)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(2)?.pricingInfo?.totalFare?.amount?.value -
              ~~newFare?.at(2)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fareinfoList?.at(0)?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.sp * searchValues?.infantNo || 0);

    if (total > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
    }
    return "";
  };
  ///////////////////// single passenger fare
  const singlePaxFare = (fare, pax) => {
    let total =
      pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(Math.round(fare?.pricingInfo?.equivBaseFare?.value))
        ),
        Number(
          CurrencyExchange(
            Math.round(fare?.pricingInfo?.taxes?.totalAmount?.value)
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fare?.fareInfoList[0]?.resBookDesigCode
        ),
        codeShareHITIT(avblfltSeg)
      )?.total_fare * pax || 0;

    return `${CurrencyCode()} ${TwoDecimalPoint(total)}`;
  };

  // add flight duration of conected flights
  // calculate duration
  const timeDuration = (time1, time2) => {
    if (!time1 || !time2) {
      return null;
    }
    const depMoment = moment(time1);
    const arrMoment = moment(time2);
    const duration = moment.duration(arrMoment.diff(depMoment));
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  // departure date and time , arrival date and time ASSIGN to the variables so code is not repeating
  const depDate = moment(
    new Date(avblfltSeg[0]?.flightSegment?.departureDateTime?.split("T")[0])
  ).format("ddd DD-MMM-YY");
  const depTime = avblfltSeg
    ?.at(0)
    ?.flightSegment?.departureDateTime?.split("T")[1]
    ?.slice(0, 5);
  const arrDate = moment(
    new Date(avblfltSeg[0]?.flightSegment?.arrivalDateTime?.split("T")[0])
  ).format("ddd DD-MMM-YY");
  const arrTime = avblfltSeg
    ?.at(0)
    ?.flightSegment?.arrivalDateTime?.split("T")[1]
    ?.slice(0, 5);

  /////////////////
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  // get city name
  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };
  ////////////////////// operated airline if code share
  const operatedAirline = avblfltSeg?.find(
    (it) => it?.flightSegment.operatingAirline
  );

  // calculate layover time between connected fligths
  function calculateTotalTimeDifference(time1, time2) {
    // Parse the input time values with the provided format
    const format = "YYYY-MM-DDTHH:mm:ss";
    const moment1 = moment(time1, format);
    const moment2 = moment(time2, format);

    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(moment1 - moment2);

    // Convert the time difference to a moment duration
    const duration = moment.duration(timeDifference);

    // Format the duration as hours and minutes
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  /// USE EFFECT
  useEffect(() => {
    dispatch(set_Airline_filter_drp("PK"));
  }, []);

  const getPaxTaxes = (taxes = []) => {
    taxes = Array.isArray(taxes) ? taxes : [taxes];

    return taxes.map((tax) => ({
      code: tax?.taxCode,
      amount: ~~tax?.taxAmount?.value,
    }));
  };

  return (
    <div key={index} id={`pia${index}`}>
      <div className="search_engin_result_box flight_card_shadow bg-white">
        <div
          className="shadow cursorpointer"
          onClick={() => {
            toggleDetail(index);
            setTimeout(() => {
              !isExpanded && scrollToElement(`pia${index}`);
            }, 200);
          }}
        >
          <div className="row align-items-center">
            <div className="col-2">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={`/NEW_LOGO/${avblfltSeg?.at(0)?.flightSegment?.airline?.code}.png`} alt="PiaLogo" width={100} />
                <h6>
                  <div className="pt-3 text-center">
                    {avblfltSeg?.map((it, idx, arr) => (
                      <span className="pe-1" key={idx}>{`${it?.flightSegment?.operatingAirline ? it?.flightSegment?.operatingAirline?.code : it?.flightSegment?.airline?.code} ${it?.flightSegment?.flightNumber
                        } ${idx < arr.length - 1 ? " -" : ""}`}</span>
                    ))}
                  </div>
                </h6>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightTakeoff
                      size={30}
                      className="color_primary"
                    />
                  </div>
                  <div>
                    <h6>{depDate}</h6>
                    <h6 className="my-3">{depTime}</h6>
                    <h6>
                      {
                        avblfltSeg[0]?.flightSegment?.departureAirport
                          ?.locationCode
                      }{" "}
                      - {getCity(locDeparture)}
                    </h6>
                  </div>
                </div>
                <div>
                  <h6 className="text-center">
                    {timeDuration(
                      avblfltSeg[0]?.flightSegment?.departureDateTime,
                      avblfltSeg[avblfltSeg?.length - 1]?.flightSegment?.arrivalDateTime
                    )}
                  </h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">
                    {avblfltSeg[2]
                      ? "Two Stop"
                      : avblfltSeg[1]
                        ? `One Stop`
                        : "Non-Stop"}
                  </h6>
                  <h6 className="text-center">
                    {avblfltSeg
                      ?.slice(0, -1)
                      ?.map(
                        (it) =>
                          `${it?.flightSegment?.arrivalAirport?.locationCode} `
                      )
                      ?.join("- ")}
                  </h6>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightLand size={30} className="color_primary" />
                  </div>

                  <div>
                    <h6>
                      {moment(
                        new Date(
                          avblfltSeg[
                            avblfltSeg?.length - 1
                          ]?.flightSegment?.arrivalDateTime?.split("T")[0]
                        )
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {avblfltSeg[
                        avblfltSeg?.length - 1
                      ]?.flightSegment?.arrivalDateTime
                        ?.split("T")[1]
                        ?.slice(0, 5)}
                    </h6>
                    <h6>
                      {
                        avblfltSeg[avblfltSeg?.length - 1]?.flightSegment
                          ?.arrivalAirport?.locationCode
                      }{" "}
                      - {getCity(locArrival)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body">
                {/* discount goes here */}

                <>
                  {" "}
                  <h6 className="text-danger">
                    {piadata?.fareComponentGroupList?.fareComponentList
                      ? DiscountPrice(
                        selectedFare
                          ? selectedFlight?.fare?.passengerFareInfoList
                          : fareComponentList?.at(0)?.passengerFareInfoList
                      )
                      : "Sold Out"}{" "}
                  </h6>
                  <h4>
                    {piadata?.fareComponentGroupList?.fareComponentList
                      ? totalPaxFare(
                        selectedFare
                          ? selectedFlight?.fare?.passengerFareInfoList
                          : fareComponentList?.at(0)?.passengerFareInfoList
                      )
                      : "Sold Out"}{" "}
                    <img
                      src="/NEW_LOGO/hitit.png"
                      alt=""
                      width={40}
                      height={40}
                    />
                  </h4>
                  {user?.agents_comp?.agent_grp_show ? (
                    <div className="fw-bold fs-5">{piadata?.agent_name}</div>
                  ) : null}
                  <button
                    onClick={() => toggleDetail(index)}
                    className="button_style w-75 my-3"
                    type="button"
                  >
                    <div>
                      <MenuOpenIcon className="me-1" />
                      Flight Detail
                    </div>
                  </button>
                </>
              </div>
            </div>
          </div>
        </div>

        {isExpanded && (
          <div className="p-3">
            {/* Tabs start */}
            {/* {avblfltSeg[1] ? ( */}
            <div className="d-flex mb-4 mt-2 flight_details_tabs">
              {["Fare Options", "Flight Details"].map((item, index) => {
                const active = selectedTab === item;
                return (
                  <button
                    key={index}
                    onClick={() => setSelectedTab(item)}
                    className={active ? "active" : ""}
                  >
                    {item}
                  </button>
                );
              })}
            </div>
            {/* ) : null} */}

            {/* Flight Details */}
            {selectedTab === "Flight Details" && (
              <div className="row rounded-2 search_engin_result_box bg-white p-4">
                {avblfltSeg?.map((item, inx, array) => {
                  return (
                    <ConnectedFlight
                      item={item}
                      inx={inx}
                      calculateTotalTimeDifference={
                        calculateTotalTimeDifference
                      }
                      fligthSegment={array}
                      operatedAirline={operatedAirline}
                      convertTime={convertTime}
                      timeDuration={timeDuration}
                    />
                  );
                })}
              </div>
            )}

            {/* Fare options start */}
            {selectedTab === "Fare Options" && (
              <div className="row rounded-2 search_engin_result_box bg-white p-4">
                <Slider {...settings}>
                  {/* headings */}
                  {/* <div className="w-25">
                    <div className="mb-4 pb-3"></div>
                    <div className="flight-detail-headings">
                      <BsHandbag size={25} className="color_primary" />
                      <span>Hand Carry Bags</span>
                    </div>
                    <div className="flight-detail-headings">
                      <BsBagCheck size={25} className="color_primary" />
                      <span>Checked Bags</span>
                    </div>
                    <div className="flight-detail-headings">
                      <MdOutlineRestaurant
                        size={25}
                        className="color_primary"
                      />
                      <span>Meals</span>
                    </div>
                    <div className="flight-detail-headings">
                      <BsPerson size={25} className="color_primary" />
                      <span>Seat Selection</span>
                    </div>
                    <div className="flight-detail-headings">
                      <MdSwapHoriz size={25} className="color_primary" />
                      <span>Refund & Exchanges</span>
                    </div>
                  </div> */}
                  {(selectedFare
                    ? selectedFareOutbound
                    : fareComponentList
                  )?.map((fare, fareIndex) => {
                    let infoList = Array.isArray(fare?.passengerFareInfoList)
                      ? fare?.passengerFareInfoList
                      : [fare?.passengerFareInfoList];

                    let fareInfoList = infoList[0]?.fareInfoList?.length ? infoList[0]?.fareInfoList : [infoList[0]?.fareInfoList]

                    return (
                      <div key={fare?.internalID}>
                        <div className="fs-5 theme-text-color mb-4 fw-bold">
                          {returnFlight
                            ? infoList[0]?.fareInfoList[0]?.fareGroupName
                            : avblfltSeg[1]
                              ? infoList[0]?.fareInfoList[0]?.fareGroupName
                              : infoList[0]?.fareInfoList?.fareGroupName}{" "}
                          (
                          {returnFlight
                            ? infoList[0]?.fareInfoList[0]?.resBookDesigCode
                            : avblfltSeg[1]
                              ? infoList[0]?.fareInfoList[0]?.resBookDesigCode
                              : infoList[0]?.fareInfoList?.resBookDesigCode}
                          {" - "}
                          {returnFlight
                            ? infoList[0]?.fareInfoList[0]?.fareReferenceCode
                            : avblfltSeg[1]
                              ? infoList[0]?.fareInfoList[0]?.fareReferenceCode
                              : infoList[0]?.fareInfoList?.fareReferenceCode}
                          )
                        </div>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          pt={2}
                        >
                          <div className="color_primary">
                            <FaSuitcaseRolling size={25} />
                          </div>
                          <h6 className="pt-2 ps-2">Hand Carry: 7KG</h6>
                        </Box>

                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          pt={2}
                        >
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            width={125}
                          >
                            <Box className="color_primary">
                              <FaSuitcase size={22} />
                            </Box>
                            <h6 className="pt-2 ps-2">Check Bag:</h6>
                          </Box>
                          <h6 className="pt-2">
                            {
                              fareInfoList?.at(0)?.fareBaggageAllowance?.maxAllowedWeight ? `${fareInfoList?.at(0)?.fareBaggageAllowance?.maxAllowedWeight?.weight} KG` : `${fareInfoList?.at(0)?.fareBaggageAllowance?.maxAllowedPieces} pieces ${fareInfoList?.at(0)?.fareBaggageAllowance?.pieceDefinitions?.split(" ")?.at(1) || ''}`
                            }
                          </h6>
                          <Box>
                          </Box>
                        </Box>

                        <Box display={"flex"}
                          alignItems={"center"}
                          pt={2}
                        >
                          <MdOutlineRestaurant
                            size={25}
                            className="color_primary"
                          />
                          <h6 className="ps-2 pt-2">Meal: Allowed</h6>
                        </Box>

                        <Box display={"flex"}
                          alignItems={"center"}
                          pt={2}>
                          <Box>
                            <CgMenuGridR size={25} className="color_primary" />
                          </Box>
                          <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                        </Box>

                        <Box
                          pt={1}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <MdSwapHoriz
                            size={30}
                            className="color_primary"
                          />
                          <h6 className="pt-1 ps-2 text-success">
                            Refundable
                          </h6>
                        </Box>

                        {/* refund and exchange dialog  */}
                        <div className="flight-detail-headings">
                          <RefundExchange />
                        </div>

                        {/* ////////////// total fare /////// */}
                        {!selectedFare ? (
                          <div className="pt-3 px-2">
                            <LightTooltip
                              title={
                                <div className="p-3">
                                  <div className="d-flex justify-content-between align-items-end">
                                    <div className="d-flex me-4">
                                      <h5 style={{ minWidth: "3rem" }}>
                                        ADT
                                      </h5>
                                      <h5>* {searchValues?.adultsCount}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                      {singlePaxFare(
                                        infoList[0],
                                        1
                                      )}
                                    </h6>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-end my-2">
                                    <div className="d-flex">
                                      <h5 style={{ minWidth: "3rem" }}>
                                        CHD
                                      </h5>
                                      <h5>* {searchValues?.childCount}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                      {singlePaxFare(
                                        infoList[1],
                                        searchValues?.childCount > 0 && 1
                                      )}
                                    </h6>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-end">
                                    <div className="d-flex">
                                      <h5 style={{ minWidth: "3rem" }}>
                                        INF
                                      </h5>
                                      <h5>* {searchValues?.infantNo}</h5>
                                    </div>
                                    <h6 style={{ minWidth: "7rem" }}>
                                      {singlePaxFare(
                                        infoList[2],
                                        searchValues?.infantNo > 0 && 1
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              }
                              placement="top"
                            >
                              <h6 className="text-danger">
                                {piadata?.fareComponentGroupList
                                  ?.fareComponentList
                                  ? DiscountPrice(infoList)
                                  : "Sold Out"}
                              </h6>
                              <button
                                type="button"
                                className="button_style px-4"
                                disabled={
                                  !piadata?.fareComponentGroupList
                                    ?.fareComponentList
                                }
                                onClick={() => {
                                  if (!selectedFare) {
                                    //  dispatch(
                                    //    setselectedFlight(
                                    let bookiongBody = {
                                      flight: piadata,
                                      fare: fare,
                                      logo: "HITIT",
                                      code: "PK",
                                      price: totalPaxFare(infoList),
                                      ttl_agent_fare: TwoDecimalPoint(
                                        CurrencyExchangeToPKR(
                                          totalPaxFare(infoList)
                                            ?.split(" ")
                                            ?.at(1) || 0
                                        )
                                      ),
                                      ttlagent_curr_fare: Number(
                                        totalPaxFare(infoList)
                                          ?.split(" ")
                                          ?.at(1) || 0
                                      ),
                                      ttl_supplier_fare: 0,
                                      ttlsup_curr_fare: 0,

                                      adultBaseFare:
                                        infoList.at(0)?.pricingInfo
                                          ?.equivBaseFare?.value,
                                      adultTaxes:
                                        Number(
                                          infoList?.at(0)?.pricingInfo
                                            ?.totalFare?.amount?.value
                                        ) -
                                        Number(
                                          infoList?.at(0)?.pricingInfo
                                            ?.equivBaseFare?.value
                                        ),
                                      childBaseFare:
                                        searchValues?.childCount === 0
                                          ? 0
                                          : infoList?.at(1)?.pricingInfo
                                            ?.equivBaseFare?.value,
                                      childTaxes:
                                        searchValues?.childCount === 0
                                          ? 0
                                          : Number(
                                            infoList?.at(1)?.pricingInfo
                                              ?.totalFare?.amount?.value
                                          ) -
                                          Number(
                                            infoList?.at(1)?.pricingInfo
                                              ?.equivBaseFare?.value
                                          ),
                                      infantBaseFare:
                                        searchValues?.infantNo === 0
                                          ? 0
                                          : infoList?.at(infoList?.length - 1)
                                            ?.pricingInfo?.equivBaseFare
                                            ?.value,
                                      infantTaxes:
                                        searchValues?.infantNo === 0
                                          ? 0
                                          : Number(
                                            infoList?.at(
                                              infoList?.length - 1
                                            )?.pricingInfo?.totalFare
                                              ?.amount?.value
                                          ) -
                                          Number(
                                            infoList[infoList?.length - 1]
                                              ?.pricingInfo?.equivBaseFare
                                              ?.value
                                          ),

                                      adlt_ttl_d_curr: CurrencyExchangeToPKR(
                                        Number(
                                          singlePaxFare(infoList?.at(0), 1)
                                            ?.split(" ")
                                            ?.at(1)
                                        )
                                      ),
                                      chld_ttl_d_curr:
                                        searchValues?.childCount === 0
                                          ? 0
                                          : CurrencyExchangeToPKR(
                                            Number(
                                              singlePaxFare(
                                                infoList?.at(1),
                                                1
                                              )
                                                ?.split(" ")
                                                ?.at(1)
                                            )
                                          ),
                                      inft_ttl_d_curr:
                                        searchValues?.infantNo === 0
                                          ? 0
                                          : CurrencyExchangeToPKR(
                                            Number(
                                              singlePaxFare(
                                                infoList?.at(
                                                  infoList?.length - 1
                                                ),
                                                1
                                              )
                                                ?.split(" ")
                                                ?.at(1)
                                            )
                                          ),

                                      baggageName: returnFlight
                                        ? infoList[0]?.fareInfoList[0]
                                          ?.fareGroupName
                                        : avblfltSeg[1]
                                          ? infoList[0]?.fareInfoList[0]
                                            ?.fareGroupName
                                          : infoList[0]?.fareInfoList
                                            ?.fareGroupName,
                                      flightNumber: `PK ${avblfltSeg[0]?.flightSegment?.flightNumber}`,
                                      depDate: depDate,
                                      depTime: depTime,
                                      arrDate: arrDate,
                                      arrTime: arrTime,
                                      flightDuration: avblfltSeg[1]
                                        ? timeDuration(
                                          avblfltSeg[0]?.flightSegment
                                            ?.journeyDuration,
                                          avblfltSeg[1]?.flightSegment
                                            ?.journeyDuration
                                        )
                                        : convertTime(
                                          avblfltSeg[0]?.flightSegment
                                            ?.journeyDuration
                                        ),

                                      ADLT_TAXES: getPaxTaxes(
                                        infoList?.at(0)?.pricingInfo?.taxes
                                          ?.taxList
                                      ),
                                      CHLD_TAXES:
                                        searchValues?.childCount === 0
                                          ? []
                                          : getPaxTaxes(
                                            infoList?.at(1)?.pricingInfo
                                              ?.taxes?.taxList
                                          ),
                                      INFT_TAXES:
                                        searchValues?.infantNo === 0
                                          ? []
                                          : getPaxTaxes(
                                            infoList?.at(
                                              infoList?.length - 1
                                            )?.pricingInfo?.taxes?.taxList
                                          ),

                                      ADLT_FARE: infoList?.at(0),
                                      CHLD_FARE:
                                        searchValues?.childCount === 0
                                          ? {}
                                          : infoList?.at(1),
                                      INFT_FARE:
                                        searchValues?.infantNo === 0
                                          ? {}
                                          : infoList?.at(
                                            infoList?.length - 1
                                          ),

                                      booking: {
                                        gds: "HITIT", // should be gds code
                                        pnr: "", // returned in pricing response
                                        agent_name: piadata?.agent_name,
                                        segments: avblfltSeg?.map(
                                          (
                                            { flightSegment, ...segment },
                                            segmentIndex
                                          ) => {
                                            const requiredFare = infoList?.at(0);

                                            const baggage = requiredFare?.fareInfoList?.fareBaggageAllowance?.maxAllowedWeight
                                              ? `${requiredFare?.fareInfoList?.fareBaggageAllowance?.maxAllowedWeight?.weight} KG`
                                              : `${requiredFare?.fareInfoList?.fareBaggageAllowance?.maxAllowedPieces} pieces ${requiredFare?.fareInfoList?.fareBaggageAllowance?.pieceDefinitions?.split(" ")?.at(1) || ''}`


                                            const getDate = (dateTime) =>
                                              dateTime?.split("T")?.at(0);
                                            const getTime = (dateTime) =>
                                              dateTime
                                                ?.split("T")
                                                ?.at(1)
                                                ?.slice(0, 5);
                                            return {
                                              boundList: {
                                                ...segment,
                                                flightSegment,
                                                bookingClassList: segment
                                                  ?.bookingClassList?.length
                                                  ? segment?.bookingClassList?.at(
                                                    fareIndex
                                                  )
                                                  : segment?.bookingClassList,
                                              },
                                              flight: "OW",
                                              status: "",
                                              fare: requiredFare,
                                              baggage: baggage,
                                              departure_date: getDate(
                                                flightSegment?.departureDateTime
                                              ),
                                              departure_time: getTime(
                                                flightSegment?.departureDateTime
                                              ),
                                              arrival_date: getDate(
                                                flightSegment?.arrivalDateTime
                                              ),
                                              arrival_time: getTime(
                                                flightSegment?.arrivalDateTime
                                              ),
                                              origin_terminal: "",
                                              desti_terminal: "",
                                              flight_number:
                                                flightSegment?.flightNumber,
                                              res_book_desig_code:
                                                requiredFare?.fareInfoList[segmentIndex]?.resBookDesigCode,
                                              des_location:
                                                flightSegment?.arrivalAirport
                                                  ?.cityInfo?.city
                                                  ?.locationCode +
                                                "- -" +
                                                flightSegment?.arrivalAirport
                                                  ?.cityInfo?.city
                                                  ?.locationName,
                                              dest_code:
                                                flightSegment?.arrivalAirport
                                                  ?.cityInfo?.city
                                                  ?.locationCode,
                                              marketing_airline: "PK",
                                              equipment_code:
                                                flightSegment?.equipment
                                                  ?.airEquipType,
                                              operatingairline_code: flightSegment?.operatingAirline ? flightSegment?.operatingAirline?.code : flightSegment?.airline?.code,
                                              orig_location:
                                                flightSegment
                                                  ?.departureAirport?.cityInfo
                                                  ?.city?.locationCode +
                                                "- -" +
                                                flightSegment
                                                  ?.departureAirport?.cityInfo
                                                  ?.city?.locationName,
                                              orig_code:
                                                flightSegment
                                                  ?.departureAirport?.cityInfo
                                                  ?.city?.locationCode,
                                              marriage_grp: "",
                                              cabin_class:
                                                requiredFare?.fareInfoList?.fareGroupName === "NILBAG" ? "ECOLIGHT" : requiredFare?.fareInfoList?.fareGroupName,
                                              segment_type: getSectorsName(
                                                flightSegment
                                                  ?.departureAirport?.cityInfo
                                                  ?.city?.locationCode,
                                                flightSegment?.arrivalAirport
                                                  ?.cityInfo?.city
                                                  ?.locationCode
                                              ), // dom | siti | soto | group
                                            };
                                          }
                                        ),
                                        tp_ur_code: "",
                                        platting_carrier: "PK",
                                        tp_ssr_verssion: 0,
                                        tp_trace_id: "",
                                      },
                                    };

                                    dispatch(setselectedFlight(bookiongBody));

                                    dispatch(setExpandFlightDetails(null));
                                    navigate("/check-out");
                                  }
                                }}
                              >
                                {piadata?.fareComponentGroupList
                                  ?.fareComponentList
                                  ? totalPaxFare(infoList)
                                  : "Sold Out"}
                              </button>
                            </LightTooltip>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </Slider>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Pia;

// refund and exchange
export const RefundExchange = () => {
  return (
    <LightTooltip
      title={
        <div className="p-3">
          <h4>Refund & Exchange</h4>
          <div className="text-danger font-italic">
            for further clarification contact with Airline Or Call our support
            team.
          </div>
          <div className="text-danger font-italic mt-2">
            After 25 days of departure ticket is Non-Refundable.
          </div>

          <h6 className="mt-3">
            contact with airline or call our support team
          </h6>

          {/* <div className="d-flex pt-3">
            <div>
              <h4>Conditions</h4>
              <h6>More than 48 hours to departure</h6>
              <h6>Within 48 hours To 4 hours to Departure</h6>
              <h6>Less than 4 hours after departure</h6>
              <h6>No show</h6>
            </div>
            <div className="mx-4">
              <h4>COB</h4>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2000)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
            </div>
            <div>
              <h4>Refund</h4>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
            </div>
          </div> */}
        </div>
      }
      placement="top"
    >
      <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
        <MdRemoveRedEye size={25} className="color_primary" />
        <h6 className="pt-2 ps-2">View</h6>
      </div>
    </LightTooltip>
  );
};

// Connected Flights
const ConnectedFlight = ({
  item,
  inx,
  calculateTotalTimeDifference,
  fligthSegment,
  operatedAirline,
  convertTime,
  timeDuration
}) => {
  const [airport_name, setairport_name] = useState("");
  const [arr_airport_name, setArrairport_name] = useState("");
  //api call for fatching airport name
  useEffect(() => {
    const fetchDataDep = async () => {
      try {
        let airportCode = item?.flightSegment?.departureAirport?.locationCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        // Handle the data here, for example, set it in the state
        setairport_name(data);
      } catch (error) {
        // Handle errors here
      }
    };

    const fetchDataArr = async () => {
      try {
        let airportCode = item?.flightSegment?.arrivalAirport?.locationCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        // Handle the data here, for example, set it in the state
        setArrairport_name(data);
      } catch (error) {
        // Handle errors here
      }
    };

    fetchDataDep(); // Call the fetchData function
    fetchDataArr(); // Call the fetchData function
  }, []);

  return (
    <div key={inx}>
      <div className="row align-items-center">
        <div className="col-2">
          <img
            src={`/NEW_LOGO/${item?.flightSegment?.operatingAirline ? item?.flightSegment?.operatingAirline?.code : item?.flightSegment?.airline?.code}.png`}
            alt="logo"
            width={70}
            height={70}
          />
          <h6 className="mt-3 ms-2">
            {item?.flightSegment?.operatingAirline ? item?.flightSegment?.operatingAirline?.code : item?.flightSegment?.airline?.code}{" "}
            {item?.flightSegment?.flightNumber}
          </h6>

          <h6 className="mt-2 ps-2" style={{ whiteSpace: "nowrap" }}>
            Operated by : {item?.flightSegment?.operatingAirline ? item?.flightSegment?.operatingAirline?.code : item?.flightSegment?.airline?.code}
          </h6>
        </div>
        <div className="col-10 d-flex justify-content-between align-items-center">
          <div className="col-5 d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightTakeoff size={30} className="color_primary" />
            </div>
            <div className="">
              <h6>
                {moment(item?.flightSegment?.departureDateTime).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>

              <h6 className="my-3">
                {moment(item?.flightSegment?.departureDateTime).format("HH:mm")}
              </h6>
              <h6>
                {item?.flightSegment?.departureAirport?.locationCode} -{" "}
                {airport_name}
              </h6>
              {item?.flightSegment?.departureAirport?.terminal && (
                <h6 className="color_primary">
                  (Terminal-{item?.flightSegment?.departureAirport?.terminal})
                </h6>
              )}
            </div>
          </div>

          <div className="col-2 d-flex flex-column align-items-start">
            <div className="d-flex justify-content-center">
              <MdFlight className="color_primary fs-5 rotate-right" />
              <h6 className="text-center">Trip Time</h6>
            </div>
            <div className="border_rounded mb-2" />
            <h6 className="text-center ms-4">
              {
                timeDuration(
                  item?.flightSegment?.departureDateTime,
                  item?.flightSegment?.arrivalDateTime
                )}
            </h6>
          </div>
          <div className="col-5 d-flex align-items-center ps-4">
            <div className="me-3">
              <MdOutlineFlightLand size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(item?.flightSegment?.arrivalDateTime).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>
              <h6 className="my-3">
                {moment(item?.flightSegment?.arrivalDateTime).format("HH:mm")}
              </h6>
              <h6>
                {item?.flightSegment?.arrivalAirport?.locationCode} -{" "}
                {arr_airport_name}
              </h6>
              {item?.flightSegment?.arrivalAirport?.terminal && (
                <h6 className="color_primary">
                  (Terminal-{item?.flightSegment?.arrivalAirport?.terminal})
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
      {inx < fligthSegment.length - 1 && (
        <div className="col-12 d-flex justify-content-center align-items-center my-4">
          <div className="border_rounded w-25" />
          <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
            <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
            <h6 className="pt-2">
              Layover time:{" "}
              <span className="fs-5">
                {" "}
                {calculateTotalTimeDifference(
                  item?.flightSegment?.arrivalDateTime,
                  fligthSegment[inx + 1]?.flightSegment?.departureDateTime
                )}
              </span>
            </h6>
          </div>
          <div className="border_rounded w-25" />
        </div>
      )}
    </div>
  );
};
