import React, { useCallback, useEffect, useMemo, useState } from "react";
import BookingTable from "../components/Booking/BookingTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllAsyncBookings } from "../redux/features/booking/bookingDetailsSlice";
import { asyncGetNewSupplier } from "../redux/features/supplier/AddNewSupplier";
import { asyncGetNewAgencyReg } from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { asyncGetLoginData } from "../redux/features/userLogin/profileHeaderSlice";
import { getAllStatus } from "../redux/features/bookingStatus/bookingStatus";
import { asyncGetFlightGroupData } from "../redux/features/promotion/FlightGroupsSlice";
import { asyncGetCurrencyData } from "../redux/features/setupRestPages/Bank_curr_RoleSlice";
import BookingFilter from "../components/Booking/BookingFilter";
import { SearchOffOutlined, SearchOutlined } from "@mui/icons-material";
import moment from "moment";

function Booking() {

  const { newSupplier } = useSelector((state) => state.supplier);
  const { newAgencyRegData } = useSelector((state) => state.newAgency);
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const allBooking = useSelector((state) => state?.booking);
  const status = useSelector((state) => state?.bookingStatus);
  const { flightGroup } = useSelector((state) => state.flightGroup);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);

  const [bookingFilter, setBookingFilter] = useState({
    fromDate: "",
    toDate: "",
    agent_acc_id: "",
    supplier_acc_id: "",
    booking_id: "",
    pax_name: "",
    service_type: "",
    status: "",
    change: false,
  });
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  const [rowsPerPage, setRowsPerPage] = useState(25); // Default to 2 rows per page
  useEffect(() => {
    dispatch(asyncGetNewSupplier());
    dispatch(asyncGetNewAgencyReg());
    dispatch(asyncGetLoginData());
    dispatch(asyncGetFlightGroupData());
    dispatch(getAllStatus());
    dispatch(asyncGetCurrencyData());
    dispatch(
      getAllAsyncBookings({
        id: user?.agents_comp?.acc_id,
        rowsPerPage: rowsPerPage,
        currentPage: currentPage,
        bookingFilter,
      })
    );
    // setTimeout(() => {
    //   setToggle(!toggle);
    // }, 10000);
  }, [dispatch, bookingFilter?.change]);
  const data =
    allBooking?.allBooking?.data?.length > 0
      ? allBooking?.allBooking?.data
      : [];
  const [filter, setFilter] = useState(false);
  return (
    <div className="Full_height">
      <div className="shadow-lg mt-4">
        <div
          className="d-flex justify-content-between  bg_primary mx-2 px-2 rounded"
          style={{ cursor: "pointer" }}
          onClick={(e) => setFilter(!filter)}
        >
          {!filter ? (
            <p className="text-white fs-5 mt-4">Booking Filter Record</p>
          ) : (
            <div
              className=" shadow-lg rounded p-4 "
              style={{
                width: "100%",
                height: "auto",
              }}
            >
              <BookingFilter
                bookingFilter={bookingFilter}
                setBookingFilter={setBookingFilter}
                user={user}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}

          <p
            className="text-white fs-5 mt-4"
            onClick={(e) => setFilter(!filter)}
          >
            <SearchOutlined />
          </p>
        </div>
        {data && (
          <div>
            <BookingTable
              allBooking={data}
              flightGroup={flightGroup?.length && flightGroup}
              currencyData={currencyData}
              supplier={newSupplier}
              newAgencyRegData={newAgencyRegData}
              user={user}
              status={status?.status}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              dispatch={dispatch}
              totalReords={allBooking?.allBooking?.Totallength}
              bookingFilter={bookingFilter}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Booking;

//  const allFilterBookingData = data?.filter((item) => {
//    // Filter by service_type
//    const serviceTypeMatch = bookingFilter?.service_type
//      ? item?.book_type?.toLowerCase() ===
//        bookingFilter.service_type.toLowerCase()
//      : true;

//    // Filter by date range (fromDate and toDate)
//    const fromDateMatch = bookingFilter?.fromDate
//      ? item?.bookdatetime?.split("T")?.at(0) >=
//        moment(bookingFilter.fromDate).format("YYYY-MM-DD")
//      : true;
//    const toDateMatch = bookingFilter?.toDate
//      ? item?.bookdatetime?.split("T")?.at(0) <=
//        moment(bookingFilter.toDate).format("YYYY-MM-DD")
//      : true;

//    // Filter by agent_acc_id
//    const agentAccIdMatch = bookingFilter?.agent_acc_id
//      ? item?.agent_acc_id === bookingFilter.agent_acc_id
//      : true;

//    // Filter by supplier_acc_id
//    const supplierAccIdMatch = bookingFilter?.supplier_acc_id
//      ? item?.supplier_acc_id === bookingFilter.supplier_acc_id
//      : true;

//    // Filter by booking_id
//    const bookingIdMatch = bookingFilter?.booking_id
//      ? item?.id === Number(bookingFilter.booking_id)
//      : true;

//    // Filter by pax_name
//    const paxNameMatch = bookingFilter?.pax_name
//      ? item?.FlightBookingModels?.at(0)?.pax_tables?.some((px) =>
//          (px?.fName?.toLowerCase() + " " + px?.lname?.toLowerCase()).includes(
//            bookingFilter.pax_name.toLowerCase()
//          )
//        )
//      : true;

//    // Filter by status
//    const statusMatch = bookingFilter?.status
//      ? item?.doc_status?.toLowerCase() === bookingFilter.status.toLowerCase()
//      : true;

//    // Combine all conditions
//    return (
//      serviceTypeMatch &&
//      fromDateMatch &&
//      toDateMatch &&
//      agentAccIdMatch &&
//      supplierAccIdMatch &&
//      bookingIdMatch &&
//      paxNameMatch &&
//      statusMatch
//    );
//  });
