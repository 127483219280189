import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncBookingDetails } from "../../../redux/features/booking/bookingDetailsSlice";
import { apiUrl } from "../../../Utils/configUrl";

const ViewVisa = ({ id, type }) => {
  const dispatch = useDispatch();
  const booking = useSelector((state) => state?.booking);
  useEffect(() => {
    dispatch(getAsyncBookingDetails({ id, type }));
  }, []);
  const paxData = booking?.booking?.id
    ? booking?.booking?.VisaBookingModels?.at(0)?.pax_tables
    : null;
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div style={{ width: 200, marginTop: 10 }}>
      {paxData?.map((pax, index) => (
        <a
          key={index}
          className={`h6 d-flex flex-column ps-2 py-2  ${
            hoveredIndex === index
              ? "booking-tab-link text-white"
              : "bookings_details_links"
          }`}
          style={{ cursor: "pointer", textDecoration:"none" }}
          href={`${apiUrl}/${pax?.visa_attachment}`}
          target="_blank"
          onMouseEnter={() => setHoveredIndex(index)} 
          onMouseLeave={() => setHoveredIndex(null)} 
        >
          {pax?.fName + " " + pax?.lname}
        </a>
      ))}
    </div>
  );
};
export default ViewVisa;

