import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncChangePassword,
  asyncConfirmOtpCode,
  asyncForgetPassword,
  asyncUserLogin,
  asyncVerifyAgentOtp,
  changePassReducer,
  loginErrorMsg,
  otpEmailReducer,
  otpMsgReducer,
  setAgentOtpNull,
} from "../../redux/features/userLogin/UserSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import BtnLoader from "../AppForm/BtnLoader";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { Check, Close } from "@mui/icons-material";

const LoginModal = ({ handleClose, setShowReisterModal }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    otp: "",
    id: "",
    agentOtp: "",
  });
  const [form_page, setform_page] = useState("login");
  const [showPassword, setShowPassword] = useState(false);
  const [counter, setCounter] = useState(60);
  const [agentcounter, setAgentCounter] = useState(60);
  const { logInLoading, otpLoading, optEmail, otpCode, loginError, otpMsg, changPass, agentOtp } =
    useSelector((state) => state.user);

  useEffect(() => {
    if (optEmail?.status === "success") {
      setform_page("enterOTP");
      setTimeout(() => {
        dispatch(otpEmailReducer(null));
      }, 3000);
    } else if (optEmail?.status === "fail") {
      setTimeout(() => {
        dispatch(otpEmailReducer(null));
      }, 3000);
    }
  }, [dispatch, optEmail?.status]);
  useEffect(() => {
    if (otpMsg?.token) {
      setform_page("resPass");
      setTimeout(() => {
        dispatch(otpMsgReducer(null));
      }, 3000);
    } else if (otpMsg?.status === "fail") {
      setTimeout(() => {
        dispatch(otpMsgReducer(null));
      }, 3000);
    }
  }, [dispatch, otpMsg]);

  useEffect(() => {
    if (form_page === "enterOTP" && counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else if (form_page === "enterOTP" && counter === 0) {
      setform_page("sentOTP");
      setCounter(60);
    } else {
      setCounter(60);
    }
  }, [counter, form_page]);

  useEffect(() => {
    if (agentOtp === "OTP send" && agentcounter > 0) {
      const timer = setTimeout(() => setAgentCounter((prevCounter) => prevCounter - 1), 1000);
      return () => clearTimeout(timer);
    }

    if (agentcounter === 0) {
      dispatch(setAgentOtpNull(null));
      setAgentCounter(60);
      dispatch(setAgentOtpNull(null));
    }
  }, [agentcounter, agentOtp]);

  /*************Login Form handler *************/

  const loginUser = (e) => {
    // e.preventDefault();
    if (!credentials.email || !credentials.password) return;
    localStorage.setItem("model", true);
    dispatch(
      asyncUserLogin({
        email: credentials.email,
        password: credentials.password,
        navigate,
      })
    );
    setTimeout(() => dispatch(loginErrorMsg(null)), 4000);
  };

  /*************verify agent Otp*****************/
  const verifyAgentOTP = () => {
    dispatch(asyncVerifyAgentOtp({ otp: credentials?.agentOtp, navigate }))
      .then(() => {
        setTimeout(() => {
          setCredentials((prevCredentials) => ({
            ...prevCredentials,
            agentOtp: "",
          }));
          dispatch(setAgentOtpNull());
        }, 3000);
      })
      .catch((error) => {
        console.error("Error verifying agent OTP:", error);
      });
  };


  /*************Send OTP handler *************/
  const sendOTP = (e) => {
    e.preventDefault();
    dispatch(asyncForgetPassword(credentials?.email));
  };
  /*************Verify OPT handler *************/
  const verifyOTP = (e) => {
    e.preventDefault();
    dispatch(asyncConfirmOtpCode(credentials?.otp));
  };
  /*************Send OTP handler *************/

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Password Required"),
    repeat_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const [requirements, setRequirements] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    minLength: false,
    specialChar: false,
  });

  const validatePassword = (values) => {
    const { password } = values;
    setRequirements({
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      minLength: password.length >= 8,
    });
  };

  return (
    <div className="back_drop_shadow_container">
      <Modal show={true} onHide={(e) => handleClose(false)} centered>
        <div className="login_form_h">
          {form_page === "login" && (
            <>
              <div className="text-center bg_primary">
                <p className="box_title text-white py-2">Sign In</p>
              </div>
              <div className="text-danger px-5 text-capitalize">
                {loginError?.message}
              </div>
              <div className="p-5">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    loginUser();
                    // if (agentOtp === "OTP send") {
                    //   verifyAgentOTP()
                    // } else {
                    // }
                  }}
                >
                  {
                    agentOtp !== "OTP send" ? (
                      <>
                        <div>
                          <TextField
                            label="User Email"
                            autoComplete="off"
                            variant="standard"
                            className="w-100"
                            onChange={(e) =>
                              setCredentials({
                                ...credentials,
                                email: e.target.value,
                              })
                            }
                            value={credentials?.email}
                            required
                            autoFocus
                          />
                        </div>
                        <div className="mt-4">
                          <FormControl className="w-100" variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">
                              Password
                            </InputLabel>
                            <Input
                              id="standard-adornment-password"
                              type={showPassword ? "text" : "password"}
                              value={credentials?.password}
                              onChange={(e) =>
                                setCredentials({
                                  ...credentials,
                                  password: e.target.value,
                                })
                              }
                              required
                              autoComplete="off"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword((show) => !show)}
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <div className="py-4">
                            <span
                              onClick={(e) => setform_page("sentOTP")}
                              className="text-primary"
                              role="button"
                            >
                              Forgot Password?
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="mt-4">
                        <>
                          <FormControl className="w-100 " variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">
                              Enter Your OTP
                            </InputLabel>
                            <Input
                              id="standard-adornment-password"
                              value={credentials?.agentOtp}
                              onChange={(e) =>
                                setCredentials({
                                  ...credentials,
                                  agentOtp: e.target.value,
                                })
                              }
                              autoComplete="off"
                            />
                          </FormControl>
                          <div className="mt-4 text-success">
                            OTP will expire in {agentcounter}
                          </div>

                          <div className="py-4">
                            <span
                              onClick={(e) => {
                                dispatch(setAgentOtpNull(null));
                                setform_page("login")
                              }}
                              className="text-primary"
                              role="button"
                            >
                              Back to login.
                            </span>
                          </div>
                        </>
                      </div>
                    )
                  }
                  <div className="my-4 text-center">
                    <button className="button_style w-100" type="submit">
                      {logInLoading || otpLoading ? <BtnLoader /> : agentOtp === "OTP send" ? "Verify OTP" : "Sign In"}
                    </button>
                  </div>
                  <div className="text-center">
                    <p>
                      Not a member?{" "}
                      <span
                        className="text-primary pointer"
                        role="button"
                        onClick={() => {
                          handleClose(false);
                          setTimeout(() => {
                            setShowReisterModal(true);
                          }, 100);
                        }}
                      >
                        Register now
                      </span>{" "}
                    </p>
                  </div>
                </form>
              </div>
            </>
          )}

          {form_page === "sentOTP" && (
            //sent OTP
            <>
              {" "}
              <div className="text-center bg_primary">
                <p className="box_title text-white py-2">Sent OTP</p>
              </div>
              <div className="p-5">
                <div>
                  <TextField
                    label="Sent OTP to email"
                    autoComplete="off"
                    variant="standard"
                    className="w-100"
                    onChange={(e) =>
                      setCredentials({
                        ...credentials,
                        email: e.target.value,
                      })
                    }
                    value={credentials.email}
                  />
                  {optEmail?.status === "fail" ? (
                    <p className="text-danger mt-2">{optEmail?.message}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="py-4">
                  <span
                    onClick={(e) => setform_page("login")}
                    className="text-primary"
                    role="button"
                  >
                    Login Instead?
                  </span>
                </div>
                <div className="my-4 text-center">
                  <button
                    className="button_style w-100"
                    type="button"
                    onClick={sendOTP}
                  >
                    {logInLoading ? <BtnLoader /> : "Sent OTP"}
                  </button>
                </div>
              </div>
            </>
          )}

          {form_page === "enterOTP" && (
            <>
              {" "}
              <div className="text-center bg_primary">
                <p className="box_title text-white py-2">Verify OTP</p>
              </div>
              <div className="p-4">
                <div className="text-success mb-2">
                  {optEmail?.status === "success" && optEmail?.message}
                </div>
                <div className="text-danger mb-2">
                  {otpMsg?.status === "fail" && otpMsg?.message}
                </div>

                <div>
                  <TextField
                    label="Enter OTP"
                    autoComplete="off"
                    variant="standard"
                    type="number"
                    className="w-100"
                    onChange={(e) =>
                      setCredentials({
                        ...credentials,
                        otp: e.target.value,
                      })
                    }
                    value={credentials.otp}
                    required
                  />
                </div>

                <div className="py-4 d-flex justify-content-between align-items-center">
                  <span
                    onClick={(e) => setform_page("login")}
                    className="text-primary"
                    role="button"
                  >
                    Login Instead?
                  </span>
                  <span>otp will expire in {counter}</span>
                </div>
                <div className="my-4 text-center">
                  <button
                    className="button_style w-100"
                    type="button"
                    onClick={verifyOTP}
                  >
                    {logInLoading ? <BtnLoader /> : "Verify OTP"}
                  </button>
                </div>
              </div>{" "}
            </>
          )}

          {form_page === "resPass" && (
            <Formik
              validateOnChange={true}
              validateOnBlur={false}
              validate={validatePassword}
              enableReinitialize={true}
              initialValues={{
                password: "",
                repeat_password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                if (
                  requirements.lowercase &&
                  requirements.uppercase &&
                  requirements.number &&
                  requirements.minLength &&
                  requirements.specialChar
                ) {
                  dispatch(
                    asyncChangePassword({
                      id: otpCode?.id,
                      password: values.password,
                    })
                  );
                  setCredentials({
                    ...credentials,
                    password: values.password,
                  });

                  setform_page("");
                  handleClose(false);
                }
              }}
            >
              {({ values, handleChange, error }) => {
                return (
                  <Form>
                    <div className="text-center bg_primary">
                      <p className="box_title text-white py-2">
                        Change Password
                      </p>
                    </div>
                    <div className="p-4">
                      <p className="text-success">
                        {changPass?.status === "success" && changPass?.message}
                      </p>
                      <p className="text-primary">
                        {changPass?.status === "fail" && changPass?.message}
                      </p>
                      <div>
                        <FormControl className="w-100" variant="standard">
                          <InputLabel htmlFor="standard-adornment-password">
                            Enter New Password
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            autoComplete="off"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowPassword((show) => !show)
                                  }
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Typography
                            variant="body2"
                            sx={{ mt: 1, fontWeight: 500 }}
                          >
                            PASSWORD MUST CONTAIN:
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: requirements.lowercase ? "green" : "red",
                            }}
                          >
                            {requirements.lowercase ? <Check /> : <Close />}
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              At least one lowercase letter
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: requirements.uppercase ? "green" : "red",
                            }}
                          >
                            {requirements.uppercase ? <Check /> : <Close />}
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              At least one uppercase letter
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: requirements.specialChar
                                ? "green"
                                : "red",
                            }}
                          >
                            {requirements.specialChar ? <Check /> : <Close />}
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              At least one special character
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: requirements.number ? "green" : "red",
                            }}
                          >
                            {requirements.number ? <Check /> : <Close />}
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              At least one number
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: requirements.minLength ? "green" : "red",
                            }}
                          >
                            {requirements.minLength ? <Check /> : <Close />}
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              minimum 8 characters
                            </Typography>
                          </Box>
                        </FormControl>
                        <ErrorMessage
                          name="password"
                          component={"div"}
                          className="mt-2 text-danger"
                        />

                      </div>
                      <div className="mt-4">
                        <TextField
                          label="Confirm Password"
                          autoComplete="off"
                          variant="standard"
                          type="password"
                          className="w-100"
                          value={values.repeat_password}
                          name="repeat_password"
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          component={"div"}
                          className="mt-2 text-danger"
                          name="repeat_password"
                        />
                      </div>

                      <div className="py-4">
                        <span
                          onClick={(e) => setform_page("login")}
                          className="text-primary"
                          role="button"
                        >
                          Login Instead?
                        </span>
                      </div>
                      <div className="my-4 text-center">
                        <button className="button_style w-100" type="submit">
                          {logInLoading ? <BtnLoader /> : "Change Password"}
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default LoginModal;
