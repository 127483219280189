import React, { useEffect, useState } from "react";
import Office from "../../assets/images/work-space.png";
import User2 from "../../assets/images/user2.png";
import User2Active from "../../assets/images/user2-active.png";
import "../../Styles/menubar.css";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Check, Close, Logout, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetLoginData,
  asyncEditLoginData,
} from "../../redux/features/userLogin/profileHeaderSlice";
import { asyncGetDashboardData } from "../../redux/features/dashboard/dashboardSlice";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import BtnLoader from "../AppForm/BtnLoader";
import { asyncUserLogout } from "../../redux/features/userLogin/UserSlice";
import { asyncUpdateUserData } from "../../redux/features/agency/usersSlice";
import { asyncGetAgentData } from "../../redux/features/setupRestPages/agentsSlice";
import { apiUrl } from "../../Utils/configUrl";
import { CurrencyExchangeBasedOnROE } from "../../Utils/currencyExchange";

function ProfileHeader({ setshowMobileMenu }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /************use state ********** */
  const [show_profileModel, setshow_profileModel] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [requirements, setRequirements] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    minLength: false,
    specialChar: false,
  });

  const validatePassword = (values) => {
    const { password } = values;
    setRequirements({
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      minLength: password.length >= 8,
    });
  };

  /************* use selector *********** */
  const { loading } = useSelector((state) => state?.userloginslice);
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { agentsData } = useSelector((state) => state.agentsCategories);
  /*********agent data of login data *********** */
  const loginUserData = agentsData?.find(
    (it) => it?.agent_id === user?.agent_id
  );

  /**************Use effect *****************/
  useEffect(() => {
    dispatch(asyncGetLoginData());
    dispatch(asyncGetDashboardData());
    dispatch(asyncEditLoginData());
    // dispatch(asyncGetAgentData());
  }, [dispatch]);

  /************Intial values formik ************/
  const initialValues = {
    f_name: user?.f_name || "",
    l_name: user?.l_name || "",
    email: user?.email || "",
    mobile: user?.mobile_no || "",

    password: "",
    rep_password: "",
  };
  /************Validation schema ***************/
  const validationSchema = yup.object().shape({
    f_name: yup.string().required("F-name Required"),
    l_name: yup.string().required("L-name Required"),
    email: yup.string().required("Email Required"),
    mobile: yup.string().required("Mobile Required"),
    password: yup.string().min(8).max(25).required("Password Required"),
    rep_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Repeat Password Required"),
  });
  /***************Onsubmit formik ************/
  const onSubmit = (values) => {
    if (
      requirements.lowercase &&
      requirements.uppercase &&
      requirements.number &&
      requirements.minLength &&
      requirements.specialChar
    ) {
      const id = user?.user_id;
      const f_name = values?.f_name;
      const l_name = values?.l_name;
      const email = values?.email;
      const password = values?.password;
      const mobile_no = values?.mobile;
      const obj = {
        f_name,
        l_name,
        email,
        password,
        mobile_no,
      };
      dispatch(asyncUpdateUserData({ id, obj }));
      setshow_profileModel(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center px-4 px-md-5 bg-white shadow">
        {/***************** LOGO  ********* */}
        <div
          className="logo_container"
          onClick={(e) => {
            navigate("/home");
            window.location.reload(true);
          }}
        >
          <img src={`${apiUrl}/${user?.agents_comp?.logo}`} alt="logo" />
        </div>
        <div className="d-block d-md-none">
          <MenuRoundedIcon onClick={(e) => setshowMobileMenu(true)} />
        </div>

        <div className="d-none d-md-flex justify-content-between align-items-center profile_icon_container">
          {/*******************Company name ******** */}
          <div className="profile_info company_name_style">
            <img
              src={Office}
              alt="company"
              width={30}
              height={30}
              className="profile_avatar"
            />
            <img
              src={User2Active}
              alt="user"
              width={30}
              height={30}
              className="profile_avatar_active"
            />
            <p>{user?.agents_comp?.agent_name}</p>
          </div>
          {/*****************user name *********** */}
          <div
            className="profile_info company_name_style"
            onClick={() => {
              setshow_profileModel(!show_profileModel);
            }}
          >
            <img src={User2} alt="user" width={30} height={30} />
            <p className="text-capitalize">
              {user?.f_name} {user?.l_name}
            </p>
          </div>

          {show_profileModel && (
            <div className="backdrop_blur_cotianer">
              <div
                className="out_side_click"
                onClick={(e) => {
                  setshow_profileModel(false);
                }}
              ></div>

              <div className="profile_model">
                {user?.agent_id > 1 && (
                  <div className="financial-info">
                    <div className="pro-title-text">Financial Info</div>
                    <div className="d-flex align-items-center pro-info-text text-success">
                      <div className="">Approved Limit: </div>
                      <div className="ps-3">
                        {user?.agents_comp?.agent_group?.currency?.curr_code}{" "}
                        {CurrencyExchangeBasedOnROE(
                          loginUserData?.credit_limit,
                          user?.agents_comp?.agent_group?.currency?.roe
                        )}
                      </div>
                    </div>
                    <div className=" d-flex align-items-center mt-3 pro-info-text text-danger">
                      <div className="">Use Limit: </div>
                      <div style={{ paddingLeft: 62 }}>
                        {user?.agents_comp?.agent_group?.currency?.curr_code}{" "}
                        {CurrencyExchangeBasedOnROE(
                          loginUserData?.credit_used,
                          user?.agents_comp?.agent_group?.currency?.roe
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-3 pro-info-text text-primary">
                      <div className="">Available Limit: </div>
                      <div className="ps-3">
                        {user?.agents_comp?.agent_group?.currency?.curr_code}{" "}
                        {CurrencyExchangeBasedOnROE(
                          loginUserData?.credit_limit -
                          loginUserData?.credit_used,
                          user?.agents_comp?.agent_group?.currency?.roe
                        )}
                      </div>
                    </div>

                    <div className="horizontal_line" />
                  </div>
                )}

                <div className="profil_model_input_section">
                  <Formik
                    validateOnChange={true}
                    validateOnBlur={false}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validate={validatePassword}
                    onSubmit={onSubmit}
                  >
                    {({ values, setFieldValue }) => {
                      return (
                        <Form>
                          <div className="row ">
                            <div className="pro-title-text">User Info</div>
                            <div className="col-6 mt-4">
                              <TextField
                                label="F-Name"
                                variant="standard"
                                className="w-100"
                                value={values?.f_name}
                                inputProps={{
                                  readOnly: true,
                                }}
                                onChange={(e) => {
                                  const value = e.target.value || "";
                                  setFieldValue("f_name", value.toUpperCase());
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                className="text-danger mt-1"
                                name="f_name"
                              />
                            </div>
                            <div className="col-6 mt-4">
                              <TextField
                                label="L-Name"
                                variant="standard"
                                className="w-100"
                                value={values?.l_name}
                                inputProps={{
                                  readOnly: true,
                                }}
                                onChange={(e) => {
                                  const value = e.target.value || "";
                                  setFieldValue("l_name", value.toUpperCase());
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                className="text-danger mt-1"
                                name="l_name"
                              />
                            </div>
                            <div className="col-6 mt-4">
                              <TextField
                                label="E-Mail"
                                variant="standard"
                                className="w-100"
                                inputProps={{
                                  readOnly: true,
                                }}
                                value={values?.email}
                                onChange={(e) => {
                                  const value = e.target.value || "";
                                  setFieldValue("email", value);
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                className="text-danger mt-1"
                                name="email"
                              />
                            </div>
                            <div className="col-6 mt-4">
                              <TextField
                                label="Mobile"
                                variant="standard"
                                className="w-100"
                                value={values?.mobile}
                                inputProps={{
                                  readOnly: true,
                                }}
                                onChange={(e) => {
                                  const value = e.target.value || "";
                                  setFieldValue("mobile", value.toUpperCase());
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                className="text-danger mt-1"
                                name="mobile"
                              />
                            </div>
                            <div className="col-6 mt-4">
                              <FormControl variant="standard">
                                <InputLabel
                                  htmlFor="standard-adornment-password"
                                  className="w-100"
                                >
                                  Password
                                </InputLabel>
                                <Input
                                  id="standard-adornment-password"
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  value={values?.password}
                                  onChange={(e) => {
                                    const value = e.target.value || "";
                                    setFieldValue("password", value);
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                          setShowPassword((show) => !show)
                                        }
                                      >
                                        {showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />

                                <Typography
                                  variant="body2"
                                  sx={{ mt: 1, fontWeight: 500 }}
                                >
                                  PASSWORD MUST CONTAIN:
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: requirements.lowercase ? "green" : "red",
                                  }}
                                >
                                  {requirements.lowercase ? <Check /> : <Close />}
                                  <Typography variant="body2" sx={{ ml: 1 }}>
                                    At least one lowercase letter
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: requirements.uppercase ? "green" : "red",
                                  }}
                                >
                                  {requirements.uppercase ? <Check /> : <Close />}
                                  <Typography variant="body2" sx={{ ml: 1 }}>
                                    At least one uppercase letter
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: requirements.specialChar
                                      ? "green"
                                      : "red",
                                  }}
                                >
                                  {requirements.specialChar ? <Check /> : <Close />}
                                  <Typography variant="body2" sx={{ ml: 1 }}>
                                    At least one special character
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: requirements.number ? "green" : "red",
                                  }}
                                >
                                  {requirements.number ? <Check /> : <Close />}
                                  <Typography variant="body2" sx={{ ml: 1 }}>
                                    At least one number
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: requirements.minLength ? "green" : "red",
                                  }}
                                >
                                  {requirements.minLength ? <Check /> : <Close />}
                                  <Typography variant="body2" sx={{ ml: 1 }}>
                                    minimum 8 characters
                                  </Typography>
                                </Box>
                              </FormControl>
                              <ErrorMessage
                                component={"div"}
                                className="text-danger mt-1"
                                name="password"
                              />
                            </div>
                            <div className="col-6 mt-4">
                              <TextField
                                label="Repeat Password"
                                type="password"
                                autoComplete="off"
                                variant="standard"
                                className="w-100"
                                name="rep_password"
                                value={values?.rep_password}
                                onChange={(e) => {
                                  const value = e.target.value || "";
                                  setFieldValue("rep_password", value);
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                className="text-danger mt-1"
                                name="rep_password"
                              />
                            </div>
                            <div className="d-flex pt-5 pb-2 justify-content-center align-items-center">
                              <button
                                type="submit"
                                className="button_style px-5 me-2"
                              >
                                {loading ? <BtnLoader /> : "Update"}
                              </button>
                              <button
                                className="button_style px-5"
                                style={{ background: "#dc3545", color: "#fff" }}
                                onClick={(e) => {
                                  setshow_profileModel(false);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="horizontal_line" />
                            <div className="w-100 text-center">
                              <button
                                className="button_style px-5"
                                onClick={(e) => {
                                  localStorage.removeItem("model");
                                  localStorage.removeItem("loginUser");
                                  localStorage.removeItem("currency");
                                  localStorage.removeItem("flightSearchData");
                                  dispatch(asyncUserLogout(navigate));
                                  setshow_profileModel(false);
                                }}
                              >
                                <span>
                                  Sign Out <Logout className="fs-5" />
                                </span>
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileHeader;
