import React from "react";

import DiscreteSlider from "./slider";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { dropMenuProp } from "../../../../Utils/MenuProps";
import airlines from "../../../../Utils/airlines.json";
import AIRBLUE from "../../../../assets/images/airlineLogo/PA.png";
import AIRSIAL from "../../../../assets/images/airlineLogo/PF.png";
import SERENE from "../../../../assets/images/airlineLogo/ER.png";
import HITIT from "../../../../assets/images/airlineLogo/PK.png";
import TRAVELPORT from "../../../../assets/images/airlineLogo/TR.png";
import SABRE from "../../../../assets/images/airlineLogo/SB.png";
import FLYDUBAI from "../../../../assets/images/airlineLogo/FZ.png";
import OMANNDC from "../../../../assets/images/airlineLogo/WY.png";
import EMIRATES from "../../../../assets/images/airlineLogo/EK.png";
import FLYJINNAH from "../../../../assets/images/airlineLogo/9P.png";
import AMADEUS from "../../../../assets/images/airlineLogo/amadeusRounded.png";
import {
  setStops_Filters,
  set_Airline_selected_filter,
  set_GDS_selected_filter,
  set_Refundable_filter,
} from "../../../../redux/features/home/FlightSearchSlice";
import { MdFlight } from "react-icons/md";
import { TbCurrencyDollarOff } from "react-icons/tb";
import { PiCurrencyDollar } from "react-icons/pi";
import { FaAnglesUp, FaAngleUp } from "react-icons/fa6";
import { RiPlaneLine } from "react-icons/ri";

function FIlter() {
  const {
    stop_filters,
    gds_filter_drp,
    gds_filter,
    airline_Filter_drp,
    airline_filter,
    refundable_filter
  } = useSelector((state) => state.FlightSearch);
  const dispatch = useDispatch();

  const images = {
    AIRBLUE,
    AIRSIAL,
    SERENE,
    HITIT,
    TRAVELPORT,
    SABRE,
    FLYDUBAI,
    FLYJINNAH,
    OMANNDC,
    EMIRATES,
    AMADEUS,
  };
  // stops drp array
  const stopsArray = ["ALL", "NON STOP", "ONE STOP", "TWO STOP"];
  // sort by
  const sortBy = [
    { id: 1, value: "Price - Low to High" },
    { id: 2, value: "Price - High to Low" },
  ];

  const refundable = ["ALL", "REFUNDABLE", "NON-REFUNDABLE", "PER-AIRLINE"];
  let refundsLogo = {
    ALL: null,
    REFUNDABLE: <PiCurrencyDollar size={20} color="blue" style={{marginRight: '5px'}} />,
    "NON-REFUNDABLE": <TbCurrencyDollarOff size={20} color="red"  style={{marginRight: '5px'}} />,
    "PER-AIRLINE": <MdFlight size={20} color="red" style={{marginRight: '5px'}} />
  }
  let stopsLogo = {
    ALL: null,
    "NON STOP": <RiPlaneLine size={20} color="blue" style={{marginRight: '5px'}} />,
    "ONE STOP": <FaAngleUp size={20} color="blue" style={{marginRight: '5px'}} />,
    "TWO STOP": <FaAnglesUp size={20} color="blue" style={{marginRight: '5px'}} />
  }

  return (
    <div className="bg-white shadow-sm rounded-2 p-3 py-4">
      <div className="d-flex flex-wrap gap-5">
        {/* price accordian */}
        {/* <div className="col-1">
          <h6>Price</h6>
          <DiscreteSlider />
        </div> */}

        {/* STOPS accordian */}
        <div className="col-2">
          <FormControl fullWidth>
            <InputLabel id="Stops">STOPS</InputLabel>
            <Select
              id="stops"
              value={stop_filters}
              label="STOPS"
              onChange={(e) => {
                let value = e.target.value;
                dispatch(setStops_Filters(value));
              }}
              MenuProps={dropMenuProp}
            >
              {stopsArray.map((it) => (
                <MenuItem value={it} key={it}>
                  {stopsLogo[it]}
                  {it}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* GDS accordian */}
        <div className="col-2">
          <FormControl fullWidth>
            <InputLabel id="GDS">GDS</InputLabel>
            <Select
              id="GDS"
              value={gds_filter}
              label="GDS"
              onChange={(e) => {
                let value = e.target.value;
                dispatch(set_GDS_selected_filter(value));
              }}
              MenuProps={dropMenuProp}
            >
              {gds_filter_drp?.map((item) => (
                <MenuItem value={item} key={item}>
                  {item !== "ALL" ? (
                    <img
                      src={images[item?.replace(/ /g, '')?.toUpperCase()]}
                      alt="9P"
                      style={{
                        width: "20px",
                        height: "20px",
                        objectFit: "contain",
                        marginRight: "5px",
                      }}
                    />
                  ) : null} {item?.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* airline accordian */}
        <div className="col-2">
          <FormControl fullWidth>
            {/* <Autocomplete
              id="tags-standard"
              options={airline_Filter_drp}
              getOptionLabel={(option) => option}
              value={airline_filter}
              onChange={(event, newValue) => {
                dispatch(set_Airline_selected_filter(newValue));
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  {option !== "ALL" ? <img
                    src={`/NEW_LOGO/${option}.png`}
                    alt="9P"
                    style={{
                      width: "20px",
                      height: "20px",
                      objectFit: "contain",
                      marginRight: "5px"
                    }}
                  /> : null}
                  {option === "ALL" ? option : airlines?.find(({iata}) => iata === option)?.name}
                </li>
              )}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="SELECT AIRLINE"
                  placeholder="SELECT AIRLINE"
                />
              )}
            /> */}
            <InputLabel id="Airline">AIRLINE</InputLabel>
            <Select
              id="Airline"
              value={airline_filter}
              label="AIRLINE"
              onChange={(e) => {
                let value = e.target.value;
                dispatch(set_Airline_selected_filter(value));
              }}
              MenuProps={dropMenuProp}
            >
              {airline_Filter_drp?.map((item) => (
                <MenuItem value={item} key={item}>
                  {item !== "ALL" ? (
                    <img
                      src={`/NEW_LOGO/${item}.png`}
                      alt="9P"
                      style={{
                        width: "20px",
                        height: "20px",
                        objectFit: "contain",
                        marginRight: "5px",
                      }}
                    />
                  ) : null}
                  {item === "ALL"
                    ? item
                    : airlines?.find(({ iata }) => iata === item)?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Refundable non-Refundable */}
        <div className="col-2">
          <FormControl fullWidth>
            <InputLabel id="refundable_filter">REFUNDABLE</InputLabel>
            <Select
              id="refundable_filter"
              value={refundable_filter}
              label="REFUNDABLE"
              onChange={(e) => {
                let value = e.target.value;
                dispatch(set_Refundable_filter(value));
              }}
              MenuProps={dropMenuProp}
            >
              {refundable?.map((item) => (
                <MenuItem value={item} key={item}>
                  {refundsLogo[item]}
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/*  SORT BY  */}
        {/* <div className="col-2">
          <FormControl fullWidth>
            <InputLabel id="Sortby">SORT BY</InputLabel>
            <Select
              id="Sortby"
              value={sort_by_filter}
              label="SORT BY"
              onChange={(e) => {
                let value = e.target.value;
                dispatch(set_sort_by_filter(value));
              }}
              MenuProps={dropMenuProp}
            >
              {sortBy.map((it) => (
                <MenuItem value={it.id} key={it.id}>
                  {it.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div> */}
      </div>
    </div>
  );
}

export default FIlter;
