import React, { useEffect, useState } from "react";
import MultiAmadeusIteneries from "./MultiAmadeusIteneries";
import { useDispatch, useSelector } from "react-redux";
import { getSectorsName } from "../../../../../Utils/FlightSector";
import AmadeusLogo from "../../../../../assets/images/airlineLogo/amadeusRounded.png";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import moment from "moment";
import {
  codeShareAmadeus,
  ExclusiveSetors,
} from "../../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";
import { pricingairline } from "../../../../../Utils/airlinePricing";
import {
  CurrencyExchange,
  CurrencyCode,
} from "../../../../../Utils/currencyExchange";
import ConnectedFlight from "../../OneWaySearchResult/amadeus/ConnectedFlight";
import AmadeusMultiNoBrandFare from "./AmadeusMultiNoBrandFare";
import {
  set_Airline_filter_drp,
  setExpandFlightDetails,
  setpricingErrorNull,
} from "../../../../../redux/features/home/FlightSearchSlice";
import FareRulesAccordion from "../../OneWaySearchResult/amadeus/FareRules";
import { NODEURL } from "../../../../../redux/features/ApiConfig";

const MultiAmadeusCard = ({
  amadeusItem,
  selectedFlight,
  index,
  toggleDetail,
  isExpanded,
  setShowOtherOption,
  showOtherOption,
}) => {
  const [selectedTab, setSelectedTab] = useState("Fare Options");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set_Airline_filter_drp(amadeusItem?.validatingAirlineCodes?.at(0)));
  }, [])

  // calculate duration
  const timeDuration = (time1, time2) => {
    if (!time1 || !time2) {
      return null;
    }
    const depMoment = moment(time1);
    const arrMoment = moment(time2);
    const duration = moment.duration(arrMoment.diff(depMoment));
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;
  const [pricingData, setPricingData] = useState({})

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha ===
      amadeusItem?.validatingAirlineCodes?.at(0)
  );

  //airline
  const airline = addAirLinesData?.find(
    (it) => it.code_alpha === amadeusItem?.validatingAirlineCodes?.at(0)
  );

  const fligthSegment = amadeusItem?.itineraries?.flatMap(({ segments }) => [
    ...segments,
  ]);

  const calculatePassangerTotal = (fare) => {
    const price = pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(fare?.price?.base)),
      Number(CurrencyExchange(fare?.price?.total - fare?.price?.base)),
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        fare?.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment),
      fare?.fareDetailsBySegment[0]?.cabin === "ECONOMY",
      fligthSegment?.length,
      fare?.travelerType !== "HELD_INFANT"
    );
    return price;
  };

  const totalFarePrice = (fare) => {
    let total = 0;
    fare?.travelerPricings.forEach((travler) => {
      let travelerFare = calculatePassangerTotal(travler);
      total += travelerFare?.total_fare;
    })
    return TwoDecimalPoint(total);
  };

  // calculate the totalDiscount
  const totalDiscount = (fare) => {
    let total = 0;

    fare?.travelerPricings.forEach((travler) => {
      let travelerFare = calculatePassangerTotal(travler);
      total += travelerFare?.discount_given;
    })
    if (total > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
    }
  }

  const singPaxFareTotal = (fare, pax, travelerType) => {
    const travelerPricing = fare?.travelerPricings?.find(
      (tp) => tp?.travelerType === travelerType
    );
    if (!travelerPricing) return 0;

    const baseFare = CurrencyExchange(Number(travelerPricing?.price?.base));
    const tax =
      CurrencyExchange(
        Number(travelerPricing?.price?.total) - Number(travelerPricing?.price?.base)
      )
      ;
    const totalFare = pricingairline(
      airline,
      pricing,
      baseFare,
      tax,
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        travelerPricing?.fareDetailsBySegment?.at(0)?.class
      ),
      codeShareAmadeus(fligthSegment),
      travelerPricing?.fareDetailsBySegment[0]?.cabin === "ECONOMY",
      fligthSegment?.length,
      travelerPricing?.travelerType !== "HELD_INFANT"
    )?.total_fare;

    return TwoDecimalPoint(totalFare * pax);
  };

  const singPaxTotalFare = (fare, travelerType) => {
    const travelerPricing = fare?.travelerPricings?.find(
      (tp) => tp?.travelerType === travelerType
    );

    if (!travelerPricing) return 0;

    const baseFare = Number(CurrencyExchange(travelerPricing?.price?.base));
    const tax = Number(
      CurrencyExchange(
        travelerPricing?.price?.total - travelerPricing?.price?.base
      )
    );
    const totalFare = ~~pricingairline(
      airline,
      pricing,
      baseFare,
      tax,
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        travelerPricing?.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment),
      travelerPricing?.fareDetailsBySegment[0]?.cabin === "ECONOMY",
      fligthSegment?.length,
      travelerPricing?.travelerType !== "HELD_INFANT"
    )?.total_fare;

    return totalFare;
  };


  const amadeusPricingcall = async () => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/amadeus-pricing", {
        type: "flight-offers-pricing",
        flightOffers: [amadeusItem],
        agent_name: amadeusItem?.agent_name,
      });
      const pricingData = response?.data?.data;
      setPricingData(pricingData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="search_engin_result_box flight_card_shadow bg-white">
      <div
        className="shadow cursorpointer d-flex justify-content-center align-items-center"
        onClick={() => {
          dispatch(setpricingErrorNull(null));
          toggleDetail(index);
          if (!selectedFlight) {
            amadeusPricingcall();
          }
        }}
      >
        <div className="col-9">
          {amadeusItem?.itineraries?.map((itinery, index, array) => {
            return (
              <div
                key={index}
                style={{
                  borderBottom:
                    index === array?.length - 1
                      ? "none"
                      : "1px solid lightgray",
                }}
              >
                <MultiAmadeusIteneries
                  totalDiscount={totalDiscount}
                  array={array}
                  timeDuration={timeDuration}
                  segments={itinery?.segments}
                  amadeusItem={amadeusItem}
                  toggleDetail={toggleDetail}
                  index={index}
                  showOtherOption={showOtherOption}
                  setShowOtherOption={setShowOtherOption}
                  isExpanded={isExpanded}
                  totalFarePrice={totalFarePrice}
                  selectedFlight={selectedFlight}
                />
              </div>
            );
          })}
        </div>
        <div className="col-3 left-shadow text-center align-self-stretch pt-5">
          <div className=" pt-5">
          {amadeusItem?.fareRules?.rules?.some(({ category, maxPenaltyAmount }) => category === "REFUND" && maxPenaltyAmount) && <h6 className="text-success">Refundable</h6>}
          {amadeusItem?.fareRules?.rules?.some(({ category, notApplicable }) => category === "REFUND" && notApplicable) && <h6 className="text-danger">Non-Refundable</h6>}
          {!(amadeusItem?.fareRules?.rules?.some(({ category }) => category === "REFUND")) && <h6 className="text-danger">As Per Airline</h6>}
            <h6 className="text-danger">{totalDiscount(amadeusItem)}</h6>
            <div className="d-flex align-items-center justify-content-center pt-2">
              <h4>{`${CurrencyCode()} ${totalFarePrice(amadeusItem)}`}</h4>
              <img
                src={AmadeusLogo}
                alt=""
                width={40}
                height={40}
                className="ms-3 mb-1"
              />
            </div>
          </div>

          <button
            onClick={(e) => {
              toggleDetail(index);
            }}
            className="button_style w-75 mb-2 cursorpointer mt-3"
            type="button"
          >
            <div className="">
              <MenuOpenIcon className="me-1" />
              Flight Detail
            </div>
          </button>
          {!selectedFlight && (
            <>
              {amadeusItem?.otherOptions?.length > 0 && (
                <button
                  className="bg-white color_primary border_primary p-2 px-4 fs-5 rounded-2 cursorpointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setExpandFlightDetails(null));
                    setShowOtherOption(!showOtherOption);
                  }}
                >
                  <div>
                    <UnfoldMoreIcon className="me-1" />
                    {showOtherOption
                      ? "Hide Options"
                      : `${amadeusItem?.otherOptions?.length} More Options`}
                  </div>
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {isExpanded && (
        <div className="p-3">
          <div className="d-flex mb-4 mt-2 flight_details_tabs">
            {["Fare Options", "Flight Details", "Fare Rules"].filter(item => !(item === "Fare Rules" && selectedFlight)).map((item, index) => {
              const active = selectedTab === item;
              return (
                <button
                  key={index}
                  onClick={() => setSelectedTab(item)}
                  className={active ? "active" : ""}
                >
                  {item}
                </button>
              );
            })}
          </div>

          <div>
            {selectedTab === "Flight Details" && (
              <div className="row rounded-2 search_engin_result_box bg-white p-4">
                {amadeusItem?.itineraries?.map((itinery, index) => {
                  return (
                    <div
                      key={index}
                      className={`${index === 0 ? "" : "pt-4 "
                        } border-bottom pb-4`}
                    >
                      {itinery?.segments?.map((segment, idx, array) => {
                        return (
                          <div key={index}>
                            <ConnectedFlight
                              timeDuration={timeDuration}
                              amadeusItem={segment}
                              inx={idx}
                              index={index}
                              flightSegment={array}
                              segment="multi"
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {selectedTab === "Fare Options" && (
            <div className="row rounded-2 search_engin_result_box bg-white p-4">
              <AmadeusMultiNoBrandFare
                amadeusItem={amadeusItem}
                selectedFlight={selectedFlight}
                totalFarePrice={totalFarePrice}
                pricing={pricing}
                airline={airline}
                singPaxFareTotal={singPaxFareTotal}
                singPaxTotalFare={singPaxTotalFare}
                timeDuration={timeDuration}
                isCodeShare={codeShareAmadeus(fligthSegment)}
                isEconomy={
                  amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]
                    ?.cabin === "ECONOMY"
                }
                segmentLength={fligthSegment?.length}
                isExclusiveSector={ExclusiveSetors(
                  searchValues,
                  pricing,
                  amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]
                    ?.class
                )}
              />
            </div>
          )}

          <div>
            {selectedTab === "Fare Rules" && (
              <div className="rounded-2 search_engin_result_box bg-white p-4">
                <FareRulesAccordion
                  pricingData={pricingData}
                />
              </div>
            )
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiAmadeusCard;
