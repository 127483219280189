import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  ERCredentialsData: [],
  editErCredentials: null,
  loading: false,
  error: null,
};

// post ER API CREDENTIAL
export const asyncPostERApiCredentials = createAsyncThunk(
  "asyncPostERApiCredentials/post",
  async (values, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/er-api-credential`, values);
      console.log("er", response);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get ER API CREDENTIAL
export const asyncGetERApiCredentials = createAsyncThunk(
  "asyncGetERApiCredentials/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/er-api-credential`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get single ER API CREDENTIAL
export const asyncGetSingleERApiCred = createAsyncThunk(
  "asyncGetSingleERApiCred/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/er-api-credential/${id}`);

      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// Update ER API CREDENTIAL
export const asyncUpdateErCredential = createAsyncThunk(
  "asyncUpdateErCredential/patch",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/er-api-credential-update/${id}`,
        values
      );
      dispatch(asyncGetERApiCredentials());
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Update ER API CREDENTIAL
export const asyncDeleteErCredential = createAsyncThunk(
  "asyncDeleteErCredential/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/er-api-credential/${id}`);
      dispatch(asyncGetERApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const ErApiCredentialSlice = createSlice({
  name: "ErApiCredentialSlice",
  initialState,
  reducers: {
    editErCredentialNull: (state) => {
      state.editErCredentials = null;
    },
  },

  extraReducers: (builder) => {
    // Post ER Api Credentials
    builder.addCase(asyncPostERApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPostERApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.ERCredentialsData = [...state.ERCredentialsData, payload];
      }
    );
    builder.addCase(
      asyncPostERApiCredentials.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // Get ER Api Credentials
    builder.addCase(asyncGetERApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetERApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.ERCredentialsData = payload;
      }
    );
    builder.addCase(asyncGetERApiCredentials.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Get single ER Api Credentials
    builder.addCase(asyncGetSingleERApiCred.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetSingleERApiCred.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editErCredentials = payload;
    });
    builder.addCase(asyncGetSingleERApiCred.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update ER Api Credentials
    builder.addCase(asyncUpdateErCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateErCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateErCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // DElete ER Api Credentials
    builder.addCase(asyncDeleteErCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeleteErCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeleteErCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
export const { editErCredentialNull } = ErApiCredentialSlice.actions;
export default ErApiCredentialSlice.reducer;
