import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { NODEURL, URL2 } from '../ApiConfig';

// Async thunk for handling the void ticket patch request
export const voidTicket = createAsyncThunk(
  'voidTicket/patch',
  async ({id,gds}, { rejectWithValue }) => {
    try {
      const response = await NODEURL.patch(`/api/v1/b2b/voidTicket/${id}`, {
        gds,
        id,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const voidTicketSlice = createSlice({
  name: 'voidTicket',
  initialState: {
    loading: false,
    success: false,
    error: null,
    data: null,
  },
  reducers: {
    resetVoidTicketState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(voidTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(voidTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload;
      })
      .addCase(voidTicket.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export const { resetVoidTicketState } = voidTicketSlice.actions;
export default voidTicketSlice.reducer;
