import React, { useState } from "react";
import VisaSummary from "../Summary/VisaSummary";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import PaxDetail from "./PaxDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncPostVisaData,
  asyncUpdateVisaData,
} from "../../../../../redux/features/visa/VisaInvoiceSlice";
import { useLocation, useNavigate } from "react-router-dom";
import BtnLoader from "../../../../AppForm/BtnLoader";
import moment from "moment";
import { TextField } from "@mui/material";

// Custom validation for unique visa_number
const checkUniqueVisaNumber = (paxDetails) => {
  const visaNumbers = paxDetails.map((pax) => pax.visa_number);
  const uniqueVisaNumbers = new Set(visaNumbers);
  return uniqueVisaNumbers.size === visaNumbers.length;
};

const validationSchema = Yup.object().shape({
  paxDetail: Yup.array()
    .of(
      Yup.object().shape({
        f_name: Yup.string().required("First name is required"),
        l_name: Yup.string().required("Last name is required"),
        passport_no: Yup.string().required("Passport number is required"),
        dob: Yup.string().required("Date of birth is required").nullable(),
        // .max(new Date(), "Date of birth cannot be in the future"),
        visa_number: Yup.string().required("Visa number is required"),
      })
    )
    .test(
      "unique-visa-number",
      "Visa numbers must be unique",
      function (paxDetail) {
        return checkUniqueVisaNumber(paxDetail);
      }
    ),
  visa_type: Yup.number().required("Please select a visa type").nullable(),
});

function Visa({ initValues, permission, VoucherBooking }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location=useLocation()
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { visaType } = useSelector((state) => state?.visaType);
  const pkr = currencyData?.find((it) => it.curr_code === "PKR");
  const availableLimit =
    initValues?.agent?.credit_limit - initValues?.agent?.credit_used;
  const visaArray = visaType?.filter(
    (it) => it.country_id === initValues.visa_country
  );
  const obj = {
    ADT: "ADULT",
    CNN: "CHILD",
    INF: "INFANT",
  };
  const [message, setMessage] = useState(false);
  const initialValues = {
    ...initValues,
    totalAgent: 0,
    visa_type:
      VoucherBooking?.id ? VoucherBooking?.VisaBookingModels?.at(0)?.VisaTypesModel
        ?.id :"",
    visa_id:VoucherBooking?.id ? VoucherBooking?.VisaBookingModels?.at(0)?.id :"",
    voucher_id:VoucherBooking?.id ? VoucherBooking?.voucherData?.voucher_id :"",
    description: "",
    paxDetail: VoucherBooking?.id
      ? VoucherBooking?.VisaBookingModels?.at(0)?.pax_tables?.map(
          (pax, index) => ({
            pax_id: pax?.pax_id,
            pax_type: obj[pax.pax_type],
            pax_title: pax?.pax_title,
            f_name: pax?.fName,
            l_name: pax?.lname,
            dob: new Date(pax.dob?.split("T")?.at(0)),
            nationality: "",
            passport_no: pax?.pspt_cnic,
            currency: visaArray?.at(0)?.VisaTypesRatesModels?.at(0)?.currency
              ?.curr_code,
            cur_label: visaArray?.at(0)?.VisaTypesRatesModels?.at(0)?.currency
              ?.curr_code,
            roe: visaArray?.at(0)?.VisaTypesRatesModels?.at(0)?.roe,
            visa_number: pax?.tkt_number,
            //supllier
            curr_pay: pax?.ttl_sup_pay_s_cur,
            pkr_pay: pax?.ttl_sup_pay_d_cur,
            //agent
            curr_rec: pax?.ttl_agt_pay_s_cur,
            pkr_rec: pax?.ttl_agt_pay_d_cur,
          })
        )
      : [
          {
            pax_type: "ADULT",
            pax_title: "MR",
            f_name: "",
            l_name: "",
            dob: new Date(),
            nationality: "",
            passport_no: "",
            currency: pkr,
            cur_label: "PKR",
            roe: 1.0,
            visa_number: "",
            curr_pay: 0,
            curr_rec: 0,
            pkr_pay: 0,
            pkr_rec: 0,
          },
        ],
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, action) => {
        try {
          if (VoucherBooking?.id) {
            setMessage(true);
            await dispatch(
              asyncUpdateVisaData({ id: VoucherBooking?.id, body: values })
            );
            setMessage(true);
            setTimeout(() => {
              setMessage(false);
              navigate("/post-voucher");
            }, 3000);
          } else {
            setMessage(true);
            await dispatch(asyncPostVisaData(values));
            setMessage(true);
            setTimeout(() => {
              setMessage(false);
              navigate("/save-voucher");
            }, 3000);
          }
        } catch (error) {
          setMessage(false);
          console.error("Error submitting form", error);
        }
      }}
    >
      {({ values, setFieldValue, resetForm, errors, touched }) => {
        return (
          <Form>
            <div className="">
              <div className="section_box row py-4">
                <h4>Visa</h4>
                <div className="black_border rounded col-3 py-3 tax_pf">
                  {!initValues.visa_country ? (
                    <p className="box_title">Please Select visa country.</p>
                  ) : visaArray?.length ? (
                    visaArray?.map((item) => (
                      <div className="py-2" key={item.id}>
                        <label class="form-control">
                          <input
                            type="radio"
                            name="radio"
                            checked={values.visa_type === item.id}
                            onChange={(e) => {
                              setFieldValue(`visa_type`, item.id);
                              values?.paxDetail?.map((pax, index) => {
                                const visaRate =
                                  item.VisaTypesRatesModels?.at(0);
                                if (visaRate) {
                                  setFieldValue(
                                    `paxDetail[${index}].pax_type`,
                                    obj[visaRate.pax_type]
                                  );
                                  setFieldValue(
                                    `paxDetail[${index}].cur_label`,
                                    visaRate.currency?.curr_code || "PKR"
                                  );
                                  setFieldValue(
                                    `paxDetail[${index}].currency`,
                                    visaRate.currency?.curr_code || "PKR"
                                  );
                                  setFieldValue(
                                    `paxDetail[${index}].roe`,
                                    visaRate.roe || 1.0
                                  );
                                  setFieldValue(
                                    `paxDetail[${index}].pkr_pay`,
                                    visaRate.pkr_pay || 0
                                  );
                                  setFieldValue(
                                    `paxDetail[${index}].pkr_rec`,
                                    visaRate.pkr_rec || 0
                                  );
                                  setFieldValue(
                                    `paxDetail[${index}].curr_pay`,
                                    visaRate.curr_pay || 0
                                  );
                                  setFieldValue(
                                    `paxDetail[${index}].curr_rec`,
                                    visaRate.curr_rec || 0
                                  );
                                }
                              });
                            }}
                            disabled={VoucherBooking?.id}
                          />
                          <span className="fs-6">{item.visa_type}</span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <p className="box_title text-danger">
                      Visa is not added for the selected visa country.
                    </p>
                  )}
                  {touched.visa_type && errors.visa_type && (
                    <div className="text-danger">{errors.visa_type}</div>
                  )}
                </div>

                <div className="col-9">
                  <PaxDetail
                    setFieldValue={setFieldValue}
                    values={values}
                    touched={touched}
                    errors={errors}
                  />
                </div>
              </div>
              <VisaSummary values={values} setFieldValue={setFieldValue} />
              {location?.state?.postVoucher ? (
                <div
                  className="d-flex gap-4  flex-column "
                  style={{ width: "70%", marginLeft: 300 }}
                >
                  <div className="d-flex gap-4">
                    {" "}
                    <div className="fs-5 mt-4 ">Action:</div>
                    <div className="col-10">
                      <TextField
                        label="Description"
                        variant="standard"
                        name="description"
                        className="w-100"
                        value={values.description}
                        autoComplete="off"
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("description", value.toUpperCase());
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    {errors.description ? (
                      <div className="text-danger">{errors.description}</div>
                    ) : null}{" "}
                  </div>
                </div>
              ) : null}

              {/* Submit */}
              <div className="d-flex flex-column justify-content-center align-item-center">
                <div className=" d-flex  justify-content-center align-item-center">
                  <button
                    className="border px-4 mt-4 selectbtn_style"
                    type="button" // Changed to "button" to prevent form submission
                    onClick={() => resetForm()}
                    disabled={!permission}
                  >
                    Clear Form
                  </button>

                  <div className="">
                    {message ? (
                      <div className="border px-4  mt-4 selectbtn_style">
                        <BtnLoader />
                      </div>
                    ) : (
                      <button
                        className="border px-4 mt-4 selectbtn_style"
                        type="submit"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
                <div className="text-center my-2">
                  {Array.isArray(errors?.paxDetail)
                    ? ""
                    : errors?.paxDetail && (
                        <div className="text-danger">{errors?.paxDetail}</div>
                      )}
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default Visa;
