import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import iata from "../../../../assets/images/iata.png";
import { apiUrl } from "../../../../Utils/configUrl";
import moment from "moment";
import { fetchOpeningBalance } from "../../../../redux/features/OpeningBalance/openingBalance";
const BalanceParent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);
  // const { getAdminData } = useSelector((state) => state.newAgency);
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const firstColor = adminAgencyData?.first_color;
  const { data, openBalance, opening } = useSelector(
    (state) => state?.openingBalance
  );

  const { id } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const op = queryParams.get("op");
  const getAdminData = queryParams.get("getAdminData");
  const acc_title = queryParams.get("acc_title");
  const fromDate = queryParams.get("fromDate");
  const toDate = queryParams.get("toDate");
  const formattedFromDate = moment(fromDate).format("ddd DD-MMM-YY/HH:mm");

  const formattedToDate = moment(toDate).format("ddd DD-MMM-YY/HH:mm");

  // Filter and group accounts by parent and child
  const group = (accounts) => {
    if (!accounts) return [];

    const parentAccount = accounts.find((acc) => acc.acc_id === Number(id));
    const childAccounts = accounts.filter(
      (acc) => acc.acc_parent === Number(id)
    );
    return [parentAccount, ...childAccounts];
  };

  useEffect(() => {
    dispatch(
      fetchOpeningBalance({
        ope: "BALANCE",
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      })
    );
  }, []);

  const handleTitleClick = (account) => {
    // Prepare query parameters
    const queryParams = new URLSearchParams({
      acc_title: account.acc_title,
      fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      op: op,
      getAdminData:getAdminData
    }).toString();

    // Construct the URL
    const url = `/journal-Ledger/${account.acc_id}?${queryParams}`;

    // Open the URL in a new tab
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div
      //  className="w-100"
      className="d-flex  flex-column gap-4 bg-white "
      style={{ width: "100%" }}
    >
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />

          {/* <button
            onClick={(e) => navigate("/balance-sheet")}
            className="bg_primary px-4 py-1 rounded text-white"
          >
            Back
          </button> */}
        </div>
      </div>

      <div className="d-flex flex-column gap-3  mt-4" ref={componentRef}>
        <div className="d-flex justify-content-around align-items-center">
          <div style={{ width: "220px" }}>
            <img
              src={`${apiUrl}/${getAdminData}`}
              alt="logo"
              style={{ width: "80%", height: "auto" }}
            />
          </div>
          <div
            className="d-flex fw-bold fs-6  flex-column gap-2 align-items-center"
            style={{ width: "1200px" }}
          >
            <span className=" text-black">
              {" "}
              {/* {userData?.agents_comp?.agent_name} */}
            </span>
            <span className=" text-black">
              {" "}
              {userData?.agents_comp?.agent_name}
            </span>
            <span className="text-black">
              {" "}
              {userData?.agents_comp?.address
                ?.split("]")
                ?.slice(0, 3)
                ?.join(" ")}{" "}
            </span>
            <span className=" text-black"> Email: {userData?.email}</span>
            <span className=" text-black"> Contact:{userData?.mobile_no}</span>
            <span className=" text-black">
              {" "}
              From: {moment(fromDate)?.format("DD-MMM-YY").toUpperCase()}
            </span>
            <span className=" text-black">
              {" "}
              To: {moment(toDate)?.format("DD-MMM-YY").toUpperCase()}
            </span>
          </div>
          <div style={{ width: "200px" }}>
            <img
              src={iata}
              alt="iata"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white fw-bold my-2"> {acc_title}</span>
        </div>
        {/* <h1>Account Details Page</h1> */}
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="d-flex flex-column gap-3  mt-4 align-items-center"
            style={{ width: "97%", height: "20%" }}
          >
            <table
              className="table table-bordered  table-sm table-striped"
              style={{ width: "100%", height: "20%", fontSize: "0.8rem" }}
            >
              <thead>
                <tr>
                  <th>Account Title</th>
                  <th>Debit Amount</th>
                  <th>Credit Amount</th>
                </tr>
              </thead>
              <tbody>
                {acc_title &&
                  data &&
                  group(data?.accountTotals)
                    ?.filter((account) => account?.acc_title !== acc_title)
                    ?.map((account) => (
                      <tr key={account?.acc_id}>
                        <td
                          className="acc-title hover-overlay"
                          onClick={() => handleTitleClick(account)}
                          style={{ cursor: "pointer" ,color: "blue"}}
                          
                        >
                          {account?.acc_title}
                        </td>
                        <td>
                          {op
                            ? account?.closingDebitAmount
                            : account.activityDebitAmount}
                        </td>
                        <td>
                          {op
                            ? account?.closingCreditAmount
                            : account.activityCreditAmount}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceParent;
